import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { Row, Container, Col, Button, Form, Modal  } from 'react-bootstrap';
import Header from './Header';
import Usermenu from '../Usermenu';
import { Redirect, NavLink } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import Breadcrumbs from '../Breadcrumbs.js';
import Fade from 'react-reveal/Fade';
import { security } from '../../helpers/Security';
import * as loadedcontent from '../loadcontent';
import axios from 'axios';
import parse from "html-react-parser";
import BtnLightbox from "../../helpers/btnLightbox";
import BtnFavorite from "../../helpers/btnFavorite";
import Lottie from 'lottie-react-web';
import animationLoader from '../../assets/loading_stats.json';
import { store } from '../../redux/store';
let storeuser = ''
let emptyData = false

class Prevdownloads extends Component {
    constructor(props) {
        super(props);
        this.handleRadio = this.handleRadio.bind(this);
        this.downloadFile = this.downloadFile.bind(this);
        this.showLicense = this.showLicense.bind(this);
        //this.handleShowMore = this.handleShowMore.bind(this)
        this.state = {
            redirect: security(),
            keymessageout: "",
            classkeymessageout: "",
            defaultlang: "pt",
            action:0,
            data:[],
            agreedFiles:[],
            currentFile:'',
            modalLicense: false,
            contLicense: '',
            content: [],
            /*************/
            itemsLenght: 0,
            showItems: 5,
            loadItems: 3,
        };
    }

    handleRadio(e){
        e.target.classList.toggle("active")
        const idFile = e.target.getAttribute("id")  
        let allfiles= this.state.agreedFiles
        let doesexist = allfiles.filter(e => e == idFile);
        if( doesexist.length > 0 ){
            allfiles = allfiles.filter(e => e != idFile);
            this.setState({agreedFiles:[...allfiles]})
        }else{
            allfiles = allfiles.filter(e => e != idFile);
            this.setState({agreedFiles:[...allfiles, idFile]})
        }
        
    }

    returnImage(image){
        let img;
        try {
            if (image.tipo_ficheiro==1)
                img =global.src +"ataimg/tb/"+image.thumb
            else
                img =global.src +"atavid/tb/"+image.thumb
        } catch (err) {
            img =""
            
        }
        
        return img
    }

    goTo(file){
        window.open(global.src+"downloads/"+file.data)
        this.loadData()
    }

    async loadData(){
        let tdata= {idregisto:storeuser["idregisto"],lang:store.getState()["getlang"]["idlingua"]}
        let data= await loadedcontent.global_postaction( "actionPrevDownloads",tdata,this.state.action )
        let theItems = [];
        let c_cont = 0;

        if(data.data.length === 0){
            emptyData = true;
        }
        this.setState({
            itemsLenght: data.data.length
        })
        for (let index in data.data){
            if( c_cont < this.state.showItems ){
                theItems.push(
                    data.data[index]
                )
            }
            c_cont++;
        }
        this.setState({data:theItems, loader: false})  
        
    }

    downloadFile(e){
        const btId = e.target.getAttribute("class").split("download-")[1]  
        let allfiles= this.state.agreedFiles
        let isInlist = allfiles = allfiles.filter(e => e ==  btId) 
        ReactDOM.render('', document.getElementById('warning-'+btId));
        if(isInlist.length>0){
            this.compressFiles(btId)
        }else{
            let error = <><span className="icon-warning"></span>{this.props.oestado.var_globalcontent["data"]["traducoes"]['verificar_aceito_termos']['palavra_traducao']}</>
            ReactDOM.render(error, document.getElementById('warning-'+btId));
        }   
    }  

    compressFiles = async (btId) => {
        const config = {
            onUploadProgress:(progressEvent) => {
            var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            this.setState({progressBar:percentCompleted})
            },
            headers: {
                'Content-Type': 'application/json'
            }
        }
        let tdata =   {idfiles:[btId],idregisto:storeuser["idregisto"],type:1}

        let crud = await loadedcontent.global_postaction( "actionFavoritos", tdata, 4 );

        if ((crud['data'] != undefined)  && (crud['data'].length >0)){
            axios.post(global.url+'compress.php', crud['data'], config)
            .then(res => this.goTo(res))
            .catch(err => console.log(err))
        
        }
    }

    showLicense = (license) => {
        //console.log(license);
        this.setState({
            modalLicense: true,
            contLicense: parse(license)
        })
    }

    closeModalHandler = () => {
        this.setState({
            modalLicense: false,
            contLicense: '',
        })
    }

    componentDidMount() {
        storeuser = store.getState()["getatauser"]["userdata"];
        if( storeuser != undefined ){
            this.loadData()
        }
    }       

    fetchMoreData = () => {
        this.setState({ showItems: (this.state.showItems + this.state.loadItems), loader: true});
        setTimeout(() => {
            this.loadData();
        }, 750);
    };
        
render() { 
    if( this.state.redirect == true ){
        return <Redirect to={"/"} />
    }
    return (
        <>
            <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} gcontent={this.props.gcontent} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} theheader="default" />
            <Container className="content contentPage">
                <Breadcrumbs {...this.props} gcontent={this.props.gcontent}/>
                <div className="titlePage">{this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b></div>
                <Row className="contentOffice">
                    <Col className="menuDrop">
                        <Usermenu gcontent={this.props.gcontent} />
                    </Col>
                    <Col>
                        <h3 className="contentTitle">{this.props.gcontent.data.traducoes['prevdownloads']['palavra_traducao']}</h3>
                        {this.state.validuserpass == false && 
                            <span class={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                        }
                        <Container className="mylist">
                            {this.state.data != '' ? (
                                <>  
                                    <InfiniteScroll
                                        dataLength={this.state.data.length}
                                        next={this.fetchMoreData}
                                        hasMore={true}
                                        style={{overflow: 'none'}}
                                        scrollThreshold={0.2}
                                    >        

                                        {this.state.data.map((files, index) => {
                                            let img = this.returnImage(files)

                                            let today = new Date()
                                            let tmpExpData = files.expiry_date.split('-');
                                            let ExpData = new Date(tmpExpData[2], (tmpExpData[1] - 1), tmpExpData[0]);
                                            let data_expira = Math.floor(( Date.parse(ExpData) - Date.parse(today) ) / 86400000); 

                                            let expiry = false;
                                            if (today > ExpData && files.expiry_date !== "00-00-0000")
                                                expiry = true

                                            return(
                                                <Fade key={files.idupload_ficheiros}>
                                                    <Row className="mylistRow">
                                                        <Col className="mylistImage" xs="8" md="7" lg="3">
                                                            <div className="itemWrapper">
                                                                <div className="itemWrapper_hover">
                                                                    <NavLink className="itemLink" to={"page/" + files.idupload_ficheiros}>
                                                                        <div className="itemType">{((files.tipo_ficheiro == 1)? (<span className="icon-typeImg"></span>) : (<span className="icon-typeVideo"></span>))}</div>
                                                                    </NavLink>
                                                                    <div className="itemWrapper_actions">
                                                                        <BtnFavorite idPhoto={files.idupload_ficheiros} {...this.props} />
                                                                        <BtnLightbox type={((files.tipo_ficheiro == 1) ? 'photo' : 'video')} source={files.tipo_ficheiro == 1 ? (global.src + 'ataimg/tb/' + files.thumb) : (global.src + 'atavid/prev/' + files.videopreview)} />
                                                                    </div>
                                                                </div>
                                                                <img id={files.id_ficheiro} src={img}  width="" height="" alt={files.titulo} />
                                                            </div>
                                                        </Col>
                                                        <Col className="mylistTitle" xs="8" md="7" lg="3">
                                                            <div className="mylistDeadlines">
                                                                <div className="downDate">{this.props.gcontent.data.traducoes['downloaded']['palavra_traducao']}: {files.downloaded_date}</div>
                                                                { !expiry && files.expiry_date !== "00-00-0000" && (<div className="expDate">{this.props.gcontent.data.traducoes['download_expira']['palavra_traducao']}: {files.expiry_date}</div>)}
                                                            </div>
                                                            <h2 className="mylistTitle_title">{files.titulo}</h2>
                                                        </Col>
                                                        { expiry ? (
                                                            <>
                                                                <Col className="mylistTerms" xs="8" md="7" lg="4">
                                                                    {expiry}
                                                                    <div className="mylistTermsWrapper_error licenseExp">
                                                                        <span className="icon-warning"></span>{parse(this.props.oestado.var_globalcontent["data"]["traducoes"]['img_expired']['palavra_traducao'])}
                                                                    </div>
                                                                </Col>
                                                            </>
                                                        ):(
                                                            <>
                                                                <Col className="mylistTerms" xs="8" md="7" lg="4">
                                                                    <div className="mylistTermsWrapper">
                                                                        <div className="customRadioBtn"  id={files.idupload_ficheiros} onClick={this.handleRadio}></div>
                                                                        {store.getState()["getlang"]["idlingua"] == 'pt' ? (
                                                                            <p>Declaro que aceito a <span className="licenseBtnmodal" onClick={() => this.showLicense(files.termos_condicoes)}>Licença, os Termos e Condições</span> deste ficheiro.</p>
                                                                        ):(
                                                                            <p>I do accept the <span className="licenseBtnmodal" onClick={() => this.showLicense(files.termos_condicoes)}>License, Terms and Conditions</span> of this file.</p>
                                                                        )}
                                                                    </div>
                                                                    <div className="mylistTermsWrapper_error" id={"warning-"+files.idupload_ficheiros}>
                                                                    </div>
                                                                </Col>
                                                                <Col className="mylistAcceptance" xs="4" md="5" lg="2">
                                                                    <div className="downButton">
                                                                        <div className={"customSubmitBtn violet download-"+files.idupload_ficheiros} onClick={this.downloadFile} >
                                                                            <span className={"d-none d-xl-block download-"+files.idupload_ficheiros}>Download</span>
                                                                            <span className="d-block d-xl-none"><i className="fas fa-download"></i></span>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </>
                                                        ) }
                                                    </Row>
                                                </Fade>
                                            )                
                                        })}
                                    </InfiniteScroll>
                                    {this.state.loader && this.state.showItems <= this.state.itemsLenght &&
                                        <div className="galleryMore">
                                            <div id="loadingStats"><Lottie options={{ animationData: animationLoader }} /></div>
                                        </div>
                                    }
                                </>
                            ):(
                                <>
                                    {emptyData === false ? (
                                            <div id="loadingContent"><Lottie options={{ animationData: animationLoader }} /></div>
                                        ):(
                                            <Row className="mylistRowEmpty">
                                                <Col className="mylistEmpty">{this.props.gcontent.data.traducoes['emptyList']['palavra_traducao']}</Col>
                                            </Row>
                                        )
                                    }
                                </>
                            ) }
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Modal className="modal modalRegist modalLicense p-0" show={this.state.modalLicense}>
                <Container className="registHeader">
                    <div className="registHeader-close" onClick={this.closeModalHandler}>
                        <span className="icon-close-icon"></span> {this.props.oestado.var_globalcontent["data"]["traducoes"]['close']['palavra_traducao']}
                    </div>
                </Container>
                <Container className="licenseContent">
                    {parse(this.state.contLicense)}
                </Container>
            </Modal>
        </>
    );
  };

}

export default Prevdownloads;