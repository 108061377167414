import React, { Component } from 'react';
import ReactDOM from 'react-dom'
import { Row, Container, Col, Button, Form, Nav, Tab, ProgressBar } from 'react-bootstrap';
import parse from 'html-react-parser';
import Usermenu from '../Usermenu';
import Breadcrumbs from '../Breadcrumbs';
import Pagination from '../Pagination';
import { validate, File } from "../../helpers/Validationfuncs";
import { security } from '../../helpers/Security';
import Header from './Header';
import axios from 'axios';
import { Redirect, NavLink } from 'react-router-dom';
//-----------------
import Loader from "../../helpers/Loader";
import respok from '../../assets/response_ok.json';
import resperror from '../../assets/response_error.json';
//-----------------
import Lottie from 'lottie-react-web';
import animationLoader from '../../assets/loading_stats.json';
import * as loadedcontent from '../loadcontent';
//--------------Data table-----------------
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import FilterComponent from '../FilterComponent';
import 'react-data-table-component-extensions/dist/index.css';
import { store } from '../../redux/store';

/*
 NOTAS CSS
 - ALTERAR PROGRESSBAR
 - LISTAGEM DE FICHEIROS COLOCAR O TIPO (IMAGEM/VIDEO) NO THUMBNAIL (ICON)
*/
const api = global.api

let uniqueIlhas= ''
let storeuser = '' 
let lang=''
let headerTable=[]

class Upload extends Component {
    _indexcats = -1;
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        //this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.cleanContent = this.cleanContent.bind(this);
        this.handleFiles = this.handleFiles.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handlePerRowsChange = this.handlePerRowsChange.bind(this);
        this.setFilterText = this.setFilterText.bind(this);
        this.helploop = this.helploop.bind(this);

        this.upload_ata_files = this.upload_ata_files.bind(this);
  
        this.state = {
            catsList: [],
            profileList: [],
            redirect: security(),
            submitlabel:this.props.gcontent["data"]["traducoes"]["bt_inserir"]["palavra_traducao"],
            socialimage: "",
            isLoading:true,
            fileForm: null, 
            allFiles:[],
            inputList:[],
            action: 1,
            asredessociais: null,
            fileName: '',
            loading: false,
            delmess: false,
            registoCRUD: false,
            keymessageout: "",
            classkeymessageout: "",
            videoinProgress: '',
            keyTab: "list",
            filesList:[],
            subcategoriasSelected: [],
            subprofileSelected: [],
            progressBar:0,
            osdados:[],
            fileCats:0,
            fileProfiles:0,
            totalRows:0,
            rowspPage:10,
            searchstring:"",
            timesearch:true,
            formvalidation: {
                formsubmited: false,
                titulo: [false, "", ["noempty"]],
                tags: [false, "", ["noempty"]],
                categoria: [false, [], ["noempty"]],
                profile: [false, [], ["noempty"]],
                licenca: [false, "", ["noempty"]],
                licencaEN: [false, "", ["noempty"]],
                tituloEN: [false, "", ["noempty"]],
                descricao: [false, "", ["noempty"]],
                descricaoEN: [false, "", ["noempty"]],
                tagsEN: [false, "", ["noempty"]],
                cedencia: [false, 0, ["noempty"]],
                ilha: [false, "", ["noempty"]],
                localidade: [true, "", []],
                autor: [false, "", ["noempty"]],
            },
            idFile: 0,
            formvalues: {
                titulo: '', 
                tags: '',
                categoria: [],
                profile: [],
                profiles: [],
                questions: [],
                subcategorias:[],                
                subprofile: [],                
                licenca: '',
                licencaEN: '',
                tituloEN: '',
                descricao: '',
                descricaoEN: '',
                tagsEN: '',
                visivel: 0,
                cedencia: 0,            
                ilha: '',
                localidade: '',
                autor: '',
            },
           

            ascolunas:[
                {minWidth: "125px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['image']['palavra_traducao'], selector: row => row["imagem"], sortable: false },
                {minWidth: "125px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['title']['palavra_traducao'], selector: row => row["titulo"], sortable: true, },
                {minWidth: "125px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['username']['palavra_traducao'], selector: row => row["username"], sortable: true},
                {minWidth: "100px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['ficheiro_visivel']['palavra_traducao'], selector: row => row["visivel"], sortable: true, },
                {minWidth: "125px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['resolution']['palavra_traducao'], selector: row => row["resolucao"], sortable: true, },
                {minWidth: "125px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['size']['palavra_traducao'], selector: row => row["tamanho"], sortable: true, },
                {minWidth: "100px", wrap: true, name: " ", selector: row => row["crud"], sortable: false, cell: d => <div>
                                                        <span className="table-actionsItem" title="Editar" onClick={() => { this.handleEdit(d.id); this.changeTab("form") }} >
                                                            {" "} <i className="far fa-edit"></i>{" "}
                                                        </span>
                                                        {store.getState()["getatauser"]["userdata"]["admin"]==1 && (
                                                            <span className="table-actionsItem" title="Eliminar" onClick={() => this.handleDeleteFile(d.id, null)} >
                                                                {" "}<i className="far fa-trash-alt"></i>{" "}
                                                            </span>
                                                        )}
                                      </div> }
            ]

        };
    }

    cleanContent(){
        ReactDOM.render('', document.getElementById('formoutput'))
        this.setState({ 
            catsList: [],
            profileList:[],
            formvalidation: {
                formsubmited: false,
                titulo: [false, "", ["noempty"]],
                tags: [false, "", ["noempty"]],
                categoria: [false, [], ["noempty"]],
                profile: [false, [], ["noempty"]],
                licenca: [false, "", ["noempty"]],
                licencaEN: [false, "", ["noempty"]],
                tituloEN: [false, "", ["noempty"]],
                descricao: [false, "", ["noempty"]],
                descricaoEN: [false, "", ["noempty"]],
                tagsEN: [false, "", ["noempty"]],
                cedencia: [false, 0, ["noempty"]],
                ilha: [false, "", ["noempty"]],
                localidade: [true, "", []],
                autor: [false, "", ["noempty"]],
            },
            idFile: 0,
            formvalues: {
                titulo: '', 
                tags: '',
                categoria: [],
                profile: [],
                profiles: [],
                subcategorias: [],
                subcategoriasSelected: [],
                subprofileSelected: [],
                subprofile:[],
                questions: [],
                profiles: [],                
                licenca: '',
                licencaEN: '',
                tituloEN: '',
                descricao: '',
                descricaoEN: '',
                tagsEN: '',
                visivel: 0,
                cedencia: 0,            
                ilha: '',
                localidade: '',
                autor: '',
            },
            submitlabel: this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao'],
            action: 1,
            fileName: '',
            fileForm: '',
            image:'',
            subcategoriasSelected: [],
            subprofileSelected: [],
            progressBar:0,
            fileCats:0,
            fileProfiles:0,
            videoinProgress: '',
            keymessageout: "",
            classkeymessageout: "",
            registoCRUD: false,
            loading: false,
            //totalRows:0,
        });
    }

    handleFile(event) {
        event.preventDefault()
        let file = event.target.files;
        this.setState({ 
            fileForm: file, 
            fileName: file[0].name
        });
    }

    changeTab = (keyTab) => {
        if (keyTab == 'list'){
            this.cleanContent()
            //this.handleFiles()    
        }
        this.setState({ keyTab });
    };

    handleChange(event) {
        let nameState=event.target.name
        let valueState=event.target.value
        let tmpvalues = this.state.formvalues
        if (nameState == 'cedencia'){
            tmpvalues[nameState] = parseInt(valueState)
            //this.setState({formvalues:{[nameState]: parseInt(valueState)}}); 
        }else{
            tmpvalues[nameState] = valueState
            //this.setState({formvalues:{[nameState]: valueState}}); 
        }    
        this.setState({formvalues:tmpvalues})
    }

    loadProfilesEdit(idFile){
        let profilesSub=[]
        if (this.state.fileProfiles!=0){

            let profiles = this.state.fileProfiles.filter(a => a.idupload_ficheiros == idFile  && (a.ididiomas == lang && (a.subidioma==lang || a.subidioma==null)))
            let indexcat=0 
            profiles.map((prof, i) => {
                profilesSub.push(
                    <div id={"hideme_prof-"+prof.idupload_ficheiros_perfis} key={"hideme_prof-"+i}>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="m-0">
                                    <Form.Control as="select" onChange={this.onprofilechange}  id={"prof-"+indexcat} >
                                        <option key={'profedit-'+prof.idperfis}>{prof.nome_perfil}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col lg={5}>
                                <Form.Group className="m-0">
                                    <Form.Control as="select" id={"subprof-"+indexcat}  disabled={!this.state.formvalues.subprofile.length && 'disabled'} onChange={this.onsubProfilechange}>
                                        <option key={'subprofedit-'+prof.idperfils_sub}>{prof.nome_perfil_sub && parse(prof.nome_perfil_sub)}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col lg={1} className="flexedCenter">
                                <div onClick={() => { this.handleDeleteEdit(prof.idupload_ficheiros_perfis,1)}} className="btn-box"><span className="icon-close-icon"></span></div>
                            </Col>
                        </Row> 
                    </div>
                ) 
                indexcat++ 
            })
            return profilesSub
        }
        return null
    }

    loadCatsEdit(idFile){
        let catSub=[];
        if (this.state.fileCats!=0){
            let categorias = this.state.fileCats.filter(a => a.idupload_ficheiros == idFile && (a.ididiomas == lang && (a.subidioma==lang) || (a.idsubcategorias==0)))
            let indexcat=0 
            categorias.map((cats) => {
                catSub.push(
                    <div id={"hideme-"+cats.idupload_ficheiros_categorias} key={"hideme-"+cats.idupload_ficheiros_categorias}>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="m-0">
                                    <Form.Control as="select" onChange={this.oncatschange}  id={"cat-"+indexcat} >
                                        <option key={'catedit-'+cats.idcategoria}>{cats.nome_categoria}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col lg={5}>
                                <Form.Group className="m-0">
                                    <Form.Control as="select" id={"subcat-"+indexcat}  disabled={!this.state.formvalues.subcategorias.length && 'disabled'} onChange={this.onsubCatschange}>
                                        <option key={'subcatedit-'+cats.idsubcategorias}>{cats.nome_subcategoria}</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col lg={1} className="flexedCenter">
                                <div onClick={() => { this.handleDeleteEdit(cats.idupload_ficheiros_categorias,0)}} className="btn-box"><span className="icon-close-icon"></span></div>
                            </Col>
                        </Row> 
                    </div>
                )
                indexcat++
            })
            return catSub
        }
        return null
    }

    async handleDeleteEdit(idline,type){
        /*
        type - 0 - Categorias
               1 - Profile        
        */
        let tdata={idline:idline,type:type}
        let crud = await loadedcontent.global_postaction( "actionUserFiles", tdata , 4)
        if(type==0) ReactDOM.render('', document.getElementById('hideme-'+idline))
        if(type==1) ReactDOM.render('', document.getElementById('hideme_prof-'+idline))
    }
    async handleDeleteFile(id, type) {
        if( id > 0 ){
            this.setState({ idFile: id, delmess: true });
        }

        if( type == 0 ){
            this.setState({ action: 2, delmess: false });
        }else if( type == 1 ){
            this.setState({ action: 2, delmess: false });
            this.setState({ loading: !this.state.loading });
            setTimeout(
                async function () {
                    let tdata = {delete_key:id, iduser: storeuser["idregisto"]};
                    let crud = await loadedcontent.global_postaction( "actionUserFiles", tdata , 2)
                    if( crud["error"] == null ){ 
                        this.setState({ classkeymessageout: "success-out" , keymessageout: crud["data"] });
                        this.setState({ registoCRUD: true });
                    }else{
                        this.setState({ classkeymessageout: "error-out" , keymessageout: crud["error"] });
                        this.setState({ registoCRUD: true });
                    } 
                    //this.props.linguafunc(store.getState()["getlang"]["idlingua"]);
                    this.handleFiles();//Esta função não está fazendo nada aqui
                    this.setState({ loading: !this.state.loading });//Não está fazendo nada aqui
                }.bind(this),
                500
            );
        }
    }

    async handleEdit(id) {
        this.cleanContent();
        let categorias = []
        let profiles = []
        //let tdata = this.state.allFiles
        //let filterVal = tdata.data.images.filter(a => a.id_ficheiro == id);

        let searchprm = { iduser: storeuser["idregisto"] , iduploadficheiro: id };
        let response = await loadedcontent.global_postaction( "actionUserFiles" , searchprm , 0 ); 
        let filterVal = response.data.images.filter(a => a.id_ficheiro == id);

        let img=this.returnImage(filterVal[0],350)
        try{
            categorias = response.data.categorias.filter(a => a.idupload_ficheiros == id && a.ididiomas == lang && ( a.subidioma == lang || a.subidioma == null ) )
        }catch{
            categorias =''
        }

        try{
            profiles = response.data.profile.filter(a => a.idupload_ficheiros == id && a.ididiomas == lang && ( a.subidioma == lang || a.subidioma == null ) )
        }catch{
            profiles =''
        }

        this.setState({
            action: 3,                        
            fileCats:categorias,
            fileProfiles:profiles,
            submitlabel:this.props.gcontent["data"]["traducoes"]["bt_actualizar"]["palavra_traducao"],
            image:img,
            idFile:id,
            formvalues: {
                subcategorias:[],                
                subprofile: [],   
                questions: [],
                profiles: [],
                profile: [],
                categoria: [],
                titulo:filterVal[0].titulo, 
                tags: filterVal[0].tags,             
                licenca:filterVal[0].termos_condicoes,
                licencaEN:filterVal[1].termos_condicoes,
                tituloEN:filterVal[1].titulo,
                descricao:filterVal[0].descricao,
                descricaoEN:filterVal[1].descricao,
                tagsEN:filterVal[1].tags,
                visivel: filterVal[0].visivel,
                cedencia:filterVal[0].anos_cedencia,    
                ilha:filterVal[0].ilha,
                localidade:filterVal[0].localidade,
                autor:filterVal[0].autor,
            },
        })
    }

    returnImage(image,width=150){
        let img
        
        try {
            if (image.tipo_ficheiro==1)
                img = <NavLink to={'/page/' + image.id_ficheiro}><div className="wrapperImgUploads"><img src={global.src+"ataimg/tb/"+image.thumb} width={width} /><span className="icon-typeImg"></span></div></NavLink>
            else
                img = <NavLink to={'/page/' + image.id_ficheiro}><div className="wrapperImgUploads"><img src={global.src+"atavid/tb/"+image.thumb} width={width} /><span className="icon-typeVideo"></span></div></NavLink>
                
        } catch (err) {
            img =""   
        }
        return img
    }

    helploop(response){
        let listFiles=[];
        let list = response.data.images.filter(a => a.ididiomas == lang);
        list.map((files) => {
            let img = this.returnImage(files);
            let visivel;
            if (files.visivel==1)
                visivel = <span className="table-actionsItem" title="Visivel" > <i className="far fa-eye"></i> </span>
            else
                visivel = <span className="table-actionsItem" title="Não visivel" > <i className="far fa-eye-slash"></i> </span>
        
            if ((store.getState()["getatauser"]["userdata"] != undefined) && (store.getState()["getatauser"]["userdata"]["admin"]==1)){
                listFiles.push({
                    id: parseInt(files.id_ficheiro),
                    imagem: img,
                    titulo: files.titulo,
                    username: files.username,
                    visivel: visivel,
                    resolucao: files.largura +"x"+files.altura,
                    tamanho: files.peso,
                })
            }else{
                listFiles.push({
                    id: parseInt(files.id_ficheiro),
                    imagem: img,
                    titulo: files.titulo,
                    visivel: visivel,
                    resolucao: files.largura +"x"+files.altura,
                    tamanho: files.peso,
                })
            }
        });

        this.setState({ osdados: listFiles, isLoading:false, registoCRUD: false, keyTab: "list", progressBar:0 })
        window.scrollTo(0, 0);
        this.cleanContent();

        return listFiles;
    }
    
    async setFilterText(evalue){
        this.setState({ searchstring :evalue.trim() });
        if( (evalue.trim().length >= 3 && this.state.timesearch) || evalue.trim().length == 0 ){
            this.setState({ timesearch: false }); 
            setTimeout( async() => {
                this.setState({ isLoading : true });
                window.scrollTo(0, 0)
                this.setState({ timesearch: true }); 
                let listFiles=[];
                try {  
                    let tdata = { iduser: storeuser["idregisto"] , lingua: lang, page: 1, rowsperpage: this.state.rowsperpage, searchStr: this.state.searchstring };
                    let response = await loadedcontent.global_postaction( "actionUserFiles" , tdata , 0 );
                    this.setState({ allFiles:response, totalRows: response.data.filescount[0].numficheiros, rowspPage: this.state.rowsperpage });
                    if (response.data.images !== undefined) {
                        listFiles = this.helploop(response);
                    } else { 
                        this.setState({ osdados: listFiles, isLoading:false, registoCRUD: false, keyTab: "list", progressBar:0 })
                        this.cleanContent();
                        this.setState({isLoading:false, registoCRUD: false, keyTab: "list", progressBar:0 })
                    }
                } catch (error) {
                   this.setState({isLoading:false })
                }

            }, 2000);
        }
    } 

    async handleFiles() {
        let listFiles=[]
        try {
            /*
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({"method":"actionUserFiles","action":0,"data":{"iduser": storeuser["idregisto"] }});
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };
                const response = await fetch(global.api, requestOptions)
            */
            let tdata = { iduser: storeuser["idregisto"] , lingua: lang, page: 1, rowsperpage: this.state.rowspPage, searchStr: "" };
            let response = await loadedcontent.global_postaction( "actionUserFiles" , tdata , 0 ); 
            //const json = await response.json()
            this.setState({ allFiles:response, totalRows: response.data.filescount[0].numficheiros });

            if ( response.data.images !== undefined ) {
                listFiles = this.helploop(response);
            } else { 
                this.setState({isLoading:false, registoCRUD: false, keyTab: "list", progressBar:0 })
            }
        } catch (error) {
           this.setState({isLoading:false })
        }
    }

    async handlePerRowsChange(newPerPage, page){  
		this.setState({ isLoading : true });
        window.scrollTo(0, 0)
        let listFiles=[];
        try {
            let tdata = { iduser: storeuser["idregisto"] , lingua: lang, page: page, rowsperpage: newPerPage, searchStr: this.state.searchstring };
            let response = await loadedcontent.global_postaction( "actionUserFiles" , tdata , 0 ); 
            this.setState({ allFiles:response, totalRows: response.data.filescount[0].numficheiros, rowspPage: newPerPage });
            if (response.data.images !== undefined) {
                listFiles = this.helploop(response);
            } else { 
                this.setState({isLoading:false, registoCRUD: false, keyTab: "list", progressBar:0 })
            }
        } catch (error) {
           this.setState({isLoading:false })
        }

	};

    async handlePageChange (page){  
		this.setState({ isLoading : true });
        window.scrollTo(0, 0)
        let listFiles=[];
        try {
            let tdata = { iduser: storeuser["idregisto"] , lingua: lang, page: page, rowsperpage: this.state.rowspPage, searchStr: this.state.searchstring };
            let response = await loadedcontent.global_postaction( "actionUserFiles" , tdata , 0 ); 
            this.setState({ allFiles:response, totalRows: response.data.filescount[0].numficheiros });
            if (response.data.images !== undefined) {
                listFiles = this.helploop(response);
            } else { 
                this.setState({isLoading:false, registoCRUD: false, keyTab: "list", progressBar:0 })
            }
        } catch (error) {
           this.setState({isLoading:false })
        }
	};


    async dbAction(filename){
    
        if (this.state.action==3){
            let tdata = {
                idFile: this.state.idFile, 
                titulo: this.state.formvalues.titulo, 
                tags: this.state.formvalues.tags,
                cat: this.state.formvalues.categoria,
                profile: this.state.formvalues.profile,
                subcat: this.state.subcategoriasSelected,
                subprofile: this.state.subprofileSelected,
                licenca: parse(String(this.state.formvalues.licenca)),
                licencaEN: parse(String(this.state.formvalues.licencaEN)),
                tituloEN: this.state.formvalues.tituloEN,
                descricao: parse(String(this.state.formvalues.descricao)),
                descricaoEN: parse(String(this.state.formvalues.descricaoEN)),
                tagsEN: this.state.formvalues.tagsEN,
                visivel: this.state.formvalues.visivel,
                cedencia: this.state.formvalues.cedencia,            
                idregisto: storeuser["idregisto"],
                ilha: this.state.formvalues.ilha,
                localidade: this.state.formvalues.localidade,
                autor: this.state.formvalues.autor
            };
            this.setState({ loading: !this.state.loading });
            setTimeout(
                async function () {
                    let crud = await loadedcontent.global_postaction( "actionUserFiles", tdata , this.state.action);
                    if( crud["error"] == null ){ 
                        this.setState({ classkeymessageout: "success-out" , keymessageout: 'global_actualizar' });
                        this.setState({ registoCRUD: true, loading: !this.state.loading });
                    }else{
                        this.setState({ classkeymessageout: "error-out" , keymessageout: 'globalerro_actualizar'});
                        this.setState({ registoCRUD: true, loading: !this.state.loading });
                    }
                    this.handleFiles()
                }.bind(this),
                500
            );
       
            //  this.props.linguafunc(store.getState()["getlang"]["idlingua"]); 
            //this.changeTab('list')
        }
        
        if ((filename != undefined) && (this.state.action==1)){
            if (filename.data.errors == ""){
                let tdata = {
                    idFile: this.state.idFile, 
                    titulo: this.state.formvalues.titulo, 
                    tags: this.state.formvalues.tags,
                    cat: this.state.formvalues.categoria,
                    profile: this.state.formvalues.profile,
                    subcat: this.state.subcategoriasSelected,
                    subprofile: this.state.subprofileSelected,             
                    visivel: this.state.formvalues.visivel,
                    licenca: parse(String(this.state.formvalues.licenca)),
                    licencaEN: parse(String(this.state.formvalues.licencaEN)),
                    descricaoEN: parse(String(this.state.formvalues.descricaoEN)),
                    descricao: parse(String(this.state.formvalues.descricao)),
                    tituloEN: this.state.formvalues.tituloEN,
                    tagsEN: this.state.formvalues.tagsEN,
                    ficheiro: filename.data.filename,
                    thumb: filename.data.thumb,
                    size: filename.data.size,
                    height: filename.data.height,
                    width: filename.data.width,
                    type: filename.data.type,
                    cedencia: this.state.formvalues.cedencia,
                    videopreview: filename.data.videopreview,
                    idregisto: storeuser["idregisto"],
                    ilha: this.state.formvalues.ilha,
                    localidade: this.state.formvalues.localidade,
                    autor: this.state.formvalues.autor
                };
             
                //this.setState({ loading: !this.state.loading });
                //   TypeError: Failed to fetch
                let crud = await loadedcontent.global_postaction( "actionUserFiles", tdata , this.state.action);
                if( crud["error"] == null ){ 
                    if(this.state.progressBar == 100){
                        this.setState({classkeymessageout: "success-out", keymessageout: 'global_inserir'});
                        this.setState({registoCRUD: true});
                    }
                }else{
                    if(this.state.progressBar == 100){
                        this.setState({classkeymessageout: "error-out", keymessageout: 'globalerro_inserir'});
                        this.setState({registoCRUD: true});
                    }
                }
                this.handleFiles()        
                    
                //  this.props.linguafunc(store.getState()["getlang"]["idlingua"]); 
                //  this.handleFiles()
            }else{
                this.setState({classkeymessageout: "error-out", keymessageout: 'globalerro_inserir'});
                this.setState({registoCRUD: true});
                this.handleFiles()
            }
        }

    }

    upload_ata_files = async () => {
        const config = {
            onUploadProgress: (progressEvent) => {
                var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                this.setState({progressBar:percentCompleted})

                if (this.state.progressBar == 100)
                    this.setState({videoinProgress: this.props.gcontent.data.traducoes['videoprocessing']['palavra_traducao']})    
            }
        };
          
        let data = new FormData()
        data.append('ficheiro',  this.state.fileForm[0])
    
        axios.post(global.url+'upload_content.php', data, config)
            .then(res => this.dbAction(res))
            .catch(err => {})
    };

    async handleSubmit(event) {
        event.preventDefault();

        let tdata = this.state.formvalues;
        var tdataform = this.state.formvalidation; //VALIDATION
        //let _tmpiconmess = tdataform["iconfile"][1];
        let tmpvalidate = validate(tdataform, tdata, '',
        [
            this.props.gcontent.data.traducoes['field_required']['palavra_traducao'],
            this.props.gcontent.data.traducoes['min_char']['palavra_traducao'],
            this.props.gcontent.data.traducoes['spaces_nallowed']['palavra_traducao'],
            this.props.gcontent.data.traducoes['min8_char']['palavra_traducao'],
            this.props.gcontent.data.traducoes['psw_rule']['palavra_traducao'],
            this.props.gcontent.data.traducoes['only_number']['palavra_traducao'],
            this.props.gcontent.data.traducoes['invalid_email']['palavra_traducao'],
        ]
        );
        this.setState({ formvalidation: tmpvalidate[1] })
        if(tmpvalidate[0] == true && this.state.action==1){
        
        this.setState({progressBar:0})   
        let filename = (this.state.fileForm != null) ? await this.upload_ata_files() : null
         
            
        }else if(this.state.action==3){
            this.dbAction()
        }else{
            let formoutput = <div><span className="icon-warning"></span><p>{this.props.gcontent.data.traducoes['formwerrors']['palavra_traducao']}</p></div>
            ReactDOM.render(formoutput, document.getElementById('formoutput'))
            //document.getElementById('formoutput').value = 'Form with errors' 
        }
    }

    componentDidMount(){    
        storeuser = store.getState()["getatauser"]["userdata"] 
        lang = store.getState()["getlang"]["idlingua"]          
        this.handleFiles()    
    /*
    this.setState({osdados:  [{minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['image']['palavra_traducao'], selector: "imagem", sortable: false ,},
        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['title']['palavra_traducao'], selector: "titulo", sortable: true, },
        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['username']['palavra_traducao'], selector: "username", sortable: true, },
        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['ficheiro_visivel']['palavra_traducao'], selector: "visivel", sortable: true, },
        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['resolution']['palavra_traducao'], selector: "resolucao", sortable: true, },
        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['size']['palavra_traducao'], selector: "tamanho", sortable: true, },
        {minWidth: "150px", wrap: true, name: " ", selector: "crud", sortable: false, cell: d => <div>
                                                                                                    <span className="table-actionsItem" title="Editar" onClick={() => { this.handleEdit(d.id); this.changeTab("form") }} >
                                                                                                        {" "} <i className="far fa-edit"></i>{" "}
                                                                                                    </span>
                                                                                                    <span className="table-actionsItem" title="Eliminar" onClick={() => this.handleEdit(d.id)} >
                                                                                                        {" "}<i className="far fa-trash-alt"></i>{" "}
                                                                                                    </span>
                                                                                                </div> }
        
        
        ]})*/
      /*  if (headerTable.length < 1){
                if ((store.getState()["getatauser"]["userdata"] != undefined) && (store.getState()["getatauser"]["userdata"]["admin"]==1)){
                
                    headerTable.push(
                        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['image']['palavra_traducao'], selector: "imagem", sortable: false ,},
                        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['title']['palavra_traducao'], selector: "titulo", sortable: true, },
                        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['username']['palavra_traducao'], selector: "username", sortable: true, },
                        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['ficheiro_visivel']['palavra_traducao'], selector: "visivel", sortable: true, },
                        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['resolution']['palavra_traducao'], selector: "resolucao", sortable: true, },
                        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['size']['palavra_traducao'], selector: "tamanho", sortable: true, },
                        {minWidth: "150px", wrap: true, name: " ", selector: "crud", sortable: false, cell: d => <div>
                                                                                                                    <span className="table-actionsItem" title="Editar" onClick={() => { this.handleEdit(d.id); this.changeTab("form") }} >
                                                                                                                        {" "} <i className="far fa-edit"></i>{" "}
                                                                                                                    </span>
                                                                                                                    <span className="table-actionsItem" title="Eliminar" onClick={() => this.handleEdit(d.id)} >
                                                                                                                        {" "}<i className="far fa-trash-alt"></i>{" "}
                                                                                                                    </span>
                                                                                                                </div> }
                    )
                }
                else{
            
                    headerTable.push(
                        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['image']['palavra_traducao'], selector: "imagem", sortable: false ,},
                        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['title']['palavra_traducao'], selector: "titulo", sortable: true, },
                        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['ficheiro_visivel']['palavra_traducao'], selector: "visivel", sortable: true, },
                        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['resolution']['palavra_traducao'], selector: "resolucao", sortable: true, },
                        {minWidth: "150px", wrap: true, name: this.props.gcontent["data"]["traducoes"]['size']['palavra_traducao'], selector: "tamanho", sortable: true, },
                        {minWidth: "150px", wrap: true, name: " ", selector: "crud", sortable: false, cell: d => <div>
                                                                                                                    <span className="table-actionsItem" title="Editar" onClick={() => { this.handleEdit(d.id); this.changeTab("form") }} >
                                                                                                                        {" "} <i className="far fa-edit"></i>{" "}
                                                                                                                    </span>
                                                                                                                    <span className="table-actionsItem" title="Eliminar" onClick={() => this.handleEdit(d.id)} >
                                                                                                                        {" "}<i className="far fa-trash-alt"></i>{" "}
                                                                                                                    </span>
                                                                                                                </div> }
                    )
                }
                this.setState({osdados:headerTable})
        }*/
    
    }

    onCedenciachange(event){
        let anos = event.target.value;
        let tmpvalues = this.state.formvalues
        tmpvalues['cedencia'] = anos;
        this.setState({formvalues:tmpvalues})
    }
    
    onIlhachange(event){
        let idIlha = event.target.value;
        let tmpvalues = this.state.formvalues
        tmpvalues['ilha'] = idIlha;
        this.setState({formvalues:tmpvalues})
    }

    checkVisivel(){
        let tmpvalues = this.state.formvalues
        tmpvalues['visivel'] = !this.state.formvalues.visivel
        this.setState({ formvalues:tmpvalues})
    }
  
    getProfiles = () => {
        let catego = [];
        for (let levelOneKey in this.props.gcontent["data"]["perfis"]) {
            if (this.props.gcontent["data"]["perfis"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"])
                catego.push(
                    <option 
                        key={"myCat-"+this.props.gcontent["data"]["perfis"][levelOneKey]["perfil_referencia"]}
                        data-index={levelOneKey}
                        data-ref={this.props.gcontent["data"]["perfis"][levelOneKey]["perfil_referencia"]}
                        value={this.props.gcontent["data"]["perfis"][levelOneKey]["idperfis"]}>
                        {parse(this.props.gcontent["data"]["perfis"][levelOneKey]["nome_perfil"])}
                        
                    </option>
                );
        }
        return catego;
    }

    getCats = () => {
        let catego = []; 
        for (let levelOneKey in this.props.gcontent["data"]["categorias"]) {
            if (this.props.gcontent["data"]["categorias"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"] && this.props.gcontent["data"]["categorias"][levelOneKey]["categoria_referencia"] != 'islands') {
                catego.push(
                    <option 
                        key={"myCat-"+this.props.gcontent["data"]["categorias"][levelOneKey]["categoria_referencia"]}
                        data-index={levelOneKey}
                        data-ref={this.props.gcontent["data"]["categorias"][levelOneKey]["categoria_referencia"]}
                        value={this.props.gcontent["data"]["categorias"][levelOneKey]["idcategorias"]}>
                        {parse(this.props.gcontent["data"]["categorias"][levelOneKey]["nome_categoria"])}
                        
                    </option>
                );
            }
        }
        return catego;
    }

    oncatschange = (event) => {
        let idcategoria = event.target.value;
        const ind = event.target.getAttribute("id").split("cat-")[1] ;
        let subcateg = [];
        let _hasSubCats = false;
        for (let levelOneKey in this.props.gcontent["data"]["subcategorias"]) {
          if (this.props.gcontent["data"]["subcategorias"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"] && 
                idcategoria == this.props.gcontent["data"]["subcategorias"][levelOneKey]["idcategorias"]){
                if(!_hasSubCats){
                    subcateg.push(
                        <option 
                            key={"mysubcat-_1"}
                            data-index={"-1"}
                            data-ref={"-1"}
                            value={"-1"}>
                            {this.props.gcontent.data.traducoes['all']['palavra_traducao']}
                        </option>
                    );
                    let subSelected = [...this.state.subcategoriasSelected];
                    
                    subSelected.push({
                        index: ind,
                        idSubcat: -1
                    })
                    this.setState({ subcategoriasSelected :  subSelected})
                    
                    //console.log(subSelected);
                    _hasSubCats = true;
                }
                subcateg.push(
                    <option 
                        key={"mysubcat-"+this.props.gcontent["data"]["subcategorias"][levelOneKey]["subcategoria_referencia"]}
                        data-index={levelOneKey}
                        data-ref={this.props.gcontent["data"]["subcategorias"][levelOneKey]["subcategoria_referencia"]}
                        value={this.props.gcontent["data"]["subcategorias"][levelOneKey]["idsubcategorias"]}>
                        {parse(this.props.gcontent["data"]["subcategorias"][levelOneKey]["nome_subcategoria"])}
                    </option>
                );
            }
        }
        const subcat='subcategorias-'+ind
        //----------------Catch index------------------------------------------
        let tindex = 0; let _tmpc = 0;
        for(let a=0; a < this.state.catsList.length; a++ ){
            if(  this.state.catsList[a] != null ){
                if( this.state.catsList[a]["key"] == ind ){
                    tindex = _tmpc; break
                }                    
                _tmpc++;
            }
        }
        //------------Delete em Subcats quando categoria altera----------------
        let subSelected = [...this.state.subcategoriasSelected];
        for(let w=0; w < subSelected.length; w++ ){
            if( subSelected[w]["index"] == tindex ){
                let _tmpSplt = subSelected.splice(w, 1);
                this.setState({ subcategoriasSelected: subSelected });
                break;
            }
        }
        //--------------Verifica se o Index existe------------------------------
        let catSelected = this.state.formvalues
        let tmparr = catSelected["categoria"];
        if(  typeof tmparr[tindex] === 'undefined'  ){
            tmparr.push({
                index: tindex.toString(),
                cat: idcategoria
            }); 
        }else{
            tmparr[tindex] = { index:tindex.toString(), cat:idcategoria } ;
        }
        //----------------------------------------------------------------------

        catSelected['categoria'] = tmparr;
        catSelected[subcat]= subcateg;

        this.setState({ 
            formvalues: catSelected,
            //[subcat]: subcateg
        }) //this.state.categoria.concat([idcategoria])});

        this.loadCats();
    }

    onprofilechange = (event) => {
        let idprofile = event.target.value;
        const ind = event.target.getAttribute("id").split("profile-")[1]  
        let subprofile= [];
        let _hasSubProf = false;
        for (let levelOneKey in this.props.gcontent["data"]["subperfis"]) {
            if (this.props.gcontent["data"]["subperfis"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"] && 
                idprofile == this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis"] ){
                if(!_hasSubProf){
                    subprofile.push(
                        <option 
                            key={"mysubprof-_1"}
                            data-index={"-1"}
                            data-ref={"-1"}
                            value={"-1"}>
                            {this.props.gcontent.data.traducoes['all']['palavra_traducao']}
                        </option>
                    );
                    let subSelected = [...this.state.subprofileSelected];
                    
                    subSelected.push({
                        index: ind,
                        idsubProfile: -1
                    })
                    this.setState({ subprofileSelected :  subSelected})
                    _hasSubProf = true;
                }
                subprofile.push(
                    <option 
                        key={"mysubprof-"+this.props.gcontent["data"]["subperfis"][levelOneKey]["subperfil_referencia"]}
                        data-index={levelOneKey}
                        data-ref={this.props.gcontent["data"]["subperfis"][levelOneKey]["subperfil_referencia"]}
                        value={this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis_sub"]}>
                            {parse(this.props.gcontent["data"]["subperfis"][levelOneKey]["nome_perfil_sub"])}
                    </option>
                );
            }
        }

        const subprof='subprofile-'+ind
        //----------------Catch index------------------------------------------
        let tindex = 0; let _tmpc = 0;
        for(let a=0; a < this.state.profileList.length; a++ ){
            if(  this.state.profileList[a] != null ){
                if( this.state.profileList[a]["key"] == ind ){
                    tindex = _tmpc; break
                }                    
                _tmpc++;
            }
        }
        //------------[Delete em Subprofiles quando categoria altera]--------------
        let subSelected = [...this.state.subprofileSelected]
        for(let w=0; w < subSelected.length; w++ ){
            if( subSelected[w]["index"] == tindex ){
                let _tmpSplt = subSelected.splice(w, 1);
                this.setState({ subprofileSelected: subSelected });
                break;
            }
        }
        //--------------Verifica se o Index existe------------------------------
        let profileSelected = this.state.formvalues
        let tmparr = profileSelected.profile
        if(  typeof tmparr[tindex] === 'undefined'  ){
            tmparr.push({
                index: tindex.toString(),
                profile: idprofile
            }); 
        }else{
            tmparr[tindex] = { index:tindex.toString(), profile:idprofile } ;
        }
        //----------------------------------------------------------------------



        profileSelected['profile'] = tmparr
        profileSelected[subprof]= subprofile
    
        this.setState({ 
            formvalues:profileSelected,
            //[subprof]:subprofile
        })
        this.loadProfiles();
    }

    onsubCatschange = (event) => {
        let idSubcategoria = event.target.value;
        const ind = event.target.getAttribute("id").split("subcat-")[1] 
        //----------------Catch index----------------------
        let tindex = 0; let _tmpc = 0;
        for(let a=0; a < this.state.catsList.length; a++ ){
            if(  this.state.catsList[a] != null ){
                if( this.state.catsList[a]["key"] == ind ){
                    tindex = _tmpc; break
                }                    
                _tmpc++;
            }
        }
        //------------------------------------------------
        let subSelected = [...this.state.subcategoriasSelected];
        let _ckg = false
        for(let w=0; w < subSelected.length; w++ ){
            if( subSelected[w]["index"] == tindex ){
                _ckg = true
                subSelected[w]["idSubcat"] = idSubcategoria.toString(); break;
            }
        }
        if(  _ckg == false ){
            subSelected.push({
                index: tindex.toString(),
                idSubcat: idSubcategoria.toString()
            })
        }
         
        this.setState({ subcategoriasSelected :  subSelected})    
        
    }

    onsubProfilechange = (event) => {
        let idSubprofile = event.target.value;
        const ind = event.target.getAttribute("id").split("subprofile-")[1] 
        //----------------------Catch index----------------------
        let tindex = 0; let _tmpc = 0;
        for(let a=0; a < this.state.profileList.length; a++ ){
            if(  this.state.profileList[a] != null ){
                if( this.state.profileList[a]["key"] == ind ){
                    tindex = _tmpc; break
                }
                _tmpc++;
            }
        }
        //-------------------------------------------------------
        let subSelected = [...this.state.subprofileSelected]
        let _ckg = false
        for(let w=0; w < subSelected.length; w++ ){
            if( subSelected[w]["index"] == tindex ){
                _ckg = true
                subSelected[w]["idsubProfile"] = idSubprofile.toString(); break;
            }
        }
        if(  _ckg == false ){
            subSelected.push({
                index: tindex.toString(),
                idsubProfile: idSubprofile.toString()
            })
        }


        this.setState({ subprofileSelected :  subSelected})
    }

    onLocalidadechange = (event) => {
        let localidade = event.target.value;
        this.setState({ formvalues:{ localidade : localidade}});
    }

    async listFiles(){
        try {
            /*var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            
            var raw = JSON.stringify({"method":"actionUserFiles","action":0,"data":{"iduser":storeuser["idregisto"]}});
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            const response = await fetch(global.api, requestOptions)
    
            const json = await response.json()*/
            let tdata = {iduser: storeuser["idregisto"]};
            let response = await loadedcontent.global_postaction( "actionUserFiles",tdata, 0)

            this.setState({filesList:response})
        } catch (error) {
            return error;   
        }
    }

    addOneCat(){
        let indexcat = this.state.catsList.length;
        let cats = this.state.catsList;
        cats.push(
            <div key={indexcat}>
                <Row>
                    <Col lg={6}>
                        <Form.Group className="m-0">
                            <Form.Control as="select" onChange={this.oncatschange}  id={"cat-"+indexcat} >
                                <option value="" defaultValue=''  hidden>{this.props.gcontent["data"]["traducoes"]["all_galleries"]["palavra_traducao"]}</option>
                                {this.getCats()}
                            </Form.Control> 
                        </Form.Group>
                    </Col>
                    <Col lg={5}>
                        <Form.Group className="m-0">
                            <Form.Control as="select" id={"subcat-"+indexcat} defaultValue="Choose..." onChange={this.onsubCatschange}>
                                <option value="" defaultValue='' hidden>{this.props.gcontent["data"]["traducoes"]["subcategories"]["palavra_traducao"]}</option>
                                {this.state.formvalues['subcategorias-'+indexcat]}
                            </Form.Control>
                        </Form.Group>
                            
                    </Col>
                    <Col lg={1} className="flexedCenter">
                        <div  onClick={this.handleDelete(indexcat)} className="btn-box"><span className="icon-close-icon">cr {indexcat} </span></div>
                        <span style={{visibility: 'hidden'}}>{indexcat++}</span>
                    </Col>
                </Row> 
            </div>
        );
        this.setState({ catsList: cats });

        this.loadCats();
    }

    loadCats(){
        let indexcat=0;
        let cats=[]        
        let tmpvalues = this.state.formvalues; 
        //let subcats = this.state.subcategoriasSelected; 
        //tmpvalues["categoria"].map((index) => {
        for(let a=0; a < this.state.catsList.length; a++ ){
            if(  this.state.catsList[a] != null ){
                cats.push(
                    <div key={this.state.catsList[a]["key"]}>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="m-0">
                                    <Form.Control as="select" onChange={this.oncatschange}  id={"cat-"+this.state.catsList[a]["key"]} >
                                        <option value="" defaultValue=''  hidden>{this.props.gcontent["data"]["traducoes"]["all_galleries"]["palavra_traducao"]}</option>
                                        {this.getCats()}
                                    </Form.Control> 
                                </Form.Group>
                            </Col>
                            <Col lg={5}>
                                <Form.Group className="m-0">
                                        <Form.Control as="select" id={"subcat-"+this.state.catsList[a]["key"]} defaultValue="Choose..." onChange={this.onsubCatschange}>
                                            <option value="" defaultValue='' hidden>{this.props.gcontent["data"]["traducoes"]["subcategories"]["palavra_traducao"]}</option>
                                            {this.state.formvalues['subcategorias-'+this.state.catsList[a]["key"]]}
                                    </Form.Control>
                                </Form.Group>
                                    
                            </Col>
                            <Col lg={1} className="flexedCenter">
                                <div  onClick={this.handleDelete(this.state.catsList[a]["key"])} className="btn-box"><span className="icon-close-icon"></span></div>
                                <span style={{visibility: 'hidden'}}>{this.state.catsList[a]["key"]}</span>
                            </Col>
                        </Row> 
                    </div>
                )
            }else{ cats.push(null); }
        }
        this.setState({ catsList: cats });
    }

    addOneProfile(){
        let indexprofile = this.state.profileList.length;
        let profiles = this.state.profileList;
        profiles.push(
            <div key={indexprofile}>
                <Row>
                    <Col lg={6}>
                        <Form.Group className="m-0">
                            <Form.Control as="select" onChange={this.onprofilechange} id={"profile-"+indexprofile} >
                                <option value="" defaultValue=''  hidden>{this.props.gcontent["data"]["traducoes"]["add_profile"]["palavra_traducao"]}</option>
                                {this.getProfiles()}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col lg={5}>
                        <Form.Group className="m-0">
                            <Form.Control as="select" id={"subprofile-"+indexprofile} defaultValue="Choose..." onChange={this.onsubProfilechange}>
                                <option value="" defaultValue='' hidden>{this.props.gcontent["data"]["traducoes"]["selectSubprofile"]["palavra_traducao"]}</option>
                                {this.state.formvalues['subprofile-'+indexprofile]}
                            </Form.Control>
                        </Form.Group> 
                    </Col>
                    <Col lg={1} className="flexedCenter">
                        <div  onClick={this.handleDeleteProfile(indexprofile)} className="btn-box"><span className="icon-close-icon"></span></div>
                        <span style={{visibility: 'hidden'}}>{indexprofile++}</span>
                    </Col>
                </Row> 
            </div>
        );
        this.setState({ profileList: profiles });

        this.loadProfiles();
    }

    loadProfiles(){
        let indexprofile=0
        let curArr = []
        let profiles = []
        curArr = this.state.formvalues.profiles;
        for(let a=0; a < this.state.profileList.length; a++ ){
            if(  this.state.profileList[a] != null ){
                profiles.push(
                    <div key={this.state.profileList[a]["key"]}>
                        <Row>
                            <Col lg={6}>
                                <Form.Group className="m-0">
                                    <Form.Control as="select" onChange={this.onprofilechange} id={"profile-" + this.state.profileList[a]["key"] } >
                                        <option value="" defaultValue='' hidden>{this.props.gcontent["data"]["traducoes"]["add_profile"]["palavra_traducao"]}</option>
                                        {this.getProfiles()}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col lg={5}>
                                <Form.Group className="m-0">
                                    <Form.Control as="select" id={"subprofile-" + this.state.profileList[a]["key"] } defaultValue="Choose..." onChange={this.onsubProfilechange}>
                                        <option value="" defaultValue='' hidden>{this.props.gcontent["data"]["traducoes"]["selectSubprofile"]["palavra_traducao"]}</option>
                                        {this.state.formvalues['subprofile-'+this.state.profileList[a]["key"]]}
                                    </Form.Control>
                                </Form.Group> 
                            </Col>
                            <Col lg={1} className="flexedCenter">
                                <div  onClick={this.handleDeleteProfile(this.state.profileList[a]["key"])} className="btn-box"><span className="icon-close-icon"></span></div>
                                <span style={{visibility: 'hidden'}}>{this.state.profileList[a]["key"]}</span>
                            </Col>
                        </Row>         
                    </div>
                );
            }else{ profiles.push(null); }
        }
        this.setState({ profileList: profiles });
    }

    handleDeleteProfile = i => e => {
        e.preventDefault()
        //----------------Catch index----------------------
        let tindex = 0; let _tmpc = 0;
        for(let a=0; a < this.state.profileList.length; a++ ){
            if(  this.state.profileList[a] != null ){
                if( this.state.profileList[a]["key"] == i ){
                    tindex = _tmpc; break
                }                    
                _tmpc++;
            }
        }
        //-----------------------------------------------
        let tmpvalues = this.state.formvalues; 
        let subprofiles = this.state.subprofileSelected;
        if( typeof tmpvalues["profile"][tindex] !== 'undefined'){
            let _indexTmp = tmpvalues["profile"][tindex]["index"];
            let _tmpSplic = tmpvalues["profile"].splice(tindex, 1);
            for(let d=0; d < subprofiles.length; d++ ){
                if( subprofiles[d]["index"] == _indexTmp ){
                    let _tmpSplt = subprofiles.splice(d, 1);
                }
            }

        
        
            for(let a=0; a < tmpvalues["profile"].length; a++ ){
                if( tmpvalues["profile"][a]["index"] != a ){
                    //let _oldvalue = tmpvalues["profiles"][a]["index"];
                    for(let w=0; w < subprofiles.length; w++ ){
                        if( subprofiles[w]["index"] == tmpvalues["profile"][a]["index"] ){
                            subprofiles[w]["index"] = a.toString(); break;
                        }
                    }
                    tmpvalues["profile"][a]["index"] = a.toString();
                }
            }
        }
    
        
        let _tmpProfiles = [];
        for(let g = 0; g < this.state.profileList.length; g++ ){
            if( g != i ){
                _tmpProfiles.push(this.state.profileList[g]);
            }else{
                _tmpProfiles.push(null);
            }
        }
        this.setState({ formvalues:tmpvalues, profileList: _tmpProfiles });
        //this.loadCats();
        //------------------------------------------------
        /*
            let tmpvalues = this.state.formvalues;
            
            let prof = [
                ...tmpvalues['profile'].slice(0, i),
                ...tmpvalues['profile'].slice(i + 1)
            ]
            tmpvalues['profile'] = prof
            let profiles = [
                ...tmpvalues['profiles'].slice(0, i),
                ...tmpvalues['profiles'].slice(i + 1)
            ]
            tmpvalues['profiles'] = profiles
            let subprof = [
                ...this.state.subprofileSelected.slice(0, i),
                ...this.state.subprofileSelected.slice(i + 1)
            ]

            this.setState({formvalues:tmpvalues, subprofileSelected:subprof}) 
        */
    }

    handleDelete = i => e => {
        e.preventDefault()
        //----------------Catch index----------------------
        let tindex = 0; let _tmpc = 0;
        for(let a=0; a < this.state.catsList.length; a++ ){
            if(  this.state.catsList[a] != null ){
                if( this.state.catsList[a]["key"] == i ){
                    tindex = _tmpc; break
                }                    
                _tmpc++;
            }
        }
        //-----------------------------------------------
        let tmpvalues = this.state.formvalues; 
        let subcats = this.state.subcategoriasSelected;
        if( typeof tmpvalues["categoria"][tindex] !== 'undefined'){
            let _indexTmp = tmpvalues["categoria"][tindex]["index"];
            let _tmpSplic = tmpvalues["categoria"].splice(tindex, 1);
            for(let d=0; d < subcats.length; d++ ){
                if( subcats[d]["index"] == _indexTmp ){
                    let _tmpSplt = subcats.splice(d, 1);
                }
            }

        /* 
        */
            for(let a=0; a < tmpvalues["categoria"].length; a++ ){
                if( tmpvalues["categoria"][a]["index"] != a ){
                    //let _oldvalue = tmpvalues["categoria"][a]["index"];
                    for(let w=0; w < subcats.length; w++ ){
                        if( subcats[w]["index"] == tmpvalues["categoria"][a]["index"] ){
                            subcats[w]["index"] = a.toString(); break;
                        }
                    }
                    tmpvalues["categoria"][a]["index"] = a.toString();
                }
            }
        }
        /*
        */
        let _tmpCats = [];
        for(let g = 0; g < this.state.catsList.length; g++ ){
            if( g != i ){
                _tmpCats.push(this.state.catsList[g]);
            }else{
                _tmpCats.push(null);
            }
        }
        this.setState({ catsList: _tmpCats });
        //this.loadCats();
        //------------------------------------------------
        /*
            let tmpvalues = this.state.formvalues;

            let cats = [
            ...tmpvalues['categoria'].slice(0, i),
            ...tmpvalues['categoria'].slice(i + 1)
            ]
            tmpvalues['categoria'] = cats
            let subcats = [
                ...this.state.subcategoriasSelected.slice(0, i),
                ...this.state.subcategoriasSelected.slice(i + 1)
            ]
            this.setState({formvalues:tmpvalues, subcategoriasSelected:subcats}) 
            let questions = [
                ...tmpvalues['questions'].slice(0, i),
                ...tmpvalues['questions'].slice(i + 1)
            ]
            tmpvalues['questions'] = questions
            this.setState({formvalues: tmpvalues})
        */
    }
    
    addQuestion = e => {
        e.preventDefault()
        //let tmpvalues = this.state.formvalues
        //tmpvalues['questions'] = (tmpvalues['questions'].length == 0)? this.state.formvalues.questions.concat(['']) : tmpvalues['questions'] ;
        //this.setState({ formvalues:tmpvalues})
        this.addOneCat();
    }

    addProfile = e => {
        e.preventDefault()
        //let tmpvalues = this.state.formvalues
        //tmpvalues['profiles'] = this.state.formvalues.profiles.concat([''])
        //this.setState({ formvalues:tmpvalues})
        this.addOneProfile();
    }

    cedenciaRender(){
        const maxCedencia = 5
        let sel = false
        let cedArr=[]

        for(let i=1;i<=maxCedencia;i++){
            //sel = false

            var selected = (this.state.formvalues.cedencia == i) ? 'selected' : '';
            /*if (this.state.formvalues.cedencia==i)
                sel = true*/
            cedArr.push( <option key={'ced-'+i} value={i}>{i} {this.props.gcontent["data"]["traducoes"]["prazo_ano"]["palavra_traducao"]}</option>)
        }

        /*** OPÇÃO SEM CEDENCIA ***/
        sel = false
        if (this.state.formvalues.cedencia == 100)
            sel=true
    
        cedArr.push(<option key={'ced-0'} value={100}>{this.props.gcontent["data"]["traducoes"]["prazo_zero"]["palavra_traducao"]}</option>)
    
        return cedArr
    }

    render() {
        
        if( this.state.redirect == true ){
            return <Redirect to={"/"} />
        }else if((store.getState()["getatauser"]["userdata"]["inserir"] != 1) && (store.getState()["getatauser"]["userdata"]["ver"] != 1)){ 
            return <Redirect to="/" /> 
        }
        const { fileName } = this.state;
        const countelements=10
        let idilha = '';
        let file = null;
        let indexcat=0
        const tableData = { columns: this.state.ascolunas, data: this.state.osdados }
        file = fileName && ( <label className="iconFile_Name"><span className="icon-paperclip"></span> {fileName}</label>);
        return (
            <>
                {this.state.loading == true && <Loader />}
                {this.state.action==1 && 
                    <>
                        {this.state.registoCRUD==false && this.state.progressBar > 0 &&
                            <div className="preload-stuff">
                                <div className="position-center">
                                    <div style={{width:'280px'}}>
                                        <ProgressBar animated={true} now={this.state.progressBar}/>
                                        <label className="progressState">{this.state.progressBar == 100 ? this.state.videoinProgress : this.state.progressBar + '%'}</label>
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.registoCRUD==true &&
                            <div className="preload-stuff">
                                <div className="position-center">
                                    <div className="message-box responseOutput">
                                        <div className="responseOutput_gif">
                                            <Lottie options={{ animationData: (this.state.classkeymessageout == "success-out")? respok : resperror , loop: false }} />
                                        </div>
                                        <span className={this.state.classkeymessageout}>{this.props.gcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
                {this.state.action==2 && this.state.registoCRUD==true && 
                    <div className="preload-stuff">
                        <div className="position-center">
                            <div className="message-box responseOutput">
                                <div className="responseOutput_gif">
                                    <Lottie options={{ animationData: (this.state.classkeymessageout == "success-out")? respok : resperror , loop: false }} />
                                </div>
                                <span className={this.state.classkeymessageout}>{this.props.gcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                            </div>
                        </div>
                    </div>
                }
                {this.state.action==3 && this.state.registoCRUD==true && 
                    <div className="preload-stuff">
                        <div className="position-center">
                            <div className="message-box responseOutput">
                                <div className="responseOutput_gif">
                                    <Lottie options={{ animationData: (this.state.classkeymessageout == "success-out")? respok : resperror , loop: false }} />
                                </div>
                                <span className={this.state.classkeymessageout}>{this.props.gcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                            </div>
                        </div>
                    </div>
                }
                {this.state.delmess == true &&
                    <div className="preload-stuff">
                        <div className="position-center">
                            <div className="message-box message-boxPopup responseOutput-box">
                                <span className="messagedel">{this.props.oestado.var_globalcontent["data"]["traducoes"]["eliminardados"]['palavra_traducao']} </span>
                                <div className="message-boxActions">
                                    <div onClick={() => this.handleDeleteFile(this.state.idFile, 1)} >
                                        <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["yes"]['palavra_traducao']} </span>
                                    </div>
                                    <div onClick={() => this.handleDeleteFile(this.state.idFile, 0)} >
                                        <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["no"]['palavra_traducao']} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} gcontent={this.props.gcontent} theheader="default" />  
                <Container className="content contentPage">
                <Breadcrumbs {...this.props} gcontent={this.props.gcontent} />
                    <div className="titlePage">{this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b></div>
                    <Row className="content-sub contentOffice">
                        <Col className="menuDrop">
                            <Usermenu gcontent={this.props.gcontent}/>
                        </Col>
                        <Col className="contentDRUP">
                            <Tab.Container activeKey={this.state.keyTab} onSelect={this.changeTab}>
                                <Nav variant="pills" className="pageClientHead">
                                    <h3 className="contentTitle">{this.props.gcontent.data.traducoes['uploads']['palavra_traducao']}</h3>
                                    <span className="separador"></span>
                                    <Nav.Item>
                                        <Nav.Link eventKey={"list"}>
                                            {this.props.gcontent.data.traducoes["listing"]["palavra_traducao"]}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={"form"}>
                                            {this.props.gcontent.data.traducoes["additems"]["palavra_traducao"]}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"form"}>
                                        <Row className="m-0">
                                            <div className="adminForms">
                                                {(store.getState()["getatauser"]["userdata"]["inserir"] == 1 || store.getState()["getatauser"]["userdata"]["editar"] == 1 && this.state.submitlabel == this.props.gcontent["data"]["traducoes"]["bt_actualizar"]["palavra_traducao"] ) &&
                                                    <Form>
                                                        <Row>
                                                            <Col>
                                                                {this.state.image !='' && (this.state.image) }                                                                
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group >
                                                                    <Form.Control type="text"  onChange={this.handleChange}  value={this.state.formvalues.titulo} name="titulo" placeholder={this.props.gcontent.data.traducoes['title']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['portugues']['palavra_traducao']} required/>
                                                                    {this.state.formvalidation["titulo"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                        <span className="error">
                                                                            { this.state.formvalidation["titulo"][1]}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group >
                                                                    <Form.Control as="textarea" rows={3} onChange={this.handleChange}  value={parse(String(this.state.formvalues.descricao))} name="descricao" placeholder={this.props.gcontent.data.traducoes['description']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['portugues']['palavra_traducao']} required />
                                                                    {this.state.formvalidation["descricao"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                        <span className="error">
                                                                            { this.state.formvalidation["descricao"][1]}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group >
                                                                    <Form.Control as="textarea" rows={8} onChange={this.handleChange}  value={parse(String(this.state.formvalues.licenca))} name="licenca" placeholder={this.props.gcontent.data.traducoes['politica-uso']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['portugues']['palavra_traducao']} required />
                                                                    {this.state.formvalidation["licenca"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                        <span className="error">
                                                                            { this.state.formvalidation["licenca"][1]}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group >
                                                                    <Form.Control type="text" onChange={this.handleChange}  value={this.state.formvalues.tituloEN} name="tituloEN" placeholder={this.props.gcontent.data.traducoes['title']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['ingles']['palavra_traducao']} required />
                                                                    {this.state.formvalidation["tituloEN"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                        <span className="error">
                                                                            { this.state.formvalidation["tituloEN"][1]}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group >
                                                                    <Form.Control as="textarea" rows={3} onChange={this.handleChange}  value={parse(String(this.state.formvalues.descricaoEN))} name="descricaoEN" placeholder={this.props.gcontent.data.traducoes['description']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['ingles']['palavra_traducao']} required />
                                                                    {this.state.formvalidation["descricaoEN"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                        <span className="error">
                                                                            { this.state.formvalidation["descricaoEN"][1]}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group >
                                                                    <Form.Control as="textarea" rows={8} onChange={this.handleChange}  value={parse(String(this.state.formvalues.licencaEN))} name="licencaEN" placeholder={this.props.gcontent.data.traducoes['politica-uso']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['ingles']['palavra_traducao']}  required/>
                                                                    {this.state.formvalidation["licencaEN"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                        <span className="error">
                                                                            { this.state.formvalidation["licencaEN"][1]}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <Form.Group >
                                                                    <Form.Control as="select" onChange={(e) =>this.onCedenciachange(e)} value={this.state.formvalues.cedencia}> 
                                                                        <option value="">{this.props.gcontent["data"]["traducoes"]["prazo_cedencia"]["palavra_traducao"]}</option> 
                                                                        {this.cedenciaRender()}
                                                                    </Form.Control>
                                                                    {this.state.formvalidation["cedencia"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                        <span className="error">
                                                                            { this.state.formvalidation["cedencia"][1]}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>   
                                                            {store.getState()["getatauser"]["userdata"]["admin"]==1 && (
                                                            <Col lg={6}>
                                                                <Form.Group className="editPermissions"  >
                                                                    <Form.Check className="customCheckbox " onChange={() => this.checkVisivel() }  type="checkbox" value={this.state.formvalues.visivel} label={this.props.gcontent["data"]["traducoes"]["ficheiro_visivel"]["palavra_traducao"]} checked={  this.state.formvalues.visivel==1 ? true : false }  />
                                                                </Form.Group>
                                                            </Col>      
                                                            )}                           
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <Form.Group >
                                                                    <Form.Control type="text"  onChange={this.handleChange}  value={this.state.formvalues.tags} name="tags" placeholder={'Tags ' + this.props.gcontent.data.traducoes['portugues']['palavra_traducao']} required/>
                                                                    <small><b>Ex:</b> tag1,tag2</small>
                                                                    {this.state.formvalidation["tags"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                        <span className="error">
                                                                            { this.state.formvalidation["tags"][1]}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>                                                
                                                            <Col lg={6}>
                                                                <Form.Group >
                                                                    <Form.Control type="text"  onChange={this.handleChange}  value={this.state.formvalues.tagsEN} name="tagsEN" placeholder={'Tags ' + this.props.gcontent.data.traducoes['ingles']['palavra_traducao']} required/>
                                                                    <small><b>Ex:</b> tag1,tag2</small>
                                                                    {this.state.formvalidation["tagsEN"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                        <span className="error">
                                                                            { this.state.formvalidation["tagsEN"][1]}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>                                                
                                                        </Row>
                                                        {this.state.action == 1 && (
                                                            <>
                                                                <Row>
                                                                    <Col>
                                                                        <Form.Group id="formcheck-iconfile" className="fileField">
                                                                            <Form.File.Input id="iconFile" onChange={(e) => this.handleFile(e)} name="iconfile" isValid />                                                    
                                                                            <div className="iconFile_Placeholder" htmlFor="iconFile">{!file && this.props.gcontent["data"]["traducoes"]["attachFile"]["palavra_traducao"]}</div>
                                                                            <div className="iconFile_button">{file}<span className="icon-plus"></span></div>   
                                                                            {file == '' && this.state.formvalidation["formsubmited"] == true && 
                                                                                <span className="error">
                                                                                    {this.props.gcontent.data.traducoes['field_required']['palavra_traducao']}
                                                                                </span>
                                                                            }
                                                                        </Form.Group>
                                                                    </Col>
                                                                </Row>                                     
                                                            </>
                                                        )}   

                                                        <Row>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Control type="text" onChange={this.handleChange}  value={this.state.formvalues.autor} name="autor" placeholder="Autor" />
                                                                    {this.state.formvalidation["autor"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                        <span className="error">
                                                                            { this.state.formvalidation["autor"][1]}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <Form.Group className="m-0">
                                                                    <Form.Control as="select" onChange={(e) =>this.onIlhachange(e)} value={this.state.formvalues.ilha}>
                                                                        <option value="" hidden>{this.props.gcontent.data.traducoes['selectIsland']['palavra_traducao']}</option>
                                                                        {this.props.gcontent != null && Object.keys(this.props.gcontent["data"]["ilhas"]).map((item, i) => {
                                                                            if(idilha != this.props.gcontent["data"]["ilhas"][item]["idilhas"] || idilha == ''){
                                                                                idilha = this.props.gcontent["data"]["ilhas"][item]["idilhas"]
                                                                                return(
                                                                                    <option key={'ilha-'+i} value={this.props.gcontent["data"]["ilhas"][item]["idilhas"]}>{this.props.gcontent["data"]["ilhas"][item]["nome_ilha"]}</option>
                                                                                )
                                                                            }
                                                                        })}
                                                                    </Form.Control>
                                                                    {this.state.formvalidation["ilha"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                        <span className="error">
                                                                            { this.state.formvalidation["ilha"][1]}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <Form.Group className="m-0">
                                                                    <Form.Control type="text"  onChange={this.handleChange}  value={this.state.formvalues.localidade} name="localidade" placeholder="Zona" />
                                                                    {this.state.formvalidation["localidade"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                        <span className="error">
                                                                            { this.state.formvalidation["localidade"][1]}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                        </Row> 
                                                        <Row>
                                                            <Col className="mt-3">
                                                                <Form.Group className="m-0">
                                                                    <h3 className="contentTitle contentTitle_sub">{this.props.gcontent.data.traducoes['category']['palavra_traducao']}</h3>
                                                                    {this.state.idFile !='' && this.loadCatsEdit(this.state.idFile) }
                                                                    {this.state.catsList.length !=0 && 
                                                                        this.state.catsList
                                                                        //this.loadCats()
                                                                    }
                                                                    {this.state.formvalues.categoria.length == 0 && this.state.formvalidation["formsubmited"] == true && this.state.idFile == 0 &&  
                                                                        <span className="error">
                                                                            {this.props.gcontent.data.traducoes['field_required']['palavra_traducao']}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col> 
                                                                <button className="add_Subcategory" onClick={(e) => this.addQuestion(e)} ><span className="icon-plus"></span>{this.props.gcontent["data"]["traducoes"]["add_categoria"]["palavra_traducao"]}</button>
                                                            </Col>
                                                        </Row> 
                                                        <Row>
                                                            <Col className="mt-3">
                                                                <Form.Group className="m-0">
                                                                    <h3 className="contentTitle contentTitle_sub">{this.props.gcontent.data.traducoes['permissions']['palavra_traducao']}</h3>
                                                                    {this.state.idFile != '' && this.loadProfilesEdit(this.state.idFile)}
                                                                    {//this.state.formvalues.profiles.length != 0 && 
                                                                        this.state.profileList.length != 0 && 
                                                                            this.state.profileList
                                                                             //this.loadProfiles()
                                                                    }
                                                                    {this.state.formvalues.profile.length == 0 && this.state.formvalidation["formsubmited"] == true && this.state.idFile == 0 &&
                                                                        <span className="error">
                                                                            {this.props.gcontent.data.traducoes['field_required']['palavra_traducao']}
                                                                        </span>
                                                                    }
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col> 
                                                                <button className="add_Subcategory" onClick={(e) => this.addProfile(e)} ><span className="icon-plus"></span>{this.props.gcontent["data"]["traducoes"]["add_profile"]["palavra_traducao"]}</button>
                                                            </Col>
                                                        </Row> 
                                                        <Row>
                                                            <Col>
                                                                <Form.Group className="formSubmit">
                                                                    <Button onClick={() => this.cleanContent()} > {this.props.gcontent["data"]["traducoes"]["bt_limpar"]["palavra_traducao"]} </Button>
                                                                    <Button onClick={(e) => this.handleSubmit(e)} variant="primary" name="submit" type="submit">{this.state.submitlabel}</Button>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                        <Row id="formoutput"></Row>
                                                    </Form>
                                                }
                                            </div>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={"list"}>
                                        <>
                                            <FilterComponent
                                                filterText={this.state.searchstring}
                                                onFilter={e => this.setFilterText(e.target.value)}
                                                placeholdertxt={this.props.gcontent.data.traducoes["search"]["palavra_traducao"] + '...'}
                                            />
                                            <DataTableExtensions 
                                                filterPlaceholder={this.props.gcontent.data.traducoes["search"]["palavra_traducao"] + '...'} 
                                                filter={false} 
                                                filterHidden={false} 
                                                columns={this.state.ascolunas} 
                                                data={this.state.osdados} 
                                            >
                                                <DataTable
                                                    noHeader={true}
                                                    defaultSortField="id"
                                                    defaultSortAsc={false}
                                                    progressPending={this.state.isLoading}
                                                    progressComponent={ <div id="loadingContent"><Lottie options={{ animationData: animationLoader }} /></div> }
                                                    //pagination={false}
                                                    //subHeader={true}
                                                    //subHeaderComponent={this.subHeaderComponentMemo}
                                                    pagination
                                                    paginationServer
                                                    paginationTotalRows={this.state.totalRows}
                                                    onChangeRowsPerPage={this.handlePerRowsChange}
                                                    onChangePage={this.handlePageChange}

                                                    highlightOnHover={true}
                                                    //paginationComponentOptions={{rowsPerPageText: this.props.gcontent.data.traducoes['rows_page']['palavra_traducao'] + ':'}}
                                                />
                                            </DataTableExtensions>
                                        </>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };

}

export default Upload;