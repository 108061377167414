import React, { Component, useState } from 'react';
import { Container } from 'react-bootstrap';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import { Modal, ModalGateway } from "react-images";

class btnLightbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentImage: 0,
            viewerIsOpen: false,
        };
    }
    openbox = (event) => {
        //console.log(event.currentTarget.parentNode.parentNode.querySelector('img').src);
        //alert(this.props.type)
        this.setState({
            currentImage: this.props.source, 
            viewerIsOpen: true
        })
    };

    closeLightbox = () => {
        this.setState({
            currentImage: 0, 
            viewerIsOpen: false
        });
    };
    
    render() {
        return (
            <>
                <button onClick={this.openbox}><span className="icon-image-lightbox"></span></button>
                <ModalGateway>
                    {this.state.viewerIsOpen ? (
                        <Modal onClick={this.closeLightbox}>
                            <ReactImageVideoLightbox
                                data={[
                                {url: this.state.currentImage,
                                type: this.props.type,
                                altTag: 'teste'}
                            ]}
                            startIndex={0}
                            onCloseCallback={this.closeLightbox} />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </>
        )
    };  
}
export default btnLightbox;
