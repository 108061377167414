import React, { useState, useCallback } from "react";
import { render } from "react-dom";
import Gallery from "react-photo-gallery";
import HoverImage from "./hoverImage.js";
import { Modal, ModalGateway } from "react-images";
import ReactImageVideoLightbox from 'react-image-video-lightbox';


function GaleryImg(props) {
 // console.log(props.photos)
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  
  const openLightbox = useCallback((event, {photo, index} ) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);
  
  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  
  const openbox = (index) => {
   // console.log(index);
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const imageRenderer = useCallback( ({ index, left, top, key, photo }) => (
      <HoverImage
        key={key}
        margin={"15px"}
        index={index}
        photo={photo}
        left={left}
        top={top}
        lightbox={openbox}
      />
    )
  );

  return (
    <>
      <Gallery photos={props.photos} targetRowHeight={280} margin={15} columns={3} renderImage={imageRenderer}/>
      <ModalGateway>
        {viewerIsOpen ? (
        <Modal onClick={closeLightbox}>
          <ReactImageVideoLightbox
          data={props.photos.map(x => ({
            key: x.id,
            url: x.url,
            type: x.type,
            altTag: x.title
          }))}
          startIndex={currentImage}
          showResourceCount={true}
          onCloseCallback={closeLightbox} />
        </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
}
export default GaleryImg

