import React, { Component } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import PageSideInfo from '../PageSideInfo';
import Breadcrumbs from '../Breadcrumbs';
import Header from './Header';
import * as loadedcontent from '../loadcontent';
import BtnFavorite from "../../helpers/btnFavorite";
import Lottie from 'lottie-react-web';
import animationLoader from '../../assets/loading_stats.json';
import { store } from '../../redux/store';
import { security } from '../../helpers/Security';
import { Redirect } from 'react-router-dom';

let storeuser = '';

class Page extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            redirect: security(),
            imagePage: '',
            myList: '',
            currentImage: 0,
            viewerIsOpen: false,
            gostos: 0,
            favorite: false,
          };
    } 
    openlightbox = (event) =>{
        this.setState({
            currentImage: event.currentTarget.getAttribute('data-url'), 
            viewerIsOpen: true
        })
    }
    closeLightbox = () => {
        this.setState({
            currentImage: 0, 
            viewerIsOpen: false
        });
    };
   /* componentDidUpdate(){
        this.loadContent();
    }
    */
	
	 componentDidUpdate (nextProps) {    
       
        if (this.state.idImage != this.props.match.params.id){
            window.scrollTo(0, 0)
            this.setState({idImage:this.props.match.params.id})
            this.loadContent()
        } 
      }
    componentDidMount(){
        window.scrollTo(0, 0)
        this.loadContent();
    }   

    async loadContent(e){
        //e.preventDefault()
        storeuser = store.getState()["getatauser"]["userdata"];
        let lang = store.getState()["getlang"]["idlingua"];
        if(storeuser != undefined){
            let tdata = { language:lang, ataregistoid:storeuser["idregisto"], "idfile": this.props.match.params.id,  
                            rightfilters: { mrecentes: false, mvistas: true, mfavoritas: false  }
                        }
            let thephotos = await loadedcontent.global_postaction("actionSearchfiles", tdata, 0)
            let setVisualizacao = await loadedcontent.global_postaction("actionAddVisualizao", {idregisto:storeuser["idregisto"] , "idfile": this.props.match.params.id}, 0)
            let themylist = await loadedcontent.global_postaction("actionFavoritos", {"idregisto": storeuser["idregisto"] } , 0);
            let osgostos = await loadedcontent.global_postaction("actionFavoritos", { 'idregisto': storeuser["idregisto"] , 'idfile' : this.props.match.params.id } , 7);
            this.setState({
                imagePage: thephotos.data.images[0],
                gostos: osgostos.data,
                myList: themylist.data
            })
        }
    }

    render() {
        if( this.state.redirect == true || this.props.match.params.id == 'null' || this.state.imagePage === undefined ){
            return <Redirect to={"/home"} />
        }        
        return (
            <>
                <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} gcontent={this.props.gcontent} theheader="default" />
                <Container className="content contentPage">
                    {this.state.imagePage && 
                        <Breadcrumbs {...this.props} gcontent={this.props.gcontent} pageImg={this.state.imagePage}/>
                    }
                    <Row className="content-sub">
                        <Col lg={3} className="">
                        {this.state.imagePage ? (
                            <PageSideInfo gostos={this.state.gostos} image={this.state.imagePage} mylist={this.state.myList} {...this.props} />
                        ):(
                            <div id="loadingContent"><Lottie options={{ animationData: animationLoader }} /></div>
                        )}
                        </Col>
                        <Col lg={9} className="">
                            {this.state.imagePage['thumb'] ?
                            (
                                <div className="itemWrapper imagePreview">
                                    <div style={{ display : "none" }} className="itemWrapper_hover">{/* imgFavorite */}
                                        <BtnFavorite idPhoto={this.state.imagePage['id_ficheiro']} />
                                    </div>
                                    {this.state.imagePage['tipo_ficheiro'] == 0 && (
                                        <video width="100%" className="imagePreview_img" src={global.src + 'atavid/prev/' +this.state.imagePage['videopreview']}  controls autoPlay muted></video>
                                    )}
                                    {this.state.imagePage['tipo_ficheiro'] == 1 && <img className="imagePreview_img" src={ global.src +  'ataimg/tb/'  + this.state.imagePage['thumb']} alt="ata"></img> }
                                    
                                </div>
                            ):(
                                <div id="loadingContent"><Lottie options={{ animationData: animationLoader }} /></div>
                            )}

                        </Col>
                    </Row>
                </Container>
 
            </>
        );
    };

}

export default Page;