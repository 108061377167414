import React, { Component, Image } from "react";
import { Row, Container, Col, Button, Form, Breadcrumb, Nav, Tab, } from "react-bootstrap";
import Usermenu from "../Usermenu";
import Breadcrumbs from "../Breadcrumbs";
import Header from "./Header";
import * as loadedcontent from "../loadcontent";
import { validate, File } from "../../helpers/Validationfuncs";
import { security } from '../../helpers/Security';
import Loader from "../../helpers/Loader";
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import Lottie from 'lottie-react-web';
import respok from '../../assets/response_ok.json';
import resperror from '../../assets/response_error.json';
//--------------Data table-----------------
import 'react-data-table-component-extensions/dist/index.css';
//-----------------------------------------

class Definicoes extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.cleanContent = this.cleanContent.bind(this);
        this.handleredessociais = this.handleredessociais.bind(this);
        this.homefileField = this.homefileField.bind(this);
        this.state = {
            redirect: security(),
            delmess: false,
            keymessageout: "",
            classkeymessageout: "",
            registoCRUD: false,
            submitlabel: this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao'],
            clearlabel: this.props.gcontent.data.traducoes['bt_limpar']['palavra_traducao'],
            homepageimage: "",
            homepageform: false,
            loading: false,
            filelink: '',
            action: 1,
            keyTab: "list",
            homefileField: false,
            image: '',
            idrede: 0,
            validimage: false,
            fileName: "",
            formvalidation: {
                formsubmited: false,
                homepagefile: [false, "", ["noempty", "file"]],
            },
            formvalues: {
                homepagefile: "",
            },
            ascolunas: [
                {minWidth: "150px", wrap: true, name: this.props.gcontent.data.traducoes['image']['palavra_traducao'], selector: row => row["imagem"], sortable: false, cell: d => <img className="socialNet_img" src={global.src+"ataficheiros/social/" + d.imagem} height="50" width="50" /> },
                {minWidth: "150px", wrap: true, name: this.props.gcontent.data.traducoes['reference']['palavra_traducao'], selector: row => row["referencia"], sortable: true, },
                {minWidth: "150px", wrap: true, name: "Link", selector: row => row["link"], sortable: true, },
                {minWidth: "150px", wrap: true, name: " ", selector: row => row["crud"], sortable: false, cell: d => <div>
                                                                                <span className="table-actionsItem" title={this.props.gcontent.data.traducoes['editar']['palavra_traducao']} onClick={() => { this.handleEdit(d.crud); this.changeTab("form"); }} >
                                                                                    {" "} <i className="far fa-edit"></i>{" "}
                                                                                </span>
                                                                                <span className="table-actionsItem" title={this.props.gcontent.data.traducoes['eliminar']['palavra_traducao']} onClick={() => this.handleDelete(d.crud, null)} >
                                                                                    {" "}<i className="far fa-trash-alt"></i>{" "}
                                                                                </span>
                                                                            </div> }
            ],
            osdados: []
        };
    }

    cleanContent() {
        this.setState({
            delmess: false,
            keymessageout: "",
            classkeymessageout: "",
            registoCRUD: false,
            submitlabel: this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao'], 
            homepageimage: "",
            homepageform: null,
            loading: false,
            action: 1,
            idrede: 0,
            fileName: "",
            formvalidation: {
                formsubmited: false,
                homepagefile: [false, "", ["noempty", "file"]],
            },
            formvalues: {
                homepagefile: "",
            },
        });
    }

    handleChange(event) {
        let fvalues = this.state.formvalues;
        fvalues[event.target.name] = event.target.value;
        this.setState({ formvalues: fvalues });
    }

    handleredessociais = () => {
        if (this.props.gcontent.data.homebackground[2]["link"] != ''){
            this.setState({filelink: global.src + "ataficheiros/homepage/" + this.props.gcontent["data"]["homebackground"][2]["link"], homefileField: false})
        }

    };

    handleFile(event) {
        event.preventDefault();
        let tdata = this.state.formvalues;
        var tdataform = this.state.formvalidation; //VALIDATION
        let file = event.target.files;
        tdata["homepagefile"] = file[0].name;
        let _tmp = File(file[0], ["image/jpeg", "image/png"] , 60000000,
            [
                this.props.gcontent.data.traducoes['only_files']['palavra_traducao'],
                this.props.gcontent.data.traducoes['file_heavy']['palavra_traducao']
            ]
        );
        if(_tmp != ""){
          tdataform["homepagefile"][1] = _tmp; 
          tdataform["homepagefile"][0] = false; 
          this.setState({ validimage: false });
        }else{
          tdataform["homepagefile"][1] = _tmp; 
          tdataform["homepagefile"][0] = true; 
          this.setState({ validimage: true });
        }
        this.setState({ 
            formvalidation: tdataform,
            formvalues: tdata,
            homepagefileform: file, 
            fileName: file[0].name
        });
    }

    async handleSubmit(event) {
        event.preventDefault();
        let tdata = this.state.formvalues;
        var tdataform = this.state.formvalidation; //VALIDATION
        let _tmpiconmess = tdataform["homepagefile"][1];
        let tmpvalidate = validate(tdataform, tdata, '',
            [
                this.props.gcontent.data.traducoes['field_required']['palavra_traducao'],
                this.props.gcontent.data.traducoes['min_char']['palavra_traducao'],
                this.props.gcontent.data.traducoes['spaces_nallowed']['palavra_traducao'],
                this.props.gcontent.data.traducoes['min8_char']['palavra_traducao'],
                this.props.gcontent.data.traducoes['psw_rule']['palavra_traducao'],
                this.props.gcontent.data.traducoes['only_number']['palavra_traducao'],
                this.props.gcontent.data.traducoes['invalid_email']['palavra_traducao'],
            ]
        ); //return [validform, tdataform, tdata ];
        tmpvalidate[1]["homepagefile"][1] = (this.state.validimage == false && _tmpiconmess != "" )? _tmpiconmess : tmpvalidate[1]["homepagefile"][1];
        tmpvalidate[1]["homepagefile"][0] = (this.state.validimage == false && _tmpiconmess != "" )? false :  tmpvalidate[1]["homepagefile"][0];
        this.setState({ formvalidation: tmpvalidate[1] }); let filename = "";
        //-----------------------------------------------
        tmpvalidate[0] = false;
        if( this.state.validimage == true ){
            tmpvalidate[0] = true;

            filename = ( tdata["homepagefile"] != "" )? await loadedcontent.upload_file(this.state.homepagefileform,'homepage') : false ;
            if( filename == false ){ tmpvalidate[0] = false; }
            tmpvalidate[2]["homepagefile"] = filename;
            this.setState({ formvalues: tmpvalidate[2] });
        }else if( this.state.validimage == false && tdata['homepagefile'] != ''){
            tmpvalidate[2]["homepagefile"] = tdata['homepagefile'];
            tmpvalidate[0] = true;
        }
        //----------------------------------------------
        if (tmpvalidate[0] == true) {
            //------------------------------------------
            this.setState({ loading: !this.state.loading });
            setTimeout(
                async function () {
                    if(this.props.gcontent.data.homebackground[2] !== 'undefined'){
                        this.setState({action: 3});
                    }
                    var data = {homepagefile: this.state.formvalues["homepagefile"]};
                    let crud = await loadedcontent.global_postaction( "actionDefinicoes", data, this.state.action );
                    if( crud["success"] != "" ){ 
                        this.setState({ classkeymessageout: "success-out" , keymessageout: "global_actualizar" });
                        this.setState({ registoCRUD: true });
                    }else{
                        this.setState({ classkeymessageout: "error-out" , keymessageout: "globalerro_actualizar" });
                        this.setState({ registoCRUD: true });
                    }
                    this.props.linguafunc(store.getState()["getlang"]["idlingua"]);
                    this.handleredessociais();
                    this.setState({ loading: !this.state.loading, image: filename});//Não está fazendo nada aqui
                }.bind(this),
                500
            );
        }
    }

    componentDidMount() {
        this.handleredessociais();
        this.setState({
            image: this.props.gcontent["data"]["homebackground"][2]["link"],
        })
    }

    changeTab = (keyTab) => {
        if (keyTab == 'list')
            this.cleanContent()

        this.setState({ keyTab });
    };

    homefileField(){

        this.setState({ homefileField: !this.state.homefileField });
    }
    
    render() {
        if( this.state.redirect == true ){
            return <Redirect to={"/"} />
        }else if(store.getState()["getatauser"]["userdata"]["admin"] != 1){ 
            return <Redirect to="/" /> 
        }
        const { fileName } = this.state;
        const tableData = { columns: this.state.ascolunas, data: this.state.osdados, };
        let file = null;
        file = fileName && (
            <label className="iconFile_Name">
                <span className="icon-paperclip"></span> {fileName}
            </label>
        );
        return (
            <>
                {this.state.loading == true && <Loader />}
                { this.state.registoCRUD == true &&
                    <div className="preload-stuff">
                        <div className="position-center">
                            <div className="message-box responseOutput">
                                <div className="responseOutput_gif">
                                    <Lottie options={{ animationData: (this.state.classkeymessageout == "success-out")? respok : resperror , loop: false }} />
                                </div>
                                <span className={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                            </div>
                        </div>
                    </div>
                }
                {this.state.delmess == true &&
                    <div className="preload-stuff">
                        <div className="position-center">
                            <div className="message-box message-boxPopup responseOutput-box">
                                <span className="messagedel">{this.props.oestado.var_globalcontent["data"]["traducoes"]["eliminardados"]['palavra_traducao']} </span>
                                <div className="message-boxActions">
                                    <div onClick={() => this.handleDelete(0 , 1)} >
                                        <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["yes"]['palavra_traducao']} </span>
                                    </div   >
                                    <div onClick={() => this.handleDelete(0 , 0)} >
                                        <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["no"]['palavra_traducao']} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} gcontent={this.props.gcontent} theheader="default"/>
                <Container className="content contentPage">
                    <Breadcrumbs {...this.props} gcontent={this.props.gcontent} />
                    <div className="titlePage">
                        {this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b>
                    </div>
                    <Row className="contentOffice">
                        <Col className="menuDrop">
                            <Usermenu gcontent={this.props.gcontent}/>
                        </Col>
                        <Col>
                            <h3 className="contentTitle">{this.props.gcontent.data.traducoes['definicoes']['palavra_traducao']}</h3>
                            <Form className="adminForms">
                                <Form.Label>{this.props.gcontent.data.traducoes['homepageImg']['palavra_traducao']}</Form.Label>
                                {this.props.gcontent.data.homebackground[2]['link'] && (
                                    <div className="homepageChange">
                                        <span className="toggle homefileField" onClick={this.homefileField}><i className="far fa-edit"></i></span>
                                        <img width="250" src={global.src + "ataficheiros/homepage/" + this.state.image}></img>
                                    </div>
                                )}
                                {this.state.homefileField && (
                                    <>
                                        <Form.Group id="formcheck-iconfile" className="fileField">
                                            <Form.File.Input id="homepageFile" onChange={(e) => this.handleFile(e)} name="homepagefile" isValid />
                                            {!file &&
                                            <div className="iconFile_Placeholder" htmlFor="homepageFile" >
                                                {this.props.gcontent.data.traducoes['attach_img']['palavra_traducao']}
                                            </div>}
                                            <div className="iconFile_button"> {file} <span className="icon-plus"></span> </div>
                                            {this.state.formvalidation["homepagefile"][0] == false && 
                                                <span className="error">
                                                    { this.state.formvalidation["homepagefile"][1]}
                                                </span>
                                            }
                                        </Form.Group>
                                        <Form.Group className="formSubmit">
                                            <Button variant="primary" onClick={(e) => this.handleSubmit(e)}  name="guardarsubmit" type="submit">{this.props.gcontent.data.traducoes['save_changes']['palavra_traducao']}</Button>
                                        </Form.Group>
                                    </>
                                )}
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Definicoes;
