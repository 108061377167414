import React, { Component } from "react";
import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "sanitize.css";
import CryptoJS from 'crypto-js';
import { store } from "./redux/store";
import * as loadedcontent from "./components/loadcontent";
import components from "./components/module";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Cookies from 'universal-cookie';
import detectBrowserLanguage from 'detect-browser-language'
import "./App.css";

class App extends Component {
  constructor(props) {
    super(props);
    this.updatelang = this.updatelang.bind(this);
    this.state = {
      var_globalcontent: null,
      var_profiles: null,
      admin: 1,
      userlogged: { admin: 0, tuser: "Loading", }, 
    };
    store.dispatch({ type: "domain", param: "https://api.visitazoresstockgallery.com/atasws/wstata.php" }); //http://zidev/ata/ataapi/atasws/wstata.php
  }

  async updatelang(idlingua){
    const cookies = new Cookies();
    let gdata = cookies.get('ataextensions');
    if( gdata != undefined ){
        var ciphertext = CryptoJS.AES.encrypt( JSON.stringify({ "idlingua" : idlingua }) , global.param3).toString();
        cookies.set('ataextensions-lang', ciphertext, { path: '/' , expires: global.cookiedate });
    }
    store.dispatch({ type: "thelang", param: { "idlingua" : idlingua } });
    this.setState({
        var_globalcontent: await loadedcontent.load_globalcontent(idlingua),
        var_profiles: await loadedcontent.load_profiles(idlingua),
    }); 
  }

  async componentDidMount() {
      let ldparam = await loadedcontent.load_qvars();
      global.param1=ldparam["param1"]; global.param2=ldparam["param2"]; global.param3=ldparam["param3"].replace('xsc','').replace('qwer','').replace('d22','');
      //--------------------------------------------------------
      if( ldparam != undefined ){
        if( ldparam["param1"] != undefined ){
            let storelang = store.getState()["getlang"];
            const cookies = new Cookies();
            let gdatalang = cookies.get('ataextensions-lang');
            if( gdatalang != undefined ){
              var bytes  = CryptoJS.AES.decrypt(gdatalang, global.param3); 
              //console.log(bytes.toString(CryptoJS.enc.Utf8));
              var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
              store.dispatch({ type: "thelang", param: decryptedData }); 
            }else if (storelang == undefined || storelang == "") {
              var dtlang = ( detectBrowserLanguage() == "pt-PT" || detectBrowserLanguage() == "pt-BR" )? "pt" : "en" ;
              store.dispatch({ type: "thelang", param: { "idlingua" : dtlang } });
              //var ciphertext = CryptoJS.AES.encrypt( JSON.stringify({ "idlingua" : dtlang }) , global.param3).toString();
              //cookies.set('ataextensions-lang', ciphertext, { path: '/' , expires: global.cookiedate });
            }

            this.setState({               
              var_globalcontent: await loadedcontent.load_globalcontent( store.getState()["getlang"]["idlingua"] ),
              var_profiles: await loadedcontent.load_profiles( store.getState()["getlang"]["idlingua"] ),
            });
        }
      }
  }

  //<Route path="/upload" component={() => <components.Upload linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
  render() {
    //console.log(this.state);
    return (
      <div className="main">
        {this.state.var_globalcontent != null && 
            <BrowserRouter>
              {/*<components.Header linguafunc={this.updatelang} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />*/}
              <Switch>
                <Route path="/" component={() => <components.Login loginfunc={this.userlog_in} linguafunc={this.updatelang} osperfis={this.state.var_profiles} gcontent={this.state.var_globalcontent} oestado={this.state} thelogo={logo} theheader="home" thefooter=""/>} exact={true}/> 
                <Route path="/passwordrecover/:id" component={(props) => <components.Passwordrecover {...props} oestado={this.state} linguafunc={this.updatelang} osperfis={this.state.var_profiles} gcontent={this.state.var_globalcontent} thelogo={logo} theheader="home" thefooter=""/>} exact={true}/> 
               
                <Route path="/home" component={() => <components.Home linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                
                <Route path="/page/:id" component={(props) => <components.Page {...props} linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/pesquisa/:search" component={(props) => <components.Pesquisa {...props} linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                {/*<Route path="/registo" component={() => <components.Registo oestado={this.state}  theheader="home" gcontent={this.state.var_globalcontent} thefooter="hidden"/>} exact={true}/>*/}
                <Route path="/politicas" component={() => <components.Politicas typePage={"politica"} linguafunc={this.updatelang} theheader="defaultHeader" thefooter="" oestado={this.state} gcontent={this.state.var_globalcontent} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/termos" component={() => <components.Politicas typePage={"termos"} linguafunc={this.updatelang} theheader="defaultHeader" thefooter="" oestado={this.state} gcontent={this.state.var_globalcontent} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/cookies" component={() => <components.Politicas typePage={"cookielink"} linguafunc={this.updatelang} theheader="defaultHeader" thefooter="" oestado={this.state} gcontent={this.state.var_globalcontent} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/politicaetermos" component={() => <components.Politicaetermos linguafunc={this.updatelang} theheader="defaultHeader" thefooter="" gcontent={this.state.var_globalcontent} oestado={this.state} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/redessociais" component={() => <components.Redessociais linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/definicoes" component={() => <components.Definicoes linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/traducoes" component={() => <components.Traducoes linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/categorias" component={() => <components.Categorias linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/sobre" component={() => <components.Sobre linguafunc={this.updatelang} theheader="defaultHeader" thefooter="" gcontent={this.state.var_globalcontent} oestado={this.state} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/perfis" component={() => <components.Perfis linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/utilizadores" component={() => <components.Utilizadores linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/editarperfil" component={() => <components.Meuperfil linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/upload" component={() => <components.Upload linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/permissoes" component={() => <components.Permissoes linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/mylist" component={() => <components.Mylist linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/gostos" component={() => <components.Gostos linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/prevdownloads" component={() => <components.Prevdownloads linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/notificacoes" component={() => <components.Notificacoes linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route path="/license/" component={(props) => <components.License {...props} linguafunc={this.updatelang} theheader="defaultHeader" gcontent={this.state.var_globalcontent} oestado={this.state} osperfis={this.state.var_profiles} osidiomas={this.state.var_globalcontent} />} exact={true}/>
                <Route component={components.Pagenotfound} exact={true} />
              </Switch>
              { <components.Footer oestado={this.state} gcontent={this.state.var_globalcontent} thelogo={logo} exact={true} /> }
            </BrowserRouter>
        }
      </div>    
    );
  };
}

export default App;
