import React, { Component, useState } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
 
/*
    export default function MyStatefulEditor(){
        const [data, setData] = useState('');

        const handlechange = (e, editor)=>{
            setData(editor.getData());
        }
        return (
            <CKEditor
                editor={ClassicEditor}
                data={''}
                onChange={handlechange}/>
        );
    }
*/
ClassicEditor.defaultConfig = {
    toolbar: [ 'heading', '|', 'bold', 'italic' , 'link', 'bulletedList' , 'numberedList', '|' 
    , 'indent', 'outdent', '|' , 'blockQuote' , 'inserttable', 'undo', 'redo' ],
    // This value must be kept in sync with the language defined in webpack.config.js.
    language: 'pt'
};
 
export default class MyStatefulEditor extends Component {
        constructor(props) {
            super(props);
            this.state = {
                dados: this.props.osdados,
                nome: this.props.onome,
                osvalores: this.props.fvalues
            };
        }

        aoalterar = ( e , editor ) =>{
            this.setState({
                dados: editor.getData()
            })
            if( this.state.nome == "formpolitica" ){
                let _tmp = this.state.osvalores;
                _tmp["politica"] = editor.getData();  
                this.setState({osvalores: _tmp});
            }else if( this.state.nome == "formtermos" ){
                let _tmp = this.state.osvalores;
                _tmp["termos"] = editor.getData();
                this.setState({osvalores: _tmp});
            }else if( this.state.nome == "formcookies" ){
                let _tmp = this.state.osvalores;
                _tmp["cookies"] = editor.getData();  
                this.setState({osvalores: _tmp});
            }else if( this.state.nome == "formregisto" ){
                let _tmp = this.state.osvalores;
                _tmp["registo"] = editor.getData();  
                this.setState({osvalores: _tmp});
            }
            //console.log(this.state.nome);
            //this.props.metodo( this.state.nome , this.state.dados );
        }
        render() {
            return (
                <CKEditor
                    editor={ClassicEditor}
                    data={this.state.dados}
                    onChange={(e, editor)=>{this.aoalterar(e, editor)}}/>
            );
        }
    }
     
 