import React, { Component, Image } from "react";
import { Row, Container, Col, Button, Form, Breadcrumb, Nav, Tab, } from "react-bootstrap";
import Usermenu from "../Usermenu";
import Breadcrumbs from "../Breadcrumbs";
import Header from "./Header";
import * as loadedcontent from "../loadcontent";
import { validate, File } from "../../helpers/Validationfuncs";
import { security } from '../../helpers/Security';
import Loader from "../../helpers/Loader";
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import Lottie from 'lottie-react-web';
import respok from '../../assets/response_ok.json';
import resperror from '../../assets/response_error.json';
//--------------Data table-----------------
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
//-----------------------------------------

class Redessociais extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.cleanContent = this.cleanContent.bind(this);
        this.handleredessociais = this.handleredessociais.bind(this);
        this.state = {
            redirect: security(),
            delmess: false,
            keymessageout: "",
            classkeymessageout: "",
            registoCRUD: false,
            submitlabel: this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao'],
            clearlabel: this.props.gcontent.data.traducoes['bt_limpar']['palavra_traducao'],
            socialimage: "",
            socialimageform: null,
            loading: false,
            action: 1,
            keyTab: "list",
            idrede: 0,
            validimage: false,
            fileName: "",
            formvalidation: {
                formsubmited: false,
                socialname: [false, "", ["noempty"]],
                sociallink: [false, "", ["noempty"]],
                iconfile: [false, "", ["noempty", "file"]],
            },
            formvalues: {
                socialname: "",
                sociallink: "",
                iconfile: "",
            },
            ascolunas: [
                {minWidth: "150px", wrap: true, name: this.props.gcontent.data.traducoes['image']['palavra_traducao'], selector: row => row["imagem"], sortable: false, cell: d => <img className="socialNet_img" src={global.src+"ataficheiros/social/" + d.imagem} height="50" width="50" /> },
                {minWidth: "150px", wrap: true, name: this.props.gcontent.data.traducoes['reference']['palavra_traducao'], selector: row => row["referencia"], sortable: true, },
                {minWidth: "150px", wrap: true, name: "Link", selector: row => row["link"], sortable: true, },
                {minWidth: "150px", wrap: true, name: " ", selector: row => row["crud"], sortable: false, cell: d => <div>
                                                                                <span className="table-actionsItem" title={this.props.gcontent.data.traducoes['editar']['palavra_traducao']} onClick={() => { this.handleEdit(d.crud); this.changeTab("form"); }} >
                                                                                    {" "} <i className="far fa-edit"></i>{" "}
                                                                                </span>
                                                                                <span className="table-actionsItem" title={this.props.gcontent.data.traducoes['eliminar']['palavra_traducao']} onClick={() => this.handleDelete(d.crud, null)} >
                                                                                    {" "}<i className="far fa-trash-alt"></i>{" "}
                                                                                </span>
                                                                            </div> }
            ],
            osdados: []
        };
    }

    cleanContent() {
        this.setState({
            delmess: false,
            keymessageout: "",
            classkeymessageout: "",
            registoCRUD: false,
            submitlabel: this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao'], 
            socialimage: "",
            socialimageform: null,
            loading: false,
            action: 1,
            idrede: 0,
            fileName: "",
            formvalidation: {
                formsubmited: false,
                socialname: [false, "", ["noempty"]],
                sociallink: [false, "", ["noempty"]],
                iconfile: [false, "", ["noempty", "file"]],
            },
            formvalues: {
                socialname: "",
                sociallink: "",
                iconfile: "",
            },
        });
    }

    handleEdit(idrede) {
        let tdata = this.state.formvalues;
        tdata["socialname"] = this.props.oestado.var_globalcontent["data"]["redessociais"][idrede]["chave_rede"];
        tdata["sociallink"] = this.props.oestado.var_globalcontent["data"]["redessociais"][idrede]["link"];
        tdata["iconfile"] = this.props.oestado.var_globalcontent["data"]["redessociais"][idrede]["icon"];
       
        this.setState({
            keyTab: "form",
            formvalues: tdata,
            submitlabel: this.props.gcontent.data.traducoes['bt_actualizar']['palavra_traducao'],
            action: 3,
            socialimage: global.src+"ataficheiros/social/" + this.props.oestado.var_globalcontent["data"]["redessociais"][idrede]["icon"],
            idrede,
        });
    }
    
    handleDelete(idrede, type) {
        if( idrede > 0 ){
            this.setState({ idrede, action: 2, delmess: true });
        }
        if( type == 0 ){
            this.setState({ action: 2, delmess: false });
        }else if( type == 1 ){
            this.setState({  action: 2, delmess: false });
            this.setState({ loading: !this.state.loading });
            setTimeout(
                async function () {
                    var data = { idrede: this.state.idrede };
                    let crud = await loadedcontent.global_postaction( "actionRedesSociais", data, this.state.action );
                    if( crud["success"] != "" ){ 
                        this.setState({ classkeymessageout: "success-out" , keymessageout: crud["success"] });
                        this.setState({ registoCRUD: true });
                    }else{
                        this.setState({ classkeymessageout: "error-out" , keymessageout: crud["error"] });
                        this.setState({ registoCRUD: true });
                    } 
                    this.props.linguafunc(store.getState()["getlang"]["idlingua"]);
                    this.handleredessociais();//Esta função não está fazendo nada aqui
                    this.setState({ loading: !this.state.loading });//Não está fazendo nada aqui
                }.bind(this),
                500
            );

        }
    }

    handleChange(event) {
        let fvalues = this.state.formvalues;
        fvalues[event.target.name] = event.target.value;
        

        this.setState({ formvalues: fvalues });
    }

    handleredessociais = () => {
        let asredes = []; let _tmpredes = [];
        //----------------Conteudos zonas----------------------
        for (let levelOneKey in this.props.oestado.var_globalcontent["data"]["redessociais"]){
            _tmpredes.push(
                {
                    id: levelOneKey,
                    imagem: this.props.oestado.var_globalcontent["data"]["redessociais"][levelOneKey]["icon"],
                    referencia: this.props.oestado.var_globalcontent["data"]["redessociais"][levelOneKey]["chave_rede"],
                    link: this.props.oestado.var_globalcontent["data"]["redessociais"][levelOneKey]["link"],
                    crud: levelOneKey
                },
            );
        }
        //---------------------------------------------------
        this.setState({ osdados: _tmpredes });
    };

    handleFile(event) {
        event.preventDefault();
        let tdata = this.state.formvalues;
        var tdataform = this.state.formvalidation; //VALIDATION
        let file = event.target.files;
        tdata["iconfile"] = file[0].name;
        let _tmp = File(file[0], ["image/jpeg", "image/png"] , 4000000000,
            [
                this.props.gcontent.data.traducoes['only_files']['palavra_traducao'],
                this.props.gcontent.data.traducoes['file_heavy']['palavra_traducao']
            ]
        );
        if(_tmp != ""){
          tdataform["iconfile"][1] = _tmp; 
          tdataform["iconfile"][0] = false; 
          this.setState({ validimage: false });
        }else{
          tdataform["iconfile"][1] = _tmp; 
          tdataform["iconfile"][0] = true; 
          this.setState({ validimage: true });
        }
        this.setState({ 
                        formvalidation: tdataform,
                        formvalues: tdata,
                        socialimageform: file, 
                        fileName: file[0].name
                    });
    }

    async handleSubmit(event) {
        event.preventDefault();
        let tdata = this.state.formvalues;
        var tdataform = this.state.formvalidation; //VALIDATION
        let _tmpiconmess = tdataform["iconfile"][1];
        let tmpvalidate = validate(tdataform, tdata, '',
            [
                this.props.gcontent.data.traducoes['field_required']['palavra_traducao'],
                this.props.gcontent.data.traducoes['min_char']['palavra_traducao'],
                this.props.gcontent.data.traducoes['spaces_nallowed']['palavra_traducao'],
                this.props.gcontent.data.traducoes['min8_char']['palavra_traducao'],
                this.props.gcontent.data.traducoes['psw_rule']['palavra_traducao'],
                this.props.gcontent.data.traducoes['only_number']['palavra_traducao'],
                this.props.gcontent.data.traducoes['invalid_email']['palavra_traducao'],
            ]
        ); //return [validform, tdataform, tdata ];

        
        tmpvalidate[1]["iconfile"][1] = (this.state.validimage == false && _tmpiconmess != "" )? _tmpiconmess : tmpvalidate[1]["iconfile"][1];
        tmpvalidate[1]["iconfile"][0] = (this.state.validimage == false && _tmpiconmess != "" )? false :  tmpvalidate[1]["iconfile"][0];
        this.setState({ formvalidation: tmpvalidate[1] }); let filename = "";
        //-----------------------------------------------
        tmpvalidate[0] = false;
        if( this.state.validimage == true ){
            tmpvalidate[0] = true;
            
            filename = ( tdata["iconfile"] != "" )? await loadedcontent.upload_file(this.state.socialimageform,'social') : false ;
            
            if( filename == false ){ tmpvalidate[0] = false; }
            tmpvalidate[2]["iconfile"] = filename;
            this.setState({ formvalues: tmpvalidate[2] });
        }else if( this.state.validimage == false && tdata['iconfile'] != ''){
            tmpvalidate[2]["iconfile"] = tdata['iconfile'];
            tmpvalidate[0] = true;
        }
        //----------------------------------------------
        if (tmpvalidate[0] == true) {
            //------------------------------------------
            this.setState({ loading: !this.state.loading });
            let sociallink=''
            if(/(http(s?)):\/\//i.test(this.state.formvalues["sociallink"])==false)
                sociallink='https://'+this.state.formvalues["sociallink"]


            setTimeout(
                async function () {
                
                    var data = { idrede: this.state.idrede, social_key: this.state.formvalues["socialname"], social_icon: this.state.formvalues["iconfile"], social_link: sociallink,};
                    let crud = await loadedcontent.global_postaction( "actionRedesSociais", data, this.state.action );
                    
                    if( crud["success"] != "" ){ 
                        this.setState({ classkeymessageout: "success-out" , keymessageout: crud["success"] });
                        this.setState({ registoCRUD: true });
                    }else{
                        this.setState({ classkeymessageout: "error-out" , keymessageout: crud["error"] });
                        this.setState({ registoCRUD: true });
                    }
                    this.props.linguafunc(store.getState()["getlang"]["idlingua"]);
                    this.handleredessociais();//Esta função não está fazendo nada aqui
                    this.setState({ loading: !this.state.loading });//Não está fazendo nada aqui
                }.bind(this),
                500
            );
        }
    }

    componentDidMount() {
        this.handleredessociais();
    }
    changeTab = (keyTab) => {
        if (keyTab == 'list')
            this.cleanContent()

        this.setState({ keyTab });
    };

    render() {
        if( this.state.redirect == true ){
            return <Redirect to={"/"} />
        }else if(store.getState()["getatauser"]["userdata"]["admin"] != 1){ 
            return <Redirect to="/" /> 
        }
        const { fileName } = this.state;
        const tableData = { columns: this.state.ascolunas, data: this.state.osdados, };
        let file = null;
        file = fileName && (
            <label className="iconFile_Name">
                <span className="icon-paperclip"></span> {fileName}
            </label>
        );
        return (
            <>
                {this.state.loading == true && <Loader />}
                { this.state.registoCRUD == true &&
                    <div className="preload-stuff">
                        <div className="position-center">
                            <div className="message-box responseOutput">
                                <div className="responseOutput_gif">
                                    <Lottie options={{ animationData: (this.state.classkeymessageout == "success-out")? respok : resperror , loop: false }} />
                                </div>
                                <span className={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                            </div>
                        </div>
                    </div>
                }
                {this.state.delmess == true &&
                    <div className="preload-stuff">
                        <div className="position-center">
                            <div className="message-box message-boxPopup responseOutput-box">
                                <span className="messagedel">{this.props.oestado.var_globalcontent["data"]["traducoes"]["eliminardados"]['palavra_traducao']} </span>
                                <div className="message-boxActions">
                                    <div onClick={() => this.handleDelete(0 , 1)} >
                                        <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["yes"]['palavra_traducao']} </span>
                                    </div>
                                    <div onClick={() => this.handleDelete(0 , 0)} >
                                        <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["no"]['palavra_traducao']} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} gcontent={this.props.gcontent} theheader="default"/>
                <Container className="content contentPage">
                    <Breadcrumbs {...this.props} gcontent={this.props.gcontent} />
                    <div className="titlePage">
                        {this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b>
                    </div>
                    <Row className="content-sub contentOffice">
                        <Col className="menuDrop">
                            <Usermenu gcontent={this.props.gcontent} />
                        </Col>
                        <Col className="contentDRUP">
                            <Tab.Container activeKey={this.state.keyTab} onSelect={this.changeTab}>
                                <Nav variant="pills" className="pageClientHead">
                                    <h3 className="contentTitle">{this.props.gcontent.data.traducoes['social_network']['palavra_traducao']}</h3>
                                    <span className="separador"></span>
                                    <Nav.Item>
                                        <Nav.Link eventKey={"list"}>
                                            {this.props.gcontent.data.traducoes["listing"]["palavra_traducao"]}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={"form"}>
                                            {this.props.gcontent.data.traducoes["additems"]["palavra_traducao"]}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"form"}>
                                        <Row className="m-0">
                                            <div className="adminForms">
                                                <Form>
                                                    {this.state.socialimage != "" && (
                                                        <div className="table-imagePreview">
                                                            <img src={this.state.socialimage} height="50" width="50"/>
                                                        </div>
                                                    )}
                                                    <Row>
                                                        <Col xs="12" md="6">
                                                            <Form.Group controlId="formBasicName">
                                                                <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["socialname"]} name="socialname" placeholder={this.props.gcontent.data.traducoes["nome_rede_social"]["palavra_traducao"]} />
                                                                {this.state.formvalidation["socialname"][0] == false && this.state.formvalidation["formsubmited"] == true && (
                                                                    <span className="error">
                                                                        {this.state.formvalidation["socialname"][1]}
                                                                    </span>
                                                                )}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs="12" md="6">
                                                            <Form.Group controlId="formBasicLink">
                                                                <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["sociallink"]} name="sociallink" placeholder={this.props.gcontent.data.traducoes["link_rede_social"]["palavra_traducao"]} />
                                                                {this.state.formvalidation["sociallink"][0] == false && this.state.formvalidation["formsubmited"] == true && (
                                                                    <span className="error">
                                                                        {this.state.formvalidation["sociallink"][1]}
                                                                    </span>
                                                                )}
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group id="formcheck-iconfile" className="fileField">
                                                                <Form.File.Input id="iconFile" onChange={(e) => this.handleFile(e)} name="iconfile" isValid />
                                                                {!file &&
                                                                <div className="iconFile_Placeholder" htmlFor="iconFile" >
                                                                    {this.props.gcontent.data.traducoes['attach_img']['palavra_traducao']}
                                                                </div>}
                                                                <div className="iconFile_button"> {file} <span className="icon-plus"></span> </div>
                                                                {this.state.formvalidation["iconfile"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className="error">
                                                                        { this.state.formvalidation["iconfile"][1]}
                                                                    </span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="formSubmit">
                                                                <Button onClick={() => this.cleanContent()}>
                                                                    {this.state.clearlabel}
                                                                </Button>
                                                                <Button onClick={(e) => this.handleSubmit(e)} variant="primary" name="redesocialsubmit" type="button">
                                                                    {this.state.submitlabel}
                                                                </Button>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={"list"}> 
                                        <div className="main">
                                            <DataTableExtensions filterPlaceholder={this.props.gcontent.data.traducoes["search"]["palavra_traducao"] + '...'} filter={true} {...tableData}>
                                                <DataTable
                                                    noHeader={true}
                                                    defaultSortField="id"
                                                    defaultSortAsc={false}
                                                    pagination={true}
                                                    highlightOnHover={true}
                                                    paginationComponentOptions={{rowsPerPageText: this.props.gcontent.data.traducoes['rows_page']['palavra_traducao'] + ':'}}
                                                />
                                            </DataTableExtensions>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default Redessociais;
