import React, { Component } from "react";
import { Row, Container, Col, Button, Form, Tab, Nav } from "react-bootstrap";
import { validate, NoEmpty } from "../../helpers/Validationfuncs";
import Usermenu from "../Usermenu";
import Header from "./Header";
import * as loadedcontent from "../loadcontent";
import Breadcrumbs from "../Breadcrumbs.js";
import parse from "html-react-parser";
import { security } from '../../helpers/Security';
import { Redirect } from 'react-router-dom';
import { store } from '../../redux/store';
//--------------Data table-----------------
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
//-----------------------------------------
import Lottie from 'lottie-react-web';
import respok from '../../assets/response_ok.json';
import resperror from '../../assets/response_error.json';
import Loader from "../../helpers/Loader";

class Perfis extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handlePerfilChange = this.handlePerfilChange.bind(this);
    this.cleanContent = this.cleanContent.bind(this);
    this.handleperfis = this.handleperfis.bind(this);
    this.handleobject = this.handleobject.bind(this);
    this.state = {
      redirect: security(),
      delmess: false,
      keymessageout: "",
      classkeymessageout: "",
      registoCRUD: false,
      submitlabel: this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao'],
      clearlabel: this.props.gcontent.data.traducoes['bt_limpar']['palavra_traducao'],
      action: 1,
      keyTab: "list",
      idperf: 0,
      idsubperfil: 0,
      idindex: 0,
      perfisall: "",
      countelements: 0,
      inputList: Array(),
      formvalidation: {
        formsubmited: false,
        perfenglish: [false, "", ["noempty"]],
        perfportugues: [false, "", ["noempty"]],
      },
      formvalues: {
        perfenglish: "",
        perfportugues: "",
      },
      ascolunas: [
          {minWidth: "150px", wrap: true, name: this.props.gcontent.data.traducoes['reference']['palavra_traducao'], selector: row => row["referencia"], sortable: false, },
          {minWidth: "150px", wrap: true, name: this.props.gcontent.data.traducoes['portugues']['palavra_traducao'], selector: row => row["portugues"], sortable: true, },
          {minWidth: "150px", wrap: true, name: this.props.gcontent.data.traducoes['ingles']['palavra_traducao'], selector: row => row["ingles"], sortable: true, },
          {minWidth: "150px", wrap: true, name: " ", selector: row => row["crud"], sortable: false, cell: d => <div>
                                                                        <span className="table-actionsItem" title={this.props.gcontent.data.traducoes['editar']['palavra_traducao']} onClick={() => { this.handleEdit(d.crud, "editar"); this.changeTab("form"); }} >
                                                                            {" "} <i className="far fa-edit"></i>{" "}
                                                                        </span>
                                                                        <span className="table-actionsItem" title={this.props.gcontent.data.traducoes['eliminar']['palavra_traducao']} onClick={() => { this.handleDelete( null, d.crud, null, null); }} >
                                                                            {" "}<i className="far fa-trash-alt"></i>{" "}
                                                                        </span>
                                                                    </div> }
      ],
      osdados: []
    };
  }
  cleanContent() {
    this.setState({
      delmess: false,
      keymessageout: "",
      classkeymessageout: "",
      registoCRUD: false,
      submitlabel: this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao'],
      clearlabel: this.props.gcontent.data.traducoes['bt_limpar']['palavra_traducao'],
      action: 1,
      idperf: 0,
      idsubperfil: 0,
      idindex: 0,
      perfisall: "",
      countelements: 0,      
      inputList: Array(),
      formvalidation: {
        formsubmited: false,
        perfenglish: [false, "", ["noempty"]],
        perfportugues: [false, "", ["noempty"]],
      },
      formvalues: {
        perfenglish: "",
        perfportugues: "",
      },
    });
  }

  handlePerfilChange(event) {
    let fvalues = this.state.formvalues;
    fvalues[event.target.name] = event.target.value;
    this.setState({ formvalues: fvalues });
    //console.log(this.state.formvalues);
  }

  handleperfis = () => {
    let _tmpredes = []; let cCount = 0;
    let perfilreferencia = "";  let nomeperfilEN = ""; let nomeperfilPT = ""; 
    //----------------Conteudos Traduções-------------------------
    //console.log(this.props.oestado.var_globalcontent["data"]["perfis"]);
    for (let levelOneKey in this.props.oestado.var_globalcontent["data"]["perfis"]) {
        //this.props.oestado.var_globalcontent["data"]["perfis"][levelOneKey]["idperfis"]
        perfilreferencia = this.props.oestado.var_globalcontent["data"]["perfis"][levelOneKey]["perfil_referencia"];  
        if(cCount == 0){ nomeperfilEN = this.props.oestado.var_globalcontent["data"]["perfis"][levelOneKey]["nome_perfil"];}
        else{ nomeperfilPT = this.props.oestado.var_globalcontent["data"]["perfis"][levelOneKey]["nome_perfil"];}
        cCount++;
        if( cCount == 2 ){
          _tmpredes.push(
              {
                  id: levelOneKey,
                  referencia: perfilreferencia,
                  ingles: nomeperfilEN,
                  portugues: nomeperfilPT,
                  crud: this.props.oestado.var_globalcontent["data"]["perfis"][levelOneKey]["idperfis"]
              },
          );
          cCount = 0;
        }
    }
    //--------------------------------------------------------
    if (this._isMounted) {
      this.setState({ osdados: _tmpredes });
    }
  };

  handleobject(obj) {
    let _tPT = "";
    let _tEN = "";
    let _tid = 0;
    Object.entries(obj).map(([key, value]) => {
      if (key.indexOf("subperfilEN") >= 0) {
        _tEN = value;
      } else if (key.indexOf("subperfilPT") >= 0) {
        _tPT = value;
      } else {
        _tid = value;
      }
    });
    return [_tEN, _tPT, _tid];
  }

  async handleSubmit(event) {
    event.preventDefault();
    let tdata = this.state.formvalues;
    var tdataform = this.state.formvalidation; //VALIDATION
    let tmpvalidate = validate(tdataform, tdata, '',
      [
        this.props.gcontent.data.traducoes['field_required']['palavra_traducao'],
        this.props.gcontent.data.traducoes['min_char']['palavra_traducao'],
        this.props.gcontent.data.traducoes['spaces_nallowed']['palavra_traducao'],
        this.props.gcontent.data.traducoes['min8_char']['palavra_traducao'],
        this.props.gcontent.data.traducoes['psw_rule']['palavra_traducao'],
        this.props.gcontent.data.traducoes['only_number']['palavra_traducao'],
        this.props.gcontent.data.traducoes['invalid_email']['palavra_traducao'],
      ]
    ); //return [validform, tdataform, tdata ];
    this.setState({ formvalidation: tmpvalidate[1] });
    //----------Valid Sub-------
    let tmpvalidatesub = true;
    this.state.inputList.map((x, i) => {
      let _tdata = this.handleobject(x);
      if (_tdata[0].trim().length <= 1 || _tdata[1].trim().length <= 1) {
        tmpvalidatesub = false;
      }
    });
    //---------------------------
    if (tmpvalidate[0] == true && tmpvalidatesub == true) {
      //------------------------------------------
      this.setState({ loading: !this.state.loading });
      setTimeout(
        async function () {
          var tdata = { idperfil: this.state.idperf, perfil_ref: this.state.formvalues["perfenglish"],
            perfil_en: this.state.formvalues["perfenglish"], perfil_pt: this.state.formvalues["perfportugues"], sub_perfil: this.state.inputList,
          };

              let crud = await loadedcontent.global_postaction( "actionPerfis", tdata, this.state.action );
              if (crud["success"] != "") {
                this.setState({ classkeymessageout: "success-out", keymessageout: crud["success"], });
                this.setState({ registoCRUD: true });
              } else {
                this.setState({ classkeymessageout: "error-out", keymessageout: crud["error"], });
                this.setState({ registoCRUD: true });
              }
              this.props.linguafunc(store.getState()["getlang"]["idlingua"]);
              this.handleperfis();
          
          //----------------------------------------------
          this.setState({ loading: !this.state.loading });
        }.bind(this),
        500
      );
    }
  }

  handleEdit(idperf, type) {
    let theid = idperf;
    let idcheck = 0;
    if (type == "editar") {
      let tdata = this.state.formvalues;
      //----------------Conteudos zonas-------------------------
      for (let levelOneKey in this.props.oestado.var_globalcontent["data"]["perfis"]) {
        if ( theid == this.props.oestado.var_globalcontent["data"]["perfis"][levelOneKey]["idperfis"] ) {
          if (idcheck == 0) {
            this.setState({
              submitlabel: this.props.gcontent.data.traducoes['save_changes']['palavra_traducao'],
              action: 3,
              idperf: theid,
            });
          }
          if ( this.props.oestado.var_globalcontent["data"]["perfis"][levelOneKey]["ididiomas"] == "en" ) {
            tdata["perfenglish"] = parse( this.props.oestado.var_globalcontent["data"]["perfis"][levelOneKey]["nome_perfil"] );
          } else if ( this.props.oestado.var_globalcontent["data"]["perfis"][levelOneKey]["ididiomas"] == "pt" ) {
            tdata["perfportugues"] = parse( this.props.oestado.var_globalcontent["data"]["perfis"][levelOneKey]["nome_perfil"] );
          }
          idcheck++;
        }
      }
      this.setState({ formvalues: tdata });
      let _tmpinput = []; let _tmpPT = ""; let _tmpEN = "";
      let _tcont = 0;
      for (let levelOneKey in this.props.oestado.var_globalcontent["data"]["subperfis"]) {
        if ( theid == this.props.oestado.var_globalcontent["data"]["subperfis"][levelOneKey]["idperfis"]) {
          if ( this.props.oestado.var_globalcontent["data"]["subperfis"][levelOneKey]["ididiomas"] == "en") {
            _tmpEN = parse( this.props.oestado.var_globalcontent["data"]["subperfis"][levelOneKey]["nome_perfil_sub"] );
          } else if ( this.props.oestado.var_globalcontent["data"]["subperfis"][levelOneKey]["ididiomas"] == "pt" ) {
            _tmpPT = parse( this.props.oestado.var_globalcontent["data"]["subperfis"][levelOneKey]["nome_perfil_sub"]);
          }
          if (_tmpEN != "" && _tmpPT != "") {
            _tmpinput.push({ ["subperfilEN" + _tcont]: _tmpEN, ["subperfilPT" + _tcont]: _tmpPT,
                idelement: this.props.oestado.var_globalcontent["data"]["subperfis"][levelOneKey]["idperfis_sub"],
            });
            _tmpEN = ""; _tmpPT = "";
            _tcont++;
          }
        }
      }
      this.setState({ inputList: _tmpinput });
      //--------------------------------------------------------
    }
  }

  handleDelete(index, idperfil, idsubperfil, type ) { //(i, 0, null, null)  s.handleDelete( null, 0 , 0, 1 )} 
      const list = [...this.state.inputList];
      if( idperfil > 0 ){
          this.setState({ idperfil, idsubperfil:0, action: 2, delmess: true });
      }
      if( idsubperfil > 0 ){
          this.setState({ idperfil: 0, idsubperfil, action: 2, delmess: true });
      }
      if( index != null ){
          if( list[index]["idelement"] > 0 ){
              this.setState({ idindex: index, idperfil: 0, idsubperfil: list[index]["idelement"], action: 2, delmess: true });
          }else{
              this.handleRemoveClick(index);
          }
      }
      if( type == 0 ){
          this.setState({ action: 2, delmess: false });
      }else if( type == 1 ){
          this.setState({  action: 2, delmess: false });
          this.setState({ loading: !this.state.loading });
          setTimeout(
              async function () {
                var data = { idperfil: this.state.idperfil, idsubperfil: this.state.idsubperfil };
                //console.log(data);
                if( this.state.idperfil > 0 || this.state.idsubperfil > 0 ){
                    let crud = await loadedcontent.global_postaction( "actionPerfis", data, this.state.action );
                    if( crud["success"] != "" ){ 
                      this.setState({ classkeymessageout: "success-out" , keymessageout: crud["success"] });
                      this.setState({ registoCRUD: true });
                    }else{
                      this.setState({ classkeymessageout: "error-out" , keymessageout: crud["error"] });
                      this.setState({ registoCRUD: true });
                    }
                    if( this.state.index ){
                      this.handleRemoveClick(this.state.idindex);
                    }
                    this.props.linguafunc(store.getState()["getlang"]["idlingua"]);
                    this.handleperfis();
                }
                this.setState({ loading: !this.state.loading });
              }.bind(this),
              500
          );
      }
  }

  //----------------------------ADD ELEMENTS DYNAMICALLY------------------------------
  setInputList = (thelist, type) => {
    if (type == "update" || type == "delete") {
      this.setState({ inputList: thelist });
    } else {
      //let _tmp = "subcategoriaEN"+this.state.inputList.length;
      const adElement = [
        ...this.state.inputList,
        {
          ["subperfilEN" + this.state.inputList.length]: "",
          ["subperfilPT" + this.state.inputList.length]: "",
          idelement: 0,
        },
      ];
      this.setState({ inputList: adElement });
    }
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.inputList];
    list[index][name] = value;
    this.setInputList(list, "update");
  };

  handleRemoveClick = (index) => {
    const list = [...this.state.inputList];
    list.splice(index, 1);
    const _tmplist = Array();
    list.map((x, i) => {
      let _tdata = this.handleobject(x);
      const adElement = {
        ["subperfilEN" + i]: _tdata[0],
        ["subperfilPT" + i]: _tdata[1],
        idelement: _tdata[2],
      };
      _tmplist.push(adElement);
    });
    this.setInputList(_tmplist, "delete");
  };

  handleAddClick = (event) => {
    event.preventDefault();
    const list = [...this.state.inputList];
    this.setInputList(list, "add");
  };

  changeTab = (keyTab) => {
    if (keyTab == 'list')
      this.cleanContent()

    this.setState({ keyTab });
  };

  componentDidMount() {
    this._isMounted = true;
    this.handleperfis();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    if( this.state.redirect == true ){
      return <Redirect to={"/"} />
    }else if( store.getState()["getatauser"]["userdata"]["admin"] != 1){
      return <Redirect to="/" /> 
    }
    const tableData = { columns: this.state.ascolunas, data: this.state.osdados, };
    return (
      <>
        {this.state.loading == true && <Loader />}
        { this.state.registoCRUD == true &&
            <div className="preload-stuff">
                <div className="position-center">
                    <div className="message-box responseOutput">
                        <div className="responseOutput_gif">
                            <Lottie options={{ animationData: (this.state.classkeymessageout == "success-out")? respok : resperror , loop: false }} />
                        </div>
                        <span className={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                    </div>
                </div>
            </div>
        }
        {this.state.delmess == true &&
            <div className="preload-stuff">
                <div className="position-center">
                    <div className="message-box message-boxPopup responseOutput-box">
                        <span className="messagedel">{this.props.oestado.var_globalcontent["data"]["traducoes"]["eliminardados"]['palavra_traducao']} </span>
                        <div className="message-boxActions">
                            <div onClick={() => this.handleDelete( null, 0 , 0, 1 )} >
                                <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["yes"]['palavra_traducao']} </span>
                            </div>
                            <div onClick={() => this.handleDelete( null, 0 , 0, 0 )} >
                                <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["no"]['palavra_traducao']} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} gcontent={this.props.gcontent} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} theheader="default"/>
        <Container className="content contentPage">
          <Breadcrumbs {...this.props} gcontent={this.props.gcontent} />
          <div className="titlePage">
            {this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}
            , <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b>
          </div>
          <Row className="content-sub contentOffice">
            <Col className="menuDrop">
              <Usermenu gcontent={this.props.gcontent} />
            </Col>
            <Col className="contentDRUP">
              <Tab.Container activeKey={this.state.keyTab} onSelect={this.changeTab}>
                <Nav variant="pills" className="pageClientHead">
                  <h3 className="contentTitle contentTitle_sub">{this.props.gcontent.data.traducoes['profiles-subprof']['palavra_traducao']}</h3>
                  <span className="separador"></span>
                  <Nav.Item>
                    <Nav.Link eventKey={"list"}>
                      {this.props.gcontent.data.traducoes["listing"]["palavra_traducao"]}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={"form"}>
                      {this.props.gcontent.data.traducoes["additems"]["palavra_traducao"]}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey={"form"}>
                    <Row className="m-0">
                      <div className="adminForms">
                        <Form>
                          <Row>
                            <Col xs="12" md="6">
                              <Form.Group controlId="formBasicportugues">
                                <Form.Control type="text" onChange={this.handlePerfilChange} value={this.state.formvalues["perfportugues"]} name="perfportugues" placeholder={this.props.gcontent.data.traducoes['profile']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['portugues']['palavra_traducao']}/>
                                {this.state.formvalidation["perfportugues" ][0] == false && this.state.formvalidation["formsubmited"] == true && (
                                    <span className="error">
                                      {this.state.formvalidation["perfportugues"][1]}
                                    </span>
                                  )}
                              </Form.Group>
                            </Col>
                            <Col xs="12" md="6">
                              <Form.Group controlId="formBasicenglish">
                                <Form.Control type="text" onChange={this.handlePerfilChange} value={this.state.formvalues["perfenglish"]} name="perfenglish" placeholder={this.props.gcontent.data.traducoes['profile']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['ingles']['palavra_traducao']}/>
                                {this.state.formvalidation["perfenglish"][0] == false && this.state.formvalidation["formsubmited"] == true && (
                                    <span className="error">
                                      {this.state.formvalidation["perfenglish"][1]}
                                    </span>
                                  )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <h3 className="contentTitle contentTitle_sub">{this.props.gcontent.data.traducoes['selectSubprofile']['palavra_traducao']}</h3>

                          {this.state.inputList.map((x, i) => {
                            let _tdata = this.handleobject(x);
                            return (
                              <div key={i} className="adminSubForms_wrapper">
                                <Row className="adminSubForms mx-0">
                                  <Col>
                                    <Form.Group>
                                      <Form.Control type="text" name={"subperfilPT" + i} placeholder={this.props.gcontent.data.traducoes['selectSubprofile']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['ingles']['palavra_traducao']} value={_tdata[1]} onChange={(e) => this.handleInputChange(e, i)}/>
                                      {_tdata[1].trim().length <= 1 && this.state.formvalidation["formsubmited"] == true && (
                                        <span className="error">{NoEmpty(_tdata[1])}</span>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    <Form.Group>
                                      <Form.Control type="text" name={"subperfilEN" + i} placeholder={this.props.gcontent.data.traducoes['selectSubprofile']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['ingles']['palavra_traducao']} value={_tdata[0]} onChange={(e) => this.handleInputChange(e, i)}/>
                                      {_tdata[0].trim().length <= 1 && this.state.formvalidation["formsubmited"] == true && (
                                        <span className="error">
                                          {NoEmpty(_tdata[0],
                                            [
                                              this.props.gcontent.data.traducoes['field_required']['palavra_traducao']
                                            ]  
                                          )}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col className="adminSubForms_actions">
                                    <Form.Group>
                                      <div className="btn-box">
                                        {this.state.inputList.length != 0 && (
                                          <div onClick={() => this.handleDelete(i, 0, null, null) } >
                                            <span className="icon-close-icon"></span>
                                          </div>
                                        )}
                                      </div>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                          <Row>
                            <Col>
                              <button className="add_Subcategory" onClick={(e) => this.handleAddClick(e)} >
                                <span className="icon-plus"></span>{this.props.gcontent.data.traducoes['add_subprofile']['palavra_traducao']}
                              </button>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Group className="formSubmit">
                                <Button onClick={() => this.cleanContent()}>
                                  {this.state.clearlabel}
                                </Button>
                                <Button onClick={(e) => this.handleSubmit(e)} variant="primary" name="redesocialsubmit" type="submit">
                                  {this.state.submitlabel}
                                </Button>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey={"list"}>
                    <div className="main">
                        <DataTableExtensions filterPlaceholder={this.props.gcontent.data.traducoes["search"]["palavra_traducao"] + '...'} filter={true} {...tableData}>
                            <DataTable
                                noHeader={true}
                                defaultSortField="id"
                                defaultSortAsc={false}
                                pagination={true}
                                highlightOnHover={true}
                                paginationComponentOptions={{rowsPerPageText: this.props.gcontent.data.traducoes['rows_page']['palavra_traducao'] + ':'}}
                            />
                        </DataTableExtensions> 
                    </div> 
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Perfis;
