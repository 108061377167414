import React, { Component } from 'react';
import { Container, Form, Row, Col } from 'react-bootstrap';
import { store } from "../redux/store";
import Modal from 'react-bootstrap/Modal';
import parse from 'html-react-parser';
import { Redirect, NavLink,Link  } from 'react-router-dom';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import BtnFavorite from "../helpers/btnFavorite";
import { ReactComponent as Logopt } from '../assets/images/logoPt.svg';
import { ReactComponent as Logoen } from '../assets/images/logoEn.svg';
import * as loadedcontent from './loadcontent';

class Searchbar extends Component {
    _lastfotos = [];
    defaultIlhas = "";
    defaultGalerias = "";
    defaultSubcategorias = "";
    toRedirect = false;
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.openModalHandler = this.openModalHandler.bind(this);
        this.state = {
            campopesquisa: "",
            searchstring: "",
            isShowing: false,
            subcategorias: [],
            currentImage: 0,
            viewerIsOpen: false,
            redirect: false,
            formvalues: { 
                "search": "", "stilhas": "0", "stcategorias": "0", "stsubcategorias": "0"
            },
          };
    }

    handleChange(event) {
        let fvalues = this.state.formvalues;
        fvalues[event.target.name] = event.target.value;
        this.setState({ formvalues: fvalues, campopesquisa: fvalues["search"]  });
        if(event.target.name == "stilhas"){ this.defaultIlhas = event.target.value; }else if(event.target.name == "stsubcategorias"){ this.defaultSubcategorias = event.target.value; }
    }

    clearForm() {
        this.defaultIlhas = "";
        this.defaultGalerias = "";
        this.defaultSubcategorias = "";
        this.setState({ 
            campopesquisa: "",
            searchstring: "",
            formvalues: { 
                "search": "", "stilhas": "0", "stcategorias": "0", "stsubcategorias": "0"
            },
        });
    }

    getCats = () => {
        let catego = [];
        for (let levelOneKey in this.props.gcontent["data"]["categorias"]) {
          if (this.props.gcontent["data"]["categorias"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"] && this.props.gcontent["data"]["categorias"][levelOneKey]["categoria_referencia"] != 'islands') {
                //let select = (this.props.gcontent["data"]["categorias"][levelOneKey]["idcategorias"] == this.state.formvalues["stcategorias"]) ? "selected" : "";
                if( this.props.gcontent["data"]["categorias"][levelOneKey]["idcategorias"] == this.state.formvalues["stcategorias"] ){
                    this.defaultGalerias = this.state.formvalues["stcategorias"];
                }
                catego.push(
                    <option 
                            //selected={select}
                            key={this.props.gcontent["data"]["categorias"][levelOneKey]["categoria_referencia"]}
                            data-index={levelOneKey}
                            data-ref={this.props.gcontent["data"]["categorias"][levelOneKey]["categoria_referencia"]}
                            value={this.props.gcontent["data"]["categorias"][levelOneKey]["idcategorias"]}>
                            {parse(this.props.gcontent["data"]["categorias"][levelOneKey]["nome_categoria"])}
                    </option>
                );
          }
        }
        return catego;
    }

    getsubCats = () => {
        let fvalues = this.state.formvalues;  let subcateg = [];
        if( fvalues["stcategorias"] != "" ){
            for (let levelOneKey in this.props.gcontent["data"]["subcategorias"]) {
                if (  this.props.gcontent["data"]["subcategorias"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"] && 
                    fvalues["stcategorias"] == this.props.gcontent["data"]["subcategorias"][levelOneKey]["idcategorias"]
                ){
                    //let select = (this.props.gcontent["data"]["subcategorias"][levelOneKey]["idsubcategorias"] == this.state.formvalues["stsubcategorias"]) ? "selected" : "";
                    if( this.props.gcontent["data"]["subcategorias"][levelOneKey]["idsubcategorias"] == this.state.formvalues["stsubcategorias"] ){
                        this.defaultSubcategorias = this.props.gcontent["data"]["subcategorias"][levelOneKey]["idsubcategorias"];
                    }
                    subcateg.push(
                        <option 
                            //selected={select}
                            key={this.props.gcontent["data"]["subcategorias"][levelOneKey]["subcategoria_referencia"]}
                            data-index={levelOneKey}
                            data-ref={this.props.gcontent["data"]["subcategorias"][levelOneKey]["subcategoria_referencia"]}
                            value={this.props.gcontent["data"]["subcategorias"][levelOneKey]["idsubcategorias"]}>
                                {parse(this.props.gcontent["data"]["subcategorias"][levelOneKey]["nome_subcategoria"])}
                        </option>
                    );
                }
            }
        }
        return subcateg;
    }

    getIlhas = () => {
        let ilhaslist = []; let _tmpid = 0;
        for (let levelOneKey in this.props.gcontent["data"]["ilhas"]) {
            if(_tmpid != this.props.gcontent["data"]["ilhas"][levelOneKey]["idilhas"]){
                _tmpid =  this.props.gcontent["data"]["ilhas"][levelOneKey]["idilhas"];
                //let select = (this.props.gcontent["data"]["ilhas"][levelOneKey]["idilhas"] == this.state.formvalues["stilhas"]) ? "selected" : "";
                if(this.props.gcontent["data"]["ilhas"][levelOneKey]["idilhas"] == this.state.formvalues["stilhas"]){
                    this.defaultIlhas = this.state.formvalues["stilhas"];
                }
                ilhaslist.push(
                    <option 
                            //selected={select}
                            key={this.props.gcontent["data"]["ilhas"][levelOneKey]["idilhas"]}
                            value={this.props.gcontent["data"]["ilhas"][levelOneKey]["idilhas"]}>
                            {parse(this.props.gcontent["data"]["ilhas"][levelOneKey]["nome_ilha"])}
                    </option>
                );
            }
        }
        return ilhaslist;
    }

    oncatschange = (event) => {
        let fvalues = this.state.formvalues;
        fvalues[event.target.name] = event.target.value;
        fvalues["stsubcategorias"] = "0";
        this.defaultGalerias = event.target.value;
        this.defaultSubcategorias = "0";
        //------------------------------------------
        let idcategoria = event.target.value;
        let index = event.nativeEvent.target.selectedIndex;

        let subcateg = [];
        for (let levelOneKey in this.props.gcontent["data"]["subcategorias"]) {
          if (  this.props.gcontent["data"]["subcategorias"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"] && 
                idcategoria == this.props.gcontent["data"]["subcategorias"][levelOneKey]["idcategorias"]
            ){
                //let select = (this.props.gcontent["data"]["subcategorias"][levelOneKey]["idsubcategorias"] == this.state.formvalues["stsubcategorias"]) ? "selected" : "";
                if( this.props.gcontent["data"]["subcategorias"][levelOneKey]["idsubcategorias"] == this.state.formvalues["stsubcategorias"] ){
                    this.defaultSubcategorias = this.props.gcontent["data"]["subcategorias"][levelOneKey]["idsubcategorias"];
                }
                subcateg.push(
                    <option 
                        //selected={select}
                        key={this.props.gcontent["data"]["subcategorias"][levelOneKey]["subcategoria_referencia"]}
                        data-index={levelOneKey}
                        data-ref={this.props.gcontent["data"]["subcategorias"][levelOneKey]["subcategoria_referencia"]}
                        value={this.props.gcontent["data"]["subcategorias"][levelOneKey]["idsubcategorias"]}>
                            {parse(this.props.gcontent["data"]["subcategorias"][levelOneKey]["nome_subcategoria"])}
                    </option>
                );
          }
        }
        this.setState({ subcategorias : subcateg , formvalues: fvalues, campopesquisa: fvalues["search"] });
    }

    async openModalHandler() {
        /*------------- obter as ultimas 3 visualizações --------------- */
        let tempphotos = []; 
        let _search = { ataregistoid: store.getState()["getatauser"]["userdata"]["idregisto"],
                        language: store.getState()["getlang"]["idlingua"], 
                        };
        let thephotos = await loadedcontent.global_postaction("actionLatestVisualizao", _search, 0);
        //console.log(thephotos);
        if( thephotos.data != undefined ){
            for (let index in thephotos.data.images){
                tempphotos.push({
                    id: thephotos.data.images[index]['id_uploadficheiro'],
                    src: global.src + ((thephotos.data.images[index]['tipo_ficheiro'] == 1)? 'ataimg/tb/' : 'atavid/tb/') + thephotos.data.images[index]['thumb'],
                    url: global.src +  ((thephotos.data.images[index]['tipo_ficheiro'] == 1)? 'ataimg/tb/' : 'atavid/prev/') + ((thephotos.data.images[index]['tipo_ficheiro'] == 1)? thephotos.data.images[index]['thumb'] : thephotos.data.images[index]['videopreview']),
                    width: parseInt(thephotos.data.images[index]['largura']),
                    height: parseInt(thephotos.data.images[index]['altura']),
                    title: thephotos.data.images[index]['titulo_en'],
                    type:  (thephotos.data.images[index]['tipo_ficheiro'] == 1)? 'photo' : 'video'  
                });
            }
        }
        this._lastfotos = tempphotos;
        //------------------------------------------------------
        let subcats = this.getsubCats();
        this.setState({
            subcategorias: subcats,
            isShowing: true,
            classLogo: "white"
        });
    }

    closeModalHandler = () => {
        this.setState({
            isShowing: false,
            classLogo: ""
        });
    }

    openbox = (event) => {
        //console.log(event.currentTarget.parentNode.parentNode.querySelector('img').src);
        this.setState({
            currentImage: event.currentTarget.parentNode.parentNode.querySelector('img'), 
            viewerIsOpen: true
        });
    };

    closeLightbox = () => {
        this.setState({
            currentImage: 0, 
            viewerIsOpen: false
        });
    };

    parametersread(params){
        var splitparams = decodeURI(params).split("&");
        let _vals = this.state.formvalues;
        for(let f = 0; f < splitparams.length; f++){
            var _tmp = splitparams[f].split("=");
            if( _tmp[0] != null || _tmp[0] != undefined ){
                if( _tmp[1] != null || _tmp[1] != undefined ){
                    _vals[_tmp[0]] = _tmp[1];
                }
            }
        }
        this.setState({ formvalues: _vals });
    }
    
    handleSubmit(event) {
        event.preventDefault();
        //------------------String Search--------------------
        let _tvalues = this.state.formvalues; let _sstring = "";
        var tifOptions = Object.keys(this.state.formvalues).map(function(key) {
            _sstring += key + "=" + _tvalues[key] + "&";
        });
        //---------------------------------------------------
        store.dispatch({ type: "search", param: { "searchvalues" : this.state.formvalues , "gosearch" : true } }); 
        this.setState({ searchstring: _sstring, isShowing: false, classLogo: "" });
        //this.setState({ redirect: true });
        this.toRedirect = true;
    } 

    componentDidMount(){
        this.parametersread(this.props.searchfield); 
        let params = store.getState()["getsearch"];
        if( params.searchvalues == undefined ){
            store.dispatch({ type: "search", param: { "searchvalues" : this.state.formvalues , "gosearch" : false } });
        }
        //------------------------------------------
        let subcats = this.getsubCats();
        this.setState({ subcategorias: subcats });
        //------------------------------------------
        if( this.props.searchfield != undefined ){
            this.setState({ campopesquisa: this.state.formvalues["search"] });
        }
    }


    render() { 
        if( this.toRedirect == true ){
            //this.setState({ redirect: false });
            this.toRedirect = false;
            return <Redirect to={"/pesquisa/" + this.state.searchstring} />
        }else{
            return (
                <>
                    <div className={this.props.classBar + " searchForm"}>
                        <Form className="searchForm_wrapper" onSubmit={this.handleSubmit}>
                            <Form.Group className="searchForm_field m-0">
                                <Form.Control type="text" value={this.state.campopesquisa} onChange={this.handleChange} name="search" placeholder={this.props.gcontent["data"]["traducoes"]["search_for"]["palavra_traducao"]} />
                                <button type="button" className="searchForm_filter" onClick={this.openModalHandler}><span className="icon-search-filter"></span></button>
                            </Form.Group>
                            <Form.Group className="searchForm_submit m-0">
                                <button className="searchSubmit"><span className="icon-search-icon"></span></button>
                            </Form.Group>
                        </Form>
                    </div>
                    <Modal className="modal modalFiltersearch p-0" show={this.state.isShowing} >
                        <Container className="registHeader">
                            <div className="ata_logo modalHead" onClick={this.closeModalHandler}>  
                                <NavLink to="/home">
                                    <div className="ata_logo white">{store.getState()["getlang"]["idlingua"] == 'pt' ? <Logopt/> : <Logoen/>}</div>
                                </NavLink>
                            </div>
                            <div className="registHeader-close" onClick={this.closeModalHandler}>
                                <span className="icon-close-icon"></span> {this.props.gcontent["data"]["traducoes"]['close']['palavra_traducao']}
                            </div>
                        </Container>
                        {this.state.currentImage.src}
                        <Container className="p-0">
                            <Row className="contentFiltersearch">
                                <Col xl="6" lg="8" md="8" className="contentFiltersearch_wrapper">
                                    <h3 className="titleFiltersearch">{this.props.gcontent["data"]["traducoes"]["youfilter"]["palavra_traducao"]}</h3>
                                    <Form className="searchForm_wrapper" onSubmit={this.handleSubmit} >
                                        <div className="modalFilter searchForm">
                                            <Form.Group className="searchForm_field m-0">
                                                <Form.Control type="text" value={this.state.campopesquisa} name="search" onChange={this.handleChange} placeholder={this.props.gcontent["data"]["traducoes"]["search_for"]["palavra_traducao"]} />
                                            </Form.Group>
                                            <Form.Group className="searchForm_submit m-0">
                                                <button className="searchSubmit"><span className="icon-search-icon"></span></button>
                                            </Form.Group>
                                        </div>
                                        <Row className="filterFields">
                                            <Col lg={4}>
                                                <Form.Group className="m-0">
                                                    <Form.Control as="select" defaultValue={this.defaultIlhas} name='stilhas' onChange={this.handleChange} >
                                                        <option value="" hidden>{this.props.gcontent["data"]["traducoes"]["all_islands"]["palavra_traducao"]}</option>
                                                        {this.getIlhas()}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4}>
                                                <Form.Group className="m-0">
                                                    <Form.Control as="select" value={this.defaultGalerias} name='stcategorias' onChange={this.oncatschange}>
                                                        <option value="" hidden>{this.props.gcontent["data"]["traducoes"]["all_galleries"]["palavra_traducao"]}</option>
                                                        {this.getCats()}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={4}>
                                                <Form.Group className="m-0">
                                                    <Form.Control as="select" value={this.defaultSubcategorias} name='stsubcategorias' onChange={this.handleChange}  disabled={!this.state.subcategorias.length && 'disabled'}>
                                                        <option value="" hidden>{this.props.gcontent["data"]["traducoes"]["subcategories"]["palavra_traducao"]}</option>
                                                        {this.state.subcategorias}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row className="filterSubmit">
                                            <div className="filterSubmit_wrapper">
                                                <Form.Group className="searchForm_submit m-0" onClick={this.clearForm}>
                                                    <button className="searchSubmit">{this.props.gcontent["data"]["traducoes"]["bt_limpar"]["palavra_traducao"]}</button>
                                                </Form.Group>
                                                <Form.Group className="searchForm_submit m-0">
                                                    <button className="searchSubmit">{this.props.gcontent["data"]["traducoes"]["confirm"]["palavra_traducao"]}</button>
                                                </Form.Group>
                                            </div>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                            <Row className="contentLatestsearch">
                                <Col xl="6" lg="8" md="8" className="contentLatestsearch_wrapper">
                                    {this._lastfotos.length > 0 && 
                                        <h3 className="latestsSearch_title">{this.props.gcontent["data"]["traducoes"]["latest_viewed"]["palavra_traducao"]}</h3>
                                    }
                                    <Row className="latestsSearch_List">
                                        {Object.keys(this._lastfotos).map((item, i) => (
                                            <Col key={"foto"+this._lastfotos[item]['id']} className="latestsSearch_item" lg={4}>
                                                <div className="itemWrapper">
                                                    <div className="itemWrapper_hover">
                                                        {/* <div className="itemLink" onClick={(e) =>  window.location.replace("/page/" + this._lastfotos[item]['id'])}>
                                                            <div className="itemType">{this._lastfotos[item]['type'] == 'photo' ? (<span className="icon-typeImg"></span>) : (<span className="icon-typeVideo"></span>)}</div>
                                                        </div> */}
                                                        <Link className="itemLink" to={"/page/" + this._lastfotos[item]['id']} onClick={this.closeModalHandler}>
                                                            <div className="itemType">{this._lastfotos[item]['type'] == 'photo' ? (<span className="icon-typeImg"></span>) : (<span className="icon-typeVideo"></span>)}</div>
                                                        </Link>  
                                                        <div class="iconsHover">
                                                            { /* <button className="btfavtlight"><span className="icon-image-favorite"></span></button> */ }
                                                            <BtnFavorite idPhoto={this._lastfotos[item]['id']} />
                                                            <button className="btfavtlight" onClick={this.openbox}><span className="icon-image-lightbox"></span></button>
                                                        </div>
                                                    </div> 
                                                    <img src={this._lastfotos[item]["src"]} width={this._lastfotos[item]["width"]} height={this._lastfotos[item]["height"]} alt={this._lastfotos[item]["title"]} />
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                            </Row>
                        </Container>

                        {this.state.viewerIsOpen == true ? (
                        <Container className="inmodalLightbox">
                            <ReactImageVideoLightbox
                                data={[
                                {url: this.state.currentImage.src,
                                type: 'photo',
                                altTag: 'teste'}
                            ]}
                            startIndex={0}
                            onCloseCallback={this.closeLightbox} />
                        </Container>
                        ) : null}
                    </Modal>
                </>
            );
        }
    };

}

export default Searchbar;
