import Pagenotfound from '../pagecomponents/Pagenotfound';
import Login from '../pagecomponents/Login';
import Home from '../pagecomponents/Home';
import Page from '../pagecomponents/Page';
import Pesquisa from '../pagecomponents/Pesquisa';
import Registo from '../pagecomponents/Registo';
import Footer from '../pagecomponents/Footer';
import Politicaetermos from '../pagecomponents/Politicaetermos';
import Redessociais from '../pagecomponents/Redessociais';
import Traducoes from '../pagecomponents/Traducoes';
import Header from '../pagecomponents/Header';
import Categorias from '../pagecomponents/Categorias';
import Sobre from '../pagecomponents/Sobre';
import Perfis from '../pagecomponents/Perfis';
import Utilizadores from '../pagecomponents/Utilizadores';
import Permissoes from '../pagecomponents/Permissoes';
import Upload from '../pagecomponents/Upload';
import Meuperfil from '../pagecomponents/Editarperfil';
import Mylist from '../pagecomponents/MyList';
import Gostos from '../pagecomponents/Gostos';
import Prevdownloads from '../pagecomponents/Prevdownloads';
import License from '../pagecomponents/License';
import Politicas from '../pagecomponents/Politicas.js'
import Passwordrecover from '../pagecomponents/Passwordrecover';
import Notificacoes from '../pagecomponents/Notificacoes';
import Definicoes from '../pagecomponents/Definicoes';

export default {
  Pagenotfound, Login, Home, Pesquisa, Page, Registo, Footer, Politicaetermos, Redessociais, Header, Traducoes, Categorias, Sobre, Perfis, Utilizadores, Permissoes, Upload, Meuperfil, Mylist, Gostos, Prevdownloads, Passwordrecover, License, Politicas, Notificacoes, Definicoes
}
