import React, { Component } from 'react';
import { Row, Container, Col, Modal } from 'react-bootstrap';
import { Redirect, NavLink } from "react-router-dom";
import parse from 'html-react-parser';
import { store } from '../redux/store';
import * as loadedcontent from './loadcontent';

let storeuser = '' 

class PageSideInfo extends Component {
    toRedirect = false;
    constructor(props) {
        super(props);
        //--------------------------------------------------
        let _fileTags = null; let _inMyList = false;
        let checkMylist = false;
        if (this.props.image.tags != null){
            _fileTags = this.props.image.tags.split(',')
        }
        this.props.mylist != null && Object.keys(this.props.mylist).map((item, i) => {
            if (this.props.mylist[item]['idupload_ficheiros'] == this.props.image.id_ficheiro && !checkMylist ){
                checkMylist = true
                _inMyList = true;  
            } 
        })
        //--------------------------------------------------
        this.state = {
            searchstring: "",
            gostos: this.props.gostos,
            favorite: false,
            idfile: this.props.image.id_ficheiro,
            addMylistaction: 1,
            isActive: false,
            fileFormat: this.props.image.ficheiro.split('.'),
            fileTags: _fileTags,
            inMylist: _inMyList,
            modalLicense: false,
            contLicense: '',
            formvalues: { 
                "search": "", "stilhas": "0", "stcategorias": "0", "stsubcategorias": "0"
            },
        }
        this.addMylist = this.addMylist.bind(this);
        this.likebt = this.likebt.bind(this);
        this.loadElements = this.loadElements.bind(this);
        this.subsearch = this.subsearch.bind(this);
    }
    
    handleToggle = () => {
        this.setState({ isActive: !this.state.isActive });
    };

    closeModalHandler = () => {
        this.setState({
            modalLicense: false,
            contLicense: '',
        })
    }

    async showLicense (propimages) {
        //console.log(license);
        //----------------------------------------
        let storeuser = store.getState()["getatauser"]["userdata"];
        let lang = store.getState()["getlang"]["idlingua"];
        let tdata = { language:lang, ataregistoid:storeuser["idregisto"], "idfile": propimages.idupload_ficheiros,  
                            rightfilters: { mrecentes: false, mvistas: true, mfavoritas: false  }
                        }
        let thephotos = await loadedcontent.global_postaction("actionSearchfiles", tdata, 0)
        //-----------------------------------------
        this.setState({
            modalLicense: true,
            contLicense: parse(thephotos.data.images[0].termos_condicoes)
        })
    }

    async loadElements() {
        storeuser = store.getState()["getatauser"]["userdata"];
        let osgostos = await loadedcontent.global_postaction( "actionFavoritos", { 'idregisto': storeuser["idregisto"], 'idfile': this.props.image.id_ficheiro } , 7 ); 
        let favt = await loadedcontent.global_postaction( "actionFavoritos",  { 'idregisto': storeuser["idregisto"], 'idfile': this.props.image.id_ficheiro } , 6 );
        this.setState({ gostos: osgostos.data , idfile: 0 , favorite: ( favt.data == "yes" )? true : false} );
    }

    async componentDidMount() {
        this.loadElements();
    }

    async componentDidUpdate(){
        if( this.state.idfile != this.props.image.id_ficheiro ){
            this.setState({ idfile: this.props.image.id_ficheiro } );
            this.loadElements();
        }
    }

    async addMylist(id) {
        let data = { 'idregisto': storeuser["idregisto"], 'idfile': id } 
        let addresult = await loadedcontent.global_postaction( "actionFavoritos", data, this.state.addMylistaction )
        if (addresult.data != null && addresult.data != undefined){
            this.setState({
                inMylist: true,
                modalLicense: false,
                contLicense: '',
            })
        }
        //alert(id);
    }
    
    async likebt() {
        let favt = await loadedcontent.global_postaction( "actionFavoritos",  { 'idregisto': storeuser["idregisto"], 'idfile': this.props.image.id_ficheiro } , 5 );
        let osgostos = await loadedcontent.global_postaction( "actionFavoritos", { 'idregisto': storeuser["idregisto"], 'idfile': this.props.image.id_ficheiro } , 7 ); 
        this.setState({ gostos: osgostos.data }); 
    }


    subsearch( textsearch ) {
        //------------------String Search--------------------
        let _tvalues = this.state.formvalues; let _sstring = "";
        //console.log(_tvalues);
        var tifOptions = Object.keys(this.state.formvalues).map(function(key) {
            if( key == "search" ){
                _sstring += key + "=" + textsearch + "&";
            }else{ _sstring += key + "=" + _tvalues[key] + "&"; }
        });
        //---------------------------------------------------
        store.dispatch({ type: "search", param: { "searchvalues" : {  "search": textsearch, "stilhas": "0", "stcategorias": "0", "stsubcategorias": "0" } , "gosearch" : true } }); 
        this.setState({ searchstring: _sstring }); 
        this.toRedirect = true;
    } 

    render() { 
        const isActive = this.state.isActive;
        let checkIsla = false;
        if( this.toRedirect == true ){
            this.toRedirect = false;
            return <Redirect to={"/pesquisa/" + this.state.searchstring} />
        }else{
            return (
                <>
                    <Container fluid className="Sideinfo p-0">
                        <h3 className="titlePage"><b>{this.props.image.titulo}</b></h3>
                        <div className="locationImg"><span className="icon-img-location"></span>
                        {this.props.gcontent != null && Object.keys(this.props.gcontent["data"]["ilhas"]).map((item, i) => {
                            if (this.props.gcontent["data"]["ilhas"][item]['idilhas'] == this.props.image.ilha && !checkIsla){
                                checkIsla = true; 
                                return(
                                    <span key={this.props.gcontent["data"]["ilhas"][item]['nome_ilha']}>
                                        {this.props.gcontent["data"]["ilhas"][item]['nome_ilha']}
                                    </span>
                                )
                            }
                        }
                        )}{this.props.image.localidade && ' - ' + this.props.image.localidade}
                        </div>
                        <div className="descriptionImg">{parse(this.props.image.descricao)}</div>
                        <div className="Sideinfo_Bottom">
                            <div className="statsImg">
                                <div className="statsItem">
                                    <span className="icon-img-views"></span>
                                    {this.props.image.visualizacoes}
                                </div>
                                {<div className="statsItem likebypage" onClick={() => this.likebt()} >
                                    { !this.state.favorite ? (<span className="icon-image-favorite"></span>) : (<span className="icon-image-favorite-active"></span>)}
                                    { this.state.gostos }
                                </div>}
                                <div className="statsItem">
                                    <span className="icon-img-size"></span>
                                    {this.state.fileFormat[1] && 
                                        this.props.image.peso + ' - ' + this.state.fileFormat[1]
                                    }
                                </div>
                            </div>
                            {this.state.fileTags && 
                                <div className="tagsImg">
                                    <span className="icon-img-tags"></span>
                                    <div className="tagsImg-wrapper">
                                        {this.state.fileTags.map(function (item, i){ 
                                            return(
                                                <div key={'tag' + i} className="tagsImg-item" onClick={() => this.subsearch(item)} >
                                                    {item} 
                                                </div>
                                            )
                                        }, this )} 
                                    </div>
                                </div>
                            }
                            {this.props.image.autor && 
                                <div className="autorImg" title="Autor"><span className="icon-user"></span>{this.props.image.autor}</div>
                            }
                            {this.state.inMylist ? (
                                <div className="warninginMylist">
                                    {this.props.image.tipo_ficheiro == 1 ? (
                                        <>{this.props.gcontent.data.traducoes['img_added']['palavra_traducao']} <NavLink to="/mylist">MyList</NavLink></>
                                    ):(
                                        <>{this.props.gcontent.data.traducoes['video_added']['palavra_traducao']} <NavLink to="/mylist">MyList</NavLink></>
                                    )}
                                </div>
                            ):(
                                <div className="addMylist">
                                    {/* 
                                        <div className="licenseImg">
                                            <div className={isActive ? "termsRadio active" : "termsRadio"} onClick={this.handleToggle}></div> 
                                            {store.getState()["getlang"]["idlingua"] == 'pt' ? (
                                                <p>Eu aceito as <NavLink to={{pathname: "/license", state: {image: this.props.image}}}>Licenças, Termos e Condições</NavLink> desta foto ou vídeo</p>
                                            ):(
                                                <p>I do accept the <NavLink to={{pathname: "/license", state: {image: this.props.image}}}>License, Terms and Conditions</NavLink> of this photo or video</p>
                                            )}
                                        </div>
                                    */}
                                    <div className="addImg formSubmit">
                                        { /*<button onClick={() => this.addMylist(this.props.image.id_ficheiro)} className="addimg_toList" disabled={isActive ? null : true }>{this.props.gcontent.data.traducoes['addmylist']['palavra_traducao']}</button>*/ }
                                        <button  onClick={() => this.showLicense(this.props.image)} className="addimg_toList">{this.props.gcontent.data.traducoes['addmylist']['palavra_traducao']}</button>
                                    </div>                
                                </div>
                            )}
                        </div>    

                    </Container>
                    <Modal className="modal modalRegist modalLicense p-0" show={this.state.modalLicense}>
                        <Container className="registHeader">
                            <div className="registHeader-close" onClick={this.closeModalHandler}>
                                <span className="icon-close-icon"></span> {this.props.oestado.var_globalcontent["data"]["traducoes"]['close']['palavra_traducao']}
                            </div>
                        </Container>
                        <Container className="licenseContent">
                            <div class="titlelicensa"> <p>{this.props.gcontent.data.traducoes["license_terms"]["palavra_traducao"]}</p> </div>
                            <p>{parse(String(this.state.contLicense))}</p>
                        </Container>
                        <Container className="buttonlicenseContent">
                            <button onClick={() => this.addMylist(this.props.image.id_ficheiro)} className="addimg_toList">{this.props.gcontent.data.traducoes['aceitar']['palavra_traducao']}</button>
                        </Container>
                    </Modal>
                </>
            );
        }
    };

}

export default PageSideInfo;
