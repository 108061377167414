import React, { Component, useEffect } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import Header from './Header';
import Breadcrumbs from '../Breadcrumbs.js';
import { store } from '../../redux/store';
import parse from "html-react-parser";
import { security } from '../../helpers/Security';
import { Redirect } from 'react-router-dom';

class License extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: security(),
            cok: (this.props.typePage == "cookielink")? "cookies" :  this.props.typePage
        }
    }
    componentDidMount  = () => {
        window.scrollTo(0, 0);
    }
    render() { 
        return (
            <>
                <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} gcontent={this.props.gcontent} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} authorized="highaccess" theheader="default" />
                <Container className="content contentPage">
                    <Breadcrumbs {...this.props} gcontent={this.props.gcontent}/>
                    <Row>
                        <Col>
                            <div className="titlePage"><b>{parse(this.props.gcontent["data"]["traducoes"][this.props.typePage]['palavra_traducao'])}</b></div>
                            <div className="licenseTerms">
                            {
                            //console.log(this.props.gcontent["data"]["politicasetermos"])
                            this.props.gcontent.data.politicasetermos != null && 
                                Object.keys(this.props.gcontent["data"]["politicasetermos"]).map((item, i) => {
                                    if (store.getState()["getlang"]["idlingua"] == this.props.gcontent["data"]["politicasetermos"][item]['ididiomas']){
                                        return(
                                        <p key={this.props.gcontent["data"]["politicasetermos"][item]['idpolitica_termos']}>{parse(this.props.gcontent["data"]["politicasetermos"][item][this.state.cok])}</p>
                                        ) 
                                    }
                                })
                            }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };

}

export default License;