import React, { Component } from 'react';
import { Row, Container, Col, Button, Form, Nav, Tab } from 'react-bootstrap';
import { validate, File } from '../../helpers/Validationfuncs';
import CountrySelector from "../CountrySelector";
import Usermenu from '../Usermenu';
import Breadcrumbs from '../Breadcrumbs';
import Header from './Header';
import { Fade } from 'react-reveal';
import { store } from '../../redux/store';
import parse from 'html-react-parser';
import { security } from '../../helpers/Security';
import { Redirect } from 'react-router-dom';
import Loader from "../../helpers/Loader";
import Lottie from 'lottie-react-web';
import animationLoader from '../../assets/loading_stats.json';
import respok from '../../assets/response_ok.json';
import resperror from '../../assets/response_error.json';
//--------------Data table-----------------
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
//-----------------------------------------
import * as loadedcontent from '../loadcontent';

class Utilizadores extends Component {
    _isMounted = false;
    defaultPerfil = "";
    defaultSubperfil = "";
    defaultPermissoes = "";
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRenew = this.handleRenew.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.cleanContent = this.cleanContent.bind(this);
        this.handleuserslist = this.handleuserslist.bind(this);
        this.state = {
            redirect: security(),
            renewfile: "",
            delmess: false,
            keymessageout: "",
            classkeymessageout: "",
            fileTypeError:'',
            registoCRUD: false,
            jobfield: true,
            submitlabel: this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao'],
            clearlabel: this.props.gcontent.data.traducoes['bt_limpar']['palavra_traducao'],      
            registofile: "",          
            action: 0,
            idtuser: 0,
            idutilizador: 0,
            validimage: false,
            fileName: '',
            keyTab: "list",
            attachimageform: null,
            userslist: null,
            countelements: 0,
            subPerfis: [],
            formvalidation: {
                "formsubmited": false, "theusername": [ false , "", ["noempty","username"] ], "thepassword":  [ false , "", ["password"] ], 
                "first_name":  [ false , "", ["noempty"] ], "last_name":  [ false , "", ["noempty"] ], "selectperfis":  [ false , "", ["noempty"] ], 
                "selectsubperfis":  [ true , "", [""] ], "company_name":  [ false , "", ["noempty"] ], "job_company":  [ false , "", ["noempty"] ]
                , "nif":  [ false , "", ["noempty", "number"] ], "cae":  [ false , "", ["noempty"] ], "country":  [ false , "", ["noempty"] ], 
                "phone":  [ false , "", ["noempty"] ], "email":  [ false , "", ["noempty", "email"] ], "certificado_ficheiro":  [ true , "", ["file"] ],
            },
            formvalues: {
                "idregisto": 0, "theusername": "", "thepassword": "", "first_name": "", "last_name": "", "selectperfis": "", "selectsubperfis": "", "company_name": "", "job_company": ""
                , "nif": "", "cae": "", "country": "", "phone": "", "email": "", "certificado_ficheiro": "", "certificado_ficheiro_renew": "", "activo": 0, "idbackoffice_permissoes": 0
            },
            bkformvalues: {
                "theusername": "", "email": "", "certificado_ficheiro": ""
            },
            countrieCK: false,
            ascolunas: [
                {minWidth: "150px", wrap: true, name: " ", selector: row => row["ficheiro"], sortable: false, cell: d => (d.ficheiro !="") ? parse("<div><a target='_blank' href='"+global.src+"ataficheiros/certificados/"+d.ficheiro+"'>Doc.</a></div>"): "" },
                {minWidth: "150px", wrap: true, name: this.props.gcontent.data.traducoes['companyName']['palavra_traducao'], selector: row => row["empresa"], sortable: true, },
                {minWidth: "150px", wrap: true, name: this.props.gcontent.data.traducoes['name']['palavra_traducao'], selector: row => row["nome"], sortable: true, },
                {minWidth: "150px", wrap: true, name: this.props.gcontent.data.traducoes['active']['palavra_traducao'], selector: row => row["activo"], sortable: true, cell: d => 
                                                                                                                                                                        <span>
                                                                                                                                                                            {d.certificado_ficheiro_renew != "" && 
                                                                                                                                                                                <i className="fas fa-exclamation-circle"> Renovar conta</i>  
                                                                                                                                                                            }
                                                                                                                                                                            {d.activo == 1 && d.certificado_ficheiro_renew == "" && 
                                                                                                                                                                                <i className="fas fa-check"></i>
                                                                                                                                                                            }
                                                                                                                                                                        </span> 
                                                                                                                                                                        },
                {minWidth: "150px", wrap: true, name: " ", selector: row => row["crud"], sortable: false, cell: d => <div>
                                                                              <span className="table-actionsItem" title={this.props.gcontent.data.traducoes['editar']['palavra_traducao']} onClick={() => { this.handleEdit(d.crud, "editar"); this.changeTab("form"); }} >
                                                                                  {" "} <i className="far fa-edit"></i>{" "}
                                                                              </span>
                                                                              {/*<span className="table-actionsItem" title={this.props.gcontent.data.traducoes['eliminar']['palavra_traducao']} onClick={() => { this.handleDelete( d.crud, null); }} >
                                                                                  {" "}<i className="far fa-trash-alt"></i>{" "}
                                                                              </span>*/}
                                                                          </div> }
            ],
            osdados: []
        };
    }

    cleanContent() {
        this.defaultPerfil = "";
        this.defaultSubperfil = "";
        this.defaultPermissoes = "";
        this.setState({
            delmess: false,
            keymessageout: "",
            classkeymessageout: "",
            fileTypeError:"",
            renewfile: "",
            registoCRUD: false,
            jobfield: true,
            submitlabel: this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao'],
            clearlabel: this.props.gcontent.data.traducoes['bt_limpar']['palavra_traducao'],      
            registofile: "",          
            action: 0,
            idtuser: 0,
            idutilizador: 0,
            validimage: false,
            fileName: '',
            attachimageform: null,
            countelements: 0,
            subPerfis: [],
            formvalidation: {
                "formsubmited": false, "theusername": [ false , "", ["noempty","username"] ], "thepassword":  [ false , "", ["password"] ], 
                "first_name":  [ false , "", ["noempty"] ], "last_name":  [ false , "", ["noempty"] ], "selectperfis":  [ false , "", ["noempty"] ], 
                "selectsubperfis":  [ true , "", [""] ], "company_name":  [ false , "", ["noempty"] ], "job_company":  [ false , "", ["noempty"] ]
                , "nif":  [ false , "", ["noempty", "number"] ], "cae":  [ false , "", ["noempty"] ], "country":  [ false , "", ["noempty"] ], 
                "phone":  [ false , "", ["noempty"] ], "email":  [ false , "", ["noempty", "email"] ], "certificado_ficheiro":  [ true , "", ["file"] ],
            },
            formvalues: {
                "idregisto": 0, "theusername": "", "thepassword": "", "first_name": "", "last_name": "", "selectperfis": "", "selectsubperfis": "", "company_name": "", "job_company": ""
                , "nif": "", "cae": "", "country": "", "phone": "", "email": "", "certificado_ficheiro": "", "certificado_ficheiro_renew": "","activo": 0, "idbackoffice_permissoes": 0
            },
            bkformvalues: {
                "theusername": "", "email": "", "certificado_ficheiro": ""
            },
            countrieCK: false,
        });
    }

    handleFile(event) {
        event.preventDefault();
        //let tdata = this.state.formvalues;
        var tdataform = this.state.formvalidation;//VALIDATION
        let file = event.target.files;
        let _tmp = File(file[0], ["application/pdf"] , 4000000, 
            [
                this.props.gcontent.data.traducoes['only_files']['palavra_traducao'],
                this.props.gcontent.data.traducoes['file_heavy']['palavra_traducao']
            ]
        );
        if(_tmp != ""){
          tdataform["certificado_ficheiro"][1] = _tmp; 
          tdataform["certificado_ficheiro"][0] = false; 
          
          this.setState({ validimage: false,fileTypeError:_tmp });
        }else{
          tdataform["certificado_ficheiro"][1] = _tmp; 
          tdataform["certificado_ficheiro"][0] = true; 
          
          this.setState({ validimage: true,fileTypeError:'' });
        }
        this.setState({ 
                        formvalidation: tdataform,
                        attachimageform: file, 
                        fileName: file[0].name
                    });
    }

    getperfis = () => {
        let perfis = [];
        for (let levelOneKey in this.props.gcontent["data"]["perfis"]) {
            if (this.props.gcontent["data"]["perfis"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"]) {
                //let select = (this.props.gcontent["data"]["perfis"][levelOneKey]["idperfis"] == this.state.formvalues["selectperfis"]) ? "selected" : "";
                if( this.props.gcontent["data"]["perfis"][levelOneKey]["idperfis"] == this.state.formvalues["selectperfis"] ){
                    //this.defaultPerfil = this.state.formvalues["selectperfis"];
                }
                perfis.push(
                    <option
                        //selected={select}
                        key={this.props.gcontent["data"]["perfis"][levelOneKey]["perfil_referencia"]}
                        data-index={levelOneKey}
                        data-ref={this.props.gcontent["data"]["perfis"][levelOneKey]["perfil_referencia"]}
                        value={this.props.gcontent["data"]["perfis"][levelOneKey]["idperfis"]}>
                        {parse(this.props.gcontent["data"]["perfis"][levelOneKey]["nome_perfil"])}
                    </option>
                );
            }
        }
        return perfis;
    }

    getsubperfis = () => {
        let subperfis = [];
        for (let levelOneKey in this.props.gcontent["data"]["subperfis"]) {
            if (this.props.gcontent["data"]["subperfis"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"] &&
                this.state.formvalues["selectperfis"] == this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis"]
            ) {
                //let select = (this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis_sub"] == this.state.formvalues["selectsubperfis"]) ? "selected" : "";
                subperfis.push(
                    <option
                        //selected={select}
                        key={this.props.gcontent["data"]["subperfis"][levelOneKey]["subperfil_referencia"]}
                        data-index={levelOneKey}
                        data-ref={this.props.gcontent["data"]["subperfis"][levelOneKey]["subperfil_referencia"]}
                        value={this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis_sub"]}>
                        {parse(this.props.gcontent["data"]["subperfis"][levelOneKey]["nome_perfil_sub"])}
                    </option>
                );
            }
        }
        return subperfis;
    }

    getpermissoes = () => {
        let aspermissoes = [];
        for (let levelOneKey in this.props.gcontent["data"]["permissoes"]) {
            //let select = (this.props.gcontent["data"]["permissoes"][levelOneKey]["idbackoffice_permissoes"] == this.state.formvalues["idbackoffice_permissoes"]) ? "selected" : "";
            aspermissoes.push(
                <option
                    //selected={select}
                    key={this.props.gcontent["data"]["permissoes"][levelOneKey]["nome"]}
                    data-index={levelOneKey}
                    data-ref={this.props.gcontent["data"]["permissoes"][levelOneKey]["nome"]}
                    value={this.props.gcontent["data"]["permissoes"][levelOneKey]["idbackoffice_permissoes"]}>
                    {parse(this.props.gcontent["data"]["permissoes"][levelOneKey]["nome"])}
                </option>
            );
        }
        return aspermissoes;
    }

    changecountrieCK = () => {
        this.setState({ countrieCK: false });
    }

    getcountrie = value => {
        if (this.state.countrieCK == true) {
            return JSON.parse(this.state.formvalues["country"]);
        } else {
            let _tmpplo = this.state.formvalues;
            if(_tmpplo["country"] != JSON.stringify(value)){ 
                _tmpplo["country"] = JSON.stringify(value);
                this.setState({ formvalues: _tmpplo });
            }
            return value;
        }
    }

    showjob = () => {
        for (let levelOneKey in this.props.gcontent["data"]["perfis"]) {
            let select = (this.props.gcontent["data"]["perfis"][levelOneKey]["idperfis"] == this.state.formvalues["selectperfis"]) ? "selected" : "";
            if (select == "selected" && ("comunicação social" == this.props.gcontent["data"]["perfis"][levelOneKey]["nome_perfil"].toLowerCase().trim()
                || "social media" == this.props.gcontent["data"]["perfis"][levelOneKey]["nome_perfil"].toLowerCase().trim())) {
                    return false;
            }
        }
        return true;
    }

    handleChange(event){
        let _tmpvalues = this.state.formvalues;
        Object.keys(_tmpvalues).map(function (key) {
            if ( event.target.name == "activo" ) {
                _tmpvalues["activo"] = (event.target.value == 0)? 1 : 0;
            }else if (key == event.target.name) {
                _tmpvalues[key] = event.target.value;
            }
        });
        this.setState({ formvalues: _tmpvalues });
    }

    onperfilchange = (event) => {
        let fvalues = this.state.formvalues;
        let fvalidation = this.state.formvalidation;
        let namefield = event.target.name;
        let idperfil = event.target.value;
        fvalues[namefield] = idperfil;
        this.defaultPerfil = idperfil;
        fvalues["selectsubperfis"] = "";
        this.setState({ formvalues: fvalues });
        let index = event.nativeEvent.target.selectedIndex;
        let label = event.nativeEvent.target[index].text;
        if( label.trim().toLowerCase() == "ata" ){ //campo não obrigatorio caso Ata perfil
            fvalidation["cae"][2][0] = "empty"; //console.log(fvalidation["cae"][2][0]); 
        }else{  fvalidation["cae"][2][0] = "noempty";   }
        if ("comunicação social" == label.toLowerCase().trim() || "social media" == label.toLowerCase().trim()) { 
            this.setState({ jobfield: false }); 
            fvalues["job_company"] = "";
            fvalidation["job_company"][0] = true;
        } else { 
            this.setState({ jobfield: true }); 
            fvalidation["job_company"][0] = false;
        }
        let subperfis = [];
        for (let levelOneKey in this.props.gcontent["data"]["subperfis"]) {
            if (this.props.gcontent["data"]["subperfis"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"] &&
                idperfil == this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis"]
            ) {
                subperfis.push(
                    <option
                        key={this.props.gcontent["data"]["subperfis"][levelOneKey]["subperfil_referencia"]}
                        data-index={levelOneKey}
                        data-ref={this.props.gcontent["data"]["subperfis"][levelOneKey]["subperfil_referencia"]}
                        value={this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis_sub"]}>
                        {parse(this.props.gcontent["data"]["subperfis"][levelOneKey]["nome_perfil_sub"])}
                    </option>
                );
            }
        }

        this.setState({ formvalues: fvalues });
        this.setState({ subPerfis: subperfis });
        this.setState({ formvalidation: fvalidation });
    }

    onpermissaochange = (event) => {
        let fvalues = this.state.formvalues;
        let namefield = event.target.name;
        let idpermissao = event.target.value;
        fvalues["idbackoffice_permissoes"] = idpermissao;
        this.defaultPermissoes = idpermissao;
        this.setState({ formvalues: fvalues });
    }

    onsubperfilchange = (event) => {
        let fvalues = this.state.formvalues;
        let namefield = event.target.name;
        let idperfil_sub = event.target.value;
        fvalues[namefield] = idperfil_sub;
        this.defaultSubperfil = idperfil_sub;
        this.setState({ formvalues: fvalues });
    }

    async handleuserslist() {
        let _tmpredes = [];
        //--------------------Conteudos Utilizadores----------------------
        let theusers = await loadedcontent.global_postaction("actionRegisto", "", 0);
        for (let levelOneKey in theusers["data"]) {
            _tmpredes.push(
                {
                    ficheiro: theusers["data"][levelOneKey]["certificado_ficheiro"],
                    empresa: theusers["data"][levelOneKey]["nome_entidade"],
                    nome: theusers["data"][levelOneKey]["nome"] + " " + theusers["data"][levelOneKey]["nome_ultimo"],
                    activo: theusers["data"][levelOneKey]["activo"],
                    crud: theusers["data"][levelOneKey]["idregisto"],
                    searchactivo: (theusers["data"][levelOneKey]["activo"] == 1)? "Sim" : "Não" ,
                    certificado_ficheiro_renew: theusers["data"][levelOneKey]["certificado_ficheiro_renew"] ,
                },
            );
        }
        //----------------------------------------------
        if (this._isMounted) {
            this.setState({ osdados: _tmpredes });
        }
    }

    async handleSubmit(event) {
        event.preventDefault();
        let tdata = this.state.formvalues;
        var tdataform = this.state.formvalidation; //VALIDATION
        /*if( tdataform["job_company"][0] == true && tdata["job_company"] == ''){
            tdata["job_company"] = "none";
        }*/
        if( this.state.subPerfis.length <= 0 ){ 
            tdata["job_company"] = ( tdata["job_company"].trim() != "" )? tdata["job_company"] : "none"; 
        }
        //let tmpvalidate=[];
        let tmpvalidate = validate( tdataform, tdata, '',
        [
            this.props.gcontent.data.traducoes['field_required']['palavra_traducao'],
            this.props.gcontent.data.traducoes['min_char']['palavra_traducao'],
            this.props.gcontent.data.traducoes['spaces_nallowed']['palavra_traducao'],
            this.props.gcontent.data.traducoes['min8_char']['palavra_traducao'],
            this.props.gcontent.data.traducoes['psw_rule']['palavra_traducao'],
            this.props.gcontent.data.traducoes['only_number']['palavra_traducao'],
            this.props.gcontent.data.traducoes['invalid_email']['palavra_traducao'],
        ]
        ); //return [validform, tdataform, tdata ];
        /*if( tdataform["job_company"][0] == true && tdata["job_company"] == ''){
            tdata["job_company"] = "";
        }*/
        if( this.state.subPerfis.length <= 0 ){ 
            tdata["job_company"] = ( tdata["job_company"].trim() == "none" )? "" : tdata["job_company"]; 
        }
        this.setState({ formvalidation: tmpvalidate[1] });
        //---------------------------------------------
        if( tmpvalidate[1]["certificado_ficheiro"][0] == true && this.state.validimage == true){
            tmpvalidate[0] = ( tmpvalidate[0] == true )? true : tmpvalidate[0] ; // WTF is this
            let filename = ( this.state.attachimageform != null )? await loadedcontent.upload_file(this.state.attachimageform,'certificado') : "";
            tmpvalidate[2]["certificado_ficheiro"] = filename;
            this.setState({ formvalues: tmpvalidate[2] });
        }
        //---------------------------------------------
        if(this.state.jobfield == false){
            tdata["job_company"] = "";
        }
        //---------------------------------------------
        if( tmpvalidate[0] == true ){
            //-------------------Check username and email duplication---------------
            if( this.state.formvalues["theusername"] != this.state.bkformvalues["theusername"] ){
                let ckusername = await loadedcontent.global_postaction( "actionRegisto", { "search": " username = '" + this.state.formvalues["theusername"] + "' " } , 0);
                if( ckusername["data"].length > 0 ){
                    tmpvalidate[1]["theusername"][1] = "Username duplicado";
                    tmpvalidate[1]["theusername"][0] = false; tmpvalidate[0] = false;
                }
            }
            if( this.state.formvalues["email"] != this.state.bkformvalues["email"] ){
                let ckemail = await loadedcontent.global_postaction( "actionRegisto", { "search": " email = '" + this.state.formvalues["email"] + "' " } , 0);
                if( ckemail["data"].length > 0 ){
                    tmpvalidate[1]["email"][1] = "Email duplicado";
                    tmpvalidate[1]["email"][0] = false; tmpvalidate[0] = false;
                }
            }
            this.setState({ formvalidation: tmpvalidate[1] });
            //---------------------------------------------------------------------
            if(this.state.action != 3){ //confirma se não é para fazer update, insere
                this.setState({ action: 1 });
            }
            if( tmpvalidate[0] == true ){
                this.setState({ loading: !this.state.loading });
                setTimeout(
                  async function() {
                    let crud = await loadedcontent.global_postaction( "actionRegisto", tmpvalidate[2], this.state.action);
                    if( crud["success"] != "" ){ 
                        if(this.state.action == 1){
                            this.setState({ classkeymessageout: "success-out" , keymessageout: 'global_inserir' });
                        }else{
                            this.setState({ classkeymessageout: "success-out" , keymessageout: 'global_actualizar' });
                        }
                        this.setState({ registoCRUD: true });
                    }else{
                        if(this.state.action == 1){
                            this.setState({ classkeymessageout: "error-out" , keymessageout: 'globalerro_inserir' });
                        }else{
                            this.setState({ classkeymessageout: "error-out" , keymessageout: 'globalerro_actualizar' });
                        }
                        this.setState({ registoCRUD: true });
                    }   
                    //----------------------------------------
                    this.handleuserslist();
                    this.setState({ loading: !this.state.loading });
                    setTimeout( async function() {  this.cleanContent(); this.changeTab('list');}.bind(this), 1500 );
                  }
                  .bind(this),
                  500
              );
            }
           /*  this.changeTab('list') */
        }
    }

    async handleEdit(idutil, type) {
        this.setState({ countrieCK: true });
        let theid = idutil;
        let fvalidation = this.state.formvalidation;
        let theusers = await loadedcontent.global_postaction("actionRegisto", { "search": "idregisto = " + theid }, 0);
        if (type == "editar") {
            for (let levelOneKey in theusers["data"]) {
                let _tmpdata = {
                    "idregisto": theid,
                    "theusername": theusers["data"][levelOneKey]["username"], "thepassword": "",
                    "first_name": theusers["data"][levelOneKey]["nome"], "last_name": theusers["data"][levelOneKey]["nome_ultimo"],
                    "selectperfis": theusers["data"][levelOneKey]["idperfis"], "selectsubperfis": theusers["data"][levelOneKey]["idperfis_sub"],
                    "company_name": theusers["data"][levelOneKey]["nome_entidade"], "job_company": theusers["data"][levelOneKey]["funcao_na_empresa"],
                    "nif": theusers["data"][levelOneKey]["nif"], "cae": theusers["data"][levelOneKey]["cae"], "country": theusers["data"][levelOneKey]["pais_value"],
                    "phone": theusers["data"][levelOneKey]["numero_telefone"], "email": theusers["data"][levelOneKey]["email"],
                    "certificado_ficheiro": theusers["data"][levelOneKey]["certificado_ficheiro"], "certificado_ficheiro_renew": theusers["data"][levelOneKey]["certificado_ficheiro_renew"], "activo": theusers["data"][levelOneKey]["activo"],
                    "idbackoffice_permissoes": theusers["data"][levelOneKey]["idbackoffice_permissoes"]
                };
                this.defaultPerfil = theusers["data"][levelOneKey]["idperfis"];
                this.defaultSubperfil = theusers["data"][levelOneKey]["idperfis_sub"];
                this.defaultPermissoes = theusers["data"][levelOneKey]["idbackoffice_permissoes"];
                let _bktmpdata = {
                    "theusername": theusers["data"][levelOneKey]["username"], "email": theusers["data"][levelOneKey]["email"], 
                    "certificado_ficheiro": theusers["data"][levelOneKey]["certificado_ficheiro"]
                };
                if (this.defaultPerfil == 2 ) { 
                    this.setState({ jobfield: false }); 
                    _tmpdata["job_company"] = "";
                    fvalidation["job_company"][0] = true;
                } else { 
                    this.setState({ jobfield: true }); 
                }
                    
                this.setState({ formvalidation: fvalidation });
                this.setState({ formvalues: _tmpdata, bkformvalues: _bktmpdata, renewfile: theusers["data"][levelOneKey]["certificado_ficheiro_renew"] });
                break;
            }
        }
        this.setState({
            submitlabel: this.props.gcontent.data.traducoes['bt_actualizar']['palavra_traducao'],
            action: 3,
            idtuser: theid
        });
    }

    async handleRenew( event ) {
        event.preventDefault();
        let tdata = this.state.formvalues;
        //----------------------------------------------
        this.setState({ loading: !this.state.loading });
        setTimeout(
            async function() {
                let crud = await loadedcontent.global_postaction( "actionRegisto" ,  tdata , 4 );//4 = renew
                if( crud["success"] != "" ){ 
                    this.setState({ classkeymessageout: "success-out" , keymessageout: crud["success"] });
                    this.setState({ registoCRUD: true });
                }else{
                    this.setState({ classkeymessageout: "error-out" , keymessageout: crud["error"] });
                    this.setState({ registoCRUD: true });
                }
                //----------------------------------------
                this.handleuserslist();
                this.setState({ loading: !this.state.loading });
                setTimeout( async function() {  this.cleanContent(); }.bind(this), 1500 );
            }.bind(this), 500
        );
        this.changeTab('list')
        //----------------------------------------------
    }

    handleDelete(idutilizador, type ) {
        console.log(idutilizador);
        if( idutilizador > 0 ){
            this.setState({ idutilizador, action: 2, delmess: true });
        }         
        if( type == 0 ){
            this.setState({ action: 2, delmess: false });
        }else if( type == 1 ){
            this.setState({  action: 2, delmess: false });
            this.setState({ loading: !this.state.loading });
            setTimeout(
                async function () {
                    var data = { idutilizador: this.state.idutilizador };
                    if( this.state.idutilizador > 0 ){
                        let crud = await loadedcontent.global_postaction( "actionRegisto", data, this.state.action );
                        if( crud["success"] != "" ){ 
                            this.setState({ classkeymessageout: "success-out" , keymessageout: crud["success"] });
                            this.setState({ registoCRUD: true });
                        }else{
                            this.setState({ classkeymessageout: "error-out" , keymessageout: crud["error"] });
                            this.setState({ registoCRUD: true });
                        }
                        
                        /*
                            if( this.state.index ){
                                this.handleRemoveClick(this.state.idindex); -> ???????????
                            }
                        */
                        //this.handleuserslist();
                        //this.setState({ loading: !this.state.loading });
                        setTimeout( async function() {  this.cleanContent(); }.bind(this), 1500 );
                    }
                    this.setState({ loading: !this.state.loading });
                }.bind(this),
                500
            );
        }
    }

    changeTab = (keyTab) => {
        if (keyTab == 'list')
            this.cleanContent()

        this.setState({ keyTab });
    };

    componentDidMount() {
        this._isMounted = true;
        this.handleuserslist();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if( this.state.redirect == true ){
            return <Redirect to={"/home"} />
        }else if(store.getState()["getatauser"]["userdata"]["admin"] != 1){ 
            return <Redirect to="/home" />
        }
        const { fileName } = this.state;

        const tableData = { columns: this.state.ascolunas, data: this.state.osdados, };
        let file = null;
        file = fileName && (<label className="iconFile_Name"><span className="icon-paperclip"></span> {fileName}</label>);
        return (
            <>
                {this.state.loading == true && this.state.registoCRUD == false && 
                    <Loader />
                }
                { this.state.registoCRUD == true &&
                    <div className="preload-stuff">
                        <div className="position-center">
                            <div className="message-box responseOutput">
                                <div className="responseOutput_gif">
                                    <Lottie options={{ animationData: (this.state.classkeymessageout == "success-out")? respok : resperror , loop: false }} />
                                </div>
                                <span className={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                            </div>
                        </div>
                    </div>
                }
                {this.state.delmess == true &&
                    <div className="preload-stuff">
                        <div className="position-center">
                            <div className="message-box message-boxPopup responseOutput-box">
                                <span className="messagedel">{this.props.oestado.var_globalcontent["data"]["traducoes"]["eliminardados"]['palavra_traducao']} </span>
                                <div className="message-boxActions">
                                    <div onClick={() => this.handleDelete( this.state.idutilizador , 1 )} >
                                        <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["yes"]['palavra_traducao']} </span>
                                    </div>
                                    <div onClick={() => this.handleDelete( this.state.idutilizador , 0 )} >
                                        <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["no"]['palavra_traducao']} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} gcontent={this.props.gcontent} theheader="default" />
                <Container className="content contentPage">
                    <Breadcrumbs {...this.props} gcontent={this.props.gcontent} />
                    <div className="titlePage">{this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b></div>
                    <Row className="content-sub contentOffice">
                        <Col className="menuDrop">
                            <Usermenu gcontent={this.props.gcontent}/>
                        </Col>
                        <Col className="contentDRUP">
                            <Tab.Container activeKey={this.state.keyTab} onSelect={this.changeTab}>
                                <Nav variant="pills" className="pageClientHead">
                                    <h3 className="contentTitle">{this.props.gcontent.data.traducoes['users']['palavra_traducao']}</h3>
                                    <span className="separador"></span>
                                    <Nav.Item>
                                        <Nav.Link eventKey={"list"}>
                                            {this.props.gcontent.data.traducoes["listing"]["palavra_traducao"]}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey={"form"}>
                                            {this.state.action == 3 ? (
                                                    this.props.gcontent.data.traducoes["edituser"]["palavra_traducao"]
                                                ):(
                                                    this.props.gcontent.data.traducoes["add_user"]["palavra_traducao"]
                                                    
                                                )
                                            }
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"form"}> 
                                        <Row className="m-0 mt-4">
                                            <div className="adminForms">
                                                <Form>
                                                    <h3 className="contentTitle contentTitle_sub">{this.props.gcontent.data.traducoes['generalInfo']['palavra_traducao']}</h3>
                                                    <Row>
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group controlId="formBasicName">
                                                                <Form.Control type="hidden" value={this.state.formvalues["idregisto"]} name="idregisto" />
                                                                <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["theusername"]} name="theusername" placeholder={this.props.gcontent.data.traducoes['username']['palavra_traducao']} />
                                                                {this.state.formvalidation["theusername"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className='error'>{this.state.formvalidation["theusername"][1]}</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group controlId="formBasicLink">
                                                                <Form.Control type="password" onChange={this.handleChange} value={this.state.formvalues["thepassword"]} name="thepassword" placeholder="Password" />
                                                                {this.state.formvalidation["thepassword"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className='error'>{this.state.formvalidation["thepassword"][1]}</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group controlId="formBasicLink">
                                                                <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["first_name"]} name="first_name" placeholder={this.props.gcontent.data.traducoes['fname']['palavra_traducao']} />
                                                                {this.state.formvalidation["first_name"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className='error'>{this.state.formvalidation["first_name"][1]}</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group controlId="formBasicName">
                                                                <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["last_name"]} name="last_name" placeholder={this.props.gcontent.data.traducoes['lname']['palavra_traducao']} />
                                                                {this.state.formvalidation["last_name"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className='error'>{this.state.formvalidation["last_name"][1]}</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group controlId="formBasicName">
                                                                <Form.Control as="select" key="profile" value={this.defaultPerfil} name="selectperfis" onChange={this.onperfilchange} >
                                                                    {!this.defaultPerfil ? (
                                                                        <option value="" selected hidden>{this.props.gcontent.data.traducoes['selectProfile']['palavra_traducao']}</option>
                                                                    ):null}
                                                                    {/* <option value=""  key="profileEmp" hidden>{this.props.gcontent.data.traducoes['selectProfile']['palavra_traducao']}</option> */}
                                                                    {this.getperfis()}
                                                                </Form.Control>  
                                                                {this.state.formvalidation["selectperfis"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className='error'>{this.state.formvalidation["selectperfis"][1]}</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group controlId="formBasicLink">
                                                                <Form.Control as="select" value={this.defaultSubperfil} name="selectsubperfis" onChange={this.onsubperfilchange} >
                                                                    <option value="" selected hidden>{this.props.gcontent.data.traducoes['selectSubprofile']['palavra_traducao']}</option>
                                                                    {this.state.subPerfis.length > 0 &&
                                                                        this.state.subPerfis
                                                                    }
                                                                    {this.state.subPerfis.length == 0 &&
                                                                        this.getsubperfis()
                                                                    }
                                                                </Form.Control>
                                                                {this.state.formvalidation["selectsubperfis"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className='error'>{this.state.formvalidation["selectsubperfis"][1]}</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group controlId="formBasicLink">
                                                                <Form.Control onChange={this.handleChange} type="text" value={this.state.formvalues["company_name"]} name="company_name" placeholder={this.props.gcontent.data.traducoes['companyName']['palavra_traducao']} />
                                                                {this.state.formvalidation["company_name"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className='error'>{this.state.formvalidation["company_name"][1]}</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                        {this.showjob() == true && 
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group controlId="formBasicLink">
                                                                <Form.Control onChange={this.handleChange} type="text" value={this.state.formvalues["job_company"]} name="job_company" placeholder={this.props.gcontent.data.traducoes['job']['palavra_traducao']} />
                                                                {this.state.formvalidation["job_company"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className='error'>{this.state.formvalidation["job_company"][1]}</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                        }
                                                    </Row>
                                                    <h3 className="contentTitle contentTitle_sub">{this.props.gcontent.data.traducoes['contactDetails']['palavra_traducao']}</h3>
                                                    {/*<Row>
                                                        <Col>
                                                            <Form.Group id="formcheck-iconfile" className="fileField">
                                                                <Form.File.Input id="certificado_ficheiro" onChange={(e) => this.handleFile(e)} name="certificado_ficheiro" isValid />
                                                                <div className="iconFile_Placeholder" htmlFor="iconFile">{this.props.gcontent.data.traducoes['attachCertificate']['palavra_traducao']}</div>
                                                                <div className="iconFile_button">{file}<span className="icon-plus"></span></div>
                                                                <div className="importantSpecs">
                                                                    <span><i className="far fa-question-circle"></i></span>
                                                                    <div className="importantSpecs_content">{this.props.gcontent.data.traducoes['msg_certificate']['palavra_traducao']}</div>
                                                                </div>
                                                                {/*this.state.formvalidation["certificado_ficheiro"][0] == false && this.state.formvalidation["formsubmited"] == true && */
                                                                //   this.state.fileTypeError!='' &&
                                                                //     <span className='error'>{this.state.fileTypeError/*this.state.formvalidation["certificado_ficheiro"][1]*/}</span> 
                                                                }
                                                            {/*} </Form.Group>
                                                        </Col>
                                                    </Row> */}
                                                    <Row>
                                                        <Col>
                                                    { this.state.formvalues["certificado_ficheiro"] != "" &&
                                                        <div className="table-filePreview">
                                                            <a target='_blank' href={global.src+"ataficheiros/certificados/" + this.state.formvalues["certificado_ficheiro"] } >
                                                                <span className="fas fa-file-alt"></span> {this.props.gcontent.data.traducoes['vercertificado']['palavra_traducao']}
                                                            </a>
                                                        </div>
                                                    }
                                                    </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group controlId="formBasicLink">
                                                                <Form.Control onChange={this.handleChange} type="text" value={this.state.formvalues["nif"]} name="nif" placeholder="NIPC" />
                                                                {this.state.formvalidation["nif"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className='error'>{this.state.formvalidation["nif"][1]}</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group controlId="formBasicLink">
                                                                <Form.Control onChange={this.handleChange} type="text" value={ (this.state.formvalues["cae"] == 0)? "" : this.state.formvalues["cae"] } name="cae" placeholder="CAE" />
                                                                {this.state.formvalidation["cae"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className='error'>{this.state.formvalidation["cae"][1]}</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        {/*'{ "value": "PT", "label" : "Portugal" }' */}
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group className="countrySelector">
                                                                <CountrySelector getc={this.getcountrie} changCk={this.changecountrieCK} place={this.props.gcontent.data.traducoes['country']['palavra_traducao']}/>
                                                                {this.state.formvalidation["country"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className='error'>{this.state.formvalidation["country"][1]}</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group controlId="formBasicName">
                                                                <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["phone"]} name="phone" placeholder={this.props.gcontent.data.traducoes['phoneNum']['palavra_traducao']} />
                                                                {this.state.formvalidation["phone"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className='error'>{this.state.formvalidation["phone"][1]}</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Group controlId="formBasicName">
                                                                <Form.Control type="email" onChange={this.handleChange} value={this.state.formvalues["email"]} name="email" placeholder={this.props.gcontent.data.traducoes['yourEmail']['palavra_traducao']} />
                                                                {this.state.formvalidation["email"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                                    <span className='error'>{this.state.formvalidation["email"][1]}</span>
                                                                }
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <h3 className="contentTitle contentTitle">{this.props.gcontent.data.traducoes['accountpermissions']['palavra_traducao']}</h3>
                                                    <Row>
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group controlId="formBasicName">
                                                                <Form.Control as="select" key="permissoes" value={this.defaultPermissoes} name="selectpermissoes" onChange={this.onpermissaochange} >
                                                                    <option value=""  key="permissoesEmp" hidden>{this.props.gcontent.data.traducoes['permissions']['palavra_traducao']}...</option>
                                                                    {this.getpermissoes()}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        
                                                        <Col xs="12" md="6" lg="6">
                                                            <Form.Group controlId="formBasicCheckbox">
                                                                <Form.Check type="checkbox" onChange={this.handleChange} checked={(this.state.formvalues["activo"]=="1")?"checked":""} value={this.state.formvalues["activo"]} name="activo" label={this.props.gcontent.data.traducoes['accountactive']['palavra_traducao']} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    {this.state.renewfile != "" &&  
                                                        <div className="table-filePreview table-filePreview__renewfile">
                                                            <a target='_blank' href={global.src+"ataficheiros/certificados/" + this.state.formvalues["certificado_ficheiro_renew"] } >
                                                                <span className="fas fa-file-alt"></span> {this.props.gcontent.data.traducoes['vercertificado_renew']['palavra_traducao']}
                                                            </a>
                                                        </div>
                                                    }
                                                    <Row>
                                                        <Col>
                                                            <Form.Group className="formSubmit">
                                                                <Button onClick={() => this.cleanContent()}> {this.props.gcontent.data.traducoes['bt_limpar']['palavra_traducao']} </Button>
                                                                <Button onClick={(e) => this.handleSubmit(e)} variant="primary" name="redesocialsubmit" type="submit">{this.state.submitlabel}</Button>
                                                                { this.state.renewfile != "" && 
                                                                    <Button onClick={(e) => this.handleRenew(e)} variant="primary" name="redesocialsubmit" type="submit">{this.props.gcontent.data.traducoes['renew']['palavra_traducao']}</Button>
                                                                }
                                                            </Form.Group> 
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                        </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={"list"}> 
                                        <div className="main">
                                            {this.state.osdados.length > 1 ? (
                                                <DataTableExtensions filterPlaceholder={this.props.gcontent.data.traducoes["search"]["palavra_traducao"] + '...'} filter={true} {...tableData}>
                                                    <DataTable
                                                        noHeader={true}
                                                        defaultSortField="id"
                                                        defaultSortAsc={false}
                                                        pagination={true}
                                                        highlightOnHover={true}
                                                        paginationComponentOptions={{rowsPerPageText: this.props.gcontent.data.traducoes['rows_page']['palavra_traducao'] + ':'}}
                                                    />
                                                </DataTableExtensions> 
                                            ):(
                                                <div id="loadingContent"><Lottie options={{ animationData: animationLoader }} /></div>

                                            )}
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };

}

export default Utilizadores;