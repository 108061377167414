import React from "react";
import { NavLink } from "react-router-dom";
import BtnFavorite from "./btnFavorite.js";



const cont = {
  cursor: "pointer",
  overflow: "hidden",
  position: "relative"
};


const HoverImage = ({
  index,
  photo,
  margin,
  direction,
  left,
  top,
  lightbox
}) => {
  //calculate x,y scale
  //const sx = (100 - (30 / photo.width) * 100) / 100;
  //const sy = (100 - (30 / photo.height) * 100) / 100;

  if (direction == "column") {
    cont.position = "absolute";
    cont.left = left;
    cont.top = top;
  }
  return (
    <>
    <div style={{margin: margin, height: photo.height, width: photo.width, ...cont }} className="itemWrapper" key={photo.index}>
      <div className="itemWrapper_hover" style={{opacity: "0", justifyContent: "normal", visibility: "hidden", transition: "all 0.4s ease", backgroundColor: "rgba(0, 0, 0, 0.4)", position: "absolute", top: "0", left: "0", zIndex: "999", height: "100%", width: "100%"}}>
        <p className="itemTitle">{photo.title}</p>
        <NavLink className="itemLink" to={"/page/" + photo.id}>
          <div className="itemType">{photo.type == 'photo' ? (<span className="icon-typeImg"></span>) : (<span className="icon-typeVideo"></span>)}</div>
        </NavLink>
        <div className="iconsHover">
          <BtnFavorite idPhoto={photo.id} />
          <button onClick={() => lightbox(index)}><span className="icon-image-lightbox white"></span></button>
        </div>
      </div>
      <img key={photo.id} alt={photo.title} src={photo.src} width={photo.width} height={photo.height}/>
      <style>{`.itemWrapper:hover .itemWrapper_hover{opacity: 1 !important; justify-contents:normal; visibility: visible !important; transition: all 0.3s ease;}`}</style>
    </div>    
  </>
  );
};

export default HoverImage;
