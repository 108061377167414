import ReactDOM from 'react-dom'
import React, { Component } from 'react';
import { Row, Container, Col, Button, Form, Accordion, Card, Modal  } from 'react-bootstrap';
import { NavLink, Redirect } from 'react-router-dom';
import Header from './Header';
import { store } from '../../redux/store';
import Usermenu from '../Usermenu';
import InfiniteScroll from 'react-infinite-scroll-component';
import { security } from '../../helpers/Security';
import Breadcrumbs from '../Breadcrumbs.js';
import parse from "html-react-parser";
import Fade from 'react-reveal/Fade';
import BtnLightbox from "../../helpers/btnLightbox";
import BtnFavorite from "../../helpers/btnFavorite";
import Lottie from 'lottie-react-web';
import animationLoader from '../../assets/loading_stats.json';
import * as loadedcontent from '../loadcontent';
import axios from 'axios';
let storeuser = ''
let emptyData = false

class Gostos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keymessageout: "",
            classkeymessageout: "",
            defaultlang: "pt",
            data:[],
            action:0,
            checkedFiles:[],
            agreedFiles:[],
            warning:false,
            progressBar:0,
            totalFiles:0,
            modalLicense: false,
            contLicense: '',
            redirect: security(),
            /*************/
            itemsLenght: 0,
            showItems: 5,
            loadItems: 3,
        };
    }

    async loadData(){
        try{
            if( storeuser != undefined ){
                let tdata= {idregisto:storeuser["idregisto"]}
                let data= await loadedcontent.global_postaction( "actionFavoritos", tdata, 8 )

                //console.log(data.data);

                let theItems = [];
               
                let c_cont = 0;
                if(data.data.length === 0){
                    emptyData = true;
                }
                this.setState({
                    itemsLenght: data.data.length
                })
                //let list =  data.data.filter(a => a.ididiomas == store.getState()["getlang"]["idlingua"])
                for (let index in data.data){
                    //if( c_cont < this.state.showItems ){
                        if(data.data[index].ididiomas == store.getState()["getlang"]["idlingua"]){
                            theItems.push(
                                data.data[index]
                            )
                        }
                    //}
                    c_cont++;
                }

                this.setState({data:theItems, loader: false})  
                //console.log(this.state.data)  
                //console.log(emptyData)  
            }
        }catch(e){
            //console.log(e)
        }
    }

    async componentDidMount() {
        storeuser = store.getState()["getatauser"]["userdata"]
        this.loadData()
    }

    returnImage(image){
        let img;
        try {
            if (image.tipo_ficheiro==1)
                img =global.src +"ataimg/tb/"+image.thumb
            else
                 img =global.src +"atavid/tb/"+image.thumb
        } catch (err) {
            img =""
            
        }
        
        return img
    }

    goTo(file){
        window.open(global.src+"downloads/"+file.data)
        this.loadData()
    }
    
   
    closeModalHandler = () => {
        this.setState({
            modalLicense: false,
            contLicense: null,
        })
    }

    reloadstuff = () => {
        storeuser = store.getState()["getatauser"]["userdata"]
        this.loadData()
    };

    fetchMoreData = () => {
        this.setState({ showItems: (this.state.showItems + this.state.loadItems), loader: true});
        setTimeout(() => {
            this.loadData();
        }, 200);
    };

  render() { 
    
    if( this.state.redirect == true ){ 
        return <Redirect to={"/"} />
    }
    return (
        <>
            <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} gcontent={this.props.gcontent} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} theheader="default" />
            <Container className="content contentPage">
                <Breadcrumbs {...this.props} gcontent={this.props.gcontent}/>
                <div className="titlePage">{this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b></div>
                <Row className="contentOffice">
                    <Col className="menuDrop">
                        <Usermenu gcontent={this.props.gcontent} />
                    </Col>
                    <Col>
                        <h3 className="contentTitle">{this.props.gcontent["data"]["traducoes"]["favoritos"]["palavra_traducao"]}</h3>
                        {this.state.validuserpass == false && 
                            <span className={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                        }

                        <Container className="mylist">
                            {this.state.data != '' ? (
                                <>
                                     
                                        {this.state.data.map((files) => {
                                            let img = this.returnImage(files) 
                                            return(
                                                <Fade key={"ficheiros"+files.idupload_ficheiros}>
                                                    <Row className="mylistRow">
                                                        <Col className="mylistImage" xs="12" md="4" lg="4">
                                                            <div className="itemWrapper">
                                                                <div className="itemWrapper_hover">
                                                                    <NavLink className="itemLink" to={"page/" + files.idupload_ficheiros}>
                                                                        <div className="itemType">{((files.tipo_ficheiro == 1)? (<span className="icon-typeImg"></span>) : (<span className="icon-typeVideo"></span>))}</div>
                                                                    </NavLink>
                                                                    <div className="itemWrapper_actions">
                                                                        <BtnFavorite metodo={this.reloadstuff} idPhoto={files.idupload_ficheiros} {...this.props} dataFav={this.state.data} />
                                                                        <BtnLightbox type={((files.tipo_ficheiro == 1) ? 'photo' : 'video')} source={files.tipo_ficheiro == 1 ? (global.src + 'ataimg/tb/' + files.thumb) : (global.src + 'atavid/prev/' + files.videopreview)} />
                                                                    </div>
                                                                </div>
                                                                <img id={files.idupload_ficheiros} src={img}  width="" height="" alt={files.titulo} />
                                                            </div>
                                                        </Col>
                                                        <Col className="mylistTitle" xs="12" md="8" lg="8">
                                                            <h2 className="mylistTitle_title">{files.titulo}</h2>
                                                        </Col>
                                                    </Row>
                                                
                                                </Fade>
                                            )
                                        })}
                                     
                                </>
                            ):(
                                <>
                                    {emptyData === false ? (
                                            <div id="loadingContent"><Lottie options={{ animationData: animationLoader }} /></div>
                                        ):(
                                            <Row className="mylistRowEmpty">
                                                <Col className="mylistEmpty">{this.props.gcontent.data.traducoes['emptyList']['palavra_traducao']}</Col>
                                            </Row>
                                        )
                                    }
                                </>
                            ) }
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Modal className="modal modalRegist modalLicense p-0" show={this.state.modalLicense} >
                <Container className="registHeader">
                    <div className="registHeader-close" onClick={this.closeModalHandler}>
                        <span className="icon-close-icon"></span> {this.props.oestado.var_globalcontent["data"]["traducoes"]['close']['palavra_traducao']}
                    </div>
                </Container>
                <Container className="licenseContent">
                    {this.state.contLicense}
                </Container>
            </Modal>

        </>
    );
  };

}

export default Gostos;