export function NoEmpty(element, traducao){
  //console.log(element + " Funcao NoEmpty ");
  try{
    if( element == 0 || element.trim().length < 1 || element == "null" ){
        return traducao[0];
    }
    return "";
  }catch(e){ return "" }
};

export function Username(element, traducao){
  let _tmpuser = element.trim();
  //console.log(element + " Funcao Username ");
  if(_tmpuser.length <= 2){
    return traducao[0];
  }else if( _tmpuser.indexOf(' ') >= 0 ){
    return traducao[1];
  }
  return "";
};

export function Password(element, traducao){ 
  //console.log(element);
  let _tmppass = element.trim();
  if(_tmppass.length > 1){
      //console.log(element + " Funcao Password ");
      if(_tmppass.length <= 7){
          return traducao[0];
      }else if( _tmppass.indexOf(' ') >= 0 ){
          return traducao[1];
      }
      var lowerCaseLetters = /[a-z]/g;
      //console.log("LOWERCASE MATCH = " + _tmppass.match(lowerCaseLetters));
      let _tmpmessag = traducao[2];
      if(_tmppass.match(lowerCaseLetters) == null) {  
        return _tmpmessag;
      }
      var upperCaseLetters = /[A-Z]/g;
      if(_tmppass.match(upperCaseLetters) == null) {  
        return _tmpmessag;
      }
      var numbers = /[0-9]/g;
      if(_tmppass.match(numbers) == null) {  
        return _tmpmessag;
      }  
  }
  return "";
};

export function Fnumber(element, traducao){
  //console.log(element);
  let _tmppass = element.trim();
  var numbers = /^[0-9]+$/;
  if(_tmppass.match(numbers) == null){
    return traducao[0];
  } 
  return "";
};

export function Email(element, traducao){
  //console.log(element);
  let _tmppass = element.trim();
  //console.log(element + " Funcao Email ");
  var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if( _tmppass.match(mailformat) == null ){
    return traducao[0];
  }
  return "";
};

export function File(element, type, size, traducoes ){
    if( element != null ){
        let filetype = element.type;
        let filesize = element.size;
        let booltype = false; let tpsfich = "";
        for( let f = 0; f < type.length; f++ ){
            if( filetype == type[f] ){
                booltype = true;
            }
            tpsfich += type[f] + ",";
        }
        if( booltype == false ){
            return traducoes[0] + tpsfich;
        }
        if( filesize > size ){ //1MB = 1000000bytes
          return traducoes[1] + Math.floor(size/1000000)+ " MB";
        }
    }
    return "";
};

export function validate( tdataform, tdata, type, traducao){
  let validform = true; 
  let ghh = 0;
  var formOptions = Object.keys(tdataform).map(function(key) {
    if( tdata[key] != null && tdata[key] != undefined   ){
      let _tmp = "";
      for(let i = 0; i < tdataform[key][2].length; i++){
        //console.log( key + "---" + "noempty" );
        if( tdataform[key][2][i] == "noempty" ){
          _tmp = ( _tmp == "" ) ? NoEmpty(tdata[key], [traducao[0]]) : _tmp;
        }else if( tdataform[key][2][i] == "username" ){
          _tmp = ( _tmp == "" ) ? Username(tdata[key], [traducao[1], traducao[2]]) : _tmp;
        }else if( (tdataform[key][2][i] == 'password') && (type==0) ){
          _tmp = ( _tmp == "" ) ? Password(tdata[key], [traducao[3], traducao[2], traducao[4]]) : _tmp;
        }else if( tdataform[key][2][i] == 'email'){
          _tmp = ( _tmp == "" ) ? Email(tdata[key], [traducao[6]]) : _tmp;
        }else if( tdataform[key][2][i] == 'number' ){
          _tmp = ( _tmp == "" ) ? Fnumber(tdata[key], [traducao[5]]) : _tmp;
        }
        if( _tmp != ""){
          validform = false;
          tdataform[key][1] = _tmp; 
          tdataform[key][0] = false;                   
          break;
        }
      }  
      if( _tmp == "" ){
        tdataform[key][1] = "";   
        tdataform[key][0] = true;   
      }
    }
    ghh++;
  });
  tdataform["formsubmited"] = true;
  return [validform, tdataform, tdata ];
};



   
