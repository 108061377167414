import React from 'react';
import Lottie from 'lottie-react-web';
import { store } from '../redux/store';
import animation from '../assets/loading.json';

const Loader = () => {
return (
    <div className="preload-stuff">
        <div className="position-center">
            <div className="message-box">
                <Lottie options={{ animationData: animation }} />
                <span className="loading-message">{store.getState()["getlang"]["idlingua"] == 'pt' ? (
                    'A Processar...'
                ):(
                    'In Process...'
                )}</span>
            </div>
        </div>
    </div>
)};  
export default Loader;
