import { createStore, combineReducers } from 'redux';
import * as reducCtn  from './reducer';

const allreducers = combineReducers({ 
    getlanguage: reducCtn.getlanguage,
    getdomain: reducCtn.getdomain,
    getsearch: reducCtn.getsearch,
    getlang: reducCtn.getlang,
    getatauser: reducCtn.getatauser,
 })

export const store = createStore(allreducers);
