import CryptoJS from 'crypto-js';
import { store } from '../redux/store';
import Cookies from 'universal-cookie';

export function security(){
    //------------------Decrypt Ninja way--------------------
    const cookies = new Cookies(); var tf = true;
    let gdatalang = cookies.get('ataextensions-lang');
    let gdata = cookies.get('ataextensions');
    if( gdata != undefined ){
        var bytes  = CryptoJS.AES.decrypt(gdata, global.param3);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        let perms = decryptedData["userdata"]["permissoes"].split("-");
        var cookiedt = { idregisto: decryptedData["userdata"]["idregisto"], nome: decryptedData["userdata"]["nome"], ver: perms[0], editar: perms[1], inserir: perms[2], eliminar: perms[3], admin: perms[4] };
        store.dispatch({ type: "theatauser", param: { "userdata" : cookiedt } });//decryptedData
        tf = false;
    }
    if( gdatalang != undefined ){
        var bytes  = CryptoJS.AES.decrypt(gdatalang, global.param3);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        store.dispatch({ type: "thelang", param: decryptedData }); 
        tf = false;
    } 
    return tf;
};