import React, { Component } from 'react';
import { Row, Container, Col, Button, Form } from 'react-bootstrap';
import CountrySelector from "../CountrySelector";
import Usermenu from '../Usermenu';
import Breadcrumbs from '../Breadcrumbs';
import { validate } from '../../helpers/Validationfuncs';
import Header from './Header';
import { store } from '../../redux/store';
import parse from 'html-react-parser';
import Loader from "../../helpers/Loader";
import Lottie from 'lottie-react-web';
import { security } from '../../helpers/Security';
import { Redirect } from 'react-router-dom';
import respok from '../../assets/response_ok.json';
import resperror from '../../assets/response_error.json';
import * as loadedcontent from '../loadcontent';
let storeuser = ''

class Editarperfil extends Component {
    _isMounted = false;
    defaultPerfil = "";
    defaultSubperfil = "";
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.cleanContent = this.cleanContent.bind(this);
        this.state = {
            redirect: security(),
            //------------------
            renewuser: false,
            renewuserstandby: false,
            renewfile: "",
            //------------------
            keymessageout: "",
            classkeymessageout: "",
            registoCRUD: false,
            jobfield: true,
            submitlabel: this.props.gcontent.data.traducoes['save_changes']['palavra_traducao'],
            registofile: "",
            idtuser: 0,
            fileName: '',
            attachimageform: null,
            subPerfis: [],
            formvalidation: {
                "submit": false, "theusername": [false, "", "empty"], "thepassword": [false, "", "empty"], "first_name": [false, "", "empty"], "last_name": [false, "", "empty"], "selectperfis": [false, "", "empty"], "selectsubperfis": [false, "", "empty"], "company_name": [false, "", "empty"], "job_company": [false, "", "empty"]
                , "nif": [false, "", "empty"], "cae": [false, "", "empty"], "country": [false, "", "empty"], "phone": [false, "", "empty"], "email": [false, "", "empty"], "certificado_ficheiro": [false, "", "empty"],
            },
            bkformvalues: {
                "theusername": "", "email": ""
            },
            formvalues: {
                "idregisto": 0, "theusername": "", "thepassword": "", "first_name": "", "last_name": "", "selectperfis": "", "selectsubperfis": "", "company_name": "", "job_company": ""
                , "nif": "", "cae": "", "country": "", "phone": "", "email": "", "certificado_ficheiro": "", "activo": 0, "idbackoffice_permissoes": 0
            },
            countrieCK: false,
        };
    }

    cleanContent() {
        this.setState({
            //------------------
            renewuser: false,
            renewuserstandby: false,
            renewfile: "",
            //------------------
            keymessageout: "",
            classkeymessageout: "",
            registoCRUD: false,
            jobfield: true,
            submitlabel: this.props.gcontent.data.traducoes['save_changes']['palavra_traducao'],
            registofile: "",
            idtuser: 0,
            fileName: '',
            attachimageform: null,
            subPerfis: [],
            formvalues: {
                "idregisto": 0, "theusername": "", "thepassword": "", "first_name": "", "last_name": "", "selectperfis": "", "selectsubperfis": "", "company_name": "", "job_company": ""
                , "nif": "", "cae": "", "country": "", "phone": "", "email": "", "certificado_ficheiro": "", "activo": 0, "idbackoffice_permissoes": 0
            },
            countrieCK: false,
        });
    }

    handleFile(event) {
        let file = event.target.files;
        this.setState({
            attachimageform: file,
            fileName: file[0].name
        });
        //console.log(file[0].name);
        event.preventDefault();

      /*  
        event.preventDefault();
        let tdata = this.state.formvalues;
        
        var tdataform = this.state.formvalidation; //VALIDATION
        let file = event.target.files;

        let _tmp = new File(file[0], ["application/pdf"] , 4000000,
          [
            this.props.gcontent.data.traducoes['only_files']['palavra_traducao'],
            this.props.gcontent.data.traducoes['file_heavy']['palavra_traducao']
          ]
        );
        if(_tmp != ""){
          tdataform["certificado_ficheiro"][1] = _tmp; 
          tdataform["certificado_ficheiro"][0] = false; 
        }else{
          tdataform["certificado_ficheiro"][1] = _tmp; 
          tdataform["certificado_ficheiro"][0] = true; 
        }
        */
        this.setState({ 
                        
                        attachimageform: file, 
                        fileName: file[0].name,
                        showPlaceolder:false
                    });


    }

    getperfis = () => {
        //console.log(this.state.formvalues);
        let perfis = [];
        for (let levelOneKey in this.props.gcontent["data"]["perfis"]) {
            if (this.props.gcontent["data"]["perfis"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"]) {
                //let select = (this.props.gcontent["data"]["perfis"][levelOneKey]["idperfis"] == this.state.formvalues["selectperfis"]) ? "selected" : "";
                if( this.props.gcontent["data"]["perfis"][levelOneKey]["idperfis"] == this.state.formvalues["selectperfis"] ){
                    this.defaultPerfil = this.props.gcontent["data"]["perfis"][levelOneKey]["idperfis"];
                }
                perfis.push(
                    <option
                        //selected={select}
                        key={this.props.gcontent["data"]["perfis"][levelOneKey]["perfil_referencia"]}
                        data-index={levelOneKey}
                        data-ref={this.props.gcontent["data"]["perfis"][levelOneKey]["perfil_referencia"]}
                        value={this.props.gcontent["data"]["perfis"][levelOneKey]["idperfis"]}>
                        {parse(this.props.gcontent["data"]["perfis"][levelOneKey]["nome_perfil"])}
                    </option>
                );
            }
        }
        return perfis;
    }

    getsubperfis = () => {
        let subperfis = [];
        for (let levelOneKey in this.props.gcontent["data"]["subperfis"]) {
            if (this.props.gcontent["data"]["subperfis"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"] &&
                this.state.formvalues["selectperfis"] == this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis"]
            ){
                //let select = (this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis_sub"] == this.state.formvalues["selectsubperfis"]) ? "selected" : ""; 
                if( this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis_sub"] == this.state.formvalues["selectsubperfis"] ){
                    this.defaultSubperfil = this.state.formvalues["selectsubperfis"];
                }
                subperfis.push(
                    <option
                        //selected={select}
                        key={this.props.gcontent["data"]["subperfis"][levelOneKey]["subperfil_referencia"]}
                        data-index={levelOneKey}
                        data-ref={this.props.gcontent["data"]["subperfis"][levelOneKey]["subperfil_referencia"]}
                        value={this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis_sub"]}>
                        {parse(this.props.gcontent["data"]["subperfis"][levelOneKey]["nome_perfil_sub"])}
                    </option>
                );
            }
        }
        return subperfis;
    }

    getpermissoes = () => {
        let aspermissoes = [];
        for (let levelOneKey in this.props.gcontent["data"]["permissoes"]) {
            let select = (this.props.gcontent["data"]["permissoes"][levelOneKey]["idbackoffice_permissoes"] == this.state.formvalues["idbackoffice_permissoes"]) ? "selected" : "";
            aspermissoes.push(
                <option
                    selected={select}
                    key={this.props.gcontent["data"]["permissoes"][levelOneKey]["nome"]}
                    data-index={levelOneKey}
                    data-ref={this.props.gcontent["data"]["permissoes"][levelOneKey]["nome"]}
                    value={this.props.gcontent["data"]["permissoes"][levelOneKey]["idbackoffice_permissoes"]}>
                    {parse(this.props.gcontent["data"]["permissoes"][levelOneKey]["nome"])}
                </option>
            );
        }
        return aspermissoes;
    }

    changecountrieCK = () => {
        this.setState({ countrieCK: false });
    }

    getcountrie = value => {
        if (this.state.countrieCK == true) {
            return JSON.parse(this.state.formvalues["country"]);
        } else {
            let _tmpplo = this.state.formvalues;
            if (_tmpplo["country"] != JSON.stringify(value)) {
                _tmpplo["country"] = JSON.stringify(value);
                //this.setState({ formvalues: _tmpplo });
            }
            return value;
        }
    }

    showjob = () => {
        for (let levelOneKey in this.props.gcontent["data"]["perfis"]) {
            let select = (this.props.gcontent["data"]["perfis"][levelOneKey]["idperfis"] == this.state.formvalues["selectperfis"]) ? "selected" : "";
            if (select == "selected" && ("comunicação social" == this.props.gcontent["data"]["perfis"][levelOneKey]["nome_perfil"].toLowerCase().trim()
                || "social media" == this.props.gcontent["data"]["perfis"][levelOneKey]["nome_perfil"].toLowerCase().trim())) {
                return false;
            }
        }
        return true;
    }

    async handleEdit(theid) {
        this.setState({ countrieCK: true });
        let theusers = await loadedcontent.global_postaction("actionRegisto", { "search": "idregisto = " + theid }, 0);
        let _bkformval = this.state.bkformvalues;
        for (let levelOneKey in theusers["data"]) {
            let _tmpdata = {
                "idregisto": theid,
                "theusername": theusers["data"][levelOneKey]["username"], "thepassword": "",
                "first_name": theusers["data"][levelOneKey]["nome"], "last_name": theusers["data"][levelOneKey]["nome_ultimo"],
                "selectperfis": theusers["data"][levelOneKey]["idperfis"], "selectsubperfis": theusers["data"][levelOneKey]["idperfis_sub"],
                "company_name": theusers["data"][levelOneKey]["nome_entidade"], "job_company": theusers["data"][levelOneKey]["funcao_na_empresa"],
                "nif": theusers["data"][levelOneKey]["nif"], "cae": theusers["data"][levelOneKey]["cae"], "country": theusers["data"][levelOneKey]["pais_value"],
                "phone": theusers["data"][levelOneKey]["numero_telefone"], "email": theusers["data"][levelOneKey]["email"],
                "certificado_ficheiro": theusers["data"][levelOneKey]["certificado_ficheiro"], "activo": theusers["data"][levelOneKey]["activo"],
                "idbackoffice_permissoes": theusers["data"][levelOneKey]["idbackoffice_permissoes"]
            };
            
            _bkformval["theusername"] = theusers["data"][levelOneKey]["username"];
            _bkformval["email"] = theusers["data"][levelOneKey]["email"];

            if ("comunicação social" == _tmpdata["job_company"].toLowerCase().trim()
                || "social media" == _tmpdata["job_company"].toLowerCase().trim()) {
                this.setState({ jobfield: false });
            } else { this.setState({ jobfield: true }); }
            this.setState({ formvalues: _tmpdata, bkformvalues: _bkformval , renewfile: theusers["data"][levelOneKey]["certificado_ficheiro_renew"] });
            //console.log(_tmpdata);
            break;
        }
        this.setState({
            idtuser: theid
        });
    }

    handleChange(event) {
        let _tmpvalues = this.state.formvalues;
        var tifOptions = Object.keys(_tmpvalues).map(function (key) {
            if (event.target.name == "activo") {
                _tmpvalues["activo"] = (event.target.value == 0) ? 1 : 0;
            } else if (key == event.target.name) {
                _tmpvalues[key] = event.target.value;
            }
        });
        this.setState({ formvalues: _tmpvalues });
    }

    onperfilchange = (event) => {
        let fvalues = this.state.formvalues;
        let namefield = event.target.name;
        let idperfil = event.target.value;
        fvalues[namefield] = idperfil;
        this.defaultPerfil = idperfil;
        fvalues["selectsubperfis"] = "";
        this.defaultSubperfil = "";
        this.setState({ formvalues: fvalues });
        //console.log(fvalues);
        let index = event.nativeEvent.target.selectedIndex;
        let label = event.nativeEvent.target[index].text;
        if ("comunicação social" == label.toLowerCase().trim() || "social media" == label.toLowerCase().trim()) { this.setState({ jobfield: false }); } else { this.setState({ jobfield: true }); }
        let subperfis = [];
        for (let levelOneKey in this.props.gcontent["data"]["subperfis"]) {
            if (this.props.gcontent["data"]["subperfis"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"] &&
                idperfil == this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis"]
            ) {
                subperfis.push(
                    <option
                        key={this.props.gcontent["data"]["subperfis"][levelOneKey]["subperfil_referencia"]}
                        data-index={levelOneKey}
                        data-ref={this.props.gcontent["data"]["subperfis"][levelOneKey]["subperfil_referencia"]}
                        value={this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis_sub"]}>
                        {parse(this.props.gcontent["data"]["subperfis"][levelOneKey]["nome_perfil_sub"])}
                    </option>
                );
            }
        }
        this.setState({ subPerfis: subperfis });
    }

    onpermissaochange = (event) => {
        //let namefield = event.target.name;
        let fvalues = this.state.formvalues;
        let idpermissao = event.target.value;
        fvalues["idbackoffice_permissoes"] = idpermissao;
        this.setState({ formvalues: fvalues });
    }

    onsubperfilchange = (event) => {
        let fvalues = this.state.formvalues;
        let namefield = event.target.name;
        let idperfil_sub = event.target.value;
        this.defaultSubperfil = idperfil_sub;
        fvalues[namefield] = idperfil_sub;
        this.setState({ formvalues: fvalues });
        //console.log(fvalues);
    }

    async handleSubmit(event) {
        event.preventDefault();
        let tdata = this.state.formvalues;
        var tdataform = this.state.formvalidation; //VALIDATION
        let tmpvalidate = validate(tdataform, tdata, '',
            [
                this.props.gcontent.data.traducoes['field_required']['palavra_traducao'],
                this.props.gcontent.data.traducoes['min_char']['palavra_traducao'],
                this.props.gcontent.data.traducoes['spaces_nallowed']['palavra_traducao'],
                this.props.gcontent.data.traducoes['min8_char']['palavra_traducao'],
                this.props.gcontent.data.traducoes['psw_rule']['palavra_traducao'],
                this.props.gcontent.data.traducoes['only_number']['palavra_traducao'],
                this.props.gcontent.data.traducoes['invalid_email']['palavra_traducao'],
            ]
        ); //return [validform, tdataform, tdata ];

        this.setState({ formvalidation: tmpvalidate[1] });
        tmpvalidate[1]["formsubmited"] = true;
        if (tmpvalidate[1]["certificado_ficheiro"][0] == true) {
            tmpvalidate[0] = (tmpvalidate[0] == true) ? true : tmpvalidate[0];
            if( this.state.attachimageform != null ){
                let filename = await loadedcontent.upload_file( this.state.attachimageform , 'certificado' ) // (this.state.attachimageform != null) ? await loadedcontent.upload_file(this.state.attachimageform,'certificado') : false;
                tmpvalidate[2]["certificado_ficheiro"] = filename;
                this.setState({ formvalues: tmpvalidate[2] });
                //console.log(this.state.attachimageform);
                //console.log("FILENAME: " + filename);
            }
        }
        if (this.state.jobfield == false) {
            tdata["job_company"] = ""; //???????????????????????????
        }
        if (tmpvalidate[0] == true) {
            //---------Check username and email duplication--------------
            let ckusername = []; let ckemail = [];
            if (this.state.bkformvalues["theusername"] != this.state.formvalues["theusername"]) {
                ckusername = await loadedcontent.global_postaction("actionRegisto", { "search": " username = '" + this.state.formvalues["theusername"] + "' " }, 0);
            }
            if (this.state.bkformvalues["email"] != this.state.formvalues["email"]) {
                ckemail = await loadedcontent.global_postaction("actionRegisto", { "search": " email = '" + this.state.formvalues["email"] + "' " }, 0);
            }
            if ( ckusername["data"] != undefined ) {
                if( ckusername["data"].length > 0 ){
                    tmpvalidate[1]["theusername"][1] = "Username duplicado";
                    tmpvalidate[1]["theusername"][0] = false; tmpvalidate[0] = false;
                }
            }
            if ( ckemail["data"] != undefined ) {
                if (ckemail["data"].length > 0) {
                    tmpvalidate[1]["email"][1] = "Email duplicado";
                    tmpvalidate[1]["email"][0] = false; tmpvalidate[0] = false;
                }
            }
            this.setState({ formvalidation: tmpvalidate[1] });
            //-----------------------------------------------------------
            if (tmpvalidate[0] == true) {
                this.setState({ loading: !this.state.loading });
                setTimeout(
                    async function () {
                        if( this.state.data != undefined ){
                            if( this.state.data.profile != undefined ){
                                tmpvalidate[2]["renewuser"] = true;
                            }
                        }
                        let crud = await loadedcontent.global_postaction( "actionRegisto", tmpvalidate[2], 3);
                        if( crud["success"] != "" ){ 
                            //---------------Update store username------------------
                            let storeuser = store.getState()["getatauser"];
                            if( storeuser["userdata"] != undefined ){
                                var tmpudt = storeuser["userdata"];
                                tmpudt["nome"] = this.state.formvalues["first_name"];
                                store.dispatch({ type: "theatauser", param: { "userdata" : tmpudt } });
                            }
                            //-----------------------------------------------------
                          this.setState({ classkeymessageout: "success-out" , keymessageout: crud["success"] });
                          this.setState({ registoCRUD: true });
                        }else{
                          this.setState({ classkeymessageout: "error-out" , keymessageout: crud["error"] });
                          this.setState({ registoCRUD: true });
                        }
                        //console.log(" Vou inserir na BD ");
                        //console.log(tmpvalidate[2]);
                        //----------------------------------------
                        this.setState({ loading: !this.state.loading });
                        setTimeout(async function () { 
                            //this.cleanContent(); 
                            this.setState({ registoCRUD: false });
                            //this.handleEdit(store.getState()["getatauser"]["userdata"]["idregisto"]);
                        }.bind(this), 2500); 
                    }
                    .bind(this),
                    500
                );
            }  
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        if (store.getState()["getatauser"] != null && store.getState()["getatauser"] != undefined && store.getState()["getatauser"] != "") {
            this.handleEdit(store.getState()["getatauser"]["userdata"]["idregisto"]);
        }
        //console.log(this.state.formvalidation["theusername"][0]);
        storeuser = store.getState()["getatauser"]["userdata"];
        if( storeuser != undefined ){
            let tdata =   { iduser: storeuser["idregisto"], lang:store.getState()["getlang"]["idlingua"], expira:60} //tem de ser 60 dias
            let crud = await loadedcontent.global_postaction( "actionProfileNotification", tdata, 0)
            this.setState({ data:crud['data'] , notifications_num:crud.total })
            //--------------------------------------------------------------------------------------
            //console.log( this.state.data );
            if( this.state.data != undefined ){
                if( this.state.data.profile != undefined ){
                    this.setState({ renewuser: true });
                }
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if( this.state.redirect == true ){
            return <Redirect to={"/"} />
        }
        let file = null;
        let fileDisabled = false;
        if (this.state.formvalues["certificado_ficheiro"]){
            const fileName = this.state.formvalues["certificado_ficheiro"];
            file = fileName && (<label className="iconFile_Name"><span className="icon-paperclip"></span> <a target='_blank' href={global.src+"ataficheiros/certificados/" + this.state.formvalues["certificado_ficheiro"]}>{fileName}</a></label>);    
            
        }else{
            const fileName = this.state.fileName;
            file = fileName && (<label className="iconFile_Name"><span className="icon-paperclip"></span> {!this.state.formvalues["certificado_ficheiro"] ? fileName : this.state.formvalues["certificado_ficheiro"]}</label>);    
        }
        if(this.state.renewuser == false && this.state.renewfile == ""){
            fileDisabled = true
        }
        return (
            <>
                {this.state.loading == true && this.state.registoCRUD == false &&
                    <Loader />
                }
                {this.state.registoCRUD == true &&
                    <div className="preload-stuff">
                        <div className="position-center">
                            <div className="message-box responseOutput">
                                <div className="responseOutput_gif">
                                    <Lottie options={{ animationData: (this.state.classkeymessageout == "success-out") ? respok : resperror, loop: false }} />
                                </div>
                                <span className={this.state.classkeymessageout}>
                                    {parse(this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao'])} 
                                </span>
                            </div>
                        </div>
                    </div>
                }
                <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} gcontent={this.props.gcontent} theheader="default" />
                <Container className="content contentPage">
                    <Breadcrumbs {...this.props} gcontent={this.props.gcontent} />
                    <div className="titlePage">{this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>
                        {store.getState()["getatauser"]["userdata"]["nome"]}
                        </b>
                    </div>
                    <Row className="content-sub contentOffice">
                        <Col className="menuDrop">
                            <Usermenu gcontent={this.props.gcontent} />
                        </Col>
                        <Col className="contentDRUP">
                            {this.state.renewuser == true && this.state.renewfile == "" && 
                                <div className="info-renew">
                                    <span className="fas fa-exclamation-circle"></span>
                                    {this.props.gcontent.data.traducoes['renewSubscrition']['palavra_traducao']}
                                </div>
                            }
                            {this.state.renewuser == true && this.state.renewfile != "" && 
                                <div className="info-renewstandby">
                                    <span className="fas fa-exclamation-circle"></span>
                                        {this.props.gcontent.data.traducoes['renewstandby']['palavra_traducao']}
                                    </div>
                            }
                            <h3 className="contentTitle">{this.props.gcontent.data.traducoes['edit_profile']['palavra_traducao']}</h3>
                            <Row className="m-0">
                                <div className="adminForms">
                                    <Form>
                                        {/*this.state.formvalues["certificado_ficheiro"] != "" &&
                                            <div className="table-imagePreview">
                                                <a target='_blank' href={global.src+"images/" + this.state.formvalues["certificado_ficheiro"]} >
                                                    <span className="fas fa-file-alt"></span>
                                                </a>
                                            </div>
                                        */}
                                        <h3 className="contentTitle contentTitle_sub">{this.props.gcontent.data.traducoes['generalInfo']['palavra_traducao']}</h3>
                                        <Row>
                                            <Col xs="12" md="6" lg="6">
                                                <Form.Group controlId="formBasicName">
                                                    <Form.Control type="hidden" value={this.state.formvalues["idregisto"]} name="idregisto" disabled />
                                                    <Form.Control type="text" value={this.state.formvalues["theusername"]} name="theusername" placeholder={this.props.gcontent.data.traducoes['username']['palavra_traducao']} disabled />
                                                </Form.Group>
                                                {this.state.formvalidation["theusername"][0] == false && this.state.formvalidation["submit"] == true &&
                                                    <span className='error'>{this.state.formvalidation["theusername"][1]}</span>
                                                }
                                            </Col>
                                            <Col xs="12" md="6" lg="6">
                                                <Form.Group controlId="formBasicLink">
                                                    <Form.Control type="password" onChange={this.handleChange} value={this.state.formvalues["thepassword"]} name="thepassword" placeholder='Password' />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12" md="6" lg="6">
                                                <Form.Group controlId="formBasicLink">
                                                    <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["first_name"]} name="first_name" placeholder={this.props.gcontent.data.traducoes['fname']['palavra_traducao']} />
                                                </Form.Group>
                                                {this.state.formvalidation["first_name"][0] == false && this.state.formvalidation["submit"] == true &&
                                                    <span className='error'>{this.state.formvalidation["first_name"][1]}</span>
                                                }
                                            </Col>
                                            <Col xs="12" md="6" lg="6">
                                                <Form.Group controlId="formBasicName">
                                                    <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["last_name"]} name="last_name" placeholder={this.props.gcontent.data.traducoes['lname']['palavra_traducao']} />
                                                    {this.state.formvalidation["last_name"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                        <span className='error'>{this.state.formvalidation["last_name"][1]}</span>
                                                    }
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs="12" md="6" lg="6">
                                                <Form.Group controlId="formBasicName">
                                                    
                                                    <Form.Control as="select" key="profile" value={this.defaultPerfil} name="selectperfis" onChange={this.onperfilchange} disabled>
                                                        <option value="" hidden>{this.props.gcontent.data.traducoes['selectProfile']['palavra_traducao']}</option>
                                                        {this.getperfis()}
                                                    </Form.Control>
                                                    {this.state.formvalidation["selectperfis"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                        <span className='error'>{this.state.formvalidation["selectperfis"][1]}</span>
                                                    }
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12" md="6" lg="6">
                                                <Form.Group controlId="formBasicLink">
                                                <Form.Control as="select" value={this.defaultSubperfil} name="selectsubperfis" onChange={this.onsubperfilchange} disabled>
                                                    <option value="" hidden>{this.props.gcontent.data.traducoes['selectSubprofile']['palavra_traducao']}</option>
                                                    {this.state.subPerfis.length > 0 &&
                                                        this.state.subPerfis
                                                    }
                                                    {this.state.subPerfis.length == 0 &&
                                                        this.getsubperfis()
                                                    }
                                                </Form.Control>
                                                    {this.state.formvalidation["selectsubperfis"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                        <span className='error'>{this.state.formvalidation["selectsubperfis"][1]}</span>
                                                    }
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs="12" md="6" lg="6">
                                                <Form.Group controlId="formBasicLink">
                                                    <Form.Control onChange={this.handleChange} type="text" value={this.state.formvalues["company_name"]} name="company_name" placeholder={this.props.gcontent.data.traducoes['companyName']['palavra_traducao']} />
                                                </Form.Group>
                                            </Col>
                                            {this.showjob() == true &&
                                                <Col xs="12" md="6" lg="6">
                                                    <Form.Group controlId="formBasicLink">
                                                        <Form.Control onChange={this.handleChange} type="text" value={this.state.formvalues["job_company"]} name="job_company" placeholder={this.props.gcontent.data.traducoes['job']['palavra_traducao']} />
                                                    </Form.Group>
                                                </Col>
                                            }
                                        </Row>
                                        <h3 className="contentTitle contentTitle_sub">{this.props.gcontent.data.traducoes['contactDetails']['palavra_traducao']}</h3>
                                        <Row className={(this.state.renewuser == true && this.state.renewfile == "")? "renew-certificate" : "" }>
                                            <Col>
                                                {(this.state.renewuser == true && this.state.renewfile == "")? <div className="warningCertificate"><span className="fas fa-exclamation-circle"></span> {this.props.gcontent.data.traducoes['updateCertificate']['palavra_traducao']}</div> : null}
                                                
                                                <Form.Group id="formcheck-iconfile" className="fileField">
                                                    <Form.File.Input id="iconFile" onChange={(e) => this.handleFile(e)} name="iconfile" isValid disabled={fileDisabled} accept=".pdf"  />
                                                    <div className="iconFile_Placeholder" htmlFor="iconFile">{!file && this.props.gcontent.data.traducoes['attachCertificate']['palavra_traducao']}</div>
                                                    <div className="iconFile_button">{file}<span className="icon-plus"></span></div>
                                                    <div className="importantSpecs">
                                                        <span><i className="far fa-question-circle"></i></span>
                                                        <div className="importantSpecs_content">{this.props.gcontent.data.traducoes['msg_certificate']['palavra_traducao']}</div>
                                                    </div>
                                                   
                                                    {this.state.formvalidation["certificado_ficheiro"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                            <span className='error'>{this.state.formvalidation["certificado_ficheiro"][1]}</span> 
                                                        }
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs="12" md="6" lg="6">
                                                <Form.Group controlId="formBasicLink">
                                                    <Form.Control disabled type="text" value={this.state.formvalues["nif"]} name="nif" placeholder="NIF" />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12" md="6" lg="6">
                                                <Form.Group controlId="formBasicLink">
                                                    <Form.Control disabled type="text" value={this.state.formvalues["cae"]} name="cae" placeholder="CAE" />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            {/*'{ "value": "PT", "label" : "Portugal" }' */}
                                            <Col xs="12" md="6" lg="6">
                                                <Form.Group className="countrySelector">
                                                    <CountrySelector getc={this.getcountrie} place={this.props.gcontent.data.traducoes['country']['palavra_traducao']} showOnlySelected />
                                                </Form.Group>
                                            </Col>
                                            <Col xs="12" md="6" lg="6">
                                                <Form.Group controlId="formBasicName">
                                                    <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["phone"]} name="phone" placeholder={this.props.gcontent.data.traducoes['phoneNum']['palavra_traducao']} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group controlId="formBasicName">
                                                    <Form.Control type="email" onChange={this.handleChange} value={this.state.formvalues["email"]} name="email" placeholder={this.props.gcontent.data.traducoes['yourEmail']['palavra_traducao']} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        { 
                                            /*
                                            cookieuser.admin == 1 ?
                                            <Row>
                                                    <Col>
                                                        <Form.Group controlId="formBasicName">
                                                            <select key="profile" name="selectpermissoes" onChange={this.onpermissaochange} >
                                                                <option value="" key="profileEmp" selected hidden>Permissões...</option>
                                                                {this.getpermissoes()}
                                                            </select>
                                                        </Form.Group>
                                                    </Col> 
                                                <Col>
                                                    <Form.Group controlId="formBasicCheckbox">
                                                        <Form.Check type="checkbox" onChange={this.handleChange} checked={(this.state.formvalues["activo"] == "1") ? "checked" : ""} value={this.state.formvalues["activo"]} name="activo" label="Activar" />
                                                    </Form.Group>
                                                </Col>                                                        
                                            </Row> : null 
                                            */
                                        }
                                        <Row>
                                            <Col>
                                                <Form.Group className="formSubmit">
                                                    <Button onClick={(e) => this.handleSubmit(e)} variant="primary" name="redesocialsubmit" type="submit">{this.state.submitlabel}</Button>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Col>

                    </Row>
                </Container>
            </>
        );
    };

}

export default Editarperfil;