import ReactDOM from 'react-dom'
import React, { Component } from 'react';
import { Row, Container, Col, Button, Form, Accordion, Card, Modal  } from 'react-bootstrap';
import { NavLink, Redirect } from 'react-router-dom';
import Header from './Header';
import { store } from '../../redux/store';
import Usermenu from '../Usermenu';
import { security } from '../../helpers/Security';
import Breadcrumbs from '../Breadcrumbs.js';
import parse from "html-react-parser";
import Fade from 'react-reveal/Fade';
import BtnLightbox from "../../helpers/btnLightbox";
import BtnFavorite from "../../helpers/btnFavorite";
import Lottie from 'lottie-react-web';
import animationLoader from '../../assets/loading_stats.json';
import * as loadedcontent from '../loadcontent';
import axios from 'axios';
let storeuser = ''

class Mylist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: security()
        }
    }

    async componentDidMount(){
        storeuser = store.getState()["getatauser"]["userdata"]
        let tdata =   { iduser: storeuser["idregisto"], lang:store.getState()["getlang"]["idlingua"], expira:60}
        let crud = await loadedcontent.global_postaction( "actionProfileNotification",tdata, 0)
        this.setState({ data:crud['data'] , notifications_num:crud.total })
    }

    show_renovar(){
        const isActive = (path, match, location) => !!(match || path === location.pathname);
        let notifications = this.state.data.profile
        if( this.state.data.profile != undefined ){
            return (
                <Fade>
                    <Row className="notificationsItem">
                        <span className="icon-circle"></span>
                        <Col lg="6" md="9" xs="9" className="notificationsMsg">{parse(this.props.gcontent.data.traducoes['expiration_message_profile']['palavra_traducao'])}</Col>
                        <Col lg="3" md="3" xs="3" className="notificationsDate"><label>{this.props.gcontent.data.traducoes['expiration_data']['palavra_traducao']}</label>
                            <span>{notifications && notifications.data_fim}</span>
                            <span className="daysLeft">{notifications && notifications.expira} {this.props.gcontent.data.traducoes['expiration_left']['palavra_traducao']}</span>
                        </Col>
                        <Col lg="3" md="12" xs="12" className="notificationsBtn">
                            <NavLink isActive={isActive.bind(this, '/editarperfil')} activeClassName="active" className="menuItem_Link" to="/editarperfil"> 
                                <div className="customSubmitBtn violet">
                                    <span>{this.props.gcontent.data.traducoes['renew']['palavra_traducao']}</span>
                                </div>
                            </NavLink>
                        </Col>
                    </Row>
                </Fade>
            )
        }
    }

    returnImage(image){
        let img;
        try {
            if (image.tipo_ficheiro==1)
                img =global.src +"ataimg/tb/"+image.thumb
            else
                img =global.src +"atavid/tb/"+image.thumb

        } catch (err) {
            img =""
        }
        return img
    }

    show_images_expiration(){
        let fotos = this.state.data.fotos
        let content = []
        if (fotos != null){
            //console.log(fotos);
            
            let list = fotos.filter(a => a.ididiomas == store.getState()["getlang"]["idlingua"])
            if (list!= null && list.length > 0){
                list.map((files) => { 
                    let img = this.returnImage(files) 
                    content.push(
                        <Fade key={"ficheiros"+files.idupload_ficheiros}>
                            <Row className="notificationsItem mylistRow">
                                <Col className="mylistImage"xs="8" md="7" lg="3">
                                    <div className="itemWrapper">
                                        <div className="itemWrapper_hover">
                                            <NavLink className="itemLink" to={"page/" + files.idupload_ficheiros}>
                                                <div className="itemType">{((files.tipo_ficheiro == 1)? (<span className="icon-typeImg"></span>) : (<span className="icon-typeVideo"></span>))}</div>
                                            </NavLink>
                                            <div className="itemWrapper_actions">
                                                <BtnFavorite idPhoto={files.idupload_ficheiros} {...this.props} dataFav={this.state.data} />
                                                <BtnLightbox type={((files.tipo_ficheiro == 1) ? 'photo' : 'video')} source={files.tipo_ficheiro == 1 ? (global.src + 'ataimg/tb/' + files.thumb) : (global.src + 'atavid/prev/' + files.videopreview)} />
                                            </div>
                                        </div>
                                        <img id={files.idupload_ficheiros} src={img}  width="" height="" alt={files.titulo} />
                                    </div>
                                </Col>
                                <Col className="mylistTitle" xs="8" md="7" lg="3">
                                    <h2 className="mylistTitle_title">{files.titulo}</h2>
                                </Col>
                                <Col lg="3" md="5" xs="4" className="notificationsDate"><label>{this.props.gcontent.data.traducoes['expiration_data']['palavra_traducao']}</label>
                                    <span>{files.expiry_date}</span>
                                    <span className="daysLeft">{files.expira} {this.props.gcontent.data.traducoes['expiration_left']['palavra_traducao']}</span>
                                </Col>
                            </Row>
                        </Fade>
                    )
                })
                return content
            }
        }

        return false
    }
    
    render() { 
        let notifications = this.state.data
        let notifications_num = this.state.notifications_num

        if( this.state.redirect == true ){
            return <Redirect to={"/"} />
        }
        return (
            <>
                <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} gcontent={this.props.gcontent} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} theheader="default" />
                <Container className="content contentPage">
                    <Breadcrumbs {...this.props} gcontent={this.props.gcontent}/>
                    <div className="titlePage">{this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b></div>
                    <Row className="contentOffice">
                        <Col className="menuDrop">
                            <Usermenu gcontent={this.props.gcontent} />
                        </Col>
                        <Col>
                            <h3 className="contentTitle">{this.props.gcontent.data.traducoes['notificacoes']['palavra_traducao']} ({notifications && notifications_num})</h3>
                            {notifications_num != 0 ?
                                <Container className="notifications">
                                    {notifications && this.show_renovar() }                     
                                    {notifications && this.show_images_expiration() }                     
                                </Container>
                            :
                                <Container className="notifications">
                                    <Row className="mylistRowEmpty">
                                        <Col className="mylistEmpty">{this.props.gcontent.data.traducoes['sem_notificacoes']['palavra_traducao']}</Col>
                                    </Row>              
                                </Container>
                            }
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };

}

export default Mylist;