import React, { Component } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import {Fade, Zoom} from 'react-reveal';
import Header from './Header.js';
import homeBg from '../../assets/images/homeBack.jpg';
import { store } from "../../redux/store";
import * as loadedcontent from '../loadcontent';
import SearchBar from '../Searchbar.js';
import GaleryImg from "../../helpers/GaleryImg.js";
import Lottie from 'lottie-react-web';
import animationLoader from '../../assets/loading_stats.json';
import InfiniteScroll from 'react-infinite-scroll-component';
import { security } from '../../helpers/Security';
//import { Redirect } from 'react-router-dom';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: security(),
      //---------------------------
      showMenu: false,
      monsaryGal: [],
      //----------------------------
      mrecentes: true,
      mvistas: false,
      mfavoritas: false,
      mstring: this.props.gcontent.data.traducoes['most_recent']['palavra_traducao'],
      homeTile: this.props.gcontent.data.traducoes['recents']['palavra_traducao'],
      //----------------------------
      items: Array.from({ length: 1 }),
      _tes: 5,
      loader: false,
      endgallery: false
    };
    this.showMenu = this.showMenu.bind(this);
  }

  handleChange(event) {
    if(event.target.name == "theusername"){
      this.setState({username: event.target.value});
    }
    if(event.target.name == "thepassword"){
      this.setState({password: event.target.value});
    }
  }

  openModalHandler = () => {
    this.setState({
      isShowing: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isShowing: false
    });
  }
  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: !this.state.showMenu });
  }

  async searchfunction(_data){
    let scroll_antibug = this.state._tes;
    let tempphotos = []; 
    let lang = store.getState()["getlang"]["idlingua"]; 
    try{
        let tdata = { 
                      language:lang,
                      limitlist: scroll_antibug,
                      ataregistoid: store.getState()["getatauser"]["userdata"]["idregisto"],
                      rightfilters: {  mrecentes: this.state.mrecentes, mvistas: this.state.mvistas, mfavoritas: this.state.mfavoritas  }
                    };
        if( _data != null ){ tdata["rightfilters"] = _data; }
        let thephotos = await loadedcontent.global_postaction("actionSearchfiles", tdata, 0)
        /* let list =  data.data.filter(a => a.ididiomas == store.getState()["getlang"]["idlingua"]) */
        let c_cont = 0;
        for (let index in thephotos.data.images){
            if( c_cont < scroll_antibug ){
                tempphotos.push({
                      id: thephotos.data.images[index]['id_ficheiro'],
                      src: global.src + ((thephotos.data.images[index]['tipo_ficheiro'] == 1)? 'ataimg/tb/' : 'atavid/tb/') + thephotos.data.images[index]['thumb'],
                      url: global.src +  ((thephotos.data.images[index]['tipo_ficheiro'] == 1)? 'ataimg/tb/' : 'atavid/prev/') + ((thephotos.data.images[index]['tipo_ficheiro'] == 1)? thephotos.data.images[index]['thumb'] : thephotos.data.images[index]['videopreview']),
                      width: parseInt(thephotos.data.images[index]['largura']),
                      height: parseInt(thephotos.data.images[index]['altura']),
                      title: thephotos.data.images[index]['titulo'],
                      type:  (thephotos.data.images[index]['tipo_ficheiro'] == 1)? 'photo' : 'video' 
                    },
                );
            }
            c_cont++;
        }

        if( c_cont < scroll_antibug ){ this.setState({ endgallery: true }); }
        
        if (tempphotos.length == 0){
          tempphotos = undefined;
        }

        this.setState({
          monsaryGal: tempphotos,
          loader: false
        });
    }catch (err) {
      //clear storeuser
    }
  }

  async componentDidMount() {
    //-----------------Search blank-----------------------
    let params = store.getState()["getsearch"];
    if( params.searchvalues != undefined ){
        params["searchvalues"]["search"] = ""; params["searchvalues"]["stcategorias"] = "0"; params["searchvalues"]["stilhas"] = "0"; params["searchvalues"]["stsubcategorias"] = "0";
        store.dispatch({ type: "search", param: { "searchvalues" : params["searchvalues"] , "gosearch" : false } });
    }
    //----------------------------------------------------
    window.scrollTo(0, 0)
    if( this.props.oestado.var_globalcontent["data"]["totals"] != undefined || this.props.oestado.var_globalcontent["data"]["totals"] != null ){
        let _videos = parseInt(this.props.oestado.var_globalcontent["data"]["totals"][0]['tfiles'] , 10) - parseInt(this.props.oestado.var_globalcontent["data"]["totals"][0]['timages'] , 10)
        this.setState({
          countPhotos: this.props.oestado.var_globalcontent["data"]["totals"][0]['timages'],
          countVideos: _videos,
          countDowns: this.props.oestado.var_globalcontent["data"]["totals"][0]['tdownload'],
        });
    }
    this.searchfunction(null);
  }
  
  //pesquisa select filter lado direito
  rightfilters(tipo){
    let _data = { mrecentes: this.state.mrecentes, mvistas: this.state.mvistas, mfavoritas: this.state.mfavoritas };
    switch(tipo) {
      case 'mrecentes':
        this.setState({ mrecentes: true, mvistas: false, mfavoritas: false });
        _data = { mrecentes: true, mvistas: false, mfavoritas: false  };
        this.setState({ mstring: this.props.gcontent.data.traducoes['most_recent']['palavra_traducao'], homeTile: this.props.gcontent.data.traducoes['recents']['palavra_traducao'] });
        break;
      case 'mvistas':
        this.setState({ mrecentes: false, mvistas: true, mfavoritas: false });
        _data = {  mrecentes: false, mvistas: true, mfavoritas: false  };
        this.setState({ mstring: this.props.gcontent.data.traducoes['most_viewed']['palavra_traducao'], homeTile: this.props.gcontent.data.traducoes['viewed']['palavra_traducao'] });
        break;
      case 'mfavoritas':
        this.setState({ mrecentes: false, mvistas: false, mfavoritas: true });
        _data = { mrecentes: false, mvistas: false, mfavoritas: true };
        this.setState({ mstring: this.props.gcontent.data.traducoes['most_favorite']['palavra_traducao'], homeTile: this.props.gcontent.data.traducoes['favorite']['palavra_traducao'] });
        break;
      default:
        break;
    }
    this.setState({  _tes: 5, loader: true, endgallery: false, monsaryGal: [] });  
    this.searchfunction(_data);
  }

  fetchMoreData = () => {
    if(this.state.endgallery == false){
      this.setState({ _tes: (this.state._tes + 5), loader: true });
    }
    setTimeout(() => {
      if(this.state.endgallery == false){
        let _data = { mrecentes: this.state.mrecentes, mvistas: this.state.mvistas, mfavoritas: this.state.mfavoritas };
        this.searchfunction(_data);
      }
      //-------------------------------------------------
      this.setState({
        items: this.state.items.concat(Array.from({ length: 1 }))
      });
    }, 1000);
  };

  render() {  
    
    /*if( this.state.redirect == true ){
        return <Redirect to={"/"} />
    }*/
    //this.state.cHeight
    return (
      <>
        <Header linguafunc={this.props.linguafunc} oestado={this.props.oestado} gcontent={this.props.gcontent} theheader="home" classLogo="white" />
        <Container fluid className="bannerHomepage p-0">
          <Container fluid className="bannerHomepage_inner" style={{backgroundImage: "url("+ global.src+"ataficheiros/homepage/" + this.props.gcontent["data"]["homebackground"][2]["link"] +")", height:'772px'}}>
            <Container className="bannerHomepage_wrapper">
              <Zoom cascade>
                <div className="bannerHomepage_content">
                    <h3 className="homeTitle">{this.props.gcontent.data.traducoes['home1']['palavra_traducao']}</h3>
                    <h5 className="homesubTitle">{this.props.gcontent.data.traducoes['home2']['palavra_traducao']}</h5>
                    <Fade>
                      <SearchBar classBar="home" gcontent={this.props.gcontent}/>
                    </Fade>
                </div>
              </Zoom>
            </Container>
          </Container>
          <Container className="ata_stats">
            <Row xs="1" md="3"  className="ata_statsWrapper">
              <Fade top cascade>
                <Col className="ata_statsItem">
                  <span>{this.state.countPhotos != null && this.state.countPhotos != undefined ? (this.state.countPhotos) : (<div id="loadingStats"><Lottie options={{ animationData: animationLoader }} /></div>)}</span>
                  <label>{this.props.gcontent["data"]["traducoes"]["fotos"]["palavra_traducao"]}</label>
                </Col>
                <Col className="ata_statsItem">
                  <span>{this.state.countVideos != null && this.state.countVideos != undefined ? (this.state.countVideos) : (<div id="loadingStats"><Lottie options={{ animationData: animationLoader }} /></div>)}</span>
                  <label>{this.props.gcontent["data"]["traducoes"]["videos"]["palavra_traducao"]}</label>
                </Col>
                <Col className="ata_statsItem">
                  <span>{this.state.countDowns != null && this.state.countDowns != undefined ? (this.state.countDowns) : (<div id="loadingStats"><Lottie options={{ animationData: animationLoader }} /></div>)}</span>
                  <label>{this.props.gcontent["data"]["traducoes"]["downloads"]["palavra_traducao"]}</label>
                </Col>
              </Fade>
            </Row>
          </Container>
        </Container>
        {/* <Breadcrumbs {...this.props} gcontent={this.props.gcontent}/> */}
        <Container fluid className="homepageFeaturedimgs">
          <Container className="p-0">
            {(this.state.monsaryGal) ?(
              <>
                <Row className="headFeaturedimgs m-0">
                  <Col xs="12" lg="8">
                    <h3 className="pageTitle">{this.state.homeTile}</h3>
                    <h5 className="pagesubTitle">{this.props.gcontent["data"]["traducoes"]["home3"]["palavra_traducao"]}</h5>
                  </Col>
                  <Col xs="12" lg="4">
                    <div className="customSelect FeaturedimgsSelect">
                      <button className="customSelect-label FeaturedimgsSelect_button" onClick={this.showMenu}>
                      {this.state.mstring} <span className="icon-arrow-down"></span>
                      </button>
                      <Fade className="customSelect-menuInner FeaturedimgsSelect_menuInner" collapse when={this.state.showMenu} duration={300}>
                          <ul className="customSelect-menu FeaturedimgsSelect_menu">
                            <li onClick={() => this.rightfilters("mrecentes")}>{this.props.gcontent.data.traducoes['most_recent']['palavra_traducao']}</li>
                            <li onClick={() => this.rightfilters("mvistas")}>{this.props.gcontent.data.traducoes['most_viewed']['palavra_traducao']}</li>
                            { /* <li onClick={() => this.rightfilters("mfavoritas")}>Mais Favoritadas</li> */ }
                          </ul>
                      </Fade>
                    </div>
                  </Col>
                </Row>                
                  <GaleryImg photos={this.state.monsaryGal} />
                  {this.state.loader && 
                    <div className="galleryMore">
                      <div id="loadingStats"><Lottie options={{ animationData: animationLoader }} /></div>
                    </div>
                  }
              </>
            ):(this.state.monsaryGal == undefined) ?(
              <Row className="headFeaturedimgs noImages">
                <Col><span>{this.props.gcontent.data.traducoes['emptyGal']['palavra_traducao']}</span></Col>
              </Row>
            ):(
              <div id="loadingContent"><Lottie options={{ animationData: animationLoader }} /></div>
            )}
            <InfiniteScroll
              dataLength={this.state.items.length}
              next={this.fetchMoreData}
              hasMore={true}
              scrollThreshold={0.6}
            >
                {/*this.state.items.map((i, index) => (
                  <div style={style} key={index}>
                      tested
                  </div>
                ))*/}        
            </InfiniteScroll>
          </Container>
        </Container>
      </>
    );
  };

}

export default Home;
