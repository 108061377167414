import React, { Component } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import parse from "html-react-parser";
import Breadcrumbs from '../Breadcrumbs';
import Header from './Header';
import Fade from 'react-reveal/Fade';
import { store } from "../../redux/store";
import { security } from '../../helpers/Security';
import SearchFilter from '../SearchFilters';
import { Redirect } from 'react-router-dom';
import * as loadedcontent from '../loadcontent';
import GaleryImg from "../../helpers/GaleryImg.js";
import Lottie from 'lottie-react-web';
import animationLoader from '../../assets/loading_stats.json';
import InfiniteScroll from 'react-infinite-scroll-component';

class Pesquisa extends Component {
  _isMounted = false;
  _asfotos = [];
  constructor(props) {
    let timer = null;
    super(props);
    this.showMenu = this.showMenu.bind(this);
    this.fotosvideos = this.fotosvideos.bind(this);
    this.categoriassubcategorias = this.categoriassubcategorias.bind(this);
    this.asilhas = this.asilhas.bind(this);
    this.rightfilters = this.rightfilters.bind(this);
    window.addEventListener("resize", this.handleResize.bind(this));
    this.state = {
      items: Array.from({ length: 1 }),
      _tes: 5,
      loader: false,
      endgallery: false,
      //----------------------------
      redirect: security(),
      searchfotos: false,
      searchvideos: false,
      //----------------------------
      searchcats: [],
      searchsubcats: [],
      searchilhas: [],
      //----------------------------
      mrecentes: true,
      mvistas: false,
      mfavoritas: false,
      mstring: this.props.gcontent.data.traducoes['most_recent']['palavra_traducao'],
      searchparam: '',
      //----------------------------
      showMenu: false,
      searchfield: this.props.match.params.search,
      loaderOn: false,
      trickOn: false,
      widthWindow: window.innerWidth,
      formvalues: { 
          "search": "", "stilhas": "0", "stcategorias": "0", "stsubcategorias": "0"
      },
    };
  } 

  handleResize = (event) => {
    this.setState({
      widthWindow: event.currentTarget.innerWidth,
    })
  };

  showMenu(e) {
    e.preventDefault();
    this.setState({ showMenu: !this.state.showMenu });
  }

  //pesquisa coluna esquerda [categorias subcategorias]
  categoriassubcategorias( _tmphelpcats , _tmphelpsubcats ){ 
      let _tmpnew = []; let _ckboll = true;
      let _data = { categorias: _tmphelpcats, subcategorias: _tmphelpsubcats };
      this.setState({ searchcats: _tmphelpcats, searchsubcats: _tmphelpsubcats, endgallery: false, _tes:5 });
      this.searchfunction( _data , "categoriassubcategorias", true);
  }

  //pesquisa coluna esquerda [ilhas]
  asilhas( _tmpilhas ){ 
    let _data = { asilhas: _tmpilhas };
    this.setState({ searchilhas: _tmpilhas, endgallery: false, _tes:5 });
    this.searchfunction( _data , "asilhas", true);
  }

  //pesquisa coluna esquerda [video fotos]
  fotosvideos(ckchecked){
      let _data = { fotos: this.state.searchfotos, videos: this.state.searchvideos }; 
      if( ckchecked == "ckphotos" ){
        _data["fotos"] = !this.state.searchfotos;
        this.setState({ searchfotos: _data["fotos"], endgallery: false, _tes:5});
      }
      if( ckchecked == "ckvideos" ){
        _data["videos"] = !this.state.searchvideos;
        this.setState({ searchvideos: _data["videos"], endgallery: false, _tes:5 });
      }
      this.searchfunction( _data, "fotosvideos", true);
  }

  //pesquisa select filter lado direito
  rightfilters(tipo){
      let _data = { mrecentes: this.state.mrecentes, mvistas: this.state.mvistas, mfavoritas: this.state.mfavoritas  };
      switch(tipo) {
        case 'mrecentes':
          this.setState({ mrecentes: true, mvistas: false, mfavoritas: false, endgallery: false, _tes:5 });
          _data = { mrecentes: true, mvistas: false, mfavoritas: false  };
          this.setState({ mstring: this.props.gcontent.data.traducoes['most_recent']['palavra_traducao'] });
          break;
        case 'mvistas':
          this.setState({ mrecentes: false, mvistas: true, mfavoritas: false, endgallery: false, _tes:5 });
          _data = {  mrecentes: false, mvistas: true, mfavoritas: false  };
          this.setState({ mstring: this.props.gcontent.data.traducoes['most_viewed']['palavra_traducao'] });
          break;
        case 'mfavoritas':
          this.setState({ mrecentes: false, mvistas: false, mfavoritas: true, endgallery: false, _tes:5 });
          _data = { mrecentes: false, mvistas: false, mfavoritas: true };
          this.setState({ mstring: this.props.gcontent.data.traducoes['most_favorite']['palavra_traducao'] });
          break;
      }
      this.searchfunction( _data , "rightfilters", true);
  }

  
  async countSearchImages( data , type, showloader ){
      if ( store.getState()["getatauser"] != null && store.getState()["getatauser"] != undefined && store.getState()["getatauser"] != "") {
          let params = store.getState()["getsearch"];
          let _search = { ataregistoid: store.getState()["getatauser"]["userdata"]["idregisto"],
                          language: store.getState()["getlang"]["idlingua"], 
                          rowlenght: true, 
                          limitlist: '18446744073709551615',
                          popupform: (params.searchvalues == undefined)? "" : params.searchvalues, 
                          rightfilters: { mrecentes: this.state.mrecentes, mvistas: this.state.mvistas, mfavoritas: this.state.mfavoritas  }, 
                          fotosvideos: { fotos: this.state.searchfotos, videos: this.state.searchvideos },
                          asilhas: { asilhas: this.state.searchilhas },
                          categoriassubcategorias: { categorias: this.state.searchcats, subcategorias: this.state.searchsubcats }};
          if( type == "rightfilters" ){
            _search["rightfilters"] = data;
          }else if( type == "fotosvideos" ){
            _search["fotosvideos"] = data;
          }else if( type == "categoriassubcategorias" ){
            _search["categoriassubcategorias"] = data;
          }else if( type == "asilhas" ){
            _search["asilhas"] = data;
          }
          let thephotos = await loadedcontent.global_postaction("actionSearchfiles", _search, 0);
          if( thephotos.data != undefined ){
            return  thephotos.data.images;           
          }
      } 
      return 0;
  }

  async searchfunction( data , type, showloader ){
      let totatl = await this.countSearchImages( data, type, showloader );
      if ( store.getState()["getatauser"] != null && store.getState()["getatauser"] != undefined && store.getState()["getatauser"] != "") {
          if( showloader == true ){ this.setState({ loaderOn: true }); }
          let params = store.getState()["getsearch"];
          let _search = { ataregistoid: store.getState()["getatauser"]["userdata"]["idregisto"],
                          language: store.getState()["getlang"]["idlingua"], 
                          limitlist: this.state._tes,
                          popupform: (params.searchvalues == undefined)? "" : params.searchvalues, 
                          rightfilters: { mrecentes: this.state.mrecentes, mvistas: this.state.mvistas, mfavoritas: this.state.mfavoritas  }, 
                          fotosvideos: { fotos: this.state.searchfotos, videos: this.state.searchvideos },
                          asilhas: { asilhas: this.state.searchilhas },
                          categoriassubcategorias: { categorias: this.state.searchcats, subcategorias: this.state.searchsubcats }};
          if( type == "rightfilters" ){
            _search["rightfilters"] = data;
          }else if( type == "fotosvideos" ){
            _search["fotosvideos"] = data;
          }else if( type == "categoriassubcategorias" ){
            _search["categoriassubcategorias"] = data;
          }else if( type == "asilhas" ){
            _search["asilhas"] = data;
          }

          let tempphotos = []; 
          let thephotos = await loadedcontent.global_postaction("actionSearchfiles", _search, 0);

          let c_cont = 0;
          if( thephotos.data != undefined ){
            for (let index in thephotos.data.images){
              if ( thephotos.data.images[index]['visivel'] == 1 ){  
                if( c_cont < this.state._tes ){
                  tempphotos.push({
                    id: thephotos.data.images[index]['id_ficheiro'],
                    src: global.src + ((thephotos.data.images[index]['tipo_ficheiro'] == 1)? 'ataimg/tb/' : 'atavid/tb/') + thephotos.data.images[index]['thumb'],
                    url: global.src +  ((thephotos.data.images[index]['tipo_ficheiro'] == 1)? 'ataimg/tb/' : 'atavid/prev/') + ((thephotos.data.images[index]['tipo_ficheiro'] == 1)? thephotos.data.images[index]['thumb'] : thephotos.data.images[index]['videopreview']),
                    width: parseInt(thephotos.data.images[index]['largura']),
                    height: parseInt(thephotos.data.images[index]['altura']),
                    title: thephotos.data.images[index]['titulo_en'],
                    type:  (thephotos.data.images[index]['tipo_ficheiro'] == 1)? 'photo' : 'video'  
                  });
                } 
                c_cont++;   
              }
            }
          }

          this._asfotos = tempphotos;
          if( showloader == true ){ this.setState({ loaderOn: false }); }else{ this.setState({ loader: false }); }
      } 
  }

  fetchMoreData = () => {
    if(this.state.endgallery == false){
      this.setState({ _tes: (this.state._tes + 5), loader: true });
    }else{
    }
    setTimeout(() => {
      if(this.state.endgallery == false){
        let _data = { mrecentes: this.state.mrecentes, mvistas: this.state.mvistas, mfavoritas: this.state.mfavoritas };
        this.searchfunction(_data, '', false);
      }
      //-------------------------------------------------
      this.setState({ 
        items: this.state.items.concat(Array.from({ length: 1 }))
      });
    }, 1000);
  };


  componentDidUpdate(){
    //console.log("UPDATE");
    let params = store.getState()["getsearch"];
    if( params.gosearch == true ){
        store.dispatch({ type: "search", param: { "searchvalues" : params.searchvalues , "gosearch" : false } });   
        //--------Search---------
        this.searchfunction( null , '', true );
      }
  }

  componentDidMount(){
    this.setState({ trickOn: !this.state.trickOn }); 
  }

  render() {
      if( this.state.redirect == true ){
          return <Redirect to={"/"} />
      }
      var searchStr = '';
      if( this.state.searchfield != null && this.state.searchfield != undefined ){
        var splitparams = decodeURI(this.props.match.params.search).split("&");
        //console.log(splitparams);
        //console.log(this.props.match.params.search);
        for(let f = 0; f < splitparams.length; f++){
            var _tmp = splitparams[f].split("=");
            if( _tmp[0] != null || _tmp[0] != undefined ){
                if( _tmp[0].trim() == "search" ){
                    searchStr = _tmp[1];
                    break;
                }
            }
        } 
      }
      return (
        <>
          <Header linguafunc={this.props.linguafunc} searchfield={this.state.searchfield} oestado={this.props.oestado} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} gcontent={this.props.gcontent} theheader="default" />
          <Container className="content contentPage">
              <Breadcrumbs {...this.props} searchfield={this.state.searchfield} gcontent={this.props.gcontent}/>
              <Row className="headSearchresults">
                  <Col xs="12" lg="8">
                    {this.state.showMenu}
                      <h3 className="pageTitle">{this.props.gcontent['data']['traducoes']['search_results']['palavra_traducao']} 
                        { searchStr != "" &&
                            parse(" " + this.props.gcontent['data']['traducoes']['para']['palavra_traducao'] + " " + "<b>"+searchStr+"</b>")
                        }
                      </h3>
                  </Col>
                  <Col xs="12" lg="4">
                    <div className="customSelect FeaturedimgsSelect">
                      <button className="customSelect-label FeaturedimgsSelect_button" onClick={this.showMenu}>
                          {this.state.mstring} <span className="icon-arrow-down"></span>
                      </button>
                      <Fade className="customSelect-menuInner FeaturedimgsSelect_menuInner" collapse when={this.state.showMenu}>
                          <ul className="customSelect-menu FeaturedimgsSelect_menu">
                            <li onClick={() => this.rightfilters("mrecentes")}>{this.props.gcontent.data.traducoes['most_recent']['palavra_traducao']}</li>
                            <li onClick={() => this.rightfilters("mvistas")}>{this.props.gcontent.data.traducoes['most_viewed']['palavra_traducao']}</li>
                            { /* <li onClick={() => this.rightfilters("mfavoritas")}>Mais Favoritadas</li> */ }
                          </ul>
                      </Fade>
                    </div>
                  </Col>
              </Row>
              <Row className="content-sub">
                  <Col lg={3} className="d-none d-lg-flex">
                      {this.state.widthWindow > 768 &&
                        <SearchFilter fotosandvideos={this.fotosvideos} catssubcats={this.categoriassubcategorias} asilhas={this.asilhas} gcontent={this.props.gcontent} />
                      }
                  </Col>
                  <Col lg={9} className="">
                      {this.state.loaderOn == true &&
                        <div id="loadingContent"><Lottie options={{ animationData: animationLoader }} /></div>
                      }
                      {this._asfotos.length > 0 && this.state.loaderOn == false &&
                        <GaleryImg photos={this._asfotos} /> 
                      }
                      {this._asfotos.length == 0 && this.state.loaderOn == false &&
                        <div id="emptyContent">{this.props.gcontent['data']['traducoes']['pesquisaproblema']['palavra_traducao']}</div>
                      }
                      {this.state.loader &&
                        <div className="galleryMore">
                          <div id="loadingStats"><Lottie options={{ animationData: animationLoader }} /></div>
                        </div>
                      }
                  </Col>
              </Row>
              <InfiniteScroll
                dataLength={this.state.items.length}
                next={this.fetchMoreData}
                hasMore={true}
                scrollThreshold={0.6}
              >
                  {/*this.state.items.map((i, index) => (
                    <div style={style} key={index}>
                        tested
                    </div>
                  ))*/}        
              </InfiniteScroll>
          </Container>
        </>
      );
  };

}

export default Pesquisa;