import React, { Component } from 'react';
import { Row, Container, Col, Form } from 'react-bootstrap';
import {CollapsibleComponent, CollapsibleHead, CollapsibleContent} from "react-collapsible-component";
import parse from 'html-react-parser';
import { Redirect } from 'react-router-dom';
import { store } from "../redux/store";

class SearchFilter extends Component {
    _redirect = false;
    constructor(props) {
        super(props);
        this.state ={
            isActive: false,
            searchstring: "",
            //----------------------------
            clickcats: [],
            clicksubcats: [],
            clickilhas: [],
            //----------------------------
        }
        this.sideSearch = this.sideSearch.bind(this);
        this.ckfotosvideos = this.ckfotosvideos.bind(this);
        this.changecheckboxes = this.changecheckboxes.bind(this);
        this.changeilhasboxes = this.changeilhasboxes.bind(this);
        this.countsubcats = this.countsubcats.bind(this);
        this.getilhas = this.getilhas.bind(this);
        this.helpchangeboxes = this.helpchangeboxes.bind(this);
    }

    handleToggle = () => {
        this.setState({ isActive: !this.state.isActive });
    };

    sideSearch = () => {
        alert('sideSearch');
    }

    redirectCK( catid , subcatid ) {
        let params = store.getState()["getsearch"];
        let _tmpcat = ( subcatid > 0 )? catid : 0 ;
        if( params.searchvalues != undefined ){
            //-----------------------------------
            let _tstring = "search="+params.searchvalues["search"]+"&stilhas="+params.searchvalues["stilhas"]+"&stcategorias="+_tmpcat+"&stsubcategorias=0&";
            this.setState({ searchstring: _tstring });
            //-----------------------------------
            params.searchvalues["stcategorias"] = _tmpcat; params.searchvalues["stsubcategorias"] = 0;
            store.dispatch({ type: "search", param: { "searchvalues" : params.searchvalues , "gosearch" : true } }); 
        }
        this._redirect = true;
    } 

    //categorias & subcategorias
    helpchangeboxes( itemid, type ){
        let _tmpnew = []; let _ckboll = true; 
        let _tmp = (type == "categorias")? this.state.clickcats : this.state.clicksubcats; 
        for( let g = 0; g < _tmp.length; g++ ){
            if ( itemid != _tmp[g] ){
                _tmpnew.push(_tmp[g]);
            }else{ _ckboll = false; }
        }
        if( _tmp.length == 0 || _ckboll == true ){ _tmpnew.push(itemid); }
        //--------------------------------------------
        return _tmpnew;
    }

    //categorias & subcategorias
    changecheckboxes(catid, subcatid, changeck, allCK){
        //console.log( "WAZAAAAA - " + catid + " subcatid " + subcatid+" change "+ changeck );
        if(changeck == true){
            this.redirectCK(catid, subcatid);
        }else{
            let _tmphelpcats = this.state.clickcats; let _tmphelpsubcats = this.state.clicksubcats;
            //-----------------------------------------------
            if( catid > 0 && subcatid <= 0 ){
                _tmphelpcats = this.helpchangeboxes( catid, "categorias" );
                //console.log(_tmphelpcats);
                this.setState({ clickcats: _tmphelpcats });
            }
            //--------------Caso Clicar em ALL/TUDO---------------------
            if( allCK == true ){
                let _tmplist = []; let _objsubcats = this.props.gcontent["data"]["subcategorias"];
                for(let d = 0; d < _tmphelpsubcats.length; d++){
                    let obj = Object.keys( _objsubcats).find(key => _objsubcats[key].idsubcategorias == _tmphelpsubcats[d] && catid == _objsubcats[key].idcategorias );
                    if( obj == undefined ){
                        _tmplist.push(_tmphelpsubcats[d]);
                    }
                }
                 _tmphelpsubcats = _tmplist;
                 this.setState({ clicksubcats: _tmphelpsubcats });
             }
            //------------------------------------------------------
            if( subcatid > 0 ){ 
                _tmphelpsubcats = this.helpchangeboxes( subcatid, "subcategorias" );
                //console.log(_tmphelpsubcats);
                this.setState({ clicksubcats: _tmphelpsubcats });
                //--------------Remover check ALL/TUDO--------------
                if( catid > 0 ){
                    let _tmplist = [];
                    for(let d = 0; d < _tmphelpcats.length; d++){
                        if( catid != _tmphelpcats[d] ){
                            _tmplist.push(_tmphelpcats[d]);
                        }
                    }
                    //---------------------------------------------
                    let params = store.getState()["getsearch"];
                    if( catid == params.searchvalues["stcategorias"] ){
                        params.searchvalues["stcategorias"] = 0; params.searchvalues["stsubcategorias"] = 0;
                        store.dispatch({ type: "search", param: { "searchvalues" : params.searchvalues , "gosearch" : false } });    
                    }
                    //--------------------------------------------
                    _tmphelpcats = _tmplist;
                    this.setState({ clickcats: _tmphelpcats });
                }
                //-------------------------------------------------
            }
            //-----------------------------------------------
            this.props.catssubcats( _tmphelpcats , _tmphelpsubcats );
        }
        //console.log(" CHANGING CHECKBOXES ");
        //-----------------------------------------------
    }

    //Ilhas
    changeilhasboxes( ilhaid, allCK){
        let _tmplist = []; let output = [];
        let params = store.getState()["getsearch"];
        //-------------- Caso clicar em ALL/TUDO ---------------------
        if( allCK == true && this.state.clickilhas.length < 9 ){
            Object.keys(this.props.gcontent["data"]["ilhas"]).map((subitem, i) => {
                _tmplist.push(this.props.gcontent["data"]["ilhas"][subitem]["idilhas"]);
            });
            this.setState({ clickilhas: _tmplist });
            output = _tmplist;
        }else if( allCK == true && this.state.clickilhas.length == 9 ){
            this.setState({ clickilhas: [] });
            if( params.searchvalues != undefined ){
                params.searchvalues["stilhas"] = 0;
                store.dispatch({ type: "search", param: { "searchvalues" : params.searchvalues , "gosearch" : false } });
            }
        }
        //------------------------------------------------------------
        if( allCK == false && ilhaid > 0 ){
            let _objilhas = this.state.clickilhas; let _in = false;
            let obj = Object.keys( _objilhas).find(key => _objilhas[key] == ilhaid );
            if( obj == undefined ){
                _objilhas.push(ilhaid);
                this.setState({ clickilhas: _objilhas }); output = _objilhas; _in = true; 
            }else{
                _in = false;
                for( let g = 0; g < _objilhas.length; g++ ){
                    if( _objilhas[g] != ilhaid ){
                        _tmplist.push(_objilhas[g]);
                    }
                }
                this.setState({ clickilhas: _tmplist }); output = _tmplist;
            }
            //console.log(" ZIMGA BUE ");
            //console.log(_in);
            if( params.searchvalues != undefined && _in == false ){
                params.searchvalues["stilhas"] = 0;
                store.dispatch({ type: "search", param: { "searchvalues" : params.searchvalues , "gosearch" : false } });
            }
        }
        this.props.asilhas( output );
        //-----------------------------------------------
    }

    getilhas(){
        let params = store.getState()["getsearch"]; 
        return (
            <div className="CollapsibleItem">
                <CollapsibleHead className="CollapsibleHead">
                    {this.props.gcontent.data.traducoes['all_islands']['palavra_traducao']}
                </CollapsibleHead>
                <CollapsibleContent className="CollapsibleContent">
                    <Row className="ContentType filters m-0">
                        <Col xs={6} className="p-0">
                            <Form.Group>
                                <Form.Check checked={ ( this.state.clickilhas.length == 9 )? true : false } className="customCheckbox" onChange={() => { this.changeilhasboxes( 0, true) }} 
                                type="checkbox" value={0} label={this.props.gcontent.data.traducoes['all']['palavra_traducao']} />
                            </Form.Group>
                        </Col>
                        {   
                            Object.keys(this.props.gcontent["data"]["ilhas"]).map((subitem, i) => {
                                //------------------------------------------------------
                                let check = false;
                                if( params.searchvalues != undefined ){
                                    if( params.searchvalues["stilhas"] == this.props.gcontent["data"]["ilhas"][subitem]["idilhas"] ){
                                        check = true;
                                    }
                                }
                                for( let f = 0; f < this.state.clickilhas.length; f++ ){
                                    if( this.props.gcontent["data"]["ilhas"][subitem]["idilhas"] == this.state.clickilhas[f] ){
                                        check = true;
                                    }
                                }
                                //let select = (this.props.gcontent["data"]["ilhas"][levelOneKey]["idilhas"] == this.state.formvalues["stilhas"]) ? "selected" : "";
                                return(
                                    <Col key={"ckecks"+this.props.gcontent["data"]["ilhas"][subitem]["idilhas"]} xs={6} className="p-0">
                                        <Form.Group>
                                            <Form.Check checked={check} className="customCheckbox" 
                                                onChange={() => { this.changeilhasboxes( this.props.gcontent["data"]["ilhas"][subitem]["idilhas"], false ) }} 
                                                type="checkbox" 
                                                value={this.props.gcontent["data"]["ilhas"][subitem]["idilhas"]} 
                                                label={parse(this.props.gcontent["data"]["ilhas"][subitem]["nome_ilha"])} />
                                        </Form.Group>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </CollapsibleContent>
                <hr/>
            </div>
        );
    }

    //fotosandvideos
    ckfotosvideos(event) {
        //console.log(" CHANGING ckfotosvideos ");
        this.props.fotosandvideos(event.target.name);        
        //fvalues[event.target.name] = event.target.value;
    }

    countsubcats(item, categoriaid, categorianome){
        //console.log(" ZANCA ZIMBABUE ");
        let params = store.getState()["getsearch"];
        //console.log( params.searchvalues );
        //---------------------------------------
        let _ccount = 0;
        if( this.props.gcontent["data"]["subcategorias"] != null ){              
            Object.keys(this.props.gcontent["data"]["subcategorias"]).map((subitem, i) => {
                if ((categoriaid == this.props.gcontent["data"]["subcategorias"][subitem]['idcategorias']) &&
                    (this.props.gcontent["data"]["subcategorias"][subitem]['ididiomas'] == store.getState()["getlang"]["idlingua"] )){
                        _ccount++;
                }
            });
        }
        if(_ccount > 0){
            let check = false; let changeck = false;
            if( params.searchvalues != undefined ){
                if( categoriaid == params.searchvalues["stcategorias"] && params.searchvalues["stsubcategorias"] == 0 ){
                    check = true; changeck = true;
                }
            }
            for( let f = 0; f < this.state.clickcats.length; f++ ){
                if( categoriaid == this.state.clickcats[f] ){
                    check = true;
                }
            }
            return (
                <div className="CollapsibleItem">
                    <CollapsibleHead className="CollapsibleHead">
                        {categorianome}
                    </CollapsibleHead>
                    <CollapsibleContent className="CollapsibleContent">
                        <Row className="ContentType filters m-0">
                            <Col xs={6} className="p-0">
                                <Form.Group>
                                    <Form.Check checked={check} className="customCheckbox" onChange={() => { this.changecheckboxes( categoriaid , 0, changeck, true) }} type="checkbox" value={categoriaid} label={this.props.gcontent.data.traducoes['all']['palavra_traducao']} />
                                </Form.Group>
                            </Col>
                            {this.props.gcontent["data"]["subcategorias"] != null && 
                                Object.keys(this.props.gcontent["data"]["subcategorias"]).map((subitem, i) => {
                                    let check = false; let changeck = false;
                                    if( params.searchvalues != undefined ){
                                        if( this.props.gcontent["data"]["subcategorias"][subitem]['idsubcategorias'] == params.searchvalues["stsubcategorias"]){
                                            check = true; changeck = true;
                                        }
                                    }
                                    for( let f = 0; f < this.state.clicksubcats.length; f++ ){
                                        if( this.props.gcontent["data"]["subcategorias"][subitem]['idsubcategorias'] == this.state.clicksubcats[f] ){
                                            check = true;
                                        }
                                    }
                                    if ((this.props.gcontent["data"]["categorias"][item]['idcategorias'] == this.props.gcontent["data"]["subcategorias"][subitem]['idcategorias']) &&
                                        (this.props.gcontent["data"]["subcategorias"][subitem]['ididiomas'] == store.getState()["getlang"]["idlingua"] )){
                                        return(
                                            <Col key={"ckecks"+this.props.gcontent["data"]["subcategorias"][subitem]['idsubcategorias']} xs={6} className="p-0">
                                                <Form.Group>
                                                    <Form.Check checked={check} className="customCheckbox" onChange={() => { this.changecheckboxes( this.props.gcontent["data"]["subcategorias"][subitem]['idcategorias'], this.props.gcontent["data"]["subcategorias"][subitem]['idsubcategorias'], changeck, false) }} type="checkbox" value={this.props.gcontent["data"]["subcategorias"][subitem]['idsubcategorias']} label={this.props.gcontent["data"]["subcategorias"][subitem]['nome_subcategoria']} />
                                                </Form.Group>
                                            </Col>
                                        )
                                    }
                                })
                            }
                        </Row>
                    </CollapsibleContent>
                    <hr/>
                </div>
            );                                
        }else{
            let check = false; let changeck = false;
            if( params.searchvalues != undefined ){
                if( categoriaid == params.searchvalues["stcategorias"]){
                    check = true; changeck = true;
                }
            }
            for( let f = 0; f < this.state.clickcats.length; f++ ){
                if( categoriaid == this.state.clickcats[f] ){
                    check = true;
                }
            }
            return (
                <div className="CollapsibleItem">
                    <CollapsibleHead className="CollapsibleHead noSubcat">
                        {categorianome}
                        <Form.Group>
                            <Form.Check checked={check} onChange={() => { this.changecheckboxes( categoriaid , 0, changeck, false ) }} className="customCheckbox" type="checkbox" value={categoriaid} label=""  />
                        </Form.Group>
                    </CollapsibleHead>
                    <hr/>
                </div>
            );
        } 
    }

    componentDidMount(){
        let params = store.getState()["getsearch"];
        if( params.searchvalues != undefined ){
            let _tmpilhas = this.state.clickilhas;
            if( params.searchvalues["stilhas"] != "0" ){
                _tmpilhas.push(params.searchvalues["stilhas"]);
            }
        }
    }
 
    render() { 
        const isActive = this.state.isActive;
        if( this._redirect == true ){
            this._redirect = false;
            return <Redirect to={"/pesquisa/" + this.state.searchstring} />
        }else{
            return (
                <Container fluid className="AdminMenu filterMenu p-0">
                    <h3 className="menuTitle">{this.props.gcontent.data.traducoes['filtersSearch']['palavra_traducao']}</h3>
                    <div className="sideMenuContent">
                        <Form>
                            <Row className="ContentType">
                                <Col xs={6} className="p-0">
                                    <Form.Group>
                                        <Form.Check onChange={this.ckfotosvideos}  name="ckphotos" className="customCheckbox" type="checkbox" value="photos" label="Photos" />
                                    </Form.Group>
                                </Col>
                                <Col xs={6} className="p-0">
                                    <Form.Group>
                                        <Form.Check onChange={this.ckfotosvideos} className="customCheckbox" name="ckvideos" type="checkbox" value="videos" label="Videos" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <hr/>
                            <Row className="m-0">
                                <CollapsibleComponent>
                                    {this.getilhas()}
                                    {this.props.gcontent["data"]["categorias"] != null && 
                                        Object.keys(this.props.gcontent["data"]["categorias"]).map((item, i) => {
                                            if (this.props.gcontent["data"]["categorias"][item]['ididiomas'] == store.getState()["getlang"]["idlingua"]){
                                                return(
                                                    <span key={this.props.gcontent["data"]["categorias"][item]['idcategorias']}>
                                                        {this.countsubcats(
                                                                item, 
                                                                this.props.gcontent["data"]["categorias"][item]['idcategorias'], 
                                                                parse(this.props.gcontent["data"]["categorias"][item]['nome_categoria'])
                                                            )
                                                        }
                                                    </span>
                                                )
                                            }
                                        })
                                    }                           
                                </CollapsibleComponent>
                            </Row>
                        </Form>
                    </div>
                </Container>
           );
        }
    };

}

export default SearchFilter;
