import React, { Component } from 'react';
import { Row, Container, Col, Form } from 'react-bootstrap';
import { validate, NoEmpty, Username, Password, Email, Fnumber, File } from '../../helpers/Validationfuncs';
import * as loadedcontent from '../loadcontent';
import CountrySelector from "../CountrySelector";
import { store } from '../../redux/store';
import Footer from './Footer.js';
import parse from 'html-react-parser';
import Loader from "../../helpers/Loader";
import Lottie from 'lottie-react-web';
import respok from '../../assets/response_ok.json';
import resperror from '../../assets/response_error.json';
import { Redirect } from 'react-router';
import { NavLink } from 'react-router-dom';

class Registo extends Component {
  defaultPerfil = "";
  defaultSubperfil = "";
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.cleanContent = this.cleanContent.bind(this);
    this.changeForm = this.changeForm.bind(this);
    this.changeTerms = this.changeTerms.bind(this);
    this.closeTerms = this.closeTerms.bind(this);
    this.state = {
      isActive: false,
      theform: "none",
      accountterms: "block",
      theterms: "none",
      selectperfis:"",
      selectcountry:"",
      keymessageout: "",
      classkeymessageout: "",
      showPlaceolder:true,
      registoCRUD: false,
      jobfield: true,
      loading: false,
      fileName: '',
      attachimageform: '',
      subPerfis: [],
      /*
          theusername
          thepassword
          first_name
          last_name
          selectperfis
          selectsubperfis
          company_name
          job_company
          nif
          cae
          country
          phone
          email
      */
      formvalidation: {
          "formsubmited": false, "theusername": [ false , "", ["noempty","username"] ], "thepassword":  [ false , "", ["noempty", "password"] ], 
          "first_name":  [ false , "", ["noempty"] ], "last_name":  [ false , "", ["noempty"] ], "selectperfis":  [ false , "", ["noempty"] ], 
          "selectsubperfis":  [ false , "", ["noempty"] ], "company_name":  [ false , "", ["noempty"] ], "job_company":  [ false , "", ["noempty"] ]
          , "nif":  [ false , "", ["noempty", "number"] ], "cae":  [ false , "", ["noempty"] ], "country":  [ false , "", ["noempty"] ], 
          "phone":  [ false , "", ["noempty"] ], "email":  [ false , "", ["noempty", "email"] ], "certificado_ficheiro":  [ false , "", ["file"] ], 
      },
      formvalues: { 
          "idregisto": 0, "theusername": "", "thepassword": "", "first_name": "", "last_name": "", "selectperfis": "", "selectsubperfis": "", "company_name": "", "job_company": ""
          , "nif": "", "cae": "", "country": "", "phone": "", "email": "", "certificado_ficheiro" : ""
      },
      countrieCK: false,
    };
  };
  
  cleanContent() {
      this.setState({
          selectperfis:"",
          selectcountry:"",
          keymessageout: "",
          classkeymessageout: "",
          registoCRUD: false,
          showPlaceolder:true,
          jobfield: false,
          loading: false,
          fileName: '',
          attachimageform: '',
          subPerfis: [],
          formvalidation: {
            "formsubmited": false, "theusername": [ false , "", ["noempty","username"] ], "thepassword":  [ false , "", ["noempty", "password"] ], 
            "first_name":  [ false , "", ["noempty"] ], "last_name":  [ false , "", ["noempty"] ], "selectperfis":  [ false , "", ["noempty"] ], 
            "selectsubperfis":  [ false , "", ["noempty"] ], "company_name":  [ false , "", ["noempty"] ], "job_company":  [ false , "", ["noempty"] ]
            , "nif":  [ false , "", ["noempty", "number"] ], "cae":  [ false , "", ["noempty"] ], "country":  [ false , "", ["noempty"] ], 
            "phone":  [ false , "", ["noempty"] ], "email":  [ false , "", ["noempty", "email"] ], "certificado_ficheiro":  [ false , "", ["file"] ], 
          },
          formvalues: { 
              "idregisto": 0, "theusername": "", "thepassword": "", "first_name": "", "last_name": "", "selectperfis": "", "selectsubperfis": "", "company_name": "", "job_company": ""
              , "nif": "", "cae": "", "country": "", "phone": "", "email": "", "certificado_ficheiro" : ""
          },
      });
  }

  
  handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };
  

  handleChange(event) {
    let fvalues = this.state.formvalues;
    switch(event.target.name) {
      case 'visit_conteudosobre':
          fvalues[event.target.name] = event.target.value;
          break;
      default:
          fvalues[event.target.name] = event.target.value;
          break;
    }
    this.setState({ formvalues: fvalues });
    
  }

  onperfilchange = (event) => {
      let fvalues = this.state.formvalues;
      var tdataform = this.state.formvalidation; //VALIDATION
      let namefield = event.target.name;
      let idperfil = event.target.value;
      fvalues[namefield] = idperfil;
      this.setState({ selectperfis: idperfil }); 
      let index = event.nativeEvent.target.selectedIndex;
      let label = event.nativeEvent.target[index].text;
      //if ("comunicação social" == label.toLowerCase().trim() || "social media" == label.toLowerCase().trim()) { this.setState({ jobfield: false }); } else { this.setState({ jobfield: true }); }

      if( "comunicação social" == label.toLowerCase().trim() || "social media" == label.toLowerCase().trim() ){
        this.setState({ jobfield: false }); 
        fvalues["job_company"] = "";
        tdataform["job_company"][0] = true; 
      }else{ 
        this.setState({ jobfield: true }); 
        tdataform["job_company"][0] = false;
      }
      let subperfis = [];
      for (let levelOneKey in this.props.gcontent["data"]["subperfis"]) {
        if (  this.props.gcontent["data"]["subperfis"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"] && 
              idperfil == this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis"] 
          ){
              subperfis.push(
                <option 
                    key={this.props.gcontent["data"]["subperfis"][levelOneKey]["subperfil_referencia"]}
                    data-index={levelOneKey}
                    data-ref={this.props.gcontent["data"]["subperfis"][levelOneKey]["subperfil_referencia"]}
                    value={this.props.gcontent["data"]["subperfis"][levelOneKey]["idperfis_sub"]}>
                        {parse(this.props.gcontent["data"]["subperfis"][levelOneKey]["nome_perfil_sub"])}
                </option>
              );
        }
      }
      tdataform["selectsubperfis"][1] = "";
      if( subperfis.length > 0 ){
        fvalues["selectsubperfis"] = "";
        tdataform["selectsubperfis"][0] = false; 
      }else{
        fvalues["selectsubperfis"] = "0";
        tdataform["selectsubperfis"][0] = true; 
      }
      //console.log(fvalues);
      this.setState({ formvalues: fvalues });
      this.setState({ formvalidation: tdataform });
      this.setState({ subPerfis : subperfis });
  }

  onsubperfilchange = (event) => {
    let fvalues = this.state.formvalues;
    let namefield = event.target.name;
    let idperfil_sub = event.target.value;
    fvalues[namefield] = idperfil_sub;
    this.setState({ formvalues: fvalues });
    //console.log(fvalues);
}

  getperfis = () => {
    let perfis = [];
    for (let levelOneKey in this.props.gcontent["data"]["perfis"]) {
      if (this.props.gcontent["data"]["perfis"][levelOneKey]["ididiomas"] == store.getState()["getlang"]["idlingua"] 
          && this.props.gcontent["data"]["perfis"][levelOneKey]["perfil_referencia"] != "ata" ) {
        perfis.push(
          <option 
              key={this.props.gcontent["data"]["perfis"][levelOneKey]["perfil_referencia"]}
              data-index={levelOneKey}
              data-ref={this.props.gcontent["data"]["perfis"][levelOneKey]["perfil_referencia"]}
              value={this.props.gcontent["data"]["perfis"][levelOneKey]["idperfis"]}>
                  {parse(this.props.gcontent["data"]["perfis"][levelOneKey]["nome_perfil"])}
          </option>
        );
      }
    }
    return perfis;
  }

  changecountrieCK = (valor) => {
    //------------------------------------------------------------
    if( valor["value"] != undefined ){
      if( valor["value"] != "PT" ){
        let tdataform = this.state.formvalidation;
        tdataform["nif"][0] = true; tdataform["cae"][0] = true;
        this.setState({ formvalidation: tdataform });
      }
    }
    //------------------------------------------------------------
    this.setState({ countrieCK: false, selectcountry: valor });    
  }

  getcountrie = value => {
      if (this.state.countrieCK == true) {
          return JSON.parse(this.state.formvalues["country"]);
      } else {
          let _tmpplo = this.state.formvalues;
          if(_tmpplo["country"] != JSON.stringify(value)){ 
              _tmpplo["country"] = JSON.stringify(value);
              this.setState({ formvalues: _tmpplo });
          }
          return value;
      }
  }

  handleFile(event) {
      event.preventDefault();
      let tdata = this.state.formvalues;
      
      var tdataform = this.state.formvalidation; //VALIDATION
      let file = event.target.files;
      let _tmp = File(file[0], ["application/pdf"] , 4000000,
        [
          this.props.gcontent.data.traducoes['only_files']['palavra_traducao'],
          this.props.gcontent.data.traducoes['file_heavy']['palavra_traducao']
        ]
      );
      if(_tmp != ""){
        tdataform["certificado_ficheiro"][1] = _tmp; 
        tdataform["certificado_ficheiro"][0] = false; 
      }else{
        tdataform["certificado_ficheiro"][1] = _tmp; 
        tdataform["certificado_ficheiro"][0] = true; 
      }
      this.setState({ 
                      formvalidation: tdataform,
                      attachimageform: file, 
                      fileName: file[0].name,
                      showPlaceolder:false
                  });
      
  }

  async handleSubmit(event) {
      event.preventDefault();
      this.setState({isShowing:false})
      let tdata = this.state.formvalues;      
      var tdataform = this.state.formvalidation; //VALIDATION
      //!this.state.subPerfis.length 
      if( this.state.subPerfis.length <= 0 ){ 
          tdata["job_company"] = ( tdata["job_company"].trim() != "" )? tdata["job_company"] : "temoraryvalue"; 
          tdata["selectsubperfis"] = "temoraryvalue"; 
      }
      let tmpvalidate = validate( tdataform, tdata, 0,
        [
          this.props.gcontent.data.traducoes['field_required']['palavra_traducao'],
          this.props.gcontent.data.traducoes['min_char']['palavra_traducao'],
          this.props.gcontent.data.traducoes['spaces_nallowed']['palavra_traducao'],
          this.props.gcontent.data.traducoes['min8_char']['palavra_traducao'],
          this.props.gcontent.data.traducoes['psw_rule']['palavra_traducao'],
          this.props.gcontent.data.traducoes['only_number']['palavra_traducao'],
          this.props.gcontent.data.traducoes['invalid_email']['palavra_traducao'],
        ]  
      ); //return [validform, tdataform, tdata ];
      //------------------NIF CAE------------------------------------
      if( this.state.selectcountry["value"] != undefined ){
          if( this.state.selectcountry["value"] != "PT" ){
              tdataform["nif"][0] = true; tdataform["cae"][0] = true;
              this.setState({ formvalidation: tdataform });
          }
      }
      //-------------------------------------------------------------
      if( this.state.subPerfis.length <= 0 ){ 
        tdata["job_company"] = ( tdata["job_company"].trim() == "temoraryvalue" )? "" : tdata["job_company"]; 
        tdata["selectsubperfis"] = ""; 
      }
      this.setState({ formvalidation: tmpvalidate[1] });
      tmpvalidate[1]["formsubmited"] = true;

      if( this.state.fileName != "" ){
        tmpvalidate[0] = ( tmpvalidate[0] == true )? true : tmpvalidate[0] ;
        let filename = ( this.state.attachimageform != null )? await loadedcontent.upload_file(this.state.attachimageform,'certificado') : "";
        tmpvalidate[2]["certificado_ficheiro"] = filename;
        this.setState({ formvalues: tmpvalidate[2] });
      }else{
        tmpvalidate[0] = false;
        let validation = this.state.formvalidation;
        validation["certificado_ficheiro"][0] = false;
        if( validation["certificado_ficheiro"][1] == "" ){
          validation["certificado_ficheiro"][1] = ( store.getState()["getlang"]["idlingua"] == 'pt' )? "Campo Obrigatório" : "Field Required" ;
        }
        this.setState({ formvalidation: validation });
      }
      /*
        if( tmpvalidate[1]["certificado_ficheiro"][0] == true ){
          tmpvalidate[0] = ( tmpvalidate[0] == true )? true : tmpvalidate[0] ;
          let filename = ( this.state.attachimageform != null )? await loadedcontent.upload_file(this.state.attachimageform,'certificado') : "";
          tmpvalidate[2]["certificado_ficheiro"] = filename;
          this.setState({ formvalues: tmpvalidate[2] });
        } 
      */

      if( tmpvalidate[0] == true ){
        //---------Check username and email duplication--------------
        let ckusername = await loadedcontent.global_postaction( "actionRegisto", { "search": " username = '" + this.state.formvalues["theusername"] + "' " } , 0);
        let ckemail = await loadedcontent.global_postaction( "actionRegisto", { "search": " email = '" + this.state.formvalues["email"] + "' " } , 0);
        if( ckusername["data"].length > 0 ){
          tmpvalidate[1]["theusername"][1] = "Username duplicado";
          tmpvalidate[1]["theusername"][0] = false; tmpvalidate[0] = false;
        }
        if( ckemail["data"].length > 0 ){
          tmpvalidate[1]["email"][1] = "Email duplicado";
          tmpvalidate[1]["email"][0] = false; tmpvalidate[0] = false;
        }
        this.setState({ formvalidation: tmpvalidate[1] });
        //-----------------------------------------------------------
        if( tmpvalidate[0] == true ){
            this.setState({ loading: !this.state.loading }); let rdrtlogin = false;
            setTimeout(
              async function() {
                  let crud = await loadedcontent.global_postaction( "actionRegisto", tmpvalidate[2], 1);
                  if( crud["success"] != "" ){ 
                    this.setState({ classkeymessageout: "success-out" , keymessageout: crud["success"] });
                    this.setState({ registoCRUD: true });
                    rdrtlogin = true;
                  }else{
                    this.setState({ classkeymessageout: "error-out" , keymessageout: crud["error"] });
                    this.setState({ registoCRUD: true });
                  }
                 // console.log( " Vou inserir na BD " );
                  //console.log(tmpvalidate[2]);
                  //----------------------------------------
                  this.setState({ loading: !this.state.loading });
                  setTimeout( async function() {  this.cleanContent();this.setState({isShowing:false}) }.bind(this), 1500 );
                  if( rdrtlogin == true ){
                      setTimeout( function() {  this.props.closefunc(); }.bind(this), 2000 );
                  }
              }
              .bind(this),
              500
          );
        }
      }
  }

  changeForm(event) {
    this.setState({ accountterms: "none", theterms: "none" , theform: "block" });
  }

  changeTerms(event) {
    this.setState({ theterms: "block" , accountterms: "none", theform: "none" });
  }

  closeTerms(event) {
    this.setState({ theterms: "none" , accountterms: "none", theform: "block" });
  }
  
  render() {
    const isActive = this.state.isActive;
    const { fileName } = this.state;
    let file = null;
    file = fileName && (<label className="iconFile_Name"><span className="icon-paperclip"></span> {fileName}</label>);
    //console.log(this.props.gcontent)
    return (
      <>
        <Container fluid className="backViolet registAta">
          {this.state.loading == true && this.state.registoCRUD == false && 
              <Loader />
          }
          {this.state.registoCRUD == true &&
              <div className="preload-stuff">
                  <div className="position-center">
                      <div className="message-box responseOutput">
                        <div className="responseOutput_gif">
                          <Lottie options={{ animationData: (this.state.classkeymessageout == "success-out")? respok : resperror , loop: false }} />
                        </div>
                        <span className={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                      </div>
                  </div>
              </div>
          }
          <Container className="p-0" style={{ display: this.state.theform }}>
            <Form className="registForm">
              <Row>
                <Col md={6} className="generalInfo centercontent">
                  <h3 className="registTitle">{this.props.gcontent.data.traducoes['generalInfo']['palavra_traducao']}</h3>
                  <Row>
                    <Col xs="12" md="6">
                      <Form.Group>
                        <Form.Control type="hidden" value="0" name="idregisto" />
                        <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["theusername"]} name="theusername" placeholder={this.props.gcontent.data.traducoes['username']['palavra_traducao']} />
                        {this.state.formvalidation["theusername"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                            <span className='error'>{this.state.formvalidation["theusername"][1]}</span>
                        }
                      </Form.Group>
                    </Col>
                    <Col xs="12" md="6">
                      <Form.Group>
                        <Form.Control type="password" onChange={this.handleChange} value={this.state.formvalues["thepassword"]} name="thepassword" placeholder="Password" />
                        {this.state.formvalidation["thepassword"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                            <span className='error'>{this.state.formvalidation["thepassword"][1]}</span>
                        }
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      <Form.Group>
                        <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["first_name"]} name="first_name" placeholder={this.props.gcontent.data.traducoes['fname']['palavra_traducao']} />
                        {this.state.formvalidation["first_name"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                            <span className='error'>{this.state.formvalidation["first_name"][1]}</span>
                        }
                      </Form.Group>
                    </Col>
                    <Col xs="12" md="6">
                      <Form.Group>
                        <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["last_name"]} name="last_name" placeholder={this.props.gcontent.data.traducoes['lname']['palavra_traducao']} />
                        {this.state.formvalidation["last_name"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                            <span className='error'>{this.state.formvalidation["last_name"][1]}</span>
                        }
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row >
                    <Col xs="12" md="6">
                      <Form.Group>
                        <select key="profile" name="selectperfis" value={this.state.selectperfis} onChange={this.onperfilchange} >
                          <option value="" key="profileEmp" hidden>{this.props.gcontent.data.traducoes['selectProfile']['palavra_traducao']}</option>
                          {this.getperfis()}
                        </select>
                        {this.state.formvalidation["selectperfis"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                            <span className='error'>{this.state.formvalidation["selectperfis"][1]}</span>
                        }
                      </Form.Group>
                    </Col>
                    <Col xs="12" md="6">
                      <Form.Group className={!this.state.subPerfis.length && 'disableFField'}>
                        <select name="selectsubperfis" defaultValue="" onChange={this.onsubperfilchange} >
                          <option hidden>{this.props.gcontent.data.traducoes['selectSubprofile']['palavra_traducao']}</option>
                          {this.state.subPerfis}
                        </select>
                        {this.state.formvalidation["selectsubperfis"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                            <span className='error'>{this.state.formvalidation["selectsubperfis"][1]}</span>
                        }
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      <Form.Group>
                        <Form.Control onChange={this.handleChange} type="text" value={this.state.formvalues["company_name"]} name="company_name" placeholder={this.props.gcontent.data.traducoes['companyName']['palavra_traducao']} />
                        {this.state.formvalidation["company_name"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                            <span className='error'>{this.state.formvalidation["company_name"][1]}</span>
                        }
                      </Form.Group>
                    </Col>
                    <Col xs="12" md="6">
                      <Form.Group className={!this.state.jobfield && 'disableFField'}>
                        <Form.Control onChange={this.handleChange} type="text" value={this.state.formvalues["job_company"]} name="job_company" placeholder={this.props.gcontent.data.traducoes['job']['palavra_traducao']} />
                        {this.state.formvalidation["job_company"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                          <span className='error'>{this.state.formvalidation["job_company"][1]}</span>
                        }
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="contactDetails centercontent">
                  <h3 className="registTitle">{this.props.gcontent.data.traducoes['contactDetails']['palavra_traducao']}</h3>
                  <Row>
                    <Col>
                      <Form.Group id="formcheck-iconfile" className="fileField">
                      <Form.File.Input id="certificado_ficheiro" onChange={ (e) => this.handleFile(e) } name="certificado_ficheiro" isValid />
                          <div className="iconFile_Placeholder" htmlFor="iconFile">{!file && this.props.gcontent.data.traducoes['attachCertificate']['palavra_traducao']}</div>
                          <div className="iconFile_button">{file}<span className="icon-plus"></span></div>
                          {this.state.formvalidation["certificado_ficheiro"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                            <span className='error'>{this.state.formvalidation["certificado_ficheiro"][1]}</span> 
                          }
                          <div className="importantSpecs">
                            <span><i className="far fa-question-circle"></i></span>
                            <div className="importantSpecs_content">{this.props.gcontent.data.traducoes['msg_certificate']['palavra_traducao']}</div>
                          </div>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      <Form.Group>
                        <Form.Control onChange={this.handleChange} type="text" value={this.state.formvalues["nif"]} name="nif" placeholder="NIPC" maxLength="9" />
                        { this.state.formvalidation["nif"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                            <span className='error'> {this.state.formvalidation["nif"][1]} </span> }
                      </Form.Group>
                    </Col>
                    <Col xs="12" md="6">
                      <Form.Group>
                        <Form.Control onChange={this.handleChange} type="text" value={this.state.formvalues["cae"]} name="cae" placeholder="CAE" />
                        {this.state.formvalidation["cae"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                          <span className='error'>{this.state.formvalidation["cae"][1]}</span>
                        }
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" md="6">
                      <Form.Group className="countrySelector"> 
                        <CountrySelector testing={this.state.selectcountry} getc={this.getcountrie} changCk={this.changecountrieCK} place={this.props.gcontent.data.traducoes['country']['palavra_traducao']} {...this.props} />
                        {this.state.formvalidation["country"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                          <span className='error'>{this.state.formvalidation["country"][1]}</span>
                        }
                      </Form.Group>
                    </Col>
                    <Col xs="12" md="6">
                      <Form.Group>
                        <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["phone"]} name="phone" placeholder={this.props.gcontent.data.traducoes['phoneNum']['palavra_traducao']} maxLength="24"/>
                        {this.state.formvalidation["phone"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                            <span className='error'>{this.state.formvalidation["phone"][1]}</span>
                        }
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <Form.Group>
                        <Form.Control type="email" onChange={this.handleChange}  value={this.state.formvalues["email"]} name="email" placeholder={this.props.gcontent.data.traducoes['yourEmail']['palavra_traducao']} />
                        {this.state.formvalidation["email"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                            <span className='error'>{this.state.formvalidation["email"][1]}</span>
                        }
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="formSubmit">
                      <div className="termsCondition">

                        <div className={isActive ? "termsRadio active" : "termsRadio"} onClick={this.handleToggle}></div>
                        {store.getState()["getlang"]["idlingua"] == 'pt' ? (
                          <p>Aceito os <NavLink to='#' onClick={(e) => this.changeTerms()} >Termos e Condições</NavLink> do seu site</p>
                          ):(
                          <p>I do accept the <NavLink to='#' onClick={(e) => this.changeTerms()} >Terms and Conditions</NavLink> of your site</p>
                        )}

                      </div>
                      <button name="signinsubmit" onClick={(e) => this.handleSubmit(e)} type="submit" disabled={this.state.isActive ? null : true}>{this.props.gcontent.data.traducoes['submit']['palavra_traducao']}</button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </Container>

          <Container className="content contentPage" style={{ display: this.state.accountterms }}>
            <Row className="legaltermsconta">
                <Col>
                    <div className="titlePage titleForm"><b>{this.props.gcontent["data"]["traducoes"]["privacidade_conta"]["palavra_traducao"]}</b></div>
                    <div className="licenseForm">
                    {
                      this.props.gcontent.data.politicasetermos != null && 
                        Object.keys(this.props.gcontent["data"]["politicasetermos"]).map((item, i) => {
                            if (store.getState()["getlang"]["idlingua"] == this.props.gcontent["data"]["politicasetermos"][item]['ididiomas']){
                                return(
                                <p key={this.props.gcontent["data"]["politicasetermos"][item]['idpolitica_termos']}>{parse(this.props.gcontent["data"]["politicasetermos"][item]["registo"])}</p>
                                ) 
                            }
                        })
                    }
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                     
                    <div className="btsFormsConta">

                      <div class="btformconta" onClick={ () => { this.changeForm(); } } >{ parse(this.props.gcontent["data"]["traducoes"]["aceitar"]["palavra_traducao"]) }</div>
                      <div class="btformconta" onClick={ () => { this.props.closefunc(); } } >{ parse(this.props.gcontent["data"]["traducoes"]["naoaceito"]["palavra_traducao"]) }</div>
                    
                      { /* */ }
                    </div>
                </Col>
            </Row>
          </Container>

          <Container className="content contentPage" style={{ display: this.state.theterms }}>
            <Row className="legaltermsconta">
                <Col>
                    <div className="titlePage titleForm"><b>{this.props.gcontent["data"]["traducoes"]["termos"]["palavra_traducao"]}</b></div>
                    <div className="licenseForm">
                    {
                      this.props.gcontent.data.politicasetermos != null && 
                        Object.keys(this.props.gcontent["data"]["politicasetermos"]).map((item, i) => {
                            if (store.getState()["getlang"]["idlingua"] == this.props.gcontent["data"]["politicasetermos"][item]['ididiomas']){
                                return(
                                <p key={this.props.gcontent["data"]["politicasetermos"][item]['idpolitica_termos']}>{parse(this.props.gcontent["data"]["politicasetermos"][item]["termos"])}</p>
                                ) 
                            }
                        })
                    }
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                     
                    <div className="btsFormsConta">
                      <div class="btformconta" onClick={ () => { this.closeTerms(); } } >{ parse(this.props.gcontent["data"]["traducoes"]["close"]["palavra_traducao"]) }</div>
                    </div>
                </Col>
            </Row>
          </Container>

        </Container>
        <Footer oestado={this.props.oestado} thefooter="hidden" exact={true} />
      </>
    );
  };

}

export default Registo;