import React, { Component } from "react";
import { Row, Container, Col, Button, Form, Nav, Tab } from "react-bootstrap";
import Usermenu from "../Usermenu";
import Header from "./Header";
import { security } from '../../helpers/Security';
import { Redirect } from 'react-router-dom';
import { validate, NoEmpty } from "../../helpers/Validationfuncs";
import Breadcrumbs from "../Breadcrumbs.js";
import * as loadedcontent from "../loadcontent";
import { store } from '../../redux/store';
import parse from "html-react-parser";
//--------------Data table-----------------
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
//-----------------------------------------
import Lottie from 'lottie-react-web';
import respok from '../../assets/response_ok.json';
import resperror from '../../assets/response_error.json';
import Loader from "../../helpers/Loader";

class Categorias extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.cleanContent = this.cleanContent.bind(this);
    this.handlecategorias = this.handlecategorias.bind(this);
    this.handleobject = this.handleobject.bind(this);
    this.state = {
        redirect: security(),
        delmess: false,
        keymessageout: "",
        classkeymessageout: "",
        registoCRUD: false,
        submitlabel: this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao'],
        clearlabel: this.props.gcontent.data.traducoes['bt_limpar']['palavra_traducao'],  
        action: 1,
        keyTab: "list",
        idcat: 0,
        idsubcat: 0,
        idindex: 0,
        categoriasall: "",
        loading: false,
        countelements: 0,
        inputList: [],
        formvalidation: {
          formsubmited: false,
          catenglish: [false, "", ["noempty"]],
          catportugues: [false, "", ["noempty"]],
        },
        formvalues: {
          catenglish: "",
          catportugues: "",
        },
        ascolunas: [
            {minWidth: "150px", wrap: true, name: this.props.gcontent.data.traducoes['reference']['palavra_traducao'], selector: row => row["referencia"], sortable: false, },
            {minWidth: "150px", wrap: true, name: this.props.gcontent.data.traducoes['portugues']['palavra_traducao'], selector: row => row["portugues"], sortable: true, },
            {minWidth: "150px", wrap: true, name: this.props.gcontent.data.traducoes['ingles']['palavra_traducao'], selector: row => row["ingles"], sortable: true, },
            {minWidth: "150px", wrap: true, name: " ", selector: row => row["crud"], sortable: false, cell: d => <div>
                                                                          <span className="table-actionsItem" title={this.props.gcontent.data.traducoes['editar']['palavra_traducao']} onClick={() => { this.handleEdit(d.crud, "editar"); this.changeTab("form"); }} >
                                                                              {" "} <i className="far fa-edit"></i>{" "}
                                                                          </span>
                                                                          <span className="table-actionsItem" title={this.props.gcontent.data.traducoes['eliminar']['palavra_traducao']} onClick={() => { this.handleDelete( null, d.crud, null, null); }} >
                                                                              {" "}<i className="far fa-trash-alt"></i>{" "}
                                                                          </span>
                                                                      </div> }
        ],
        osdados: []
    };
  }

  cleanContent() {
    this.setState({
      delmess: false,
      keymessageout: "",
      classkeymessageout: "",
      registoCRUD: false,
      submitlabel: this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao'],
      clearlabel: this.props.gcontent.data.traducoes['bt_limpar']['palavra_traducao'],
      action: 1,
      idcat: 0,
      idsubcat: 0,
      idindex: 0,
      inputList: [],
      formvalidation: {
        formsubmited: false,
        catenglish: [false, "", ["noempty"]],
        catportugues: [false, "", ["noempty"]],
      },
      formvalues: {
        catenglish: "",
        catportugues: "",
      },
    });
  }

  handleCategoryChange(event) {
    let fvalues = this.state.formvalues;
    fvalues[event.target.name] = event.target.value;
    this.setState({ formvalues: fvalues });
    //console.log(this.state.formvalues);
  }

  handlecategorias = () => {
    let _tmpredes = []; let cCount = 0;
    let categoriareferencia = "";  let nomecategoriaEN = ""; let nomecategoriaPT = ""; 
    //------------------------- Conteudos Traduções -------------------------
    //console.log(this.props.oestado.var_globalcontent["data"]["categorias"]);
    for (let levelOneKey in this.props.oestado.var_globalcontent["data"]["categorias"]) {
        //this.props.oestado.var_globalcontent["data"]["categorias"][beforelevelone]["idcategorias"]  
        categoriareferencia = this.props.oestado.var_globalcontent["data"]["categorias"][levelOneKey]["categoria_referencia"];
        if(cCount == 0){ nomecategoriaEN = this.props.oestado.var_globalcontent["data"]["categorias"][levelOneKey]["nome_categoria"];}
        else{ nomecategoriaPT = this.props.oestado.var_globalcontent["data"]["categorias"][levelOneKey]["nome_categoria"];}
        cCount++;
        if( cCount == 2 ){
          _tmpredes.push(
              {
                  id: levelOneKey,
                  referencia: categoriareferencia,
                  ingles: nomecategoriaEN,
                  portugues: nomecategoriaPT,
                  crud: this.props.oestado.var_globalcontent["data"]["categorias"][levelOneKey]["idcategorias"]
              },
          );
          cCount = 0;
        }  
    }     
    //--------------------------------------------------------
    if (this._isMounted) {
      this.setState({ osdados: _tmpredes });
    }
  };

  async handleSubmit(event){
    event.preventDefault();
    let tdata = this.state.formvalues;
    var tdataform = this.state.formvalidation; //VALIDATION
    let tmpvalidate = validate(tdataform, tdata, '',
      [
        this.props.gcontent.data.traducoes['field_required']['palavra_traducao'],
        this.props.gcontent.data.traducoes['min_char']['palavra_traducao'],
        this.props.gcontent.data.traducoes['spaces_nallowed']['palavra_traducao'],
        this.props.gcontent.data.traducoes['min8_char']['palavra_traducao'],
        this.props.gcontent.data.traducoes['psw_rule']['palavra_traducao'],
        this.props.gcontent.data.traducoes['only_number']['palavra_traducao'],
        this.props.gcontent.data.traducoes['invalid_email']['palavra_traducao'],
      ]  
    ); //return [validform, tdataform, tdata ];
    this.setState({ formvalidation: tmpvalidate[1] });
    //----------Valid Sub---------------
    let tmpvalidatesub = true;
    for(let g = 0; g < this.state.inputList.length; g++){
        let _tdata = this.handleobject(this.state.inputList[g]);
        if (_tdata[0].trim().length <= 1 || _tdata[1].trim().length <= 1) {
            tmpvalidatesub = false; break;
        }
    }
    //-----------------------------------
    if (tmpvalidate[0] == true && tmpvalidatesub == true) {
      //------------------------------------------
      this.setState({ loading: !this.state.loading });
      setTimeout(
        async function () {
          var tdata = { idcategoria: this.state.idcat,
              categoria_ref: this.state.formvalues["catenglish"], categoria_en: this.state.formvalues["catenglish"],
              categoria_pt: this.state.formvalues["catportugues"], sub_categoria: this.state.inputList,
          };
          let crud = await loadedcontent.global_postaction( "actionCategorias", tdata, this.state.action );
          if (crud["success"] != "") {
            this.setState({ classkeymessageout: "success-out", keymessageout: crud["success"], });
            this.setState({ registoCRUD: true });
          } else {
            this.setState({ classkeymessageout: "error-out", keymessageout: crud["error"], });
            this.setState({ registoCRUD: true });
          }
          this.props.linguafunc(store.getState()["getlang"]["idlingua"]);
          this.handlecategorias();
          //----------------------------------------
          this.setState({ loading: !this.state.loading });
        }.bind(this),
        500
      );
    }
  }

  handleEdit(idcateg, type) {

    //console.log(" GOING UNDER ");
    //console.log(idcateg);
    //console.log(type);

    let theid = idcateg;
    let idcheck = 0;
    if (type == "editar") {
      let tdata = this.state.formvalues;
      //----------------Conteudos zonas-------------------------
      for (let levelOneKey in this.props.oestado.var_globalcontent["data"]["categorias"]) {
        if ( theid == this.props.oestado.var_globalcontent["data"]["categorias"][levelOneKey]["idcategorias"]) {
          if (idcheck == 0) { 
              this.setState({ submitlabel: this.props.oestado.var_globalcontent["data"]["traducoes"]['save_changes']['palavra_traducao'], action: 3, idcat: theid});
          }
          if ( this.props.oestado.var_globalcontent["data"]["categorias"][levelOneKey]["ididiomas"] == "en") {
              tdata["catenglish"] = parse( 
                this.props.oestado.var_globalcontent["data"]["categorias"][levelOneKey]["nome_categoria"]
              );
          } else if ( this.props.oestado.var_globalcontent["data"]["categorias"][levelOneKey]["ididiomas"] == "pt" ) {
              tdata["catportugues"] = parse( 
                this.props.oestado.var_globalcontent["data"]["categorias"][levelOneKey]["nome_categoria"]
              );
          }
          idcheck++;
        }
      }
      this.setState({ formvalues: tdata });
      let _tmpinput = [];
      let _tmpPT = "";
      let _tmpEN = "";
      let _tcont = 0;
      for (let levelOneKey in this.props.oestado.var_globalcontent["data"]["subcategorias"]) {
        if ( theid == this.props.oestado.var_globalcontent["data"]["subcategorias"][levelOneKey]["idcategorias"] ) {
          if ( this.props.oestado.var_globalcontent["data"]["subcategorias"][levelOneKey]["ididiomas"] == "en" ) {
            _tmpEN = parse(
              this.props.oestado.var_globalcontent["data"]["subcategorias"][levelOneKey]["nome_subcategoria"]
            );
          } else if ( this.props.oestado.var_globalcontent["data"]["subcategorias"][levelOneKey]["ididiomas"] == "pt") {
            _tmpPT = parse(
              this.props.oestado.var_globalcontent["data"]["subcategorias"][levelOneKey]["nome_subcategoria"]
            );
          }
          if (_tmpEN != "" && _tmpPT != "") {
              _tmpinput.push({
                ["subcategoriaEN" + _tcont]: _tmpEN,
                ["subcategoriaPT" + _tcont]: _tmpPT,
                idelement: this.props.oestado.var_globalcontent["data"]["subcategorias"][levelOneKey]["idsubcategorias"],
              });
              _tmpEN = "";
              _tmpPT = "";
              _tcont++;
          }
        }
      }
      this.setState({ inputList: _tmpinput });
      //--------------------------------------------------------
    }
  }

  handleDelete(index, idcat, idsubcat, type ) {
      const list = [...this.state.inputList];
      if( idcat > 0 ){
          this.setState({ idcat, idsubcat:0, action: 2, delmess: true });
      }
      if( idsubcat > 0 ){
          this.setState({ idcat: 0, idsubcat, action: 2, delmess: true });
      }
      if( index != null ){
          if( list[index]["idelement"] > 0 ){
              this.setState({ idindex: index, idcat: 0, idsubcat: list[index]["idelement"], action: 2, delmess: true });
          }else{
              this.handleRemoveClick(index);
          }
      }
      if( type == 0 ){
          this.setState({ action: 2, delmess: false });
      }else if( type == 1 ){
          this.setState({  action: 2, delmess: false });
          this.setState({ loading: !this.state.loading });
          setTimeout(
              async function () {
                var data = { idcat: this.state.idcat, idsubcat: this.state.idsubcat };
                if( this.state.idcat > 0 || this.state.idsubcat > 0 ){
                  let crud = await loadedcontent.global_postaction( "actionCategorias", data, this.state.action );
                  if( crud["success"] != "" ){ 
                    this.setState({ classkeymessageout: "success-out" , keymessageout: crud["success"] });
                    this.setState({ registoCRUD: true });
                  }else{
                    this.setState({ classkeymessageout: "error-out" , keymessageout: crud["error"] });
                    this.setState({ registoCRUD: true });
                  }
                  if( this.state.index ){
                    this.handleRemoveClick(this.state.idindex);
                  }
                  this.props.linguafunc(store.getState()["getlang"]["idlingua"]);
                  this.handlecategorias();
                }
                this.setState({ loading: !this.state.loading });
              }.bind(this),
              500
          );
      }
  }

  handleRemoveClick = (index) => {
    const list = [...this.state.inputList];
    list.splice(index, 1);
    const _tmplist = [];
    list.map((x, i) => {
      let _tdata = this.handleobject(x);
      const adElement = {
        ["subcategoriaEN" + i]: _tdata[0],
        ["subcategoriaPT" + i]: _tdata[1],
        idelement: _tdata[2],
      };
      _tmplist.push(adElement);
    });
    this.setInputList(_tmplist, "delete");
  };

  componentDidMount() {
    this._isMounted = true;
    this.handlecategorias();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  //----------------------------ADD ELEMENTS DYNAMICALLY------------------------------
  setInputList = (thelist, type) => {
    if (type == "update" || type == "delete") {
      this.setState({ inputList: thelist });
    } else {
      //let _tmp = "subcategoriaEN"+this.state.inputList.length;
      const adElement = [
        ...this.state.inputList,
        {
          ["subcategoriaEN" + this.state.inputList.length]: "",
          ["subcategoriaPT" + this.state.inputList.length]: "",
          idelement: 0,
        },
      ];
      //console.log(adElement);
      this.setState({ inputList: adElement });
    }
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.inputList];
    list[index][name] = value;
    this.setInputList(list, "update");
  };

  handleobject(obj) {
    let _tPT = "";
    let _tEN = "";
    let _tid = 0;
    Object.entries(obj).map(([key, value]) => {
      if (key.indexOf("subcategoriaEN") >= 0) {
        _tEN = value;
      } else if (key.indexOf("subcategoriaPT") >= 0) {
        _tPT = value;
      } else {
        _tid = value;
      }
    });
    return [_tEN, _tPT, _tid];
  }

  handleAddClick = (event) => {
    event.preventDefault();
    const list = [...this.state.inputList];
    this.setInputList(list, "add");
  };
  changeTab = (keyTab) => {
    if(keyTab == 'list'){
      this.cleanContent()
    }
    this.setState({ keyTab });
    
  };
  //----------------------------ADD ELEMENTS DYNAMICALLY------------------------------
  //<div style={{ marginTop: 20 }}>{JSON.stringify(this.state.inputList)}</div>
  render() {     
    if( this.state.redirect == true ){
      return <Redirect to={"/"} />
    }else if( store.getState()["getatauser"]["userdata"]["admin"] != 1){ 
      return <Redirect to="/" /> 
    }
    const tableData = { columns: this.state.ascolunas, data: this.state.osdados, };
    return (
      <>
        {this.state.loading == true && <Loader />}
        { this.state.registoCRUD == true &&
            <div className="preload-stuff">
                <div className="position-center">
                    <div className="message-box responseOutput">
                        <div className="responseOutput_gif">
                            <Lottie options={{ animationData: (this.state.classkeymessageout == "success-out")? respok : resperror , loop: false }} />
                        </div>
                        <span className={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                    </div>
                </div>
            </div>
        }
        {this.state.delmess == true &&
            <div className="preload-stuff">
                <div className="position-center">
                    <div className="message-box message-boxPopup responseOutput-box">
                        <span className="messagedel">{this.props.oestado.var_globalcontent["data"]["traducoes"]["eliminardados"]['palavra_traducao']} </span>
                        <div className="message-boxActions">
                            <div onClick={() => this.handleDelete( null, 0 , 0, 1 )} >
                                <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["yes"]['palavra_traducao']} </span>
                            </div>
                            <div onClick={() => this.handleDelete( null, 0 , 0, 0 )} >
                                <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["no"]['palavra_traducao']} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} gcontent={this.props.gcontent} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} theheader="default"/>
        <Container className="content contentPage">
          <Breadcrumbs {...this.props} gcontent={this.props.gcontent} />
          <div className="titlePage">
            {this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b>
          </div>
          <Row className="content-sub contentOffice">
            <Col className="menuDrop">
              <Usermenu gcontent={this.props.gcontent} />
            </Col>
            <Col className="contentDRUP">
              <Tab.Container activeKey={this.state.keyTab} onSelect={this.changeTab}>
                <Nav variant="pills" className="pageClientHead">
                  <h3 className="contentTitle contentTitle_sub">{this.props.gcontent.data.traducoes['cats-subcats']['palavra_traducao']}</h3>
                  <span className="separador"></span>
                  <Nav.Item>
                    <Nav.Link eventKey={"list"}>
                      {this.props.gcontent.data.traducoes["listing"]["palavra_traducao"]}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={"form"}>
                      {this.props.gcontent.data.traducoes["additems"]["palavra_traducao"]}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey={"form"}>
                    <Row className="m-0">
                      <div className="adminForms">
                        <Form>
                          <Row>
                            <Col xs="12" md="6">
                              <Form.Group controlId="formBasicportugues">
                                <Form.Control type="text" onChange={this.handleCategoryChange} value={this.state.formvalues["catportugues"]} name="catportugues" placeholder={this.props.gcontent.data.traducoes['category']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['portugues']['palavra_traducao']}/>
                                {this.state.formvalidation["catportugues"][0] == false && this.state.formvalidation["formsubmited"] == true && (
                                    <span className="error">
                                      {this.state.formvalidation["catportugues"][1]}
                                    </span>
                                  )}
                              </Form.Group>
                            </Col>
                            <Col xs="12" md="6">
                              <Form.Group controlId="formBasicenglish">
                                <Form.Control type="text" onChange={this.handleCategoryChange} value={this.state.formvalues["catenglish"]} name="catenglish" placeholder={this.props.gcontent.data.traducoes['category']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['ingles']['palavra_traducao']}/>
                                {this.state.formvalidation["catenglish"][0] == false && this.state.formvalidation["formsubmited"] == true && (
                                    <span className="error">
                                      {this.state.formvalidation["catenglish"][1]}
                                    </span>
                                  )}
                              </Form.Group>
                            </Col>
                          </Row>
                          <h3 className="contentTitle contentTitle_sub">{this.props.gcontent.data.traducoes['subcategories']['palavra_traducao']}</h3>
                          {this.state.inputList.map((x, i) => {
                            let _tdata = this.handleobject(x);
                            return (
                              <div key={"ls"+i} className="adminSubForms_wrapper">
                                <Row className="adminSubForms mx-0">
                                  <Col>
                                    <Form.Group>
                                      <Form.Control type="text" name={"subcategoriaPT" + i} placeholder={this.props.gcontent.data.traducoes['subcategories']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['portugues']['palavra_traducao']} value={_tdata[1]} onChange={(e) => this.handleInputChange(e, i)}/>
                                      {_tdata[1].trim().length <= 1 && this.state.formvalidation["formsubmited"] == true && (
                                        <span className="error">
                                          {NoEmpty(_tdata[1], 
                                            [
                                              this.props.gcontent.data.traducoes['field_required']['palavra_traducao']
                                            ]
                                          )}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col>
                                    <Form.Group>
                                      <Form.Control type="text" name={"subcategoriaEN" + i} placeholder={this.props.gcontent.data.traducoes['subcategories']['palavra_traducao'] + ' ' + this.props.gcontent.data.traducoes['ingles']['palavra_traducao']} value={_tdata[0]} onChange={(e) => this.handleInputChange(e, i)}/>
                                      {_tdata[0].trim().length <= 1 && this.state.formvalidation["formsubmited"] == true && (
                                        <span className="error">
                                          {NoEmpty(_tdata[0],
                                            [
                                              this.props.gcontent.data.traducoes['field_required']['palavra_traducao']
                                            ]
                                          )}
                                        </span>
                                      )}
                                    </Form.Group>
                                  </Col>
                                  <Col className="adminSubForms_actions">
                                    <Form.Group>
                                      <div className="btn-box">
                                        {this.state.inputList.length != 0 && (
                                          <div onClick={() => this.handleDelete(i, 0, null, null) } >
                                            <span className="icon-close-icon"></span>
                                          </div>
                                        )}
                                      </div>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </div>
                            );
                          })}
                          <Row>
                            <Col>
                              <button className="add_Subcategory" onClick={(e) => this.handleAddClick(e)}>
                                <span className="icon-plus"></span>{this.props.gcontent.data.traducoes['add_subcategory']['palavra_traducao']}
                              </button>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Form.Group className="formSubmit">
                                <Button onClick={() => this.cleanContent()}>
                                  {this.state.clearlabel}
                                </Button>
                                <Button onClick={(e) => this.handleSubmit(e)} variant="primary" name="redesocialsubmit" type="submit">
                                  {this.state.submitlabel}
                                </Button>
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey={"list"}>
                    <div className="main">
                        <DataTableExtensions filterPlaceholder={this.props.gcontent.data.traducoes["search"]["palavra_traducao"] + '...'} filter={true} {...tableData}>
                            <DataTable
                              noHeader={true}
                              defaultSortField="id"
                              defaultSortAsc={false}
                              pagination={true}
                              highlightOnHover={true}
                              paginationComponentOptions={{rowsPerPageText: this.props.gcontent.data.traducoes['rows_page']['palavra_traducao'] + ':'}}
                            />
                        </DataTableExtensions>
                    </div>                    
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Categorias;
