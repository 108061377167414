import React, { Component } from 'react';
import { Row, Container, Col, Button, Form  } from 'react-bootstrap';
import Header from './Header';
import Usermenu from '../Usermenu';
import { store } from '../../redux/store';
import Breadcrumbs from '../Breadcrumbs.js';
import * as loadedcontent from '../loadcontent';
import { validate } from '../../helpers/Validationfuncs';
import { security } from '../../helpers/Security';
import { Redirect } from 'react-router-dom';
import parse from "html-react-parser";
//-----------------------------------------
import Lottie from 'lottie-react-web';
import respok from '../../assets/response_ok.json';
import resperror from '../../assets/response_error.json';
import Loader from "../../helpers/Loader";

class Sobre extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.changelang = this.changelang.bind(this);
        this.state = {
            redirect: security(),
            keymessageout: "",
            classkeymessageout: "",
            defaultlang: "pt",
            formvalidation: {
                "formsubmited": false, "pt_titulo_photos": [ false , "", ["noempty"] ], "pt_conteudo_photos": [ false , "", ["noempty"] ],
                "en_titulo_photos": [ false , "", ["noempty"] ], "en_conteudo_photos": [ false , "", ["noempty"] ], "pt_titulo_visitazores": [ false , "", ["noempty"] ],
                "pt_conteudo_visitazores": [ false , "", ["noempty"] ], "en_titulo_visitazores": [ false , "", ["noempty"] ], "en_conteudo_visitazores": [ false , "", ["noempty"] ]
            },
            formvalues: { 
                "pt_titulo_photos": "", "pt_conteudo_photos": "", "en_titulo_photos": "", "en_conteudo_photos": "", 
                "pt_titulo_visitazores": "", "pt_conteudo_visitazores": "", "en_titulo_visitazores": "", "en_conteudo_visitazores": ""
            },
        };
        //----------------------Load Langs----------------------------- 
        for (let levelOneKey in this.props.gcontent["data"]["sobre"]){
            if( this.props.gcontent["data"]["sobre"][levelOneKey]["sobre_referencia"] == "photos" ){
                this.state.formvalues[this.props.gcontent["data"]["sobre"][levelOneKey]["ididiomas"]+"_titulo_photos"] = this.props.gcontent["data"]["sobre"][levelOneKey]["titulo_sobre"];
                this.state.formvalues[this.props.gcontent["data"]["sobre"][levelOneKey]["ididiomas"]+"_conteudo_photos"] = this.props.gcontent["data"]["sobre"][levelOneKey]["conteudo_sobre"];
            }
            if( this.props.gcontent["data"]["sobre"][levelOneKey]["sobre_referencia"] == "visitazores" ){
                this.state.formvalues[this.props.gcontent["data"]["sobre"][levelOneKey]["ididiomas"]+"_titulo_visitazores"] = this.props.gcontent["data"]["sobre"][levelOneKey]["titulo_sobre"];
                this.state.formvalues[this.props.gcontent["data"]["sobre"][levelOneKey]["ididiomas"]+"_conteudo_visitazores"] = this.props.gcontent["data"]["sobre"][levelOneKey]["conteudo_sobre"];
            }
        }
        //------------------------------------------------------------
    }
 
    handleChange(event) {
        let fvalues = this.state.formvalues;
        fvalues[event.target.name] = event.target.value;
        this.setState({ formvalues: fvalues });
        //console.log( this.state.formvalues );
    }

    changelang(event) {
        this.setState({ defaultlang: event.target.value });
    }
    
    async handleSubmit(event){
        event.preventDefault();
        let tdata = this.state.formvalues; 
        var tdataform = this.state.formvalidation; //VALIDATION
        let tmpvalidate = validate( tdataform, tdata, '',
            [
                this.props.gcontent.data.traducoes['field_required']['palavra_traducao'],
                this.props.gcontent.data.traducoes['min_char']['palavra_traducao'],
                this.props.gcontent.data.traducoes['spaces_nallowed']['palavra_traducao'],
                this.props.gcontent.data.traducoes['min8_char']['palavra_traducao'],
                this.props.gcontent.data.traducoes['psw_rule']['palavra_traducao'],
                this.props.gcontent.data.traducoes['only_number']['palavra_traducao'],
                this.props.gcontent.data.traducoes['invalid_email']['palavra_traducao'],
            ]
        ); //return [validform, tdataform, tdata ];
        this.setState({ formvalidation: tmpvalidate[1] });
        if( tmpvalidate[0] == true ){
            //-------------------------------------------------------
            this.setState({ loading: !this.state.loading });
            setTimeout(
                async function() {
                    let crud = await loadedcontent.global_postaction( "actionSobre", this.state.formvalues , 3);
                    if( crud["success"] != "" ){
                        this.setState({ classkeymessageout: "success-out" , keymessageout: crud["success"] });
                        this.setState({ validuserpass: false });
                    }else{
                        this.setState({ classkeymessageout: "error-out" , keymessageout: crud["error"] });
                        this.setState({ validuserpass: false });
                    }
                    this.setState({ loading: !this.state.loading });
                    setTimeout(() => {
                        this.setState({ validuserpass: !this.state.validuserpass })
                    },2000);
                }
                .bind(this),
                500
            );
        }
    }

  render() { 
    if( this.state.redirect == true ){
        return <Redirect to="/" /> 
    }else if(store.getState()["getatauser"]["userdata"]["admin"] != 1){ 
        return <Redirect to="/" /> 
    }
    return (
        <>
            {this.state.loading == true && <Loader /> }
            {this.state.validuserpass == false && 
                <div class="preload-stuff">
                    <div class="position-center">
                        <div class="message-box responseOutput">
                            <div className="responseOutput_gif">
                                <Lottie options={{ animationData: (this.state.classkeymessageout == "success-out")? respok : resperror , loop: false }} />
                            </div>
                            <span class={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                        </div>
                    </div>
                </div>
                //<span class={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
            }
            <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} gcontent={this.props.gcontent} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} theheader="default" />
            <Container className="content contentPage">
                <Breadcrumbs {...this.props} gcontent={this.props.gcontent}/>
                <div className="titlePage">{this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b></div>
                <Row className="contentOffice">
                    <Col className="menuDrop">
                        <Usermenu gcontent={this.props.gcontent}/>
                    </Col>
                    <Col>
                        <h3 className="contentTitle">{this.props.gcontent.data.traducoes['about']['palavra_traducao']}</h3>
                        <Form className="adminForms">
                            <Form.Group className="adminLangSwitcher" controlId="exampleForm.ControlSelect">
                                <Form.Label>{this.props.gcontent.data.traducoes['translations']['palavra_traducao']}</Form.Label>
                                <Form.Control name="formlang" as="select" onChange={this.changelang} >
                                    <option value="pt">{this.props.gcontent.data.traducoes['portugues']['palavra_traducao']}</option>
                                    <option value="en">{this.props.gcontent.data.traducoes['ingles']['palavra_traducao']}</option>
                                </Form.Control>
                            </Form.Group>
                            <div><p style={{fontSize: 13, color: "#828990", fontWeight: 700, lineHeight: 1.3, marginBottom: 20, marginTop: -20}}>{this.props.gcontent.data.traducoes['saveBefore']['palavra_traducao']}</p></div>
                            {this.state.defaultlang == "pt" && 
                                <>
                                    <h3 className="contentTitle contentTitle_sub">Fotos & Videos</h3>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>{this.props.gcontent.data.traducoes['title']['palavra_traducao']}</Form.Label>
                                        <Form.Control type="text" name="pt_titulo_photos" value={parse(this.state.formvalues[this.state.defaultlang + "_titulo_photos"])} onChange={this.handleChange}/>
                                        {this.state.formvalidation["pt_titulo_photos"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                            <span className='error'>{this.state.formvalidation["pt_titulo_photos"][1]}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="adminTextarea" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>{this.props.gcontent.data.traducoes['description']['palavra_traducao']}</Form.Label>
                                        <Form.Control as="textarea" 
                                            rows={10} 
                                            name="pt_conteudo_photos"
                                            defaultValue={parse(this.state.formvalues[this.state.defaultlang + "_conteudo_photos"])} 
                                            onChange={this.handleChange} />

                                        {this.state.formvalidation["pt_conteudo_photos"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                            <span className='error'>{this.state.formvalidation["pt_conteudo_photos"][1]}</span>
                                        }
                                    </Form.Group>

                                    <h3 className="contentTitle contentTitle_sub">VisitAzores</h3>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>{this.props.gcontent.data.traducoes['title']['palavra_traducao']}</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        name="pt_titulo_visitazores" 
                                        defaultValue={parse(this.state.formvalues[this.state.defaultlang + "_titulo_visitazores"])} 
                                        onChange={this.handleChange} />
                                        {this.state.formvalidation["pt_titulo_visitazores"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                            <span className='error'>{this.state.formvalidation["pt_titulo_visitazores"][1]}</span>
                                        }
                                    </Form.Group>

                                    <Form.Group className="adminTextarea" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>{this.props.gcontent.data.traducoes['description']['palavra_traducao']}</Form.Label>
                                        <Form.Control 
                                        as="textarea" 
                                        rows={10} 
                                        name="pt_conteudo_visitazores" 
                                        defaultValue={parse(this.state.formvalues[this.state.defaultlang + "_conteudo_visitazores"])}
                                        onChange={this.handleChange} />
                                        {this.state.formvalidation["pt_conteudo_visitazores"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                            <span className='error'>{this.state.formvalidation["pt_conteudo_visitazores"][1]}</span>
                                        }
                                    </Form.Group>
                                </>
                            }
                            {this.state.defaultlang == "en" && 
                                <>
                                    <h3 className="contentTitle contentTitle_sub">Photos & Videos</h3>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control type="text" name="en_titulo_photos" value={this.state.formvalues[this.state.defaultlang + "_titulo_photos"]} onChange={this.handleChange}/>
                                    </Form.Group>

                                    <Form.Group className="adminTextarea" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Content</Form.Label>
                                        <Form.Control 
                                        as="textarea" 
                                        rows={10} 
                                        name="en_conteudo_photos" 
                                        defaultValue={parse(this.state.formvalues[this.state.defaultlang + "_conteudo_photos"])}
                                        onChange={this.handleChange} />
                                    </Form.Group>

                                    <h3 className="contentTitle contentTitle_sub">VisitAzores</h3>
                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control type="text" name="en_titulo_visitazores" value={parse(this.state.formvalues[this.state.defaultlang + "_titulo_visitazores"])} onChange={this.handleChange}/>
                                    </Form.Group>

                                    <Form.Group className="adminTextarea" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>Content</Form.Label>
                                        <Form.Control as="textarea" 
                                        defaultValue={parse(this.state.formvalues[this.state.defaultlang + "_conteudo_visitazores"])}
                                        rows={10} 
                                        name="en_conteudo_visitazores" 
                                        onChange={this.handleChange} />
                                    </Form.Group>
                                </>
                            }
                            <Form.Group className="formSubmit">
                                <Button variant="primary" onClick={(e) => this.handleSubmit(e)}  name="guardarsubmit" type="submit">{this.props.gcontent.data.traducoes['save_changes']['palavra_traducao']}</Button>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </>
    );
  };

}

export default Sobre;