export const getlanguage = (state = null, action) => {    
    if( action.type == "lang" ){ return action.param; }
    return state;
};
export const getdomain = (state = "", action) => {    
    if( action.type == "domain" ){ return action.param; }
    return state;
}; 
export const getsearch = (state = "", action) => {    
    if( action.type == "search" ){ return action.param; }
    return state;
}; 
export const getlang = (state = "", action) => {    
    if( action.type == "thelang" ){ return action.param; }
    return state;
}; 
export const getatauser = (state = "", action) => {    
    if( action.type == "theatauser" ){ return action.param; }
    return state;
}; 

 