import React, { Component, useState } from 'react';
import Fade from "react-reveal/Fade";
import parse from "html-react-parser";
import { Redirect } from 'react-router-dom';
import { store } from "../redux/store";

class langSwitch extends Component {
    _redir = false;
    constructor(props) {
        super(props);
        this.showMenu = this.showMenu.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {
            showMenu: false,
            searchstring: "",
        }
    }
    showMenu(event) {
        event.preventDefault();
        this.setState({ showMenu: !this.state.showMenu });
    }
    handleSubmit(idcategory) {
        this.setState({ showMenu: !this.state.showMenu });
        let fvalues = { formvalues: {  "search": "", "stilhas": "0", "stcategorias": idcategory, "stsubcategorias": "0" } };
        let params = store.getState()["getsearch"];
        if( params.searchvalues != undefined ){
          //------------------------------
          let _tstring = "search="+params.searchvalues["search"]+"&stilhas="+params.searchvalues["stilhas"]+"&stcategorias="+idcategory+"&stsubcategorias=0&";
          this.setState({ searchstring: _tstring });
          //------------------------------
          params.searchvalues["stcategorias"] = idcategory; params.searchvalues["stsubcategorias"] = 0;
          store.dispatch({ type: "search", param: { "searchvalues" : params.searchvalues , "gosearch" : true } }); 
        }else{
          //------------------------------
          this.setState({ searchstring: "search=&stilhas=0&stcategorias="+idcategory+"&stsubcategorias=0&" });
          //-------------------------------
          store.dispatch({ type: "search", param: { "searchvalues" : fvalues , "gosearch" : true } }); 
        }
        this._redir = true;
    } 

    render() {
        if( this._redir == true ){
            this._redir = false;
            return <Redirect to={"/pesquisa/" + this.state.searchstring} />
        }else{
            return (
                <div className="customSelect galleriesSelect">
                  <button className={this.state.showMenu  ? "customSelect-label galleriesSelect_button active"  : "customSelect-label galleriesSelect_button"} onClick={this.showMenu}>
                    {this.props.gcontent["data"]["traducoes"]["galleries"]["palavra_traducao"]}{" "}
                    <span className="icon-arrow-down"></span>
                  </button>
                  <Fade className="customSelect-menuInner galleriesSelect_menuInner" collapse when={this.state.showMenu} duration={300}>
                    <ul className="customSelect-menu galleriesSelect_menu">
                      {this.props.gcontent.data["categorias"] != null &&
                        Object.keys(this.props.gcontent.data["categorias"]).map((item, i) => {
                            //console.log(this.props.oestado.var_profiles["data"]);
                            if (store.getState()["getlang"]["idlingua"] == this.props.gcontent.data["categorias"][item]["ididiomas"]) {
                              return (
                                <li key={this.props.gcontent.data["categorias"][item]["categoria_referencia"]} data-index={i}>
                                  <div onClick={() => { this.handleSubmit(this.props.gcontent["data"]["categorias"][item]["idcategorias"]) }}>
                                    {parse(this.props.gcontent.data["categorias"][item]["nome_categoria"])}
                                  </div>
                                </li>
                              );
                            }
                          }
                        )}
                    </ul>
                  </Fade>
                </div>
            );
        }
    }
}
export default langSwitch;
