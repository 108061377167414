import React, { Component } from "react";
import { Row, Container, Col, Button, Form, Tab, Nav } from "react-bootstrap";
import Usermenu from "../Usermenu";
import Header from "./Header";
import * as loadedcontent from "../loadcontent";
import Breadcrumbs from "../Breadcrumbs.js";
import { store } from '../../redux/store';
import { validate } from "../../helpers/Validationfuncs";
import { security } from '../../helpers/Security';
import { Redirect } from 'react-router-dom';
//--------------Data table-----------------
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
//-----------------------------------------
import Lottie from 'lottie-react-web';
import animationLoader from '../../assets/loading_stats.json';
import respok from '../../assets/response_ok.json';
import resperror from '../../assets/response_error.json';
import Loader from "../../helpers/Loader";

class Traducoes extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.cleanContent = this.cleanContent.bind(this);
    this.handletraducoes = this.handletraducoes.bind(this);
    this.state = {
      redirect: security(),
      delmess: false,
      keymessageout: "",
      classkeymessageout: "",
      registoCRUD: false,
      submitlabel: this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao'],
      clearlabel: this.props.gcontent.data.traducoes['bt_limpar']['palavra_traducao'],
      tradchave: "",
      tradenglish: "",
      tradportugues: "",
      action: 1,
      keyTab: "list",
      idtrad: 0,
      traducoesall: "",
      countelements: 0,
      formvalidation: {
        formsubmited: false,
        tradchave: [false, "", ["noempty"]],
        tradenglish: [false, "", ["noempty"]],
        tradportugues: [false, "", ["noempty"]],
      },
      formvalues: {
        tradchave: "",
        tradenglish: "",
        tradportugues: "",
      },
      ascolunas: [
            {minWidth: "150px", wrap: true, name: this.props.oestado.var_globalcontent["data"]["traducoes"]["reference"]['palavra_traducao'], selector: row => row["chave"], sortable: true, },
            {minWidth: "150px", wrap: true, name: this.props.oestado.var_globalcontent["data"]["traducoes"]["portugues"]['palavra_traducao'], selector: row => row["portugues"], sortable: true, },
            {minWidth: "150px", wrap: true, name: this.props.oestado.var_globalcontent["data"]["traducoes"]["ingles"]['palavra_traducao'], selector: row => row["ingles"], sortable: true, },
            {minWidth: "150px", wrap: true, name: " ", selector: row => row["crud"], sortable: false, cell: d => <div>
                                                                          <span className="table-actionsItem" title={this.props.gcontent.data.traducoes['editar']['palavra_traducao']} onClick={() => { this.handleEdit(d.crud, "editar"); this.changeTab("form"); }} >
                                                                              {" "} <i className="far fa-edit"></i>{" "}
                                                                          </span>
                                                                          <span className="table-actionsItem" title={this.props.gcontent.data.traducoes['eliminar']['palavra_traducao']} onClick={() => { this.handleDelete(d.crud, null); }} >
                                                                              {" "}<i className="far fa-trash-alt"></i>{" "}
                                                                          </span>
                                                                      </div> }
        ],
        osdados: []
    };
  }

  cleanContent() {
    this.setState({
      delmess: false,
      keymessageout: "",
      classkeymessageout: "",
      registoCRUD: false,
      submitlabel: this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao'],
      clearlabel: this.props.gcontent.data.traducoes['bt_limpar']['palavra_traducao'],
      tradchave: "",
      tradenglish: "",
      tradportugues: "",
      action: 1,
      idtrad: 0,
      formvalidation: {
        formsubmited: false,
        tradchave: [false, "", ["noempty"]],
        tradenglish: [false, "", ["noempty"]],
        tradportugues: [false, "", ["noempty"]],
      },
      formvalues: {
        tradchave: "",
        tradenglish: "",
        tradportugues: "",
      },
    });
  }

  handleChange(event) {
    let fvalues = this.state.formvalues;
    fvalues[event.target.name] = event.target.value;
    this.setState({ formvalues: fvalues });
    //console.log(this.state.formvalues);
  }

  handletraducoes = () => {
    let _tmpredes = []; let cCount = 0;
    let traducaochave = "";  let traducaoEN = ""; let traducaoPT = ""; 
    //----------------Conteudos Traduções-------------------------
    for (let levelOneKey in this.props.oestado.var_globalcontent["data"]["traducoesall"]) {
        //this.props.oestado.var_globalcontent["data"]["traducoesall"][levelOneKey]["idtraducoes"]
        traducaochave = this.props.oestado.var_globalcontent["data"]["traducoesall"][levelOneKey]["chave"];  
        if(cCount == 0){ traducaoEN = this.props.oestado.var_globalcontent["data"]["traducoesall"][levelOneKey]["palavra_traducao"];}
        else{ traducaoPT = this.props.oestado.var_globalcontent["data"]["traducoesall"][levelOneKey]["palavra_traducao"];}
        cCount++;
        if( cCount == 2 ){
          _tmpredes.push(
              {
                  id: levelOneKey,
                  chave: traducaochave,
                  portugues: traducaoPT,
                  ingles: traducaoEN,
                  crud: this.props.oestado.var_globalcontent["data"]["traducoesall"][levelOneKey]["idtraducoes"]
              },
          );
          cCount = 0;
        }
    }
    //--------------------------------------------------------
    if (this._isMounted) {
        this.setState({ osdados: _tmpredes });
    }
  };

  async handleSubmit(event) {
    event.preventDefault();
    let tdata = this.state.formvalues;
    var tdataform = this.state.formvalidation; //VALIDATION
    let tmpvalidate = validate(tdataform, tdata, '',
      [
        this.props.gcontent.data.traducoes['field_required']['palavra_traducao'],
        this.props.gcontent.data.traducoes['min_char']['palavra_traducao'],
        this.props.gcontent.data.traducoes['spaces_nallowed']['palavra_traducao'],
        this.props.gcontent.data.traducoes['min8_char']['palavra_traducao'],
        this.props.gcontent.data.traducoes['psw_rule']['palavra_traducao'],
        this.props.gcontent.data.traducoes['only_number']['palavra_traducao'],
        this.props.gcontent.data.traducoes['invalid_email']['palavra_traducao'],
      ]
    ); //return [validform, tdataform, tdata ];
    this.setState({ formvalidation: tmpvalidate[1] });
    if (tmpvalidate[0] == true) {
      //------------------------------------------
      this.setState({ loading: !this.state.loading });
      setTimeout(
        async function () {
          var tdata = {
            idtraducoes: this.state.idtrad,
            str_key: this.state.formvalues["tradchave"],
            str_en: this.state.formvalues["tradenglish"],
            str_pt: this.state.formvalues["tradportugues"],
          };
          let crud = await loadedcontent.global_postaction( "actionTraducoes", tdata, this.state.action );
          if (crud["success"] != "") {
            this.setState({ classkeymessageout: "success-out", keymessageout: crud["success"], });
            this.setState({ registoCRUD: true });
          } else {
            this.setState({ classkeymessageout: "error-out", keymessageout: crud["error"], });
            this.setState({ registoCRUD: true });
          }
          this.props.linguafunc(store.getState()["getlang"]["idlingua"]);
          this.handletraducoes();
          this.setState({ loading: !this.state.loading });
        }.bind(this),
        500
      );
    }
  }

  handleEdit(idtrad, type) {
    let theid = idtrad;
    let idcheck = 0;
    if (type == "editar") {
      let fvalues = this.state.formvalues;
      //----------------Conteudos zonas-------------------------
      for (let levelOneKey in this.props.oestado.var_globalcontent["data"]["traducoesall"]) {
        if ( theid == this.props.oestado.var_globalcontent["data"]["traducoesall"][levelOneKey]["idtraducoes"] ) {
          if (idcheck == 0) {
              fvalues["tradchave"] = this.props.oestado.var_globalcontent["data"]["traducoesall"][levelOneKey]["chave"];
              this.setState({ submitlabel: this.props.gcontent.data.traducoes['bt_actualizar']['palavra_traducao'], action: 3, idtrad });
          }
          if ( this.props.oestado.var_globalcontent["data"]["traducoesall"][levelOneKey]["ididiomas"] == "pt" ) {
            fvalues["tradportugues"] = this.props.oestado.var_globalcontent["data"]["traducoesall"][levelOneKey]["palavra_traducao"];
          } else if ( this.props.oestado.var_globalcontent["data"]["traducoesall"][levelOneKey]["ididiomas"] == "en" ) {
            fvalues["tradenglish"] = this.props.oestado.var_globalcontent["data"]["traducoesall"][levelOneKey]["palavra_traducao"];
          }
          idcheck++;
        }
      }
      this.setState({ formvalues: fvalues });
      //--------------------------------------------------------
    }
  }

  handleDelete(idtrad, type) {
      if( idtrad > 0 ){
          this.setState({ idtrad, action: 2, delmess: true });
      }
      if( type == 0 ){
          this.setState({ action: 2, delmess: false });
      }else if( type == 1 ){
          this.setState({  action: 2, delmess: false });
          this.setState({ loading: !this.state.loading });
          setTimeout(
              async function () {
                var data = { idtrad: this.state.idtrad };
                let crud = await loadedcontent.global_postaction( "actionTraducoes", data, this.state.action );
                //console.log(" TITU DKDJKSJ AJASSJ ");
                //console.log(crud);
                if( crud["success"] != "" ){ 
                    this.setState({ classkeymessageout: "success-out" , keymessageout: crud["success"] });
                    this.setState({ registoCRUD: true });
                }else{
                    this.setState({ classkeymessageout: "error-out" , keymessageout: crud["error"] });
                    this.setState({ registoCRUD: true });
                } 
                this.props.linguafunc(store.getState()["getlang"]["idlingua"]);
                this.handletraducoes();
                this.setState({ loading: !this.state.loading });
              }.bind(this),
              500
          );
      }
  }

  componentDidMount() {
    this._isMounted = true;
    this.handletraducoes();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }
  changeTab = (keyTab) => {
    if(keyTab == 'list'){
      this.cleanContent()
    }
    this.setState({ keyTab });
  };

  render() {
    if( this.state.redirect == true ){
      return <Redirect to={"/"} />
  }else if(store.getState()["getatauser"]["userdata"]["admin"] != 1){ 
      return <Redirect to="/" />
    }
    const tableData = { columns: this.state.ascolunas, data: this.state.osdados, };
    return (
      <>
        {this.state.loading == true && <Loader />}
        { this.state.registoCRUD == true &&
            <div className="preload-stuff">
                <div className="position-center">
                    <div className="message-box responseOutput">
                        <div className="responseOutput_gif">
                            <Lottie options={{ animationData: (this.state.classkeymessageout == "success-out")? respok : resperror , loop: false }} />
                        </div>
                        <span className={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                    </div>
                </div>
            </div>
        }

        {this.state.delmess == true &&
            <div className="preload-stuff">
                <div className="position-center">
                    <div className="message-box message-boxPopup responseOutput-box">
                        <span className="messagedel">{this.props.oestado.var_globalcontent["data"]["traducoes"]["eliminardados"]['palavra_traducao']} </span>
                        <div className="message-boxActions">
                            <div onClick={() => this.handleDelete(0 , 1)} >
                                <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["yes"]['palavra_traducao']} </span>
                            </div>
                            <div onClick={() => this.handleDelete(0 , 0)} >
                                <span className="buttonyesorno">{this.props.oestado.var_globalcontent["data"]["traducoes"]["no"]['palavra_traducao']} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }
        <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} gcontent={this.props.gcontent} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} theheader="default"/>
        <Container className="content contentPage">
          <Breadcrumbs {...this.props} gcontent={this.props.gcontent} />
            <div className="titlePage">
              {this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b>
            </div>
          <Row className="content-sub contentOffice">
            <Col className="menuDrop">
              <Usermenu gcontent={this.props.gcontent}/>
            </Col>
            <Col className="contentDRUP">
              <Tab.Container activeKey={this.state.keyTab} onSelect={this.changeTab} >
                <Nav variant="pills" className="pageClientHead">
                  <h3 className="contentTitle">{this.props.gcontent.data.traducoes['translations']['palavra_traducao']}</h3>
                  <span className="separador"></span>
                  <Nav.Item>
                    <Nav.Link eventKey={"list"}>
                      { this.props.gcontent.data.traducoes["listing"]["palavra_traducao"] }
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={"form"}>
                      {this.props.gcontent.data.traducoes["additems"]["palavra_traducao"]}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey={"form"}>
                    <Row className="m-0">
                      <div className="adminForms">
                        <Form>
                            <Row>
                              <Col>
                                <Form.Group controlId="formBasicchave">
                                  <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["tradchave"]} name="tradchave" placeholder={this.props.gcontent.data.traducoes['reference']['palavra_traducao']}/>
                                  {this.state.formvalidation["tradchave"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                      <span className="error">
                                        { this.state.formvalidation["tradchave"][1] }
                                      </span>
                                  }
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" md="6">
                                <Form.Group controlId="formBasicportugues">
                                  <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["tradportugues"]} name="tradportugues" placeholder={this.props.gcontent.data.traducoes['portugues']['palavra_traducao']}/>
                                    {this.state.formvalidation["tradportugues"][0] == false && this.state.formvalidation["formsubmited"] == true && (
                                      <span className="error">
                                        {this.state.formvalidation["tradportugues"][1]}
                                      </span>
                                    )}
                                </Form.Group>
                              </Col>
                              <Col xs="12" md="6">
                                <Form.Group controlId="formBasicenglish">
                                  <Form.Control type="text" onChange={this.handleChange} value={this.state.formvalues["tradenglish"]} name="tradenglish" placeholder={this.props.gcontent.data.traducoes['ingles']['palavra_traducao']}/>
                                  {this.state.formvalidation["tradenglish"][0] == false && this.state.formvalidation["formsubmited"] == true && (
                                    <span className="error">
                                      {this.state.formvalidation["tradenglish"][1]}
                                    </span>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Form.Group className="formSubmit">
                                  <Button onClick={() => this.cleanContent()}>
                                    {this.state.clearlabel}
                                  </Button>
                                  <Button onClick={(e) => this.handleSubmit(e)} variant="primary" name="redesocialsubmit" type="submit">
                                    {this.state.submitlabel}
                                  </Button>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                      </div>
                    </Row>
                  </Tab.Pane>
                  <Tab.Pane eventKey={"list"}>
                    <div className="main">
                      {this.state.osdados.length > 1 ? (
                        <DataTableExtensions filterPlaceholder={this.props.gcontent.data.traducoes["search"]["palavra_traducao"] + '...'} filter={true} {...tableData}>
                            <DataTable
                                noHeader={true}
                                defaultSortField="id"
                                defaultSortAsc={false}
                                pagination={true}
                                highlightOnHover={true}
                                paginationComponentOptions={{rowsPerPageText: this.props.gcontent.data.traducoes['rows_page']['palavra_traducao'] + ':'}}
                            />
                        </DataTableExtensions> 
                      ):(
                          <div id="loadingContent"><Lottie options={{ animationData: animationLoader }} /></div>
                      )}
                    </div> 
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Traducoes;
