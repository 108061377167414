import React, { Component } from 'react';
import { Row, Container, Col, Button, Form  } from 'react-bootstrap';
import Header from './Header';
import Usermenu from '../Usermenu';
import Breadcrumbs from '../Breadcrumbs.js';
import * as loadedcontent from '../loadcontent';
import { validate } from '../../helpers/Validationfuncs';
import BtnLightbox from "../../helpers/btnLightbox";
import BtnFavorite from "../../helpers/btnFavorite";
import parse from "html-react-parser";
import Lottie from 'lottie-react-web';
import animationLoader from '../../assets/loading_stats.json';
import { security } from '../../helpers/Security';
import { Redirect } from 'react-router-dom';
import { store } from '../../redux/store';

let storeuser = '';

class License extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: security(),
            imagePage: ''
        };
    }

    async componentDidMount(){
        window.scrollTo(0, 0)
        if( !this.props.location.state.image )
            this.props.history.goBack();

        storeuser = store.getState()["getatauser"]["userdata"];
        let lang = store.getState()["getlang"]["idlingua"];
        let tdata = { language:lang, ataregistoid:storeuser["idregisto"], "idfile": this.props.location.state.image.idupload_ficheiros,  
                            rightfilters: { mrecentes: false, mvistas: true, mfavoritas: false  }
                        }
        let thephotos = await loadedcontent.global_postaction("actionSearchfiles", tdata, 0)
        this.setState({
            imagePage: thephotos.data.images[0],
        })
       
    }

  render() {
    if( this.state.redirect == true ){
        return <Redirect to={"/"} />
    }
    return (
        <>
            <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} gcontent={this.props.gcontent} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} theheader="default" />
            <Container className="content contentPage">
                <Breadcrumbs {...this.props} gcontent={this.props.gcontent} pageLicense={this.state.imagePage}/>
                <div className="backBtn" onClick={this.props.history.goBack}><i className="fas fa-angle-left"></i> {this.props.gcontent.data.traducoes['back']['palavra_traducao']}</div>
                {this.state.imagePage ?
                    (
                        <Row>
                            <Col className="menuDrop">
                                <div className="titlePage"><b>{this.props.gcontent.data.traducoes["license_terms"]["palavra_traducao"]}</b></div>
                                <div className="licenseImg">
                                    <div className="itemWrapper">
                                        <div className="itemWrapper_hover">
                                            <div className="itemType">{((this.state.imagePage['tipo_ficheiro'] == 1)? (<span className="icon-typeImg"></span>) : (<span className="icon-typeVideo"></span>))}</div>
                                            <BtnFavorite idPhoto={this.state.imagePage['idupload_ficheiros']} {...this.props} />
                                            <BtnLightbox type={((this.state.imagePage['tipo_ficheiro'] == 1)? 'photo' : 'video')} source={this.state.imagePage['tipo_ficheiro'] == 1 ? (global.src + 'ataimg/tb/' + this.state.imagePage.thumb) : (global.src + 'atavid/prev/' + this.state.imagePage.videopreview)} />
                                        </div>
                                        <img id={this.state.imagePage['idupload_ficheiros']} src={ global.src + ((this.state.imagePage['tipo_ficheiro'] == 1)? 'ataimg/tb/' : 'atavid/tb/') + this.state.imagePage['thumb']} width="" height="" alt={this.state.imagePage.titulo_ficheiro} />
                                    </div>
                                </div>
                            </Col>
                            <Col>
                                <div className="licenseTerms">
                                    <p>{parse(String(this.state.imagePage.termos_condicoes))}</p>
                                </div>
                            </Col>
                        </Row>
                    ):(
                        <div id="loadingContent"><Lottie options={{ animationData: animationLoader }} /></div>
                    )
                }
                
            </Container>
        </>
    );
  };

}

export default License;