import React, { Component } from 'react';
import { Row, Container, Col, Button, Form } from 'react-bootstrap';
import Usermenu from '../Usermenu';
import { store } from '../../redux/store';
import Breadcrumbs from '../Breadcrumbs';
import Header from './Header';
import { security } from '../../helpers/Security';
import { Redirect } from 'react-router-dom';
import * as loadedcontent from '../loadcontent';

class Permissoes extends Component {
    constructor(props) {
        super(props);
        this.handlepermissoes = this.handlepermissoes.bind(this);
        this.changeNewUserGroupName = this.changeNewUserGroupName.bind(this);
        this.state = {  
            redirect: security(),          
            aspermissoes: null,
            addUG: false,
            canEdit: false,
            newuser: ""
        };
    }

    handlepermissoes = () => {
        let asperms = [];

        //----------------Conteudos zonas----------------------
        for (let levelOneKey in this.props.oestado.var_globalcontent["data"]["permissoes"]) {

            asperms.push(
                <Row key={this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey]["nome"]} className="table-item" style={{ background: (levelOneKey == this.state.canEdit) && '#ddd' }}>
                    <Col className="table-col table-text permission-check thead nAllowed">
                        {this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey]["nome"]}
                    </Col>
                    <Col className="table-col table-text permission-check nAllowed">
                        {this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey]["ver"] == 1 ? <span className="icon-check"></span> : '---'}
                    </Col>
                    <Col className="table-col table-text permission-check">
                        { this.state.canEdit == levelOneKey ?
                            <Form.Group className="editPermissions">
                                <Form.Check className="customCheckbox editPermissionsCheck" 
                                    onChange={() => this.chechPermissionsChange( levelOneKey, "editar") } 
                                    type="checkbox" 
                                    value="editar" 
                                    label="" 
                                    checked={ this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey]["editar"] == 1 ? true : false } />
                            </Form.Group> :
                            this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey]["editar"] == 1 ? <span className="icon-check"></span> : '---'
                        }
                    </Col>
                    <Col className="table-col table-text permission-check">
                        { this.state.canEdit == levelOneKey ?
                            <Form.Group className="editPermissions">
                                <Form.Check className="customCheckbox editPermissionsCheck" 
                                    onChange={() => this.chechPermissionsChange( levelOneKey, "inserir") }
                                    type="checkbox" 
                                    value="inserir" 
                                    label="" 
                                    checked={ this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey]["inserir"] == 1 ? true : false } />
                            </Form.Group> :
                            this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey]["inserir"] == 1 ? <span className="icon-check"></span> : '---'
                        }
                    </Col>
                    <Col className="table-col table-text permission-check">
                        { this.state.canEdit == levelOneKey ?
                            <Form.Group className="editPermissions">
                                <Form.Check onChange={() => this.chechPermissionsChange( levelOneKey, "eliminar") }
                                    className="customCheckbox editPermissionsCheck" 
                                    type="checkbox" 
                                    value="eliminar" 
                                    label="" 
                                    checked={ this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey]["eliminar"] == 1 ? true : false } />
                            </Form.Group> :
                            this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey]["eliminar"] == 1 ? <span className="icon-check"></span> : '---'
                        }
                    </Col>
                    <Col className="table-col table-text permission-check nAllowed">
                        {this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey]["admin"] == 1 ? <span className="icon-check"></span> : '---'}
                    </Col>
                    {this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey]["admin"] != 1 ?
                        <Col className="table-col table-text ops">
                            <i className="table-actionsItem far fa-edit" title={this.props.gcontent.data.traducoes['editar']['palavra_traducao']} onClick={() => this.toggleEditGroupPermissions(levelOneKey) }></i>
                            {/* <i className="table-actionsItem far fa-trash-alt" title={this.props.gcontent.data.traducoes['eliminar']['palavra_traducao']} onClick={() => this.removeUserGroup(levelOneKey) }></i> */}
                        </Col> :
                        <Col className="table-col table-text"></Col>
                    }                    
                </Row>
            );
        }
        //----------------------------------------------

        this.setState({aspermissoes: asperms });
    }

    async chechPermissionsChange(levelOneKey, permission) {

        if (this.state.canEdit == levelOneKey) {
            if (this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey]["admin"] != 1) {
                if (permission != "ver") {
                    this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey][permission] = (this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey][permission] == 1) ? 0 : 1; 
                    this.handlepermissoes();

                    let result = await loadedcontent.global_postaction("actionPermissions", this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey], 3);

                    if (!result["data"]) {
                        this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey][permission] = (this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey][permission] == 1) ? 0 : 1; 
                        this.handlepermissoes();
                    }
                }
            }
        }
    }

    toggleNewUserGroup() {
        this.setState({ addUG: !this.state.addUG })
    }

    toggleEditGroupPermissions(levelOneKey) {

        if (this.state.canEdit == false) {
            this.setState({ canEdit: levelOneKey }, this.handlepermissoes );    
        } else {
            if (this.state.canEdit == levelOneKey) {
                this.setState({ canEdit: false }, this.handlepermissoes );
            } else {
                this.setState({ canEdit: levelOneKey }, this.handlepermissoes );
            }
        }

    }

    changeNewUserGroupName(evt) {
        this.setState({ newuser: evt.target.value });
    }

    async addNewUserGroup() {
        if (this.state.newuser.length >= 3) {
            this.toggleNewUserGroup();
            
            let index = Object.keys(this.props.oestado.var_globalcontent["data"]["permissoes"]).length + 1;         

            let newUserGroup = {
                "idbackoffice_permissoes": index,
                "nome": this.state.newuser,
                "ver": 1,
                "editar": 0,
                "inserir": 0,
                "eliminar": 0,
                "admin": 0
            };

            let result = await loadedcontent.global_postaction("actionPermissions", newUserGroup, 1);
            
            if ( result["data"] ) {
                this.props.oestado.var_globalcontent["data"]["permissoes"][index] = newUserGroup;
                this.handlepermissoes();  
            }
        }
    }

    async removeUserGroup(levelOneKey) {
        let result = await loadedcontent.global_postaction("actionPermissions", this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey], 2);

        if ( result["data"] ) {
            delete this.props.oestado.var_globalcontent["data"]["permissoes"][levelOneKey];
            this.handlepermissoes();
        }
    }

    componentDidMount(){
        this.handlepermissoes();
    }

    render() {
        if( this.state.redirect == true ){
            return <Redirect to={"/"} />
        }else if(store.getState()["getatauser"]["userdata"]["admin"] != 1){ 
            return <Redirect to="/" /> 
        }
        const { fileName } = this.state;
        let file = null;
        file = fileName && ( <label className="iconFile_Name"><span className="icon-paperclip"></span> {fileName}</label>);
        return (
            <>
                <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} gcontent={this.props.gcontent} theheader="default" />
                <Container className="content contentPage">
                    <Breadcrumbs {...this.props} gcontent={this.props.gcontent}/>
                    <div className="titlePage">{this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b></div>
                    <Row className="content-sub contentOffice">
                        <Col className="menuDrop">
                            <Usermenu gcontent={this.props.gcontent}/>
                        </Col>
                        <Col className="contentDRUP">
                            <div className="permissionsTitle pageClientHead">
                                <h3 className="contentTitle"> {this.props.gcontent["data"]["traducoes"]["permissions"]["palavra_traducao"]} </h3>
                                <Button className="btAdd" onClick={() => this.toggleNewUserGroup() }> {this.props.gcontent.data.traducoes['adicionar_grupo']['palavra_traducao']} </Button>
                            </div>
                            <Row className="m-0">
                                <Col className="table-DefauWrapper p-0">
                                    <div className="tableInner">
                                        <Row className="table-item header">
                                            <Col className="table-col table-text thead">
                                                {this.props.gcontent.data.traducoes['nome']['palavra_traducao']}
                                            </Col>
                                            <Col className="table-col table-text">
                                                {this.props.gcontent.data.traducoes['ver']['palavra_traducao']}
                                            </Col>
                                            <Col className="table-col table-text">
                                                {this.props.gcontent.data.traducoes['editar']['palavra_traducao']}
                                            </Col>
                                            <Col className="table-col table-text">
                                                {this.props.gcontent.data.traducoes['bt_inserir']['palavra_traducao']}
                                            </Col>
                                            <Col className="table-col table-text">
                                                {this.props.gcontent.data.traducoes['eliminar']['palavra_traducao']}
                                            </Col>
                                            <Col className="table-col table-text">
                                                Admin
                                            </Col>
                                            <Col className="table-col table-text">
                                                {this.props.gcontent.data.traducoes['acoes']['palavra_traducao']} 
                                            </Col>
                                        </Row>
                                        {this.props.oestado.var_globalcontent["data"]["permissoes"] != null &&
                                            this.state.aspermissoes       
                                        }
                                        { this.state.addUG ?
                                            <Row key="addingNew" className="table-item">
                                                <Col className="table-col">
                                                    <input type="text" name="usergroupname" placeholder={this.props.gcontent["data"]["traducoes"]["nome_do_grupo"]["palavra_traducao"]} onChange={ this.changeNewUserGroupName }/>
                                                </Col>
                                                <Col className="table-add-options">
                                                    <Button className="buttons" onClick={() => this.addNewUserGroup() }>{this.props.gcontent["data"]["traducoes"]["guardar"]["palavra_traducao"]} </Button>
                                                    <Button className="buttons" onClick={() => this.toggleNewUserGroup() }>{this.props.gcontent["data"]["traducoes"]["cancelar"]["palavra_traducao"]} </Button>
                                                </Col>
                                            </Row> : null
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    };

}

export default Permissoes;