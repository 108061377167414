import React, { Component, useState } from 'react';
import { store } from '../redux/store';
import * as loadedcontent from '../components/loadcontent';
let storeuser = ''

let counter=0
class notifCounter extends Component {
    constructor(props) {
        super(props);
    }    
    async componentDidMount(){
        storeuser = store.getState()["getatauser"]["userdata"]
        if (storeuser){
            let tdata =   {iduser: storeuser["idregisto"],lang:store.getState()["getlang"]["idlingua"],expira:60}
            let crud = await loadedcontent.global_postaction( "actionProfileNotification",tdata, 0)
            counter=crud.total
        }
        
    }
    render() {
        return (
            <>  
                {counter !== 0 &&
                    <div className="notificationCounter">{counter}</div>
                }
            </>
        )
    };  
}
export default notifCounter;
