import React, { Component } from 'react';
import { Row, Container, Col } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { store } from '../redux/store';
import NotificationCounter from "../helpers/notifCounter.js";
//let storeuser = '' 

class Usermenu extends Component {
    constructor(props) {
        super(props);
        //-----------------------------------
        let _mobilemenu = false;
        //storeuser = store.getState()["getatauser"]["userdata"]
        if (window.innerWidth < 991) {
            _mobilemenu = true;
        }else{
            _mobilemenu = false;
        }
        //-----------------------------------
        this.state = {
            stateadmin: (store.getState()["getatauser"] != "") ? store.getState()["getatauser"]["userdata"]["admin"] : 0,
            mobilemenu: _mobilemenu,
            submobilemenu: false,
            mobilemenuPlaceholder: ''
        };
        window.addEventListener("resize", this.handleResize);
    }

    handleResize = (event) => {
        if (event.currentTarget.innerWidth < 991) {
          this.setState({
            mobilemenu: true
          });
        }else{
            this.setState({
                mobilemenu: false
            });
        }
    };

    subMenuTog = () => {
        this.setState({
            submobilemenu: !this.state.submobilemenu,
        });
    }

    componentDidMount(){
        this.setState({
            mobilemenuPlaceholder: document.getElementsByClassName("menuItem_Link active")[0].text,
        })
        //console.log(document.getElementsByClassName("menuItem_Link active")[0].text);
    }
 
    render() { 
        const isActive = (path, match, location) => !!(match || path === location.pathname);
        return ( 
            <>    
                {store.getState()["getatauser"] != "" &&
                    <Container fluid className={this.state.mobilemenu ? "AdminMenu p-0 menuMobile" : "AdminMenu p-0"}>
                        {!this.state.mobilemenu ? 
                            (
                                <h3 className="menuTitle">Menu</h3>
                            ):(
                                <div className="AdminMenubtn menuPlaceholder" onClick={this.subMenuTog}>{this.state.mobilemenuPlaceholder}</div>
                            )
                        }
                        <ul className={this.state.submobilemenu ? "submenuAdmin toggled" : "submenuAdmin"}>
                            <li className="menuItem"><NavLink isActive={isActive.bind(this, '/mylist')} activeClassName="active" className="menuItem_Link" to="/mylist"> MyList{/*this.props.gcontent.data.traducoes['mylist']['palavra_traducao']*/} </NavLink></li>
                            <li className="menuItem"><NavLink isActive={isActive.bind(this, '/gostos')} activeClassName="active" className="menuItem_Link" to="/gostos"> {this.props.gcontent.data.traducoes['favoritos']['palavra_traducao']} </NavLink></li>
                            <li className="menuItem"><NavLink isActive={isActive.bind(this, '/prevdownloads')} activeClassName="active" className="menuItem_Link" to="/prevdownloads"> {this.props.gcontent.data.traducoes['prevdownloads']['palavra_traducao']} </NavLink></li>
                            <li className="menuItem"><NavLink isActive={isActive.bind(this, '/editarperfil')} activeClassName="active" className="menuItem_Link" to="/editarperfil"> {this.props.gcontent.data.traducoes['edit_profile']['palavra_traducao']} </NavLink></li>
                            <li className="menuItem"><NavLink isActive={isActive.bind(this, '/notificacoes')} activeClassName="active" className="menuItem_Link" to="/notificacoes"> {this.props.gcontent.data.traducoes['notificacoes']['palavra_traducao']} <span><NotificationCounter/></span></NavLink></li>
                            {this.state.stateadmin == 1 && (
                                <>
                                    <li className="menuItem adminMenu"><NavLink isActive={isActive.bind(this, '/definicoes')} activeClassName="active" className="menuItem_Link" to="/definicoes"> {this.props.gcontent.data.traducoes['definicoes']['palavra_traducao']} </NavLink></li>
                                    <li className="menuItem adminMenu"><NavLink isActive={isActive.bind(this, '/categorias')} activeClassName="active" className="menuItem_Link" to="/categorias"> {this.props.gcontent.data.traducoes['cats-subcats']['palavra_traducao']} </NavLink></li>
                                    <li className="menuItem adminMenu"><NavLink isActive={isActive.bind(this, '/politicaetermos')} activeClassName="active" className="menuItem_Link" to="/politicaetermos"> {this.props.gcontent.data.traducoes['politic-terms']['palavra_traducao']} </NavLink></li>
                                    <li className="menuItem adminMenu"><NavLink isActive={isActive.bind(this, '/perfis')} activeClassName="active" className="menuItem_Link" to="/perfis"> {this.props.gcontent.data.traducoes['profiles-subprof']['palavra_traducao']} </NavLink></li>
                                    <li className="menuItem adminMenu"><NavLink isActive={isActive.bind(this, '/permissoes')} activeClassName="active" className="menuItem_Link" to="/permissoes"> {this.props.gcontent.data.traducoes['permissions']['palavra_traducao']} </NavLink></li>
                                    <li className="menuItem adminMenu"><NavLink isActive={isActive.bind(this, '/redessociais')} activeClassName="active" className="menuItem_Link" to="/redessociais"> {this.props.gcontent.data.traducoes['social_network']['palavra_traducao']} </NavLink></li>
                                    <li className="menuItem adminMenu"><NavLink isActive={isActive.bind(this, '/sobre')} activeClassName="active" className="menuItem_Link" to="/sobre"> {this.props.gcontent.data.traducoes['about']['palavra_traducao']} </NavLink></li>
                                    <li className="menuItem adminMenu"><NavLink isActive={isActive.bind(this, '/traducoes')} activeClassName="active" className="menuItem_Link" to="/traducoes"> {this.props.gcontent.data.traducoes['translations']['palavra_traducao']} </NavLink></li>
                                </>
                            )}

                            {(store.getState()["getatauser"]["userdata"]["inserir"] == 1 || store.getState()["getatauser"]["userdata"]["editar"] == 1 || store.getState()["getatauser"]["userdata"]["eliminar"] == 1) &&                                                                  
                                <li className="menuItem adminMenu"><NavLink isActive={isActive.bind(this, '/upload')} activeClassName="active" className="menuItem_Link" to="/upload"> {this.props.gcontent.data.traducoes['uploads']['palavra_traducao']} </NavLink></li>   
                            }

                            {this.state.stateadmin == 1 &&  
                                <li className="menuItem adminMenu"><NavLink isActive={isActive.bind(this, '/utilizadores')} activeClassName="active" className="menuItem_Link" to="/utilizadores"> {this.props.gcontent.data.traducoes['users']['palavra_traducao']} </NavLink></li>
                            }
                            
                        </ul>
                    </Container>
                }
            </>
        );
    }   
}

export default Usermenu;
