import React, { Component } from "react";
import { Row, Container, Col, Button, alert, Form } from "react-bootstrap";
import { NavLink, Switch, Route, } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import parse from "html-react-parser";
import CryptoJS from 'crypto-js';
import { ReactComponent as Logopt } from "../../assets/images/logoPt.svg";
import { ReactComponent as Logoen } from "../../assets/images/logoEn.svg";
import { store } from "../../redux/store";
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";
import flagpt from "../../images/flags/pt.png";
import flagen from "../../images/flags/en.png";
import { Redirect } from "react-router-dom";
import SearchBar from "../Searchbar.js";
import SearchFilter from "../SearchFilters.js";
import LangSwitcher from "../../helpers/langSwither.js";
import GallerySelect from "../../helpers/galleriesSelect.js";
import NotificationCounter from "../../helpers/notifCounter.js";
import "../../config";
import Gallery from "react-photo-gallery";
import Cookies from 'universal-cookie';
import * as loadedcontent from '../loadcontent';

class Header extends Component {
  constructor(props) {
    super(props);
    this.showUserMenu = this.showUserMenu.bind(this);
    //this.closeUserMenu = this.closeUserMenu.bind(this);
    this.sideMenu = this.sideMenu.bind(this);
    this.logout = this.logout.bind(this);
    this.sideSearch = this.sideSearch.bind(this);
    window.addEventListener("resize", this.handleResize);
    this.state = {
      showMenu: false,
      sideSearch: false,
      showUserMenu: false,
      sideMenu: false,
      classLogo: this.props.classLogo,
      authorized: true,
      tuser: "",
    };
  }
  handleResize = (event) => {
    if (event.currentTarget.innerWidth > 768) {
      this.setState({
        sideMenu: false,
        sideSearch: false,
      });
    }
  };

  logout = () => {
      store.dispatch({ type: "theatauser", param: "" }); 
      const cookies = new Cookies();
      cookies.remove( 'ataextensions' );
      cookies.remove( 'ataextensions-lang' );
      //window.location.reload(); 
      window.location.href = "/";
  };

  gallerySelec(event) {
    this.setState({
      valueCat: event.target[event.target.selectedIndex].getAttribute("value"),
    });
    this.getSubcategories();
  }

  sideMenu = () => {
    this.setState({
      sideMenu: !this.state.sideMenu,
    });
  };
  sideSearch = () => {
    this.setState({
      sideSearch: !this.state.sideSearch,
    });
  };
  
  showUserMenu(event) {
    event.preventDefault();
    this.setState({ showUserMenu: !this.state.showUserMenu });
  }

  async componentDidUpdate(){
      if( store.getState()["getatauser"] != undefined ){
          let storeuser = store.getState()["getatauser"];
          if( storeuser["userdata"] != undefined ){
              if( this.state.tuser != storeuser["userdata"]["nome"] ){
                  this.setState({ tuser: storeuser["userdata"]["nome"] });
                  /* -------------Update cookie------------------------ */
                  const cookies = new Cookies();
                  let gdata = cookies.get('ataextensions');
                  if( gdata != undefined ){
                    var bytes  = CryptoJS.AES.decrypt(gdata, global.param3);
                    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                    decryptedData["userdata"]["nome"] = storeuser["userdata"]["nome"];
                    var ciphertext = CryptoJS.AES.encrypt( JSON.stringify({ "userdata" : decryptedData["userdata"] }) , global.param3).toString();
                    cookies.set('ataextensions', ciphertext, { path: '/', expires: global.cookiedate });
                  }
                  /* -------------------------------------------------  */
              }

              let tuser = await loadedcontent.global_postaction("actionRegisto", { "search": "idregisto = " + store.getState()["getatauser"]["userdata"]["idregisto"] }, 0);
              try {
                  if( tuser.data[0]["activo"] == 0 ){
                      this.logout();
                  }
              } catch (error) {
                  console.log(error);
              }
          }
      }
  }

  componentDidMount() {
    let storeuser = store.getState()["getatauser"];
      if (storeuser == null || storeuser == undefined || storeuser == "") {
        this.setState({ authorized: false });
      }else{
        this.setState({ tuser: storeuser["userdata"]["nome"] });
      }
   }

  render() {
    let curPath = window.location.pathname;
    if (this.state.authorized == false && this.props.authorized != "login" && this.props.authorized != "highaccess"){
        return <Redirect exact to="/" />;
    }
    /*if (curPath=='/' && this.state.authorized) 
        return <Redirect exact to="/home" /> */
    
    return (
      <>
        <div className={this.state.sideMenu ? "sideMenu active" : "sideMenu"}>
          <span
            className="icon-close-icon white"
            onClick={this.sideMenu}
          ></span>
          <div className="sideMenu-top">
            <NavLink to="/home">
              <div className="ata_logo white">
                {store.getState()["getlang"]["idlingua"] == "pt" ? <Logopt /> : <Logoen />}
              </div>
            </NavLink>
            <LangSwitcher {...this.props}/>
          </div>
          <div className="sideMenu-content">
            <NavLink to="/home">Home</NavLink>
            <GallerySelect {...this.props}/>
            <div className="username">
              {this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{this.state.tuser}</b>
              <NotificationCounter/>
            </div>
            <div className="userMenu">
              <NavLink className="userMenu-item" to="/mylist">
                <span><i className="fas fa-list"></i></span> MyList
                {/*this.props.gcontent.data.traducoes['mylist']['palavra_traducao']*/}
              </NavLink>
              <NavLink className="userMenu-item" to="/editarperfil">
                <span className="icon-user"></span> {this.props.gcontent.data.traducoes['edit_profile']['palavra_traducao']}
              </NavLink>
              <div className="userMenu-item" onClick={this.logout}>
                <span className="icon-logout"></span> Log Out
              </div>
            </div>
          </div>
        </div>
        <div className={this.state.sideSearch ? "sideSearch active" : "sideSearch"}>
          <span className="icon-close-icon" onClick={this.sideSearch}></span>
          <SearchBar classBar="" searchfield={this.props.searchfield} gcontent={this.props.gcontent} />
          {this.state.sideSearch && (
            <SearchFilter gcontent={this.props.gcontent} />
          )}
        </div>
        <header className={this.props.theheader ? "siteHeader defaultHeader " + this.props.theheader : "siteHeader"}>
          <Container>
            <div className="header">
              <Row className="m-0">
                <Col xs={2} sm={2} className="d-sm-flex d-lg-none p-0 buttonSideSearch">
                  {this.props.theheader != "home" &&
                  this.props.authorized != "login" && !this.state.authorized == false ? (
                    /*<SearchBar classBar="mobile" gcontent={this.props.gcontent}/>*/
                    <button onClick={this.sideSearch} className="searchSubmit">
                      <span className="icon-search-icon"></span>
                    </button>
                  ) : null}
                </Col>
                <Col xs="8" sm="8" lg="2" xl="3" className="p-0">
                  <NavLink to="/home">
                    <div className={this.state.classLogo ? "ata_logo " + this.state.classLogo : "ata_logo"}>
                      {store.getState()["getlang"]["idlingua"] == "pt" ? <Logopt /> : <Logoen />}
                    </div>
                  </NavLink>
                </Col>
                {this.props.authorized != "login" && !this.state.authorized == false ? (
                  <Col xs={2} sm={2} className="d-sm-flex d-lg-none p-0 buttonSideMenu">
                    <span onClick={this.sideMenu} className="fas fa-bars"></span>
                  </Col>
                ) : null}
                {this.props.theheader && this.state.tuser ? (
                  <Col sm="9" lg="10" xl="9" className="defaultHeader_components d-none d-lg-flex p-0">
                    <div className="searchForm-Inner">
                      {this.props.theheader != "home"  ? (
                        <SearchBar searchfield={this.props.searchfield} classBar="" gcontent={this.props.gcontent} />
                      ) : null}
                    </div>
                    <GallerySelect {...this.props}/>
                    <LangSwitcher {...this.props}/>
                    <div className="userArea customSelect">
                      <button className="userLogged" onClick={this.showUserMenu}>
                        <NotificationCounter/>
                        <p className="m-0">
                            {this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{this.state.tuser}</b>
                        </p>
                      </button>
                      <Fade className="customSelect-menuInner userSubmenu" collapse when={this.state.showUserMenu} duration={300}>
                        <ul className="customSelect-menu userSubmenu-menu">
                          <li>
                            <NavLink to="/mylist">
                              <span><i className="fas fa-list"></i></span>{" "}
                              MyList
                              {/*this.props.gcontent.data.traducoes['mylist']['palavra_traducao']*/}
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/editarperfil">
                              <span className="icon-user"></span>{" "}
                              {this.props.gcontent["data"]["traducoes"]["myaccount"]["palavra_traducao"]}
                            </NavLink>
                          </li>
                          <li>
                            <div onClick={this.logout}>
                              <span className="icon-logout"></span>{" "}
                              {this.props.gcontent["data"]["traducoes"]["logout"]["palavra_traducao"]}
                            </div>
                          </li>
                        </ul>
                      </Fade>
                    </div>
                  </Col>
                ) : (
                  <Col className="">
                    <LangSwitcher {...this.props}/>
                  </Col>
                )}
              </Row>
            </div>
          </Container>
        </header>
      </>
    );
  }
}

export default Header;
