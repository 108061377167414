global.base_url="https://visitazoresstockgallery.com/"
global.api="https://api.visitazoresstockgallery.com/"
global.url="https://visitazoresstockgallery.com/"
global.server="https://visitazoresstockgallery.com/"
//global.src=global.server
//global.src='http://zidev'+global.api+'atafiles/'
global.src='https://media.visitazoresstockgallery.com/'
global.param1=""
global.param2=""
global.param3=""
var d = new Date(); var year = d.getFullYear(); var month = d.getMonth(); var day = d.getDate(); 
global.cookiedate = new Date(year + 1, month, day);

