import ReactDOM from 'react-dom'
import React, { Component } from 'react';
import { Row, Container, Col, Button, Form, Accordion, Card, Modal  } from 'react-bootstrap';
import { NavLink, Redirect } from 'react-router-dom';
import Header from './Header';
import { store } from '../../redux/store';
import Usermenu from '../Usermenu';
import InfiniteScroll from 'react-infinite-scroll-component';
import { security } from '../../helpers/Security';
import Breadcrumbs from '../Breadcrumbs.js';
import parse from "html-react-parser";
import Fade from 'react-reveal/Fade';
import BtnLightbox from "../../helpers/btnLightbox";
import BtnFavorite from "../../helpers/btnFavorite";
import Lottie from 'lottie-react-web';
import animationLoader from '../../assets/loading_stats.json';
import * as loadedcontent from '../loadcontent';
import axios from 'axios';
let storeuser = ''
let emptyData = false

class Mylist extends Component {
    constructor(props) {
        super(props);
        this.checkFiles = this.checkFiles.bind(this);
        this.handleRadio = this.handleRadio.bind(this);
        this.downloadFiles = this.downloadFiles.bind(this);
        this.compressFiles = this.compressFiles.bind(this);
        this.showLicense = this.showLicense.bind(this);
        this.state = {
            keymessageout: "",
            classkeymessageout: "",
            defaultlang: "pt",
            data:[],
            action:0,
            checkedFiles:[],
            agreedFiles:[],
            warning:false,
            progressBar:0,
            totalFiles:0,
            modalLicense: false,
            contLicense: '',
            redirect: security(),
            /*************/
            itemsLenght: 0,
            showItems: 5,
            loadItems: 3,
        };
    }
    handleRadio(e){
        e.target.classList.toggle("active");
        const idFile = e.target.getAttribute("id")  
        let allfiles= this.state.agreedFiles;
        let ckFil = false;
        for( let fg = 0; fg < allfiles.length; fg ){
            ckFil = false;
            if( allfiles[fg] == idFile ){
                ckFil = true;
                allfiles = allfiles.filter(e => e != idFile);      
                break;
            }
        }
        if( ckFil == false ){
            this.setState({agreedFiles:[...allfiles, idFile]});
        }else{
            this.setState({ agreedFiles: allfiles });
        }
    }

    checkFiles(e){
        const idFile = e.target.value   
        //console.log(idFile)
        let allfiles= this.state.checkedFiles
        allfiles = allfiles.filter(e => e != idFile);
        this.setState({checkedFiles:[...allfiles, idFile]})     
    }

    async loadData(){
        try{
            if( storeuser != undefined ){
                let tdata= {idregisto:storeuser["idregisto"]}
                let data= await loadedcontent.global_postaction( "actionFavoritos", tdata, this.state.action )
                let theItems = [];
               
                let c_cont = 0;
                if(data.data.length === 0){
                    emptyData = true;
                }
                this.setState({
                    itemsLenght: data.data.length
                })
                //let list =  data.data.filter(a => a.ididiomas == store.getState()["getlang"]["idlingua"])
                for (let index in data.data){
                    if( c_cont < this.state.showItems ){
                        if(data.data[index].ididiomas == store.getState()["getlang"]["idlingua"])
                            theItems.push(
                                data.data[index]
                            )
                    }
                    c_cont++;
                }

                this.setState({data:theItems, loader: false})  
                //console.log(this.state.data)  
                //console.log(emptyData)  
            }
        }catch(e){
            //console.log(e)
        }
    }

    async componentDidMount() {
        storeuser = store.getState()["getatauser"]["userdata"]
        this.loadData()
    }

    returnImage(image){
        let img;
        try {
            if (image.tipo_ficheiro==1)
                img =global.src +"ataimg/tb/"+image.thumb
            else
                 img =global.src +"atavid/tb/"+image.thumb
        } catch (err) {
            img =""
            
        }
        
        return img
    }



    goTo(file){
        window.open(global.src+"downloads/"+file.data)
        this.loadData()
    }

    compressFiles = async () => {
   
        const config = {
            onUploadProgress:(progressEvent) => {
               var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
               this.setState({progressBar:percentCompleted})
             },
             headers: {
                'Content-Type': 'application/json'
              }
          }
          
        if( storeuser != undefined ){
            let tdata =   {idfiles:this.state.checkedFiles,idregisto:storeuser["idregisto"],type:0}
            let crud = await loadedcontent.global_postaction( "actionFavoritos", tdata, 4 );
        
            if ((crud['data'] != undefined)  && (crud['data'].length >0)){
                axios.post(global.url+'compress.php', crud['data'], config)
                .then(res => this.goTo(res))
                .catch(err => {})
            
            }
        }
    
    }

    downloadFiles(){
      
        let agreedFiles = this.state.agreedFiles
        let checkedFiles = this.state.checkedFiles
        let tudoOk=true
        let errors=document.querySelectorAll('[id^=warning-]')
        let error = <><span className="icon-warning"></span>{this.props.oestado.var_globalcontent["data"]["traducoes"]['verificar_aceito_termos']['palavra_traducao']}</>

        this.setState({warning:false})
        for (let e=0;e<errors.length;e++) //clean
                ReactDOM.render('', document.getElementById(errors[e].id))     

        if (checkedFiles.length==0 ||checkedFiles.length==0 ){
            tudoOk=false
            this.setState({warning:true})
            
            for (let e=0;e<errors.length;e++)
                   ReactDOM.render(error, document.getElementById(errors[e].id))     
                   
        }
        
        for (let i=0;i<checkedFiles.length;i++){ 
            if(checkedFiles[i]!=agreedFiles[i]){ 
                this.setState({warning:true})
                ReactDOM.render(error, document.getElementById('warning-'+checkedFiles[i]))
                tudoOk=false
             }
        }
      if (tudoOk)
        this.compressFiles()
    }
      
    showLicense = (license) => {
        //console.log(license);
        this.setState({
            modalLicense: true,
            contLicense: parse(String(license))
        })
    }
    closeModalHandler = () => {
        this.setState({
            modalLicense: false,
            contLicense: null,
        })
    }

    /*loadfiles(){
        let data = this.state.data
        let content = []
        let warning = this.state.warning
        if (data.data != null && data.data.length > 0){
            let list =  data.data.filter(a => a.ididiomas == store.getState()["getlang"]["idlingua"])
            
            list.map((files) => {
                let img = this.returnImage(files) 
                content.push(
                    <Fade key={"ficheiros"+files.idupload_ficheiros}>
                        <Row className="mylistRow">
                            <Col className="mylistImage" xs="8" md="7" lg="3">
                                <div className="itemWrapper">
                                    <div className="itemWrapper_hover">
                                        <NavLink className="itemLink" to={"page/" + files.idupload_ficheiros}>
                                            <div className="itemType">{((files.tipo_ficheiro == 1)? (<span className="icon-typeImg"></span>) : (<span className="icon-typeVideo"></span>))}</div>
                                        </NavLink>
                                        <div className="itemWrapper_actions">
                                            <BtnFavorite idPhoto={files.idupload_ficheiros} {...this.props} dataFav={this.state.data} />
                                            <BtnLightbox type={((files.tipo_ficheiro == 1) ? 'photo' : 'video')} source={files.tipo_ficheiro == 1 ? (global.src + 'ataimg/tb/' + files.thumb) : (global.src + 'atavid/prev/' + files.videopreview)} />
                                        </div>
                                    </div>
                                    <img id={files.idupload_ficheiros} src={img}  width="" height="" alt={files.titulo} />
                                </div>
                            </Col>
                            <Col className="mylistTitle" xs="8" md="7" lg="3">
                                <h2 className="mylistTitle_title">{files.titulo}</h2>
                            </Col>
                            <Col className="mylistTerms" xs="8" md="7" lg="4">
                                <div className="mylistTermsWrapper">
                                    <div className="customRadioBtn" id={files.idupload_ficheiros}  onClick={this.handleRadio}></div>
                                    {store.getState()["getlang"]["idlingua"] == 'pt' ? (
                                        <p>Declaro que aceito a <span className="licenseBtnmodal" onClick={() => this.showLicense(files.termos_condicoes)}>Licença, os Termos e Condições</span> deste ficheiro.</p>
                                    ):(
                                        <p>I do accept the <span className="licenseBtnmodal" onClick={() => this.showLicense(files.termos_condicoes)}>License, Terms and Conditions</span> of this file.</p>
                                    )}                                    </div>
                                <div className="mylistTermsWrapper_error" id={'warning-'+files.idupload_ficheiros}></div>
                            </Col>
                            <Col className="mylistAcceptance" lg="2">
                                <Form.Group className="editPermissions">
                                    <Form.Check className="customCheckbox" type="checkbox" value={files.idupload_ficheiros} onClick={this.checkFiles} />
                                </Form.Group>
                            </Col>
                        </Row>
                    
                    </Fade>
                )
            }) 
            content.push(
                <Container key={"ficheiros111"} className="mylist">                                            
                    <div className="downButton" onClick={this.downloadFiles}>
                        <div className="customSubmitBtn violet">Download</div>
                    </div>
                </Container>

            )
       }
       else{
            content.push(
                <Row className="mylistRowEmpty">
                    <Col className="mylistEmpty">Lista Vazia</Col>
                </Row>
            )
       }
    
       return content
    }*/

    fetchMoreData = () => {
        this.setState({ showItems: (this.state.showItems + this.state.loadItems), loader: true});
        setTimeout(() => {
            this.loadData();
        }, 750);
    };

  render() { 
    
    if( this.state.redirect == true ){
        return <Redirect to={"/"} />
    }
    return (
        <>
            <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} gcontent={this.props.gcontent} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} theheader="default" />
            <Container className="content contentPage">
                <Breadcrumbs {...this.props} gcontent={this.props.gcontent}/>
                <div className="titlePage">{this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b></div>
                <Row className="contentOffice">
                    <Col className="menuDrop">
                        <Usermenu gcontent={this.props.gcontent} />
                    </Col>
                    <Col>
                        <h3 className="contentTitle">MyList</h3>
                        {this.state.validuserpass == false && 
                            <span className={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                        }

                        <Container className="mylist">
                            {this.state.data != '' ? (
                                <>
                                    <InfiniteScroll
                                    dataLength={this.state.data.length}
                                    next={this.fetchMoreData}
                                    hasMore={true}
                                    style={{overflow: 'none'}}
                                    scrollThreshold={0.2}
                                    > 
                                        {this.state.data.map((files) => {
                                            let img = this.returnImage(files) 
                                            return(
                                                <Fade key={"ficheiros"+files.idupload_ficheiros}>
                                                    <Row className="mylistRow">
                                                        <Col className="mylistImage" xs="8" md="7" lg="3">
                                                            <div className="itemWrapper">
                                                                <div className="itemWrapper_hover">
                                                                    <NavLink className="itemLink" to={"page/" + files.idupload_ficheiros}>
                                                                        <div className="itemType">{((files.tipo_ficheiro == 1)? (<span className="icon-typeImg"></span>) : (<span className="icon-typeVideo"></span>))}</div>
                                                                    </NavLink>
                                                                    <div className="itemWrapper_actions">
                                                                        <BtnFavorite idPhoto={files.idupload_ficheiros} {...this.props} dataFav={this.state.data} />
                                                                        <BtnLightbox type={((files.tipo_ficheiro == 1) ? 'photo' : 'video')} source={files.tipo_ficheiro == 1 ? (global.src + 'ataimg/tb/' + files.thumb) : (global.src + 'atavid/prev/' + files.videopreview)} />
                                                                    </div>
                                                                </div>
                                                                <img id={files.idupload_ficheiros} src={img}  width="" height="" alt={files.titulo} />
                                                            </div>
                                                        </Col>
                                                        <Col className="mylistTitle" xs="8" md="7" lg="3">
                                                            <h2 className="mylistTitle_title">{files.titulo}</h2>
                                                        </Col>
                                                        <Col className="mylistTerms" xs="8" md="7" lg="4">
                                                            <div className="mylistTermsWrapper">
                                                                <div className="customRadioBtn" id={files.idupload_ficheiros}  onClick={this.handleRadio}></div>
                                                                {store.getState()["getlang"]["idlingua"] == 'pt' ? (
                                                                    <p>Declaro que aceito a <span className="licenseBtnmodal" onClick={() => this.showLicense(files.termos_condicoes)}>Licença, os Termos e Condições</span> deste ficheiro.</p>
                                                                ):(
                                                                    <p>I do accept the <span className="licenseBtnmodal" onClick={() => this.showLicense(files.termos_condicoes)}>License, Terms and Conditions</span> of this file.</p>
                                                                )}                                    </div>
                                                            <div className="mylistTermsWrapper_error" id={'warning-'+files.idupload_ficheiros}></div>
                                                        </Col>
                                                        <Col className="mylistAcceptance" lg="2">
                                                            <Form.Group className="editPermissions">
                                                                <Form.Check className="customCheckbox" type="checkbox" value={files.idupload_ficheiros} onClick={this.checkFiles} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                
                                                </Fade>
                                            )
                                        })}
                                    </InfiniteScroll>
                                    <Container key={"ficheiros111"} className="mylist">                                            
                                        <div className="downButton" onClick={this.downloadFiles}>
                                            <div className="customSubmitBtn violet">Download</div>
                                        </div>
                                    </Container>
                                </>
                            ):(
                                <>
                                    {emptyData === false ? (
                                            <div id="loadingContent"><Lottie options={{ animationData: animationLoader }} /></div>
                                        ):(
                                            <Row className="mylistRowEmpty">
                                                <Col className="mylistEmpty">{this.props.gcontent.data.traducoes['emptyList']['palavra_traducao']}</Col>
                                            </Row>
                                        )
                                    }
                                </>
                            ) }
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Modal className="modal modalRegist modalLicense p-0" show={this.state.modalLicense} >
                <Container className="registHeader">
                    <div className="registHeader-close" onClick={this.closeModalHandler}>
                        <span className="icon-close-icon"></span> {this.props.oestado.var_globalcontent["data"]["traducoes"]['close']['palavra_traducao']}
                    </div>
                </Container>
                <Container className="licenseContent">
                    {this.state.contLicense}
                </Container>
            </Modal>

        </>
    );
  };

}

export default Mylist;