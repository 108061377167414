import React, { Component } from 'react';
import { Row, Container, Col, Button, Form  } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import ataPartners from '../../images/ata_partners.png';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { store } from '../../redux/store';
import CookieConsent from "react-cookie-consent";
import parse from "html-react-parser";

class Footer extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
                text1: "",
                textarea1: "",
                text2: "",
                textarea2: "",
        }
    }
    

    handleChange(event) {
        if(event.target.name == "theusername"){
            this.setState({username: event.target.value});
        }
        if(event.target.name == "thepassword"){
            this.setState({password: event.target.value});
        }
    }

    handleSubmit(event) {
        //alert('My username: ' + this.state.username);
        //alert('My passweord: ' + this.state.password);
        event.preventDefault();
    }

    render() { 
        //console.log(this.props.gcontent)
        try{
            return (
                <footer>
                    {this.props.thefooter != 'hidden' ? (
                        <>
                            <Container fluid className="subFooter">
                                <Container className="">
                                    <div className="aboutWrapper">
                                        <Fade>
                                            <h3 className="aboutTitle">{this.props.gcontent["data"]["traducoes"]['about']['palavra_traducao']}</h3>
                                        </Fade>
                                        <Row className="aboutText">
                                            {this.props.gcontent != null && 
                                                Object.keys(this.props.gcontent["data"]["sobre"]).map((item, i) => { 
                                                    if(this.props.gcontent["data"]["sobre"][item]["ididiomas"] == store.getState()["getlang"]["idlingua"]){
                                                        return(
                                                            <Col key={this.props.gcontent["data"]["sobre"][item]["idsobre_trad"]} md={6} lg={6} className="aboutItem">
                                                                <Fade key={i}>
                                                                    <h3 className="aboutItem_title">{parse(this.props.gcontent["data"]["sobre"][item]["titulo_sobre"])}</h3>
                                                                    <p className="aboutItem_text">{parse(this.props.gcontent["data"]["sobre"][item]["conteudo_sobre"])}</p>
                                                                </Fade>
                                                            </Col> 
                                                        )
                                                    }
                                                })
                                            } 
                                        </Row>
                                        <Row className="aboutLinks">
                                            <Col lg={6} className="contactLink">
                                                <Fade>
                                                    <p>{this.props.gcontent["data"]["traducoes"]['any_question']['palavra_traducao']}</p>
                                                    <p className="contactLink_link"> <a className="textBlue" href="https://www.visitazores.com/contact-us" target="_blank">{this.props.gcontent["data"]["traducoes"]['contact_us']['palavra_traducao']}. </a><span className="icon-arrow-right"></span></p>
                                                </Fade>
                                            </Col>
                                            {
                                                <Col lg={6} className="socialsLink">
                                                    <Fade>
                                                        <p>{this.props.gcontent["data"]["traducoes"]['social_media']['palavra_traducao']}</p>
                                                        <div className="socialIconsWrapper">
                                                            {Object.keys(this.props.gcontent["data"]["redessociais"]).map((item, i) => (
                                                                <Zoom key={this.props.gcontent["data"]["redessociais"][item]['idredes_sociais']} >
                                                                    <a href={this.props.gcontent["data"]["redessociais"][item]['link']} target="_blank" title={this.props.gcontent["data"]["redessociais"][item]['chave_rede']}>
                                                                        <img src={global.src+"ataficheiros/social/" + this.props.gcontent["data"]["redessociais"][item]['icon']} />
                                                                    </a>
                                                                </Zoom>
                                                            ))}
                                                        </div>
                                                    </Fade>
                                                </Col>
                                            }
                                            
                                        </Row>
                                    </div>
                                </Container>
                            </Container>
                            <Container fluid className="bottomFooter">
                                <Container className="">
                                    <div className="bottom-footer">
                                        <Row className="privacyLinks">
                                            <Col className="leftcontent">
                                                <NavLink to="/politicas">{this.props.gcontent["data"]["traducoes"]['politica']['palavra_traducao']}</NavLink>
                                                <span>/</span>
                                                <NavLink to="/termos">{this.props.gcontent["data"]["traducoes"]['termos']['palavra_traducao']}</NavLink>
                                                <span>/</span>
                                                <NavLink to="/cookies">{this.props.gcontent["data"]["traducoes"]['cookielink']['palavra_traducao']}</NavLink>
                                            </Col>
                                        </Row>
                                        <Row className="ata_bottom">
                                            <Col md={6} className="ata_copyright">
                                                <p>© ATA {new Date().getFullYear()}</p>
                                            </Col>
                                            <Col md={6} className="ata_partnership">
                                                <img src={ataPartners} alt="União Europeia"></img>
                                            </Col>
                                        </Row>
                                    </div>
                                </Container>
                            </Container>
                        </>
                    ) : null}
                    <CookieConsent
                        location="top"
                        buttonText={this.props.gcontent.data.traducoes['cookieBtn']['palavra_traducao']}
                        cookieName="ATA Cookie Consent"
                        style={{ background: "rgba(48, 40, 119, 1", fontSize: 16 }}
                        buttonStyle={{background:"#4175d4", color: "#fff", fontSize: "14px", fontWeight: 'bold' }}
                        expires={150}
                    >
                        {this.props.gcontent.data.traducoes['cookieText']['palavra_traducao']}<span> <NavLink style={{color: 'inherit', textDecoration: 'underline'}} to="/cookies">{this.props.gcontent.data.traducoes['aqui']['palavra_traducao']}</NavLink></span>.
                    </CookieConsent>
                </footer>
            );
        
        }
        catch{
            return null
        }
    };
}

export default Footer;