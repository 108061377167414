import React, { Component } from 'react';

class Pagenotfound extends Component {
  constructor(props) {
      super(props)
  }
  

  componentDidMount(){
    window.location='/home'
  }
  render() {
    return null
  }

}

export default Pagenotfound;
