import React from "react";

const FilterComponent = ({ filterText, onFilter, placeholdertxt }) => (
  <>
    <div class="data-table-extensions fieldcss"> 
        <div class="data-table-extensions-filter"> 
            <label for="filterDataTable" class="icon handtouch"></label>
            <input
                id="filterDataTable"
                name="filterDataTable"
                type="text"
                class="filter-text"
                placeholder={placeholdertxt}
                value={filterText}
                onChange={onFilter}
            />
        </div>
    </div>
  </>
);

export default FilterComponent;
