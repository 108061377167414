import React, { Component } from 'react';
import { Row, Container, Col, Form  } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { validate } from '../../helpers/Validationfuncs';
import Zoom from 'react-reveal/Zoom';
import Header from './Header.js';
import { store } from '../../redux/store';
import { NavLink } from 'react-router-dom';
import * as loadedcontent from '../loadcontent';
import Regist from './Registo.js';
import Loader from "../../helpers/Loader";
import { ReactComponent as Logopt } from '../../assets/images/logoPt.svg';
import { ReactComponent as Logoen } from '../../assets/images/logoEn.svg';

class Passwordrecover extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
        keymessageout: "",
        classkeymessageout: "",
        loading: false,
        username: '',
        password: '',
        isShowing: false,
        passrecover: false,
        validuserpass: true,
        formvalidation: {
          "formsubmited": false, "password": [ false , "", ["noempty","password"] ], "retypepassword":  [ false , "", ["noempty", "password"] ], "email":  [ false , "", ["noempty", "email"] ]
        },
        formvalues: { 
          "password": "", "retypepassword": "", "email": ""
        },
    };
  }

  handleChange(event) {
    let fvalues = this.state.formvalues;
    fvalues[event.target.name] = event.target.value;
    this.setState({ formvalues: fvalues });
    //console.log( this.state.formvalues );
  }

  async handleSubmit(event) {
    event.preventDefault();
    let tdata = this.state.formvalues; 
    var tdataform = this.state.formvalidation; //VALIDATION
    let tmpvalidate = validate( tdataform, tdata, '',
      [
        this.props.gcontent.data.traducoes['field_required']['palavra_traducao'],
        this.props.gcontent.data.traducoes['min_char']['palavra_traducao'],
        this.props.gcontent.data.traducoes['spaces_nallowed']['palavra_traducao'],
        this.props.gcontent.data.traducoes['min8_char']['palavra_traducao'],
        this.props.gcontent.data.traducoes['psw_rule']['palavra_traducao'],
        this.props.gcontent.data.traducoes['only_number']['palavra_traducao'],
        this.props.gcontent.data.traducoes['invalid_email']['palavra_traducao'],
      ]
    ); //return [validform, tdataform, tdata ];
    this.setState({ formvalidation: tmpvalidate[1] });
    if(tdata["password"] != tdata["retypepassword"]){
        this.setState({ classkeymessageout: "error-out" , keymessageout:  "mismatchpasswords" });
    }else if( tmpvalidate[0] == true ){
        //------------------------------------------
        this.setState({ loading: !this.state.loading });
        setTimeout(
          async function() {
            let recoverpass = await loadedcontent.global_postaction("actionPasswordrecover", { "email" : this.state.formvalues["email"], "password": this.state.formvalues["password"], "chave": this.props.match.params.id }, 3);
            if( recoverpass["success"] != "" ){ 
              this.setState({ classkeymessageout: "success-out" , keymessageout: recoverpass["success"] });
              this.setState({ validuserpass: false });
            }else{
              this.setState({ classkeymessageout: "error-out" , keymessageout: recoverpass["error"] });
              this.setState({ validuserpass: false });
            }
            this.setState({ loading: !this.state.loading });
          }
          .bind(this),
          500
        );  
    }
  }

  openModalHandler = () => {
    this.setState({
      isShowing: true
    });
  }

  forgotpassword = () => {
    this.clearstate();
    this.setState({
      passrecover: !this.state.passrecover
    });
  }

  closeModalHandler = () => {
    this.setState({
      isShowing: false
    });
  }

  componentDidMount(){
  }
  
  render() {  
      return (
        <>
          <div className="homeBG">
            {this.state.loading == true &&
              <Loader />
            }
            <Header linguafunc={this.props.linguafunc} osperfis={this.props.osperfis} oestado={this.props.oestado} gcontent={this.props.gcontent} authorized ="login" theheader="" />
              <Container fluid className="loginContainer">
                <Zoom>
                  <div className="home-loginForm">
                      <Row className="m-0">
                          <Col className="centercontent p-0">
                              <Form className="loginForm" onSubmit={this.handleSubmit}>
                                <Form.Group controlId="formBasicLoginaccount">
                                  <span className="icon-login-secure textViolet"></span>
                                  <h3 className="loginTitle">{this.props.oestado.var_globalcontent["data"]["traducoes"]['psw_recovery']['palavra_traducao']}</h3>
                                </Form.Group>
                                  {this.state.validuserpass == false && 
                                    <span class={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                                  }
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control type="text" value={this.state.value} onChange={this.handleChange}  name="email" placeholder="Email" />
                                    {this.state.formvalidation["email"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                        <span className='error'>{this.state.formvalidation["email"][1]}</span>
                                    }
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control type="password" onChange={this.handleChange} name="password" placeholder={this.props.oestado.var_globalcontent["data"]["traducoes"]['psw_new']['palavra_traducao']} />
                                    {this.state.formvalidation["password"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                        <span className='error'>{this.state.formvalidation["password"][1]}</span>
                                    }
                                </Form.Group>

                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control type="password" onChange={this.handleChange} name="retypepassword" placeholder={this.props.oestado.var_globalcontent["data"]["traducoes"]['psw_retype']['palavra_traducao']}/>
                                    {this.state.formvalidation["retypepassword"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                        <span className='error'>{this.state.formvalidation["retypepassword"][1]}</span>
                                    }
                                </Form.Group>

                                <button name="signinsubmit" type="submit">
                                    {this.props.oestado.var_globalcontent["data"]["traducoes"]['submit']['palavra_traducao']}
                                </button>

                                <Form.Group controlId="formBasicLink" className="formLinks">
                                    <NavLink to="/home">Fazer login</NavLink>
                                </Form.Group>

                                <Form.Group controlId="formBasicLinknotmember" className="formLinks">
                                  {this.props.oestado.var_globalcontent["data"]["traducoes"]['not_member']['palavra_traducao']} 
                                  <span className='linkBtn' onClick={this.openModalHandler}>
                                      <u>{this.props.oestado.var_globalcontent["data"]["traducoes"]['regist_now']['palavra_traducao']}</u>
                                  </span>
                                </Form.Group>
                                 
                              </Form>
                          </Col>
                      </Row>
                  </div>
                </Zoom>
              </Container> 
            
            <Modal className="modal modalRegist p-0" show={this.state.isShowing} close={this.closeModalHandler}>
              <Container className="registHeader">
                <div className="ata_logo modalHead">  
                  <NavLink to="/">
                      <div className="ata_logo white">{store.getState()["getlang"]["idlingua"] == 'pt' ? <Logopt/> : <Logoen/>}</div>
                  </NavLink>
                </div>
                <div className="registHeader-close" onClick={this.closeModalHandler}>
                  <span className="icon-close-icon"></span> Close
                </div>
              </Container>
              <Regist oestado={this.props.oestado} gcontent={this.props.oestado.var_globalcontent} />
            </Modal>
          </div>
        </>
      );
  };
}

export default Passwordrecover;
