
//var user = process.env.REACT_APP_API_USER
//var pass = process.env.REACT_APP_API_PASS

export async function load_globalcontent( language ){
    try {
        const response = await fetch(global.api+"?globalcontent=ok&lang="+language,{ 
              method: 'GET',
              headers: {
                "Authorization": 'Basic ' + btoa(global.param1 + ':' + global.param2)
              }
        });
        const json = await response.json();
        return json;
    } catch (error) {
        return error;   
    }
};

export async function load_qvars(){
    try {
        const formData = new FormData();
        formData.append('ajquery', 'gtdt')
        const requestOptions = {
            method: 'POST',
            body: formData
        };
        const response = await fetch( global.api+"k-scrt.php" , requestOptions );
        const data = await response.json();
        return data;
    } catch (error) {
        return error;   
    }
};

export async function upload_file( ofile,type ){
    try {

        const formData = new FormData();
        formData.append('ficheiro', ofile[0])
        formData.append('type', type)
        const requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": 'Basic ' + btoa(global.param1 + ':' + global.param2)
            },
            body: formData
        };
        const response = await fetch( global.url+'ata_file_upload.php' , requestOptions );
        const data = await response.json();
        return data[0];
    } catch (error) {
        //return error;   
        return false;
    }
};


export async function load_profiles( language ){
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Basic ' + btoa(global.param1 + ':' + global.param2));
        var raw = JSON.stringify({"method":"actionPerfis","action":0,"data":{"ididioma":language}});
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        const response = await fetch(global.api, requestOptions)

        const json = await response.json();
        return json;
    } catch (error) {
        return error;   
    }
};

export async function global_postaction( metodo, dados, accao, file ){
    try {
        //const fileData = new FormData();
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", 'Basic ' + btoa(global.param1 + ':' + global.param2));
        var raw = JSON.stringify({ "method": metodo, "action":accao, "data":dados });
        /* 
            if (file!=null){
                fileData.append('ficheiro',file[0])
                raw = JSON.stringify({ "method": metodo, "action":accao, "data":dados,"ficheiro": fileData})
            }
        */
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
            processData: false,
            contentType: false
        };
        const response = await fetch(global.api, requestOptions);
        const json = await response.json();
        return json;
    } catch (error) {
        return error;   
    }
};
