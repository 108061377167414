import React, { Component } from 'react';
import { store } from '../redux/store';
import flagpt from "../images/flags/pt.png";
import flagen from "../images/flags/en.png";

class langSwitch extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(element) {
        this.props.linguafunc(element);
    }
    render() {
        return (
            <div className="lang-select">
                <ul className="ul-lang-select">
                    {this.props.gcontent["data"]["idiomas"] != null && Object.keys(this.props.gcontent["data"]["idiomas"]).map((item, i) => (
                        <li className={this.props.gcontent["data"]["idiomas"][item]["codigo"] == store.getState()["getlang"]["idlingua"] ? "langItem active" : "langItem"} key={i} onClick={() => this.handleClick( this.props.gcontent["data"]["idiomas"][item]["codigo"])}>
                        {this.props.gcontent["data"]["idiomas"][item]["codigo"] == "pt" ? (
                            <img src={flagpt} width="40" alt={this.props.gcontent["data"]["idiomas"][item]["nome_idioma"]}/>
                        ) : (
                            <img src={flagen} width="40" alt={this.props.gcontent["data"]["idiomas"][item]["nome_idioma"]}/>
                        )}
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}
export default langSwitch;
