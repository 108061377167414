import React, { Component } from 'react';
import { Row, Container, Col, Button, Form  } from 'react-bootstrap';
import Header from './Header';
import Usermenu from '../Usermenu';
import { store } from '../../redux/store';
import { Redirect } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs.js';
import * as loadedcontent from '../loadcontent';
import { validate } from '../../helpers/Validationfuncs';
import { security } from '../../helpers/Security';
import parse from "html-react-parser";
import MyStatefulEditor from "../../helpers/MyStatefulEditor";
//-----------------------------------------
import Lottie from 'lottie-react-web';
import respok from '../../assets/response_ok.json';
import resperror from '../../assets/response_error.json';
import Loader from "../../helpers/Loader";

class Politicaetermos extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            redirect: security(),
            stateadmin: ( store.getState()["getatauser"] != "")? store.getState()["getatauser"]["userdata"]["admin"] : 0,
            keymessageout: "",
            classkeymessageout: "",
            defaultlang: "pt",
            loading: false,
            validuserpass: true,
            formvalidation: {
                "formsubmited": false, "politica": [ false , "", ["noempty"] ], "termos":  [ false , "", ["noempty"] ], "cookies":  [ false , "", ["noempty"] ], "registo":  [ false , "", ["noempty"] ]
            },
            formvalues: { 
                "politica": "", "termos": "", "cookies": "", "registo": ""
            },
        };
        //---------------Load Langs-----------------------------
        for (let levelOneKey in this.props.gcontent["data"]["politicasetermos"]){
            if( this.props.gcontent["data"]["politicasetermos"][levelOneKey]["ididiomas"] == this.state.defaultlang ){
                this.state.formvalues["politica"] = this.props.gcontent["data"]["politicasetermos"][levelOneKey]["politica"];
                this.state.formvalues["termos"] = this.props.gcontent["data"]["politicasetermos"][levelOneKey]["termos"];
                this.state.formvalues["cookies"] = this.props.gcontent["data"]["politicasetermos"][levelOneKey]["cookies"];
                this.state.formvalues["registo"] = this.props.gcontent["data"]["politicasetermos"][levelOneKey]["registo"];
            }
        }
        //--------------------------------------------
    }
 
    
    handleChange(event) {
        if (event.target.name == "formlang") {
            this.setState({ defaultlang: event.target.value });
            let _tmp = this.state.formvalues;
            for (let levelOneKey in this.props.gcontent["data"]["politicasetermos"]){
                if( this.props.gcontent["data"]["politicasetermos"][levelOneKey]["ididiomas"] == event.target.value ){
                    _tmp["politica"] = this.props.gcontent["data"]["politicasetermos"][levelOneKey]["politica"];
                    _tmp["termos"] = this.props.gcontent["data"]["politicasetermos"][levelOneKey]["termos"];
                    _tmp["cookies"] = this.props.gcontent["data"]["politicasetermos"][levelOneKey]["cookies"];
                    _tmp["registo"] = this.props.gcontent["data"]["politicasetermos"][levelOneKey]["registo"];
                }
            }
            this.setState({formvalues: _tmp});
        }
        /*if(event.target.name == "formpolitica"){
            let _tmp = this.state.formvalues;
            _tmp["politica"] = event.target.value;  
            this.setState({formvalues: _tmp});
        }
        if(event.target.name == "formtermos"){
            let _tmp = this.state.formvalues;
            _tmp["termos"] = event.target.value;  
            this.setState({formvalues: _tmp});
        }
        if(event.target.name == "formcookies"){
            let _tmp = this.state.formvalues;
            _tmp["cookies"] = event.target.value;  
            this.setState({formvalues: _tmp});
        }
        if(event.target.name == "formregisto"){
            let _tmp = this.state.formvalues;
            _tmp["registo"] = event.target.value;  
            this.setState({formvalues: _tmp});
        }*/
    }

    cleanContent() {
        this.setState({
            validuserpass: true
        })
    }

    handleSubmit(event) {
        event.preventDefault();
        let tdata = this.state.formvalues; 
        var tdataform = this.state.formvalidation; //VALIDATION
        let tmpvalidate = validate( tdataform, tdata, '',
            [
                this.props.gcontent.data.traducoes['field_required']['palavra_traducao'],
                this.props.gcontent.data.traducoes['min_char']['palavra_traducao'],
                this.props.gcontent.data.traducoes['spaces_nallowed']['palavra_traducao'],
                this.props.gcontent.data.traducoes['min8_char']['palavra_traducao'],
                this.props.gcontent.data.traducoes['psw_rule']['palavra_traducao'],
                this.props.gcontent.data.traducoes['only_number']['palavra_traducao'],
                this.props.gcontent.data.traducoes['invalid_email']['palavra_traducao'],
            ]
        ); //return [validform, tdataform, tdata ];
        this.setState({ formvalidation: tmpvalidate[1] });
        if( tmpvalidate[0] == true){
            for (let levelOneKey in this.props.gcontent["data"]["politicasetermos"]){
                if( this.props.gcontent["data"]["politicasetermos"][levelOneKey]["ididiomas"] == this.state.defaultlang ){
                    this.props.gcontent["data"]["politicasetermos"][levelOneKey]["politica"] = this.state.formvalues["politica"];
                    this.props.gcontent["data"]["politicasetermos"][levelOneKey]["termos"] = this.state.formvalues["termos"];
                    this.props.gcontent["data"]["politicasetermos"][levelOneKey]["cookies"] = this.state.formvalues["cookies"];
                    this.props.gcontent["data"]["politicasetermos"][levelOneKey]["registo"] = this.state.formvalues["registo"];
                }
            }
            //------------------------------------------
            this.setState({ loading: !this.state.loading });
            setTimeout(
                async function() {
                    var tdata = { lang: this.state.defaultlang, politica: this.state.formvalues["politica"], termos: this.state.formvalues["termos"], cookies: this.state.formvalues["cookies"], registo: this.state.formvalues["registo"] };
                    let crud = await loadedcontent.global_postaction( "actionPolitics", tdata, 3);
                    if( crud["success"] != "" ){ 
                        this.setState({ classkeymessageout: "success-out" , keymessageout: crud["success"] });
                        this.setState({ validuserpass: false });
                      }else{
                        this.setState({ classkeymessageout: "error-out" , keymessageout: crud["error"] });
                        this.setState({ validuserpass: false });
                      }
                    this.setState({ loading: !this.state.loading });
                    setTimeout(() => {
                        this.setState({ validuserpass: !this.state.validuserpass })
                    },2000);
                }
                .bind(this),
                500
            );
        }
    }

    

  render() {
    if( this.state.redirect == true ){
        return <Redirect to={"/"} />
    }else if(store.getState()["getatauser"]["userdata"]["admin"] != 1){ 
        return <Redirect to="/" /> 
    }
    return (
        <>
            {this.state.loading == true && <Loader /> }
            {this.state.validuserpass == false && 
                <div class="preload-stuff">
                    <div class="position-center">
                        <div class="message-box responseOutput">
                            <div className="responseOutput_gif">
                                <Lottie options={{ animationData: (this.state.classkeymessageout == "success-out")? respok : resperror , loop: false }} />
                            </div>
                            <span class={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
                        </div>
                    </div>
                </div>
                //<span class={this.state.classkeymessageout}>{this.props.oestado.var_globalcontent["data"]["traducoes"][this.state.keymessageout]['palavra_traducao']} </span>
            }
            <Header className="default" linguafunc={this.props.linguafunc} oestado={this.props.oestado} gcontent={this.props.gcontent} osperfis={this.props.osperfis} osidiomas={this.props.osidiomas} theheader="default" />
            <Container className="content contentPage">
                <Breadcrumbs {...this.props} gcontent={this.props.gcontent}/>
                <div className="titlePage">{this.props.gcontent["data"]["traducoes"]["hi"]["palavra_traducao"]}, <b>{store.getState()["getatauser"]["userdata"]["nome"]}</b></div>
                {this.state.stateadmin != 1 && 
                    <Row>
                        <Col className="centercontent">
                            {this.props.gcontent["data"]["politicasetermos"][this.props.oestado.language] != null && this.props.tipoc == "politica" &&   
                                this.props.gcontent["data"]["politicasetermos"][this.props.oestado.language]["politica"]
                            }
                            {this.props.gcontent["data"]["politicasetermos"][this.props.oestado.language] != null && this.props.tipoc == "termos" &&   
                                this.props.gcontent["data"]["politicasetermos"][this.props.oestado.language]["termos"]
                            }
                            {this.props.gcontent["data"]["politicasetermos"][this.props.oestado.language] != null && this.props.tipoc == "cookies" &&   
                                this.props.gcontent["data"]["politicasetermos"][this.props.oestado.language]["cookies"]
                            }
                            {this.props.gcontent["data"]["politicasetermos"][this.props.oestado.language] != null && this.props.tipoc == "registo" &&   
                                this.props.gcontent["data"]["politicasetermos"][this.props.oestado.language]["registo"]
                            }
                        </Col>
                    </Row>
                }
                {this.state.stateadmin == 1 && 
                    <Row className="contentOffice">
                        <Col className="menuDrop">
                                <Usermenu gcontent={this.props.gcontent} />
                            </Col>
                        <Col>
                            <h3 className="contentTitle">{this.props.gcontent.data.traducoes['politica']['palavra_traducao'] + ' & ' + this.props.gcontent.data.traducoes['termos']['palavra_traducao']}</h3>
                            <Form className="adminForms">
                                <Form.Group className="adminLangSwitcher" controlId="exampleForm.ControlSelect">
                                    <Form.Label>{this.props.gcontent.data.traducoes['translations']['palavra_traducao']}</Form.Label>
                                    <Form.Control name="formlang" as="select" onChange={this.handleChange} >
                                        <option value="pt">{this.props.gcontent.data.traducoes['portugues']['palavra_traducao']}</option>
                                        <option value="en">{this.props.gcontent.data.traducoes['ingles']['palavra_traducao']}</option>
                                    </Form.Control>
                                </Form.Group>
                                <div><p style={{fontSize: 13, color: "#828990", fontWeight: 700, lineHeight: 1.3, marginBottom: 20, marginTop: -20}}>{this.props.gcontent.data.traducoes['saveBefore']['palavra_traducao']}</p></div>
                                {this.state.defaultlang == "pt" && 
                                    <>
                                        <Form.Group className="adminTextarea" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>{this.props.gcontent["data"]["traducoes"]['politica']['palavra_traducao']}</Form.Label>
                                            
                                            <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["politica"]} onome="formpolitica" metodo={this.handlefieldsChange} />
                                            {
                                                /*
                                                <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["politica"]} onome="formpolitica" metodo={this.handlefieldsChange} />
                                                    <Form.Control as="textarea" defaultValue={parse(this.state.formvalues["politica"])} rows={10} name="formpolitica" onChange={this.handleChange} />    
                                                */
                                            }
                                            
                                            {this.state.formvalidation["politica"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                <span className='error'>{this.state.formvalidation["politica"][1]}</span>
                                            }
                                        </Form.Group>

                                        <Form.Group className="adminTextarea" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>{this.props.gcontent["data"]["traducoes"]['termos']['palavra_traducao']}</Form.Label>
                                            <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["termos"]} onome="formtermos" metodo={this.handlefieldsChange} />
                                            
                                            {/*
                                            <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["termos"]} onome="formtermos" metodo={this.handlefieldsChange} />
                                                <Form.Control as="textarea" defaultValue={parse(this.state.formvalues["termos"])} rows={10} name="formtermos" onChange={this.handleChange} />
                                            */}
                                            
                                            {this.state.formvalidation["termos"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                <span className='error'>{this.state.formvalidation["termos"][1]}</span>
                                            }
                                        </Form.Group>

                                        <Form.Group className="adminTextarea" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>{this.props.gcontent["data"]["traducoes"]['cookielink']['palavra_traducao']}</Form.Label>
                                            
                                            <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["cookies"]} onome="formcookies" metodo={this.handlefieldsChange} /> 
                                            
                                            {/*
                                            <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["cookies"]} onome="formcookies" metodo={this.handlefieldsChange} />
                                                <Form.Control as="textarea" defaultValue={parse(this.state.formvalues["cookies"])} rows={10} name="formcookies" onChange={this.handleChange} />
                                            */}
                                            {this.state.formvalidation["cookies"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                <span className='error'>{this.state.formvalidation["cookies"][1]}</span>
                                            }
                                        </Form.Group>

                                        <Form.Group className="adminTextarea" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>{this.props.gcontent["data"]["traducoes"]['registolink']['palavra_traducao']}</Form.Label>
                                            
                                            <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["registo"]} onome="formregisto" metodo={this.handlefieldsChange} />
                                            {
                                            /*
                                            <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["registo"]} onome="formregisto" metodo={this.handlefieldsChange} />
                                                <Form.Control as="textarea" defaultValue={parse(this.state.formvalues["registo"])} rows={10} name="formregisto" onChange={this.handleChange} />    
                                            */
                                            }
                                            {this.state.formvalidation["registo"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                <span className='error'>{this.state.formvalidation["registo"][1]}</span>
                                            }
                                        </Form.Group>
                                    </>
                                }
                                {this.state.defaultlang == "en" && 
                                    <>
                                        <Form.Group className="adminTextarea" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>{this.props.gcontent["data"]["traducoes"]['politica']['palavra_traducao']}</Form.Label>
                                            <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["politica"]} onome="formpolitica" metodo={this.handlefieldsChange} /> 
                                            {
                                            /*
                                            <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["politica"]} onome="formpolitica" metodo={this.handlefieldsChange} />
                                                <Form.Control as="textarea" defaultValue={parse(this.state.formvalues["politica"])} rows={10} name="formpolitica" onChange={this.handleChange} />
                                            */
                                            } 
                                            {this.state.formvalidation["politica"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                <span className='error'>{this.state.formvalidation["politica"][1]}</span>
                                            }
                                        </Form.Group>

                                        <Form.Group className="adminTextarea" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>{this.props.gcontent["data"]["traducoes"]['termos']['palavra_traducao']}</Form.Label>
                                            
                                            <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["termos"]} onome="formtermos" metodo={this.handlefieldsChange} />

                                            {/*
                                             <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["termos"]} onome="formtermos" metodo={this.handlefieldsChange} />
                                            <Form.Control as="textarea" defaultValue={parse(this.state.formvalues["termos"])} rows={10} name="formtermos" onChange={this.handleChange} />
                                            */}
                                            
                                            {this.state.formvalidation["termos"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                <span className='error'>{this.state.formvalidation["termos"][1]}</span>
                                            }
                                        </Form.Group>

                                        <Form.Group className="adminTextarea" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>{this.props.gcontent["data"]["traducoes"]['cookielink']['palavra_traducao']}</Form.Label>
                                            <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["cookies"]} onome="formcookies" metodo={this.handlefieldsChange} />
                                            
                                            {
                                                /*
                                               
                                                    <Form.Control as="textarea" defaultValue={parse(this.state.formvalues["cookies"])} rows={10} name="formcookies" onChange={this.handleChange} />    
                                                */
                                            }

                                            {this.state.formvalidation["cookies"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                <span className='error'>{this.state.formvalidation["cookies"][1]}</span>
                                            }
                                        </Form.Group>

                                        <Form.Group className="adminTextarea" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>{this.props.gcontent["data"]["traducoes"]['registolink']['palavra_traducao']}</Form.Label>
                                            <MyStatefulEditor fvalues={this.state.formvalues} osdados={this.state.formvalues["registo"]} onome="formregisto" metodo={this.handlefieldsChange} />
                                            {
                                                /*
                                                
                                                    <Form.Control as="textarea" defaultValue={parse(this.state.formvalues["registo"])} rows={10} name="formregisto" onChange={this.handleChange} />   
                                                */
                                            }
                                            
                                            {this.state.formvalidation["registo"][0] == false && this.state.formvalidation["formsubmited"] == true && 
                                                <span className='error'>{this.state.formvalidation["registo"][1]}</span>
                                            }
                                        </Form.Group>
                                    </>
                                }
                                <Form.Group className="formSubmit">
                                    <Button variant="primary" onClick={(e) => this.handleSubmit(e)}  name="guardarsubmit" type="submit">{this.props.gcontent.data.traducoes['save_changes']['palavra_traducao']}</Button>
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                }
            </Container>
        </>
    );
  };

}

export default Politicaetermos;