import React, { Component } from 'react';
import Breadcrumbs from 'react-router-dynamic-breadcrumbs';
import { store } from "../redux/store";
import { BrowserRouter as Router, withRouter, NavLink, Link} from "react-router-dom";



class Breadcrumb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchparam: "",
            imageIsland: "",
            imageLocal: "",
            imageName: "",
        };
    }
     
    jumpData = (theisland) => {
        let params = store.getState()["getsearch"];
        if( params.searchvalues != undefined ){
            params.searchvalues["stilhas"] = theisland;
            store.dispatch({ type: "search", param: { "searchvalues" : params.searchvalues , "gosearch" : true } }); 
        }
        this.props.history.push("/pesquisa/search=&stilhas=" + theisland + "&stcategorias=0&stsubcategorias=0&")
    };

   customBread(){
    const routesList = {
        '/': 'Home',
        '/home': 'Home',
        '/redessociais': this.props.gcontent['data']['traducoes']['myaccount']['palavra_traducao'],
        '/traducoes': this.props.gcontent['data']['traducoes']['myaccount']['palavra_traducao'],
        '/politicaetermos': this.props.gcontent['data']['traducoes']['myaccount']['palavra_traducao'],
        '/categorias': this.props.gcontent['data']['traducoes']['myaccount']['palavra_traducao'],
        '/sobre': this.props.gcontent['data']['traducoes']['myaccount']['palavra_traducao'],
        '/utilizadores': this.props.gcontent['data']['traducoes']['myaccount']['palavra_traducao'],
        '/upload': this.props.gcontent['data']['traducoes']['myaccount']['palavra_traducao'],
        '/editarperfil': this.props.gcontent['data']['traducoes']['myaccount']['palavra_traducao'],
        '/permissoes': this.props.gcontent['data']['traducoes']['myaccount']['palavra_traducao'],
        '/perfis': this.props.gcontent['data']['traducoes']['myaccount']['palavra_traducao'],
        '/pesquisa': this.props.gcontent['data']['traducoes']['search']['palavra_traducao'],
        '/mylist': this.props.gcontent["data"]["traducoes"]['myaccount']['palavra_traducao'],
        '/gostos': this.props.gcontent["data"]["traducoes"]['myaccount']['palavra_traducao'],
        '/prevdownloads': this.props.gcontent['data']['traducoes']['myaccount']['palavra_traducao'],
        '/notificacoes': this.props.gcontent['data']['traducoes']['myaccount']['palavra_traducao'],
        '/license': this.props.gcontent['data']['traducoes']['license']['palavra_traducao'] + ' - ' + this.state.imageName,
        '/page': this.state.imageIsland,
        '/page/:id': this.state.imageLocal,
        '/termos':this.props.gcontent["data"]["traducoes"]['termos']['palavra_traducao'],
        '/politicas':this.props.gcontent["data"]["traducoes"]['politica']['palavra_traducao'],
        '/cookies':this.props.gcontent["data"]["traducoes"]['cookielink']['palavra_traducao'],
        '/definicoes':this.props.gcontent["data"]["traducoes"]['definicoes']['palavra_traducao'],
        
    }


       if(this.props.location != undefined){
            const location = this.props.location.pathname
            let isPage = location.split('/')[1]          
            if (isPage=='page'){
         
                let contentBread= this.props.pageImg
                return (
                    <ol className="breadcrumb">{this.props.children}
                        <li className="breadcrumb-item" 
                            onClick={ (e) => {this.props.history.push("/home")}}>
                            Home
                        </li>
                        <li className="breadcrumb-item active" 
                            onClick={ (e) =>{ this.jumpData(contentBread.ilha) }}>
                            {contentBread.nome_ilha}
                        </li>
                        {this.props.pageImg.localidade && 
                            <li className="breadcrumb-item" >
                                {this.props.pageImg.localidade}
                            </li>
                        }
                    </ol>
                )
            }
           
           
        }
        else{
            
            return (
                <Breadcrumbs 
                    WrapperComponent={(props) => <ol className="breadcrumb">{props.children}</ol>}
                    ActiveLinkComponent={(props) => <li className="breadcrumb-item active" >{( this.props.searchfield != null && this.props.searchfield != undefined )? this.state.searchparam : props.children }</li>}
                    LinkComponent={(props) => <li className="breadcrumb-item">{props.children}</li>} 
                    mappedRoutes={routesList}
                /> 
                )

        }
     
   } 
    componentDidMount(){
       

        if( this.props.searchfield != null && this.props.searchfield != undefined ){
            var splitparams = decodeURI(this.props.searchfield).split("&");
          
            let _vals = this.state.formvalues;
            for(let f = 0; f < splitparams.length; f++){
                var _tmp = splitparams[f].split("=");
                if( _tmp[0] != null || _tmp[0] != undefined ){
                    if( _tmp[0].trim() == "search" ){
                        this.setState({ searchparam: _tmp[1] });
                        break;
                    }
                }
            } 
        }

        if( this.props.pageImg != null && this.props.pageImg != undefined ){
            this.setState({ 
                imageIsland: this.props.gcontent['data']['ilhas'][this.props.pageImg.ilha]['nome_ilha'],
                imageLocal: this.props.pageImg.localidade
            });
        }
        
        if( this.props.pageLicense != null && this.props.pageLicense != undefined ){
            this.setState({ 
                imageName: this.props.pageLicense['titulo']
            });
        }
    }
    render() { 
        return (
            <>
                <div className="breadcrumbWrapper">
                    {this. customBread()}                 
                </div>
            </>
        );
    };

}

export default Breadcrumb;
