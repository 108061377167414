import React, { Component } from "react";
import Select, { components } from "react-select";
import countryList from "react-select-country-list";

export function CountryFlag(props) {
  return (
    <span
        style={{ backgroundImage: 'url("/assets/flags/'+props.code.toUpperCase()+'.png")', width:30, height: 15, backgroundRepeat:"no-repeat", backgroundSize: "contain", marginRight:10
        }}
    />
  );
}

export const CountryFlagSelectOption = props => {
  return (
    <components.Option {...props}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <CountryFlag size={props.flagSize} code={props.value.toLowerCase()} />
        {props.label}
      </div>
    </components.Option>
  );
};

export const CountryFlagValueContainer = ({ children, ...props }) => {
  const code = (props.hasValue && props.getValue()[0].value) || false;

  return (
    <div style={{ display: "flex", flexGrow: 1 }}>
      {(code && <CountryFlag code={code.toLowerCase()} />) || null}
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    </div>
  );
};

const styles = {
  valueContainer: (base, state) => {
    const height = "60px";
    return { ...base, height };
  }
};

export default class CountrySelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: countryList().getData(),
      value: null
    };
  }

  changeHandler = value => {
    this.props.changCk(value);
    this.setState({ value });
  };

    render() {
        return (
          <>
          {this.props.showOnlySelected ? 
            <div style={styles}>
                <div className="countryContainer showOnlySelected">
                  <div className="countryControl showOnlySelected">
                    { this.props.getc(this.state.value) != null ?
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ backgroundImage: 'url("/assets/flags/'+ (this.props.getc(this.state.value).value).toUpperCase() +'.png")', width:30, height: 15, backgroundRepeat:"no-repeat", backgroundSize: "contain", marginRight:10 }} />
                        <div className="ValueContainer showOnlySelected" style={{ display: "flex", alignItems: "center" }}>
                          <div className="singleValue showOnlySelected">{this.props.getc(this.state.value).label}</div>
                        </div>
                      </div> : null }
                  </div>
                </div>
            </div> : 
              <Select
                  styles={styles}
                  options={this.state.options}
                  value={ ( this.props.testing != "" )? this.props.getc(this.state.value) : "" }
                  onChange={this.changeHandler}
                  components={{
                    Option: CountryFlagSelectOption,
                    ValueContainer: CountryFlagValueContainer
                  }}
                  placeholder={this.props.place}
              />
            }
          </>
        );
    }
}