import React, { Component, useState } from 'react';
import { Container } from 'react-bootstrap';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import { Modal, ModalGateway } from "react-images";
import * as loadedcontent from '../components/loadcontent';

class btnLightbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            favorite: false,
        };
    }
    favorite = (event) => {
        //console.log(this.props.idPhoto);
        this.setState({
            favorite: true
        });

        /*
            method: actionFavoritos
            data: idfile,idregisto
            action:1        
        */
    };
    render() {
        //console.log(this.props.dataFav);
        return (
            <>
                {/*<button onClick={this.favorite}>{!this.state.favorite ? (<span className="icon-image-favorite"></span>) : (<span className="icon-image-favorite-active"></span>)}</button>*/}
            </>
        )
    };  
}
export default btnLightbox;
